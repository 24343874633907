import React, { useState, useEffect } from "react";
import { Grid, Typography, Box, FormHelperText, Paper } from "@material-ui/core";
import { useWatch, useFormState, useFormContext } from "react-hook-form";
import {Input, Select} from "components/react-hook-form";
import Aux from "../../hoc/auxiliar";

// helpers
import { phoneMask } from "../../helpers/masks";
import { makeSelectOptions } from "../../helpers/makeSelectOptions";
import { orderByName } from "../../helpers/functions";

// domain
import { brandListOptions } from "../../domain/selectOptions";

// skeleton
import FormDeviceSkeleton from "../Skeletons/FormDeviceSkeleton";

import useStyles from "./styles";
import { getRoleDeviceStatus, statusOptions } from "../../helpers/deviceStatus";

const DeviceForm = (props) => {
  const { status } = props
  const classes = useStyles();
  const [ listBrands, setListBrands ] = useState(null);
  const { control, setValue } = useFormContext();
  const { errors } = useFormState({ control });

  const fetchBrandsList = () => {
    const options = makeSelectOptions({
      data: brandListOptions,
      keyLabel: "name",
      keyValue: "id",
    });
    const brandsOptions = orderByName(options);
    setListBrands(brandsOptions);
  };

  const simcard = useWatch({ control, name: "simcard" });

  useEffect(() => {
    if (simcard) {
      setValue("simcard", phoneMask(simcard));
    }
  }, [simcard]);

  useEffect(() => {
    fetchBrandsList();
  }, []);

  const getSelectDisabled = () => {
    if(props.isEdit && status === 5) return true
  }

  if(!listBrands) return <FormDeviceSkeleton />;

  return (
    <Aux>
      <Paper elevation={0} className={classes.container}>
        <Typography className={classes.title}>Dados Gerais:</Typography>
        <Grid container spacing={2}>
          <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
            <Box sx={{ m: 2 }}>
              <Select
                options={listBrands}
                name="brand"
                placeholder="Marca*"
              />
              <FormHelperText className={classes.formHelperText} error={true}>
                {errors.brand?.message}
              </FormHelperText>
            </Box>
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
            <Box sx={{ m: 2 }}>
              <Input
                name="identification"
                variant="outlined"
                fullWidth
                label="ID*"
                helperText={errors.identification?.message}
                error={errors.identification && true}
              />
            </Box>
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
            <Box sx={{ m: 2 }}>
              <Input
                name="simcard"
                variant="outlined"
                fullWidth
                label="SIM Card*"
                helperText={errors.simcard?.message}
                error={errors.simcard && true}
              />
            </Box>
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
            <Box sx={{ m: 2 }}>
              <Select
                isDisabled={getSelectDisabled()}
                options={props.isEdit ? getRoleDeviceStatus(status) : statusOptions.register}
                name="status"
                placeholder="Selecione o status*"
              />
              <FormHelperText className={classes.formHelperText} error={true}>
                {errors.status?.message}
              </FormHelperText>
            </Box>
          </Grid>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Box sx={{ mt: 4 }}>
            <Typography className={classes.explanation}>*Os campos que estão marcados com o asterisco(*) são os que precisam obrigatoriamente serem preenchidos.</Typography>
          </Box>
        </Grid>
      </Paper>
    </Aux>
  );
};

export default DeviceForm;
