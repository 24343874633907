import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop';
import { useFormContext, Controller } from "react-hook-form";
import classNames from 'classnames';
import { permissions } from 'helpers/permissions';
import useStyles from './styles';

const PermissionForm = ({formData}) => {
  const classes = useStyles();
  const { control, formState: { errors }, setValue } = useFormContext();
  const [checked, setChecked] = useState([0]);
  const [expanded, setExpanded] = useState(false);

  const removePermission = (value, list) => {

    let index = list.findIndex(item => item === value)
    index === -1 || list.splice(index, 1)

    if(value.includes('can_')) {
      setValue(`permissions[${value.toString()}]`, false);
    }
    return list

  }

  const addPermission = (value, list) => {

    const currentIndex = list.indexOf(value);

    if (currentIndex === -1) {
      list.push(value);
    }

    if(value.includes('can_')) {
      setValue(`permissions[${value.toString()}]`, true);
    }

    return list;
  }

  const togglePermissions = (value, list) => {

    const currentIndex = list.indexOf(value);

    if (currentIndex === -1) {
      list.push(value);
    } else {
      list.splice(currentIndex, 1);
    }

    return list;
  }

  const handleToggleParent = (value) => () => {

    let parent = permissions.find(h => h.id === value)

    const currentIndex = checked.indexOf(value)

    const action = currentIndex === -1
      ? (value, list) => addPermission(value, list)
      : (value, list) => removePermission(value, list)

    let newChecked = togglePermissions(value, [...checked])

    if (!parent) {
      permissions.forEach(p => {

        if (!p.children) return

        let search = p.children.find(c => c.id === value)

        if (!search) return

        parent = search;
        // desmarcando segundo nível, desmarcar pai
        if (currentIndex >= 0) {
          newChecked = removePermission(p.id, newChecked)
        }
        else {

          let allChecked = true;
          p.children.forEach(p => {
            let index = newChecked.indexOf(p.id)

            if (index === -1) { allChecked = false }

          })

          if (allChecked) {
            newChecked = addPermission(p.id, newChecked)
            return
          }
          newChecked = removePermission(p.id, newChecked)
        }
      })
    }

    if (!parent) {
      setChecked(newChecked);
      return
    }

    if (!parent.children) {
      parent.permissions.forEach(p => {
        newChecked = action(p.id, newChecked);
      })
      setChecked(newChecked);
      return
    }

    parent.children.forEach(child => {
      newChecked = action(child.id, newChecked);

      if (!child.children) {
        child.permissions.forEach(p => {
          newChecked = action(p.id, newChecked);
        })
      }
    })
    setChecked(newChecked);
  }

  const handleToggle = (value) => {

    let newChecked = togglePermissions(value, [...checked]);
    
    let grandfather;
    let parent;
    let children
    permissions.forEach(p => {

      if (!p.children) {
        parent = p.id;
        children = p.permissions.find(j => j.id === value)

        // element found first level
        if (children) {
          // verificar se todos os p.permissions estão na lista checked list
          let allChecked = true;
          p.permissions.forEach((perm) => { if (newChecked.findIndex(item => perm.id === item) === -1) { allChecked = false } })

          // se tiver marcar o parent como checked senão desmarcar
          if (allChecked) {
            newChecked.push(parent);
          } else {
            newChecked = removePermission(parent, newChecked);
          }
        }
        return;
      }

      p.children.forEach(l => {
        if (!l.children) {
          grandfather = p.id
          parent = l.id
          children = l.permissions.find(h => h.id === value)

          // element found second level
          if (children) {
            // verificar se todos os l.permissions estão na lista checked list
            let allChecked = true;
            l.permissions.forEach((perm) => { if (newChecked.findIndex(item => perm.id === item) === -1) { allChecked = false } })

            // se tiver marcar o parent como checked senão desmarcar
            if (allChecked) {
              newChecked.push(parent);

              // verificar se todos os parents estão marcados, se sim marcar o grandparent
              let grandchildren = permissions.find(el => el.id === grandfather);

              let grandChildrenAllChecked = true;

              grandchildren.children.forEach(child => {
                let childIndex = newChecked.findIndex(item => item === child.id);
                if (childIndex === -1) { grandChildrenAllChecked = false; }
              })

              if (grandChildrenAllChecked) {
                newChecked.push(grandfather);
              }

            } else {
              newChecked = removePermission(parent, newChecked)
              newChecked = removePermission(grandfather, newChecked)
            }
          }
          return;
        }
      })
    })

    setChecked(newChecked);
  };

  useEffect(() => {
    if(formData) {
      setValue("name", formData[0].name);
      formData.forEach((item) => {
        setValue(`permissions[${item.permission}]`, true);
        handleToggle(item.permission);
      });
    }
  }, [formData, setValue]);
  
  const PermissionItem = (item) => {
    return (
      <ListItem 
        role={undefined} 
        dense 
        button 
        onClick={() => handleToggle(item.id)}
      >
        <ListItemIcon>
          <FormControlLabel
            control={
              <Controller
                render={(props) => {
                  const { ref, ...fieldProps } = props.field;
                  return (
                    <Checkbox
                      {...fieldProps}
                      edge='start'
                      tabIndex={-1}
                      disableRipple
                      checked={fieldProps.value || checked.indexOf(item.id) !== -1}
                      value={fieldProps.value}
                      inputProps={{ 'aria-labelledby': item.id }}
                    />
                  )
                }}
                name={`permissions[${item.id}]`}
                type="checkbox"
                control={control}
              />
            }
          />
        </ListItemIcon>
        <ListItemText id={item.id} primary={item.title} />
      </ListItem>
    )
  };

  const Summary = (item) => (
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-label='Expandir'
      aria-controls={`${item.id}-content`}
      id={`${item.id}-header`}
    >
      <FormControlLabel
        aria-label={`${item.id}`}
        onClick={(event) => event.stopPropagation()}
        onFocus={(event) => event.stopPropagation()}
        onChange={handleToggleParent(item.id)}
        control={
          <Checkbox
            edge='start'
            checked={checked.indexOf(item.id) !== -1}
            tabIndex={-1}
            disableRipple
            inputProps={{ 'aria-labelledby': item.id }}
          />
        }
        label={<Typography className={classNames(classes.heading, {
          [classes.subheading]: item.subheading
        })}>{item.title}</Typography>}
      />
    </AccordionSummary>
  );

  return (
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
      {/* TODO - fazer funcionar */}
      {/* <Grid container direction="row-reverse">
        <Button
          color="default"
          variant="outlined"
          startIcon={<VerticalAlignTopIcon />}
          onClick={() => setExpanded(false)}
        >
          Recolher Tudo
        </Button>
        <Button
          color="default"
          variant="outlined"
          startIcon={<VerticalAlignBottomIcon />}
          onClick={() => setExpanded(true)}
        >
          Expandir Tudo
        </Button>
      </Grid> */}
      <Box sx={{ m: 2 }}>
        <div className={classNames(classes.rootAccordion, classes.fullWidth)}>
          {errors && errors.permissions && (
            <Typography className={classes.errorMessage}>{errors.permissions.message}</Typography>
          )}
          {permissions.map(value => {
            return (
              <Accordion
                key={value.id}
              >
                <Summary {...value} />
                <AccordionDetails>
                  {value.permissions.length > 0 &&
                    value.permissions.map(item => <PermissionItem key={item.id} {...item} />)
                  }
                  {value.children && value.children.length > 0 && (
                    <div className={classNames(classes.rootAccordion, classes.fullWidth)}>
                      {value.children.map(item => {
                        return (
                          <Accordion
                            key={item.id}
                            className={classNames(classes.fullWidth, classes.submenu)}
                          >
                            <Summary {...item} subheading />
                            <AccordionDetails>
                              <List className={classes.fullWidth}>
                                {item.permissions.length > 0 &&
                                  item.permissions.map(elm => <PermissionItem key={elm.id} {...elm} />)
                                }
                              </List>
                            </AccordionDetails>
                          </Accordion>
                        )
                      })}
                    </div>
                  )}
                </AccordionDetails>
              </Accordion>
            )
          })}
        </div>
      </Box>
    </Grid>
  );
};

export default PermissionForm;
