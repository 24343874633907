import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { useForm, FormProvider, useFormState } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

// components
import Aux from "hoc/auxiliar";
import RoleForm from 'components/RoleForm';
import BackButton from "components/BackButton";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";

// helpers
import makeDefaultValues from "helpers/makeDefaultValues";
import { permissionsDefaultValues } from 'helpers/permissions';

// domain
import fields from 'domain/forms/formRoleInitialValues';
import schemaValidation from 'domain/validation/yup/formRoleValidation';

// services
import { createRole, getRoleById, deleteRoleById, getUsersRoles, updateUserRole } from 'services/roles';

// model
import RoleModel from 'models/Role';
import UserRoleModel from 'models/UserRole';

// style
import useStyles from "./styles";
import "react-datepicker/dist/react-datepicker.css";

const EditRole = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [formData, setFormData] = useState(null)
  const history = useHistory()

  const defaultValues = makeDefaultValues({
    fields,
    data: {
      permissions: permissionsDefaultValues()
    },
  });

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schemaValidation),
  });
  
  const { isSubmitting } = useFormState({ control: methods.control });
  
  const onSubmit = async (data) => {
    try {
      // Primeiro pega as permissões já existentes dos usuários
      const responseUsersRole = await getUsersRoles();
      // Pega os ids da role que serão modificados
      const responseRoleIds = await getRoleById(id);
      const rolesIds = responseRoleIds.data.data.map(item => item.id);
      // Monta o array de permissões que será inserido
      let permissionsArray = Object.keys(data.permissions).filter((permission) => {
        if(data.permissions[permission]) {
          return permission.toString();
        }
      });
      // Insere as novas permissões na mesma role
      const roleModel = new RoleModel(
        data.role,
        permissionsArray,
        data.name,
        data.login_redirect
      );
      const response = await createRole(roleModel); 
      if (response.status === 200) {
        // Percorre e atualiza os usuários com as novas permissões (PS. necessário deslogar os usuários para ter efeito as novas permissões)
        if (responseUsersRole.status === 200 && responseUsersRole.data?.data) {
          const usersWithThisRole = responseUsersRole.data.data.filter(user => user.role === data.role);
          if (usersWithThisRole.length > 0) {
            for await (const user of usersWithThisRole) {
              let userRoleModel = new UserRoleModel(
                user.role,
                user.user
              );
              await updateUserRole(userRoleModel);
            }
          }
        }
      } 
      // Remove as permissões anteriores
      const responseDelete = await deleteRoleById(rolesIds);
      if (responseDelete.status !== 200) { 
        throw new Error('Erro ao editar permissões');
      }
      toast.success('Perfil editado com sucesso');
      history.push('/fleet/role/list-roles');
    } catch (error) {
      console.log('error', error)
      toast.error("Erro ao atualizar perfil. Verifique com o suporte");
    }
  };

  const fetchRoleById = async (roleId) => {
    try {
      const response = await getRoleById(roleId);
      const { data } = response.data;
      // Seta o nome da role para recriá-la com o mesmo nome de criação
      methods.setValue("role", data[0].role);
      methods.setValue("login_redirect", data[0].login_redirect);
      setFormData(data)
    } catch (error) {
      toast.error(
        "Erro ao buscar dados do perfil. Verifique com o suporte",
      );
    };
  };

  useEffect(() => {
    if (hasPermission({scopes: ['can_edit_user_role']})) {
      fetchRoleById(id);
    }
  }, [id]);

  if (!defaultValues) {
    return "...carregando";
  }

  return (
    <Aux>
      <PermissionsGate scopes={['can_edit_user_role']}>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box>
              <Grid item xl={12} xs={12}>
                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Typography variant='h1' className={classes.title}>Cadastro de Perfil</Typography>
                    {formData && 
                      <input type="hidden" {...methods.register('role')} name="role" /> && 
                      <RoleForm formData={formData} />}
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Grid container justifyContent='flex-end' spacing={2}>
                        <Grid item container justifyContent='flex-end' xl={12} lg={12} md={12} sm={12} xs={12}>
                          <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                            <Box sx={{ m: 2 }}>
                              <BackButton className={classes.backButton} />
                            </Box>
                          </Grid>
                          <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                            <Box sx={{ m: 2 }}>
                              <Button
                                fullWidth
                                variant='contained'
                                type='submit'
                                disabled={isSubmitting}
                                className={classes.btConfirm}
                              >
                                Atualizar
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </FormProvider>  
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </PermissionsGate>
    </Aux>
  );
};

export default EditRole;
