import React, { useEffect, useState } from "react";
import { Grid, Typography, Paper, Box, FormHelperText } from "@material-ui/core";
import { useFormContext, useFormState, useWatch } from "react-hook-form";
import { toast } from "react-toastify";
import useStyles from "./styles";
import Aux from "hoc/auxiliar";
import PermissionsGate from "components/PermissionsGate";
import { requirementLevelOptions } from "domain/selectOptions";
import { Input, Select } from "components/react-hook-form";
import { makeSelectOptions } from "helpers/makeSelectOptions";
import { getOperationTypesByCustomers } from "services/operationType";
import { useAppSelector } from "redux/store";
import { useCallback } from "react";

const FormVehicleGroupComponent = () => {
  const { currentCustomer } = useAppSelector((state) => state.global.user);
  const classes = useStyles();
  const { control, setValue } = useFormContext();
  const { errors } = useFormState({ control });
  const [isDisabledRequirementLevel, setIsDisabledRequirementLevel] = useState(true)
  const [operationTypeAllData, setOperationTypeAllData] = useState([])
  const [operationTypeOptions, setOperationTypeOptions] = useState([]);

  const operationType = useWatch({ control, name: "operationType" });
  const operationTypeOption = useWatch({ control, name: "operationTypeOption" });
  const requirementLevelOption = useWatch({ control, name: "requirementLevelOption" });

  const fetchOperationTypes = useCallback(async () => {
    try {
      const response = await getOperationTypesByCustomers(currentCustomer);
      const options = makeSelectOptions({
        data: response.data.operationTypes,
        keyValue: "id",
        keyLabel: "name",
      });
      setOperationTypeOptions(options);
      setOperationTypeAllData(response.data.operationTypes)
    } catch (error) {
      toast.error(
        "Erro ao buscar tipos de operações! Entre em contato com o suporte! ",
      );
    }
  }, [currentCustomer])

  useEffect(() => {
    if (operationType) {
      const findType = operationTypeAllData.find(
        (op) => op.id === operationType.value,
      );
      if (findType?.type === 2) {
        setValue("requirementLevel", { value: 4, label: "Personalizado" });
        setIsDisabledRequirementLevel(true);
      } else {
        if (requirementLevelOption === "" || requirementLevelOption === 4) {
          setValue("requirementLevel", null);
        } else {
          // fix editing and control requirementLevel
          setValue("requirementLevelOption", "");
        }
        setIsDisabledRequirementLevel(false);
      }
    }
  }, [operationType]);

  useEffect(() => {
    if (operationTypeOption && operationTypeOptions && operationTypeOptions.length > 0) {
      setValue(
        "operationType",
        operationTypeOptions.find((elm) => elm.value === operationTypeOption),
      );
    }
  }, [operationTypeOption, operationTypeAllData]);

  useEffect(() => {
    if (requirementLevelOption && requirementLevelOptions && requirementLevelOptions.length > 0) {
      setValue(
        "requirementLevel",
        requirementLevelOptions.find((elm) => elm.value === requirementLevelOption),
      );
    }
  }, []);

  useEffect(() => {
    currentCustomer && fetchOperationTypes(currentCustomer);
  }, [currentCustomer, fetchOperationTypes]);

  return (
    <Aux>
      <Paper elevation={2} className={classes.container}>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography className={classes.title}>Dados do grupo</Typography>
              <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                  <Box sx={{ m: 2 }}>
                    <Input
                      name="name"
                      variant="outlined"
                      fullWidth
                      label="Nome do grupo *"
                      error={errors.name && true}
                    />
                    <FormHelperText className={classes.textError} error={true}>
                      {errors.name?.message}
                    </FormHelperText>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography className={classes.title}>Valores de Meta e Premiação</Typography>
              <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Grid item xl={6} lg={6} md={8} sm={12} xs={12}>
                  <Box sx={{ m: 2 }}>
                    <Input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      name="targetMileage"
                      variant="outlined"
                      fullWidth
                      label="Meta de Km rodado por Mês*"
                      error={errors.targetMileage && true}
                    />
                    <FormHelperText className={classes.textError} error={true}>
                      {errors.targetMileage?.message}
                    </FormHelperText>
                  </Box>
                </Grid>
                <Grid item xl={6} lg={6} md={8} sm={12} xs={12}>
                  <Box sx={{ m: 2 }}>
                    <Input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      name="maxAmount"
                      variant="outlined"
                      fullWidth
                      label="Premiação Máx por Usuário*"
                      error={errors.maxAmount && true}
                    />
                    <FormHelperText className={classes.textError} error={true}>
                      {errors.maxAmount?.message}
                    </FormHelperText>
                  </Box>
                </Grid>
                <PermissionsGate scopes={['can_create_minscore', 'can_edit_minscore']}>
                  <Grid item xl={6} lg={6} md={8} sm={12} xs={12}>
                    <Box sx={{ m: 2 }}>
                      <Input
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        name="minScore"
                        variant="outlined"
                        fullWidth
                        label="Nota Mínima de Avaliação Diária*"
                        error={errors.minScore && true}
                      />
                      <FormHelperText className={classes.textError} error={true}>
                        {errors.minScore?.message}
                      </FormHelperText>
                    </Box>
                  </Grid>
                </PermissionsGate>
              </Grid>
            </Grid>
            <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography className={classes.title}>Tipo de avaliação para premiação</Typography>
              <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Box sx={{ m: 2 }}>
                    <Select
                      fullWidth
                      name={"operationType"}
                      options={operationTypeOptions}
                      placeholder="Grupo de premiação *"
                      label="Grupo de premiação *"
                      error={errors.operationType && true}
                    />
                    <FormHelperText className={classes.textError} error={true}>
                      {errors.operationType?.message}
                    </FormHelperText>
                  </Box>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Box sx={{ m: 2 }}>
                    <Select
                      fullWidth
                      options={requirementLevelOptions}
                      name="requirementLevel"
                      placeholder="Exigência *"
                      label="Exigência *"
                      isDisabled={isDisabledRequirementLevel}
                      error={errors.requirementLevel && true}
                    />
                    <FormHelperText className={classes.textError} error={true}>
                      {errors.requirementLevel?.message}
                    </FormHelperText>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Aux>
  );
};

export default FormVehicleGroupComponent;
