import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Paper,
  Typography,
} from "@material-ui/core";
import { useWatch, useFormContext, useFormState } from "react-hook-form";
import { toast } from "react-toastify";

// components
import Checkbox from "../react-hook-form/checkbox";
import Input from "../react-hook-form/input";
import InputAdornment from "../react-hook-form/inputAdornment";
import Select from "../react-hook-form/select";

// helpers
import Aux from '../../hoc/auxiliar';
import { makeSelectOptions } from "../../helpers/makeSelectOptions";
import { orderByName } from "../../helpers/functions";
import { floatMask } from "../../helpers/masks";

// domain
import {
  accelerationIdentificationMethodOptions,
  fuelCalcMethodOptions,
  distanceCalcMethodOptions,
  odomTypeOptions,
} from "../../domain/selectOptions";

// skeleton
import FormVehicleSkeleton from "../Skeletons/FormVehicleSkeleton";

// services
import { getCustomersById } from "../../services/customers";
import { getOperations } from "../../services/operations";
import {
  getBrands,
  getModelsByBrand,
  getWheeldrivers,
  getFueltypes,
} from "../../services/vehicle";

import useStyles from "./styles";
import "react-datepicker/dist/react-datepicker.css";
import { useAppSelector } from "redux/store";
import { useCallback } from "react";

const FormVehicle = () => {
  const { currentCustomer, listCustomers } = useAppSelector((state) => state.global.user);
  const classes = useStyles();

  const [accelerationIdentificationMethods, setAccelerationIdentificationMethods] = useState([]);
  const [brands, setBrands] = useState(null);
  const [customers, setCustomers] = useState(null);
  const [distanceCalcMethods, setDistanceCalcMethods] = useState([]);
  const [fuelCalcMethods, setFuelCalcMethods] = useState([]);
  const [fueltypes, setFueltypes] = useState(null);
  const [models, setModels] = useState([]);
  const [operations, setOperations] = useState([]);
  const [wheeldrivers, setWheeldrivers] = useState([]);
  const { control, setValue } = useFormContext();
  const { errors } = useFormState({ control });

  const fetchAccelerationIdentificationMethodList = () => {
    const options = makeSelectOptions({
      data: accelerationIdentificationMethodOptions,
      keyLabel: "name",
      keyValue: "id",
    });
    const accelerationOptions = orderByName(options);
    setAccelerationIdentificationMethods(accelerationOptions);
  };

  const fetchBrandList = async (brandId) => {
    try {
      const response = await getBrands();
      const options = makeSelectOptions({
        data: response.data.data?.brands,
        keyLabel: "name",
        keyValue: "id",
      });
      const brandOptions = orderByName(options);
      setBrands(brandOptions ?? []);
    } catch (err) {
      toast.error(
        "Erro ao carregar lista de marcas. Entre em contato com o suporte!",
      );
      console.log(err);
    }
  };

  const fetchCustomerList = useCallback(async () => {
    try {
      const response = await getCustomersById(listCustomers);
      const customerOptions = makeSelectOptions({
        data: response.data?.customers ?? [],
        keyLabel: "name",
        keyValue: "id",
      });
      const orderOptions = orderByName(customerOptions);
      setCustomers(orderOptions);
    } catch (err) {
      toast.error(
        "Erro ao carregar lista de rastreadores. Entre em contato com o suporte!",
      );
      console.log(err);
    }
  }, [listCustomers])

  const fetchDistanceCalcMethodsList = () => {
    const options = makeSelectOptions({
      data: distanceCalcMethodOptions,
      keyLabel: "name",
      keyValue: "id",
    });
    const distanceOptions = orderByName(options);
    setDistanceCalcMethods(distanceOptions);
  };

  const fetchFuelCalcMethodList = () => {
    const options = makeSelectOptions({
      data: fuelCalcMethodOptions,
      keyLabel: "name",
      keyValue: "id",
    });
    const fuelOptions = orderByName(options);
    setFuelCalcMethods(fuelOptions);
  };

  const fetchFueltypesList = async () => {
    try {
      const response = await getFueltypes();
      const options = makeSelectOptions({
        data: response.data?.fuelTypes,
        keyLabel: "name",
        keyValue: "id",
      });
      const modelsOptions = orderByName(options);
      setFueltypes(modelsOptions ?? []);
    } catch (err) {
      toast.error(
        "Erro ao carregar lista de combustíveis. Entre em contato com o suporte!",
      );
      console.log(err);
    }
  };

  const fetchModelList = async () => {
    try {
      if (brand?.value) {
        const response = await getModelsByBrand(brand.value);
        const options = makeSelectOptions({
          data: response.data.data?.models,
          keyLabel: "name",
          keyValue: "id",
        });
        const modelsOptions = orderByName(options);
        const defaultModel = modelName && modelOption ? [{ label: modelName, value: modelOption }] : [];

        setModels(modelsOptions ? modelsOptions : defaultModel);
      }
    } catch (err) {
      toast.error(
        "Erro ao carregar lista de perfis. Entre em contato com o suporte!",
      );
      console.log(err);
    }
  };

  const fetchOperationList = async (customer) => {
    if (customer && customer.value) {
      try {
        const response = await getOperations(customer.value);
        const operationOptions = makeSelectOptions({
          data: response.data.data?.operation ?? [],
          keyLabel: "name",
          keyValue: "id",
        });
        const orderOptions = orderByName(operationOptions);
        setOperations(orderOptions);
      } catch (err) {
        toast.error(
          "Erro ao carregar lista de operações. Entre em contato com o suporte!",
        );
        console.log(err);
      }
    }
  };

  const fetchWheeldriversList = async () => {
    try {
      const response = await getWheeldrivers();
      const options = makeSelectOptions({
        data: response.data.data?.wheeldrivers,
        keyLabel: "name",
        keyValue: "id",
      });
      const modelsOptions = orderByName(options);
      setWheeldrivers(modelsOptions ?? []);
    } catch (err) {
      toast.error(
        "Erro ao carregar lista de trações. Entre em contato com o suporte!",
      );
    }
  };

  // whatchers
  const accelerationIdentificationMethodOption = useWatch({ control, name: "accelerationIdentificationMethodOption" });
  const brand = useWatch({ control, name: "brand" });
  const brandOption = useWatch({ control, name: "brandOption" });
  const customer = useWatch({ control, name: "customer" });
  const customerOption = useWatch({ control, name: "customerOption" });
  const distanceCalcMethodOption = useWatch({ control, name: "distanceCalcMethodOption" });
  const fuelCalcMethodOption = useWatch({ control, name: "fuelCalcMethodOption" });
  const fueltypeOption = useWatch({ control, name: "fueltypeOption" });
  const hasDafEcoRoll = useWatch({ control, name: "hasDafEcoRoll" });
  const leveragePeak = useWatch({ control, name: "leveragePeak" });
  const maxAcceleration = useWatch({ control, name: "maxAcceleration" });
  const maxSpeedAllowed = useWatch({ control, name: "maxSpeedAllowed" });
  const maxSpeedAllowedWithRain = useWatch({ control, name: "maxSpeedAllowedWithRain" });
  const modelOption = useWatch({ control, name: "modelOption" });
  const modelName = useWatch({ control, name: "modelName" });
  const initialKm = useWatch({ control, name: "initialKm" });
  const odomType = useWatch({ control, name: "odomType" });
  const operationOption = useWatch({ control, name: "operationOption" });
  const wheeldriverOption = useWatch({ control, name: "wheeldriverOption" });

  useEffect(() => {
    if (distanceCalcMethodOption && distanceCalcMethods?.length > 0) {
      setValue(
        "distanceCalcMethod",
        distanceCalcMethods.find((elm) => elm.value === distanceCalcMethodOption),
      );
    }
  }, [distanceCalcMethodOption, distanceCalcMethods]);

  useEffect(() => {
    if (fuelCalcMethodOption && fuelCalcMethods?.length > 0) {
      setValue(
        "fuelCalcMethod",
        fuelCalcMethods.find((elm) => elm.value === fuelCalcMethodOption),
      );
    }
  }, [fuelCalcMethodOption, fuelCalcMethods]);

  useEffect(() => {
    if (accelerationIdentificationMethodOption && accelerationIdentificationMethods?.length > 0) {
      setValue(
        "accelerationIdentificationMethod",
        accelerationIdentificationMethods.find((elm) => elm.value === accelerationIdentificationMethodOption),
      );
    }
  }, [accelerationIdentificationMethodOption, accelerationIdentificationMethods]);

  useEffect(() => {
    const justNumbers = floatMask(maxSpeedAllowed);
    setValue("maxSpeedAllowed", justNumbers);
  }, [maxSpeedAllowed]);

  useEffect(() => {
    const justNumbers = floatMask(maxSpeedAllowedWithRain);
    setValue("maxSpeedAllowedWithRain", justNumbers);
  }, [maxSpeedAllowedWithRain]);

  useEffect(() => {
    const justNumbers = floatMask(leveragePeak);
    setValue("leveragePeak", justNumbers);
  }, [leveragePeak]);

  useEffect(() => {
    const justNumbers = floatMask(maxAcceleration);
    setValue("maxAcceleration", justNumbers);
  }, [maxAcceleration]);

  useEffect(() => {
    if (wheeldriverOption && wheeldrivers && wheeldrivers.length > 0) {
      setValue(
        "wheeldriver",
        wheeldrivers.find((elm) => elm.value === wheeldriverOption),
      );
    }
  }, [wheeldriverOption, wheeldrivers]);

  useEffect(() => {
    if (fueltypeOption && fueltypes && fueltypes.length > 0) {
      setValue(
        "fueltype",
        fueltypes.find((elm) => elm.value === fueltypeOption),
      );
    }
  }, [fueltypeOption, fueltypes]);

  useEffect(() => {
    if (customerOption && customers && customers.length > 0) {
      setValue(
        "customer",
        customers.find((elm) => elm.value === customerOption),
      );
    }
  }, [customerOption, customers]);

  useEffect(() => {
    if (operationOption && operations && operations.length > 0) {
      setValue(
        "operation",
        operations.find((elm) => elm.value === operationOption),
      );
    }
  }, [operationOption, operations]);

  useEffect(() => {
    if (brandOption && brands?.length > 0) {
      setValue(
        "brand",
        brands.find((elm) => elm.value === brandOption),
      );
    }
  }, [brandOption, brands]);

  useEffect(() => {
    if (modelOption && models?.length > 0) {
      setValue(
        "model",
        models.find((elm) => elm.value === modelOption),
      );
    }
  }, [modelOption, models]);

  useEffect(() => {
    if (wheeldriverOption && wheeldrivers?.length > 0) {
      setValue(
        "wheeldriver",
        wheeldrivers.find((elm) => elm.value === wheeldriverOption),
      );
    }
  }, [wheeldriverOption, wheeldrivers]);

  useEffect(() => {
    if (fueltypeOption && fueltypes?.length > 0) {
      setValue(
        "fueltype",
        fueltypes.find((elm) => elm.value === fueltypeOption),
      );
    }
  }, [fueltypeOption, fueltypes]);

  useEffect(() => {
    if (odomType && odomType.value === 1) {
      setValue("initialKm", "");
    }
  }, [odomType]);

  useEffect(() => {
    fetchOperationList(customer);
  }, [customer]);

  useEffect(() => {
    fetchModelList();
  }, [brand, modelName, modelOption]);

  useEffect(() => {
    if (currentCustomer) {
      fetchAccelerationIdentificationMethodList();
      fetchBrandList();
      fetchCustomerList();
      fetchDistanceCalcMethodsList();
      fetchFuelCalcMethodList();
      fetchFueltypesList();
      fetchWheeldriversList();
    }
  }, [currentCustomer, fetchCustomerList]);

  if (!customers || !brands || !fueltypes) {
    return <FormVehicleSkeleton />;
  }

  return (
    <Aux>
      <Paper elevation={2} className={classes.container}>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid container>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography className={classes.title}>Empresa</Typography>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Box sx={{ m: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                      <Select
                        options={customers}
                        name="customer"
                        placeholder="Selecione uma empresa *"
                      />
                      <FormHelperText className={classes.formHelperText} error={true}>
                        {errors.customer?.message}
                      </FormHelperText>
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                      <Select
                        options={operations}
                        name="operation"
                        placeholder="Grupo de Veículo"
                      />
                      <FormHelperText className={classes.formHelperText} error={true}>
                        {errors.operations?.message}
                      </FormHelperText>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography className={classes.title}>Dados do Veículo</Typography>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Box sx={{ m: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                      <Input
                        name="identification"
                        variant="outlined"
                        fullWidth
                        label="Frota *"
                        helperText={errors.identification?.message}
                        error={errors.identification && true}
                      />
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                      <Input
                        name="plate"
                        variant="outlined"
                        fullWidth
                        label="Placa *"
                        helperText={errors.plate?.message}
                        error={errors.plate && true}
                      />
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                      <Select
                        options={fueltypes}
                        name="fueltype"
                        placeholder="Tipo de Combustível"
                      />
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                      <Select
                        options={wheeldrivers}
                        name="wheeldriver"
                        placeholder="Tração"
                      />
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                      <Select
                        options={odomTypeOptions}
                        name="odomType"
                        placeholder="Tipo de Odômetro *"
                      />
                      <FormHelperText className={classes.formHelperText} error={true}>
                        {errors.odomType?.message}
                      </FormHelperText>
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                      <Input
                        name="initialKm"
                        variant="outlined"
                        fullWidth
                        type="number"
                        label="Km Inicial"
                        value={initialKm ?? ""}
                        className={classes.disabledInput}
                        helperText={errors.initialKm?.message}
                        error={errors.initialKm && true}
                        disabled={odomType?.value !== 2}
                      />
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                      <FormControl className={classes.formControl} variant="outlined">
                        <InputLabel htmlFor="maxFuelCapacity">
                          Limite Tanque *
                        </InputLabel>
                        <InputAdornment
                          name="maxFuelCapacity"
                          label="Limite Tanque *"
                          position="start"
                          adornment="Litros"
                          type="number"
                          error={errors.maxFuelCapacity && true}
                        />
                        <FormHelperText className={classes.formHelperText} error={true}>
                          {errors.maxFuelCapacity?.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                      <FormControl
                        className={classes.formControl}
                        variant="outlined"
                      >
                        <InputLabel htmlFor="maxAcceleration">
                          Aceleração Máx *
                        </InputLabel>
                        <InputAdornment
                          name="maxAcceleration"
                          label="Aceleração Máx *"
                          position="start"
                          adornment="m/s²"
                          type="number"
                          error={errors.maxAcceleration && true}
                        />
                        <FormHelperText
                          className={classes.formHelperText}
                          error={true}
                        >
                          {errors.maxAcceleration?.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                      <Input
                        name="chassi"
                        variant="outlined"
                        fullWidth
                        label="Chassi"
                        error={errors.chassi && true}
                      />
                      <FormHelperText className={classes.formHelperText} error={true}>
                        {errors.chassi?.message}
                      </FormHelperText>
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                      <Input
                        name="motor"
                        variant="outlined"
                        fullWidth
                        label="Motorização"
                        error={errors.motor && true}
                      />
                      <FormHelperText className={classes.formHelperText} error={true}>
                        {errors.motor?.message}
                      </FormHelperText>
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                      <Input
                        name="fabricationYear"
                        variant="outlined"
                        fullWidth
                        type="number"
                        label="Ano do veículo"
                        error={errors.fabricationYear && true}
                      />
                      <FormHelperText className={classes.formHelperText} error={true}>
                        {errors.chassi?.message}
                      </FormHelperText>
                    </Grid>

                    <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                      <Input
                        name="truckModel"
                        variant="outlined"
                        fullWidth
                        label="Modelo"
                        error={errors.truckModel && true}
                      />
                      <FormHelperText className={classes.formHelperText} error={true}>
                        {errors.truckModel?.message}
                      </FormHelperText>
                    </Grid>

                  </Grid>
                </Box>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography className={classes.title}>Cálculo de</Typography>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Box sx={{ m: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                      <Select
                        options={distanceCalcMethods}
                        name="distanceCalcMethod"
                        placeholder="Distância por *"
                      />
                      <FormHelperText className={classes.formHelperText} error={true}>
                        {errors.distanceCalcMethod?.message}
                      </FormHelperText>
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                      <Select
                        options={fuelCalcMethods}
                        name="fuelCalcMethod"
                        placeholder="Consumo por *"
                      />
                      <FormHelperText className={classes.formHelperText} error={true}>
                        {errors.fuelCalcMethod?.message}
                      </FormHelperText>
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                      <Select
                        options={accelerationIdentificationMethods}
                        name="accelerationIdentificationMethod"
                        placeholder="Aceleração por *"
                      />
                      <FormHelperText className={classes.formHelperText} error={true}>
                        {errors.accelerationIdentificationMethod?.message}
                      </FormHelperText>
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                      <Box flexDirection="row">
                        <Checkbox checked={hasDafEcoRoll} name="hasDafEcoRoll" label="DAF ECO-ROLL" />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography className={classes.title}>Velocidade Máxima</Typography>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Box sx={{ m: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                      <FormControl
                        className={classes.formControl}
                        variant="outlined"
                      >
                        <InputLabel htmlFor="maxSpeedAllowed">Sem chuva *</InputLabel>
                        <InputAdornment
                          name="maxSpeedAllowed"
                          label="Sem chuva *"
                          position="start"
                          adornment="Km/h"
                          type="number"
                          error={errors.maxSpeedAllowed && true}
                        />
                        <FormHelperText
                          className={classes.formHelperText}
                          error={true}
                        >
                          {errors.maxSpeedAllowed?.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                      <FormControl
                        className={classes.formControl}
                        variant="outlined"
                      >
                        <InputLabel htmlFor="maxSpeedAllowedWithRain">Com chuva *</InputLabel>
                        <InputAdornment
                          name="maxSpeedAllowedWithRain"
                          label="Com chuva *"
                          position="start"
                          adornment="Km/h"
                          type="number"
                          error={errors.maxSpeedAllowedWithRain && true}
                        />
                        <FormHelperText
                          className={classes.formHelperText}
                          error={true}
                        >
                          {errors.maxSpeedAllowedWithRain?.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <FormControl
                        className={classes.formControl}
                        variant="outlined"
                      >
                        <InputLabel htmlFor="leveragePeak">
                          Pico aproveitamento de embalo *
                        </InputLabel>
                        <InputAdornment
                          name="leveragePeak"
                          label="Pico aproveitamento de embalo *"
                          position="start"
                          adornment="Km/h"
                          type="number"
                          error={errors.leveragePeak && true}
                        />
                        <FormHelperText
                          className={classes.formHelperText}
                          error={true}
                        >
                          {errors.leveragePeak?.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography className={classes.title}>Vínculo Faixas de RPM</Typography>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Box sx={{ m: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                      <Select
                        options={brands}
                        name="brand"
                        placeholder="Marca *"
                      />
                      <FormHelperText className={classes.formHelperText} error={true}>
                        {errors.brand?.message}
                      </FormHelperText>
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                      <Select
                        options={models}
                        name="model"
                        placeholder="Perfil *"
                        className={classes.disabledInput}
                        isDisabled={!brand?.value}
                      />
                      <FormHelperText className={classes.formHelperText} error={true}>
                        {errors.model?.message}
                      </FormHelperText>
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                      <Box
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Button
                          variant="text"
                          color="primary"
                          onClick={() => { console.log('Click') }}
                          className={classes.buttonModel}
                        >
                          Editar Modelo Perfil
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography className={classes.title}>Anotações sobre o Veículo</Typography>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Box sx={{ m: 2 }}>
                  <Grid container spacing={2}>
                    <Input
                      name="observation"
                      variant="outlined"
                      fullWidth
                      multiline
                      minRows={3}
                      label="Observações"
                    />
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Box sx={{ mt: 2 }}>
              <Typography className={classes.explanation}>*Os campos que estão marcados com o asterisco(*) são os que precisam obrigatoriamente serem preenchidos.</Typography>
              <Typography className={classes.explanation}>*LEMBRE-SE de que após o cadastro do veículo é necessário VINCULÁ-LO a uma unidade eletrônica para que os dados comecem a aparecer para o cliente.</Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Aux>
  );
};

export default FormVehicle;
