import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  loading: {
    color: theme.palette.text.secondary,
    paddingTop: theme.spacing(4),
    textAlign: 'center',
    fontStyle: 'italic',
  },
  fontBold: {
    fontWeight: "bold",
  }
}));
