import { format } from "date-fns";
const dateFormatTemplate = "yyyy-MM-dd'T'HH:mm:ssXX";

export const makeInitialAndFinalDate = (indexMonth) => {

  const date = new Date();
  const year = date.getFullYear();
  const selectedMonth = new Date(year, indexMonth);
  const lastDay = new Date(year, selectedMonth.getMonth() + 1, 0);
  return {
    initialDate: format(selectedMonth, dateFormatTemplate),
    finalDate: format(lastDay, "yyyy-MM-dd'T'23:59:59XX"),
  };
};