import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  root: {
    "& .MuiToolbar-root": {
      minHeight: "20px",
    },
    "& .MUIDataTableFilterList-root-224": {
      "& div": {
        marginBottom: 10
      },
    },
    "& .MUIDataTableToolbar-left-136": {
      "& div": {
        display: "flex",
        justifyContent: "center",
      },
    },
    "& .MUIDataTableToolbar-left-123": {
      "& div": {
        display: "flex",
        justifyContent: "center",
      },
    },
    "@media (max-width: 960px)": {
      "& td:first-child": {
        backgroundColor: "#f7df36",
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        display: 'flex',
        "& :nth-last-child(2)": {
          width: "auto",
          fontWeight: 600,
        },
        "& :last-child": {
          width: "auto",
          fontWeight: 600,
          marginTop: 1,
          marginLeft: 1,
        },
      },
      "& tfoot": {
        "& td:first-child": {
          backgroundColor: "transparent",
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          "& :nth-last-child(2)": {
            width: "auto",
            fontWeight: 600,
            overflow: "hidden",
            height: "-webkit-fill-available",
          },
          "& :last-child": {
            width: "auto",
            fontWeight: 600,
            marginTop: 1,
            marginLeft: 1,
            overflow: "hidden",
            height: "-webkit-fill-available",
          },
        },
      },
      "& td:last-child": {
        borderBottomRightRadius: 20,
        borderBottomLeftRadius: 20,
      },
    },
    "& .MuiToolbar-regular": {
      backgroundColor: theme.palette.background.default,
    },
    "& .MuiIconButton-root": {
      padding: "10px",
    },
    "& thead": {
      "& tr": {
        height: "20px",
      },
      "& tr:first-child": {
        "& th:first-child": {
          borderTopLeftRadius: "10px",
        },
        "& th:last-child": {
          borderTopRightRadius: "10px",
        },
      },
      "& th:not(:last-child)": {
        borderRightWidth: 2,
        borderRightColor: theme.palette.white,
        borderRightStyle: "solid",
      },
      "& th": {
        padding: "5px 20px 0",
        backgroundColor: `${theme.palette.secondary.main} !important`,
        "& .MuiButtonBase-root": {
          margin: 0,
          whiteSpace: "nowrap",
        },
        "& .MuiButton-label": {
          lineHeight: "1",
          fontWeight: "bold",
          fontSize: "0.9rem",
        },
        "& div": {
          lineHeight: "1",
          fontWeight: "bold",
          fontSize: "0.9rem",
          whiteSpace: "nowrap",
          justifyContent: "center",
          width: "100%",
          textAlign: "center",
        },
      },
    },
    "& tbody": {
      "& tr": {
        height: "50px",
        borderWidth: "inherit",
        borderRadius: 30,
      },
      "& td": {
        //height: "20px",
        borderColor: "transparent",
        padding: "5px 20px",
        fontSize: "0.8rem",
      },
    },
    ...customCheckbox(theme),
  },
}));

function customCheckbox(theme) {
  return {
    "& .MuiCheckbox-root svg": {
      width: 16,
      height: 16,
      backgroundColor: "transparent",
      border: `1px solid ${theme.palette.gray.extraLight}`,
      borderRadius: 2,
    },
    "& .MuiCheckbox-root svg path": {
      display: "none",
    },
    "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg": {
      backgroundColor: theme.palette.primary.light,
      borderColor: theme.palette.primary.light,
    },
    "& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after": {
      position: "absolute",
      display: "table",
      border: `2px solid ${theme.palette.white}`,
      borderTop: 0,
      borderLeft: 0,
      transform: "rotate(45deg) translate(-50%,-50%)",
      opacity: 1,
      transition: "all .2s cubic-bezier(.12,.4,.29,1.46) .1s",
      content: '""',
      top: "50%",
      left: "40%",
      width: 5.71428571,
      height: 9.14285714,
    },
    "& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after":
      {
        width: 8,
        height: 8,
        backgroundColor: theme.palette.primary.light,
        transform: "none",
        top: "40%",
        border: 0,
      },
  };
}
