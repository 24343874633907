import api from "./api";

const itensUrl = (itens) => {
  let partialUrl = "";
  if (itens) {
    for (let i = 0; i < itens.length; i++) {
      if (i + 1 === itens.length) {
        partialUrl += `${itens[i]}`;
      } else {
        partialUrl += `${itens[i]},`;
      }
    }
  }
  return partialUrl;
};

export const getAllRecalculations = async (initialDate, finalDate) => {
  try {
    const response = await api.get(`/get-recalc-drivers?endDate=${finalDate}&startDate=${initialDate}`);
    return response;
  } catch (err) {
    return err;
  }
};

export const getRecalculationById = async (recalcId) => {
  try {
    const response = await api.get(`/get-recalc-drivers?recalcId=${recalcId}`);
    return response;
  } catch (err) {
    return err;
  }
};

export const createRecalculation = async (driverList, email, initialDate, finalDate) => {
  const partialUrl = itensUrl(driverList);
  let params = `drivers=${partialUrl}&endDate=${finalDate}&startDate=${initialDate}&user=${email}`;
  try {
    const response = await api.get(`/recalc-drivers?${params}`);
    return response;
  } catch (err) {
    throw err;
  }
};
