import { makeStyles } from '@material-ui/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';

export default makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(3)
  },
  contentDialog: {
    '& .MuiPaper-rounded': {
      padding: theme.spacing(4),
      borderRadius: 28
    }    
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  btConfirm: {
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    borderRadius: 10,
    backgroundColor: theme.palette.secondary.main,
    boxShadow: 'none',
    transition: '0.3s',
    '&:hover': {
      backgroundColor: alpha(theme.palette.secondary.main, 0.8),
      fontWeight: 600
    },
  },
  gridItem: {
    padding: theme.spacing(2),
  },
  title: {
    color: theme.palette.text.title,
    fontSize: theme.typography.h3.fontSize
  },
  inputCalendar: {
    fontSize: theme.typography.h5.fontSize,
    color: theme.palette.gray.default,
    '& .MuiInputBase-input': {
      textAlign: 'center',
      border: '1px solid '+theme.palette.text.hint,
      width: '250px',
      borderRadius: '20px',
      padding: '15px',
    },
    '&:before': {
      transition: 'none',
      border: 'transparent'
    },
    '&:after': {
      transition: 'none',
      border: 'transparent'
    },
    '& input': {
      cursor: 'pointer',
    },
    '&:hover:not(.Mui-disabled):before': {
      border: 'transparent'
    }
  },
  datepicker: {
    [`& fieldset`]: {
      borderRadius: 20,
    }
  },
  buttonBack: {
    borderRadius: 10,
    boxShadow: 'none',
    textTransform: 'inherit',
    background: theme.palette.background.backgroundButton,
    fontSize: 16,
  },
  legendTitle: {
    color: theme.palette.text.title,
    paddingBottom: theme.spacing(2),
    fontWeight: 'bold'
  },
  textDriver: {
    marginLeft: 10,
    maxWidth: 200
  },
  containerCheckbox: {
    height:'100px'
  },
  paper: {
    borderRadius: 20,
    maxWidth: '350px',
    margin: '0 auto'
  },
}));
