import React, { useCallback, useEffect, useState } from 'react';
import { Box, FormHelperText, Grid, Paper, TextField, Typography } from '@material-ui/core';
import { useWatch, useFormContext, useFormState } from 'react-hook-form';
import { useTheme } from '@material-ui/styles';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

// components
import { AutoComplete, Input, Select } from 'components/react-hook-form';

// helpers
import Aux from '../../hoc/auxiliar';
import { makeSelectOptions } from '../../helpers/makeSelectOptions';
import { orderByName, removeSpecialCharactersNumberMask } from '../../helpers/functions';

// skeleton
import FormVehicleProfileSkeleton from "../Skeletons/FormVehicleProfileSkeleton";

// services
import {
  createModel,
  getModelsByBrand,
  getBrands,
} from '../../services/vehicle';

import useStyles from './styles';
import classnames from 'classnames';
import 'react-datepicker/dist/react-datepicker.css';
import { useInputsProfile } from 'context/useInputsProfile';

export const InputField = ({ value, enabled, onChange }) => {
  return (
    <TextField
      type="number"
      value={value}
      disabled={!enabled}
      onChange={onChange}
    />
  );
};

const FormVehicleProfile = () => {
  const theme = useTheme();
  const classes = useStyles();
  const { inputs, setInputs } = useInputsProfile()

  const [models, setModels] = useState([]);
  const [brands, setBrands] = useState([]);

  const { control, setValue } = useFormContext();
  const { errors } = useFormState({ control });

  // watchers
  const brandId = useWatch({ control, name: 'brandId' });
  const brandIdOption = useWatch({ control, name: 'brandIdOption' });
  const modelId = useWatch({ control, name: 'modelId' });
  const modelIdOption = useWatch({ control, name: 'modelIdOption' });

  const confirmAlertConfig = (title, text, confirmBt) => {
    return {
      title: title,
      text: text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: theme.palette.primary.main,
      cancelButtonColor: theme.palette.error.main,
      confirmButtonText: confirmBt,
    };
  };

  const fetchBrandsList = async (brandId) => {
    try {
      const response = await getBrands();
      if (brandId) {
        setValue(
          'brandId',
          response.data.data?.brands.find((elm) => elm.id === brandId),
        );
      } else {
        const options = makeSelectOptions({
          data: response.data.data?.brands,
          keyLabel: 'name',
          keyValue: 'id',
        });
        const brandsOptions = orderByName(options);
        setBrands(brandsOptions);
      }
    } catch (err) {
      toast.error(
        'Erro ao carregar lista de marcas. Entre em contato com o suporte!',
      );
      console.log(err);
    }
  };

  const fetchModelList = useCallback(async (modelId) => {
    try {
      const response = await getModelsByBrand(brandId.value);
      if (modelId) {
        setValue(
          'modelId',
          response.data.data?.models.find((elm) => elm.id === modelId),
        );
      } else {
        setModels(response.data.data?.models ?? []);
      }
    } catch (err) {
      toast.error(
        'Erro ao carregar lista de modelos. Entre em contato com o suporte!',
      );
      console.log(err);
    }
  }, [brandId.value, setValue]);

  const handleCreateModel = async () => {
    try {
      const response = await createModel({
        name: modelId.inputValue,
        brandId: brandId.value
      });
      if (response.status === 200) {
        toast.success('Modelo criado com sucesso!');
        fetchModelList(response.data.data.id);
      } else {
        throw new Error();
      }
    } catch (error) {
      toast.error('Erro ao criar Marca. Verifique com o suporte');
    }
  };

  useEffect(() => {
    if (modelIdOption && models?.length > 0) {
      setValue(
        'modelId',
        models.find((elm) => elm.id === modelIdOption),
      );
    }
  }, [modelIdOption, models]);

  useEffect(() => {
    if (brandIdOption && brands?.length > 0) {
      setValue(
        'brandId',
        brands.find((elm) => elm.value === brandIdOption),
      );
    }
  }, [brandIdOption, brands]);

  useEffect(() => {
    if (modelId && modelId.inputValue) {
      if (!brandId) {
        Swal.fire({
          title: 'Atenção!',
          text: 'Selecione uma marca para adicionar um novo modelo',
          icon: 'warning',
          confirmButtonText: 'Ok',
        });
        return;
      }
      const alertConfig = confirmAlertConfig(
        '',
        'Confirma a criação de um novo Modelo?',
        'Sim, inserir!',
      );
      Swal.fire(alertConfig).then((result) => {
        if (result.isConfirmed) {
          handleCreateModel();
        }
      });
    }
  }, [modelId]);

  useEffect(() => {
    brandId && fetchModelList();
  }, [brandId, fetchModelList]);

  useEffect(() => {
    fetchBrandsList();
  }, []);

  if (!brands) {
    return <FormVehicleProfileSkeleton />;
  }

  const handleInputChange = (index) => (event) => {
    const value = event.target.value;
    const newInputs = [...inputs];
    newInputs[index].value = value;

    // Reset all inputs to the right if the left input is empty
    if (value === '') {
      for (let i = index + 1; i < inputs.length; i++) {
        newInputs[i].value = '';
        newInputs[i].enabled = false;
        newInputs[i].invalid = false;
      }
      newInputs[index].invalid = false;
    } else if (value <= 0) {
      for (let i = index + 1; i < inputs.length; i++) {
        newInputs[i].value = '';
        newInputs[i].enabled = false;
        newInputs[i].invalid = false;
      }
      newInputs[index].invalid = true;
    } else {
      if (index > 0 && parseInt(value) <= parseInt(newInputs[index - 1].value)) {
        newInputs[index].invalid = true;
        for (let i = index + 1; i < inputs.length; i++) {
          newInputs[i].value = '';
          newInputs[i].enabled = false;
          newInputs[i].invalid = false;
        }
      } else {
        newInputs[index].invalid = false;
        let enabledIndex = inputs.length;
        for (let i = index + 1; i < inputs.length; i++) {
          if (!newInputs[i - 1].invalid) {
            const nextValue = parseInt(newInputs[i - 1].value) + 1;
            if (nextValue > parseInt(newInputs[i].value) || isNaN(parseInt(newInputs[i].value))) {
              newInputs[i].enabled = true;
              newInputs[i].invalid = false;
              enabledIndex = i;
              break;
            } else {
              newInputs[i].enabled = true;
              newInputs[i].invalid = false;
              newInputs[i].value = '';
              for (let j = i + 1; j < inputs.length; j++) {
                newInputs[j].value = '';
                newInputs[j].enabled = false;
                newInputs[j].invalid = false;
              }
              break;
            }
          }
        }
        
        for (let i = enabledIndex + 1; i < inputs.length; i++) {
          newInputs[i].value = '';
          newInputs[i].enabled = false;
          newInputs[i].invalid = false;
        }
      }
    }

    setInputs(newInputs);
  };

  return (
    <Aux>
      <Paper elevation={2} className={classes.container}>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid container>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography className={classes.title}>Vínculo Marca/Perfil</Typography>
              </Grid>
              <Grid container>
                <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                  <Box sx={{ m: 2 }}>
                    <Select
                      options={brands}
                      name='brandId'
                      placeholder='Marca *'
                      required
                    />
                    <FormHelperText className={classes.formHelperText} error={true}>
                      {errors.brandId?.message}
                    </FormHelperText>
                  </Box>
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                  <Box sx={{ m: 2 }}>
                    <AutoComplete
                      classes={{ inputRoot: classes.autoComplete }}
                      name='modelId'
                      options={models}
                      uppercase='true'
                      freeSolo
                      selectOnFocus
                      clearOnBlur
                      disabled={!brandId}
                      handleHomeEndKeys
                      label='Perfil *'
                    />
                    <FormHelperText className={classes.formHelperText} error={true}>
                      {errors.modelId?.message}
                    </FormHelperText>
                  </Box>
                </Grid>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Box sx={{ mt: 2 }}>
                  <Typography className={classes.title}>Faixas de RPM</Typography>
                </Box>
              </Grid>
              <Box sx={{ m: 2 }}>
                <Grid container>
                  <Grid item xl={1} lg={1} md={1} sm={3} xs={12} className={classes.item}>
                    <Typography className={classes.subtitle}>Lenta</Typography>
                    <Input
                      variant='outlined'
                      name='neutralRangeStart'
                      type='number'
                      value={0}
                      disabled
                      className={classnames(
                        classes.disabledInput,
                        classes.rpmInput,
                      )}
                    />
                  </Grid>
                  {inputs.map((input, index) => (
                    <React.Fragment key={index}>
                      <ArrowRightAltIcon className={classes.iconRight} />
                      <Grid item xl={1} lg={1} md={1} sm={3} xs={12} className={classes.item}>
                        <Typography className={classes.subtitle}>{input.title} *</Typography>
                        <Input
                          onKeyPress={removeSpecialCharactersNumberMask}
                          name={input.name}
                          key={index}
                          variant='outlined'
                          type="number"
                          value={input.value} 
                          className={!input.enabled && classes.disabledInput}
                          disabled={!input.enabled}
                          error={input.invalid}
                          onChange={handleInputChange(index)}
                          inputProps={{ min: 0 }}
                        />
                      </Grid>
                    </React.Fragment>
                  ))}
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Box sx={{ mt: 2 }}>
              <Typography className={classes.explanation}>*Os campos que estão marcados com o asterisco(*) são os que precisam obrigatoriamente serem preenchidos.</Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Aux>
  );
};

export default FormVehicleProfile;
