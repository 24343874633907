import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  bgVideo: {
    objectFit: 'cover',
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: -10,
  },
  loginForm: {
    borderRadius: '28px',
  },
  paper: {
    margin: theme.spacing(4, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logotype: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  forgotPass: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  logotypeIcon: {
    width: 230,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(1, 0, 1),
  },
  cancel: {
    margin: theme.spacing(1, 0, 1, 3),
  },
  csrf: {
    visibility: 'hidden'
  },
}));
