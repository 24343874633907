import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  paper: {
    width: 215,
    height: 230,
    overflow: 'auto',
    border: `1px solid ${theme.palette.gray.light}`,
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  listItemIcon: {
    padding: 0,
    minWidth: '35px'
  },
  checkbox: {
    padding: 0,
    margin: 0
  },
  labelTitle: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    fontSize: 18,
    fontWeight: 500,
    color: theme.palette.text.title,
  },
  listItemText: {
    color: theme.palette.primary.main,
    '& span, & svg': {
      fontSize: '0.8rem'
    }
  },
  buttonList: {
    paddingTop: `${theme.spacing(5)}px !important`,
    display: 'flex'
  }
}));
