export const infoRpmRange = {
  economy: {
    title: "Final da Faixa Verde",
    color: "#5E8900"
  },
  engine_brake: {
    title: "Faixa Freio Motor",
    color: "#E3E3E3"
  },
  extra: {
    title: "Início da Faixa Verde",
    color: "#81BC02"
  },
  neutral: {
    title: "Faixa Lenta",
    color: "#fff"
  },
  power: {
    title: "Acelerando Acima do Verde",
    color: "#F7A801"
  },
  red: {
    title: "Faixa Vermelha",
    color: "#CC0D07"
  },
  white: {
    title: "Faixa Branca",
    color: "#e5e5e5"
  },
};

export const getColor = (theme) => {
  return {
    altitude: theme.palette.success.main,
    fuel: theme.palette.warning.main,
    rpm: theme.palette.teal,
    speed: theme.palette.primary.dark
  };
};

// Normalização da ordenação de data
export const normalizeData = (data) => {
  let dataNormalized = data.sort((a, b) => new Date(a.time) - new Date(b.time));
  dataNormalized.shift();
  return dataNormalized;
};