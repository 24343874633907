import {
  mdiAccountStar,
  mdiClipboardAccount,
  mdiCompare,
  mdiHammerScrewdriver,
  mdiMoleculeCo2,
  mdiShieldLinkVariantOutline,
  mdiTableCog,
  mdiTruckFast,
  mdiViewDashboard,
  mdiBadgeAccountOutline,
  mdiGraph,
  mdiChartAreaspline
} from '@mdi/js';
import { hasPermission, getUserPermissions } from 'components/PermissionsGate';

export function getMenuStructure() {
  const permissions = getUserPermissions();
  if (!permissions) {
    return [];
  }

  const bonusMenu = [{
    permission: 'can_view_bonus',
    id: 'Bonus',
    label: 'Premiação',
    link: '/fleet/bonus/follow',
    icon: mdiAccountStar
  }]
  const co2Menu = [{
    permission: 'can_view_co2',
    id: 'CO2',
    label: 'Emissão de C0²',
    link: '/fleet/c02-emission',
    icon: mdiMoleculeCo2
  }]
  const dashboardMenu = [{
    permission: 'can_view_dashboard',
    id: 'Fleet',
    label: 'Frota',
    link: '/fleet/dashboard/general',
    icon: mdiTruckFast,
    isMain: true,
  }, {
    permission: 'can_view_dashboard',
    id: 'Dashboard',
    label: 'Análise Individual',
    link: '/fleet/dashboard/general',
    icon: mdiViewDashboard
  }, {
    permission: 'can_view_consolidated',
    id: 'Consolidated',
    label: 'Minha Frota',
    link: '/fleet/consolidated',
    icon: mdiTruckFast
  },
  {
    permission: 'can_view_comparison',
    id: 'Comparison',
    label: 'Comparativo de Veículos',
    link: '/fleet/dashboard/comparison',
    icon: mdiCompare
  }, {
    permission: 'can_view_evolutive',
    id: 'Evolutive',
    label: 'Evolutivo',
    link: '/fleet/dashboard/evolutive',
    icon: mdiChartAreaspline
  }]
  const operationMenu = [{
    permission: 'can_view_operations',
    id: 'Maintenance',
    label: 'Gerenciamento',
    link: '/fleet/maintenance/operations',
    icon: mdiHammerScrewdriver,
    isMain: true
  }, {
    permission: 'can_view_drivers',
    id: 'Drivers',
    label: 'Motoristas',
    link: '/fleet/driver/all-drivers',
    icon: mdiClipboardAccount
  }, {
    permission: 'can_view_operations',
    id: 'Operations',
    label: 'Grupo de Veículo',
    link: '/fleet/maintenance/operations/operationList',
    icon: mdiTableCog
  }, {
    permission: 'can_view_linkdrivers',
    id: 'Linkdrivers',
    label: 'Vínculo Veículo / Motorista',
    link: '/fleet/maintenance/linkdrivers',
    icon: mdiShieldLinkVariantOutline
  }];
  const driversMonitoringMenu = [{
    permission: 'can_view_drivers_follow',
    id: 'DriversMonitoring',
    label: 'Motorista',
    link: '/fleet/driversMonitoring',
    icon: mdiBadgeAccountOutline
  }]

  const countPermissions = (permissionsArray) => {
    let count = 0;
    permissions.forEach((scope) => {
      if (permissionsArray.includes(scope.permission)) {
        count++;
      }
    });
    return count;
  };

  const getChildrenMenu = (item) => {
    return {
      id: item.id,
      label: item.label,
      link: item.link,
      icon: item.icon,
      isChildren: true
    }
  }

  const getMainMenu = (item) => {
    return {
      id: item.id,
      label: item.label,
      link: item.link,
      icon: item.icon
    }
  }

  let structure = []

  if (hasPermission({ scopes: [bonusMenu[0].permission] })) {
    structure.push(bonusMenu[0])
  }

  if (hasPermission({ scopes: [co2Menu[0].permission] })) {
    structure.push(co2Menu[0])
  }

  if (hasPermission({ scopes: [driversMonitoringMenu[0].permission] })) {
    structure.push(driversMonitoringMenu[0])
  }

  const dashboardPermissions = dashboardMenu.map(obj => obj.permission);
  if (hasPermission({ scopes: dashboardPermissions })) {
    const total = countPermissions(dashboardPermissions);
    // menu com filhos
    if (total > 1) {
      let structureWithChildren = []
      dashboardMenu.forEach(item => {
        if (!item.isMain && hasPermission({ scopes: [item.permission] })) {
          structureWithChildren.push(getChildrenMenu(item))
        }
      })
      let mainItem = dashboardMenu.filter(elm => elm.isMain)[0];
      mainItem.children = structureWithChildren;
      structure.push(mainItem)
    } else {
      // menu sem filhos
      dashboardMenu.forEach(item => {
        if (!item.isMain && hasPermission({ scopes: [item.permission] })) {
          structure.push(getMainMenu(item))
        }
      })
    }
  }

  const operationsPermissions = operationMenu.map(obj => obj.permission);
  if (hasPermission({ scopes: operationsPermissions })) {
    let total = countPermissions(operationsPermissions);
    // menu com filhos
    if (total > 1) {
      let structureWithChildren = []
      operationMenu.forEach(item => {
        if (!item.isMain && hasPermission({ scopes: [item.permission] })) {
          structureWithChildren.push(getChildrenMenu(item))
        }
      })
      let mainItem = operationMenu.filter(elm => elm.isMain)[0];
      mainItem.children = structureWithChildren;
      structure.push(mainItem)
    } else {
      // menu sem filhos
      operationMenu.forEach(item => {
        if (!item.isMain && hasPermission({ scopes: [item.permission] })) {
          structure.push(getMainMenu(item))
        }
      })
    }
  }

  return structure
}
