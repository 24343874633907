import React from "react";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { format } from "date-fns";
import Rating from "@material-ui/lab/Rating";
import { makeValueStars } from "helpers/functions";
import { Tooltip } from "@material-ui/core";
import makeStyles from "./styles";

export const makeColumns = (size) => {
  const classes = makeStyles();
  const returnOptions = [
    {
      name: "vehicleIdentification",
      label: "Frota",
    },
    {
      name: "achievedMileage",
      label: "Dist. Percorrida",
      options: {
        customBodyRender: (value) => {
          return <Typography variant="body2">{value.toFixed(2)}</Typography>;
        },
      },
    },
    {
      name: "score",
      label: "Nota Geral",
    },
    {
      name: "extraEconomicDrivingScore",
      label: "Início da Faixa Verde",
      options: {
        customBodyRender: (value) => {
          return (
            <Tooltip title={`Nota: ${value}`}>
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "leveragingScore",
      label: "Aproveitamento de Embalo",
      options: {
        customBodyRender: (value) => {
          return (
            <Tooltip title={`Nota: ${value}`}>
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "idleScore",
      label: "Motor Ligado Parado",
      options: {
        customBodyRender: (value) => {
          return (
            <Tooltip title={`Nota: ${value}`}>
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "powerScore",
      label: "Acelerando Acima do Verde",
      options: {
        customBodyRender: (value) => {
          return (
            <Tooltip title={`Nota: ${value}`}>
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "cruiseControlScore",
      label: "Piloto Automático",
      options: {
        customBodyRender: (value) => {
          return (
            <Tooltip title={`Nota: ${value}`}>
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "defensiveDrivingScore",
      label: "Direção Defensiva",
      options: {
        customBodyRender: (value) => {
          return (
            <Tooltip title={`Nota: ${value}`}>
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "throttlePressureScore",
      label: "Pressão do Acelerador",
      options: {
        customBodyRender: (value, tableMeta) => {
          const tableData = tableMeta.tableData[tableMeta.rowIndex];
          return tableData[tableData.length - 1] ? (
            <Tooltip title={`Nota: ${value}`}>
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                />
              </Box>
            </Tooltip>
          ) : (
            <Typography variant="body2">Não avaliado</Typography>
          );
        },
      },
    },
    {
      name: "id",
      label: "id",
      options: {
        display: false,
        searchable: false,
        filter: false,
        viewColumns: false,
        hideColumn: true,
      },
    },
    {
      name: "hasThrottlePenalizer",
      label: "hasThrottlePenalizer",
      options: {
        display: false,
        searchable: false,
        filter: false,
        viewColumns: false,
        hideColumn: true,
      },
    },
  ];
  return size.mobile
    ? [
        {
          name: "resumeMobile",
          label: "Data",
          options: {
            customBodyRender: (value) => {
              return (
                <Typography
                  variant="body2"
                  className={classes.textBold}
                  noWrap={true}
                >
                  {format(new Date(value.origin), "dd/MM/yyyy")} -{" "}
                  {format(new Date(value.destination), "dd/MM/yyyy")}
                </Typography>
              );
            },
          },
        },
        {
          name: "origin",
          label: "Origem",
          options: {
            customBodyRender: (value) => {
              if (value.date) {
                return (
                  <Typography
                    variant="body2"
                    className={classes.textBold}
                    noWrap={true}
                  >
                    {format(new Date(value.date), "HH:mm")} - {value.name}
                  </Typography>
                );
              }
            },
          },
        },
        {
          name: "destination",
          label: "Destino",
          options: {
            customBodyRender: (value) => {
              return (
                <Typography
                  variant="body2"
                  className={classes.textBold}
                  noWrap={true}
                >
                  {format(new Date(value.date), "HH:mm")} - {value.name}
                </Typography>
              );
            },
          },
        },
        ...returnOptions,
      ]
    : [
        {
          name: "origin",
          label: "Origem",
          options: {
            customBodyRender: (value) => {
              if (value.date) {
                return (
                  <Typography
                    variant="body2"
                    className={classes.textBold}
                    noWrap={true}
                  >
                    {format(new Date(value.date), "dd/MM/yyyy HH:mm")} -{" "}
                    {value.name}
                  </Typography>
                );
              }
            },
          },
        },
        {
          name: "destination",
          label: "Destino",
          options: {
            customBodyRender: (value) => {
              return (
                <Typography
                  variant="body2"
                  className={classes.textBold}
                  noWrap={true}
                >
                  {format(new Date(value.date), "dd/MM/yyyy HH:mm")} -{" "}
                  {value.name}
                </Typography>
              );
            },
          },
        },
        ...returnOptions,
      ];
};
