import React, { useState } from "react";
import { IconButton, Grid, Typography } from "@material-ui/core";
import { DeleteOutline } from "@material-ui/icons";

import { format } from "date-fns";

export const makeColumns = ({ onUnlink }) => {
  let current = null
  return [
    {
      name: "identification",
      label: "Nome",
    },
    {
      name: "startDate",
      label: "Data inicial",
      options: {
        customBodyRender: (value) => (
          <Typography variant="body2">
            {format(new Date(value), "dd/MM/yyyy HH:mm")}
          </Typography>
        ),
      },
    },
    {
      name: "endDate",
      label: "Data Final",
      options: {
        customBodyRender: (value) => {
          if(value) {
            return (
              <Typography variant="body2">{format(new Date(value), "dd/MM/yyyy HH:mm")}</Typography>
            )
          } else {
            current = true
            return (
              <Typography variant="body2">Vínculo atual</Typography>
            )
          }
        },
          // value ? (
          //   <Typography variant="body2">{format(new Date(value), "dd/MM/yyyy HH:mm")}</Typography>
          // ) : (
          //   <Typography variant="body2">Vínculo atual</Typography>
          // )
      },
    },
    {
      name: "id",
      label: "Ações",
      options: {
        sort: false,
        customBodyRender: (value, metaData) => {
          if (!metaData.rowData[2]) {
            return (
              <Grid container>
                <Grid item>
                  <IconButton
                    onClick={() => onUnlink(value)}
                    aria-label="unlink"
                    title="Desvincular"
                  >
                    <DeleteOutline />
                  </IconButton>
                </Grid>
              </Grid>
            );
          }
        },
      },
    },
  ];
};
