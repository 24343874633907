import React from "react";
import Paper from "@material-ui/core/Paper";
import { Grid } from "@material-ui/core";
import Icon from "@mdi/react";
import colors from '../../themes/gobrax'
import useStyles from "./styles";
import { useWindowSize } from "hooks/useWindowsSize";

const StatisticsCard = (props) => {
  const classes = useStyles();
  const size = useWindowSize();
  return (
    <Paper
      elevation={0}
      style={size.mobile ? { display: "flex", minHeight: 100 } : {}}
      className={classes.container}
    >
      <Grid container justifyContent={"space-between"} alignItems="center">
        <Grid item xs={2} className={classes.contentIcon}>
          <Icon
            path={props.icon}
            color={colors.palette.secondary.contrastText}
            size={1}
          />
        </Grid>
        <Grid item xs={10}>
          <Grid container direction={'column'} alignItems='flex-end'>
            <Grid item className={classes.title}>
              {props.title}
            </Grid>
            <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
              <Grid container alignItems="flex-end">
                <Grid className={classes.label}>
                  {props.label} {props.unit}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default StatisticsCard;
