import { useCallback, useEffect, useState } from 'react';
import { useAppSelector } from 'redux/store';
import { getUserSelection } from 'services/userSelection';

const useUserTableColumns = (tableName) => {
  const { userId } = useAppSelector((state) => state.global.user);
  const [loadingColumns, setLoadingColumns] = useState(true);
  const [userCustomizationColumns, setUserCustomizationColumns] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      const response = await getUserSelection(tableName, userId);
      setUserCustomizationColumns(response);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingColumns(false);
    }
  }, []);

  useEffect(() => {
    if (tableName) {
      fetchData();
    }
  }, [tableName, fetchData]);

  return { loadingColumns, userCustomizationColumns };
}

export default useUserTableColumns;
