import React, { useCallback, useEffect, useState } from 'react'
import { Button, Grid, Paper, Typography } from '@material-ui/core'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { toast } from 'react-toastify'
import { format } from 'date-fns'
import { useLocation } from 'react-router-dom'
import sign from 'jwt-encode'
import classnames from 'classnames'

// hoc
import Aux from 'hoc/auxiliar'

// components
import PermissionsGate, { hasPermission } from 'components/PermissionsGate'
import ConsolidatedScore from './components/ConsolidatedScore'
import ConsolidatedScoreSkeleton from './components/ConsolidatedScore/Skeleton'
import ConsolidatedStatistics from './components/ConsolidatedStatistics'
import ConsolidatedStatisticsSkeleton from './components/ConsolidatedStatistics/Skeleton'
import ConsolidatedIndicators from './components/ConsolidatedIndicators'
import ConsolidatedIndicatorsSkeleton from './components/ConsolidatedIndicators/Skeleton'
import ConsolidatedBrand from './components/ConsolidatedBrand'
import ConsolidatedBrandSkeleton from './components/ConsolidatedBrand/Skeleton'
import ConsolidatedProductivity from './components/ConsolidatedProductivity'
import ConsolidatedProductivitySkeleton from './components/ConsolidatedProductivity/Skeleton'
import ConsolidatedRisk from './components/ConsolidatedRisk'
import ConsolidatedRiskSkeleton from './components/ConsolidatedRisk/Skeleton'
import ConsolidatedGroups from './components/ConsolidatedGroups'
import ConsolidatedGroupsSkeleton from './components/ConsolidatedGroups/Skeleton'
import ConsolidatedVehicles from './components/ConsolidatedVehicles'
import ConsolidatedVehiclesSkeleton from './components/ConsolidatedVehicles/Skeleton'

// services
import { getCustomersById } from 'services/customers'
import { getOperations } from 'services/operations'
import { formatVehicleGroups, generateRange, getYearsOptions } from 'helpers/operations'
import { getVehiclesByOperation } from 'services/vehicle'

// helpers
import { orderVehiclesByCustomer } from 'helpers/vehicles'
import { defaultOperation } from 'helpers/follow'

// context
import { useLayoutState } from 'context/LayoutContext'

// styles
import useStyles from './styles'
import { colourStyles } from './colourStyles'
import { store, useAppDispatch, useAppSelector } from 'redux/store'
import { getAccidentRisk, getFleetBrand, getFleetOperation, getFleetProductivity, getFleetVehicle } from 'services/consolidated'
import { getFleetPerformance, getFleetStatistics } from 'services/fleetPerformance'
import { clearConsolidatedFilters, setFilters } from 'redux/features/consolidatedSlice'
import { saveCustomerProfile } from 'redux/features/customerProfileSlice'
import DriversMonitoringSkeleton from 'components/Skeletons/DriversMonitoringSkeleton'
import Infobox from 'components/Infobox'

export default function Consolidated() {
  const dispatch = useAppDispatch()
  const { currentCustomer } = useAppSelector((state) => state.global.user)
  const { filters, fields } = useAppSelector((state) => state.consolidated);
  const { profile } = useAppSelector((state) => state.customerProfile);
  const { state } = useLocation()
  const classes = useStyles()
  const animatedComponents = makeAnimated()
  const yearsOptions = getYearsOptions()
  const layoutState = useLayoutState()

  const [cutOffDatesOptions, setCutOffDatesOptions] = useState(null)
  const dateFormatTemplate = "yyyy-MM-dd'T'HH:mm:ssXX"
  const today = new Date()
  const [accidentRisk, setAccidentRisk] = useState([])
  const [fleetBrand, setFleetBrand] = useState([])
  const [operationGroup, setOperationGroup] = useState([])
  const [fleetVehicle, setFleetVehicle] = useState([])
  const [fleetProductivity, setFleetProductivity] = useState([])
  const [selectedYear, setSelectedYear] = useState({
    value: today.getFullYear(),
    label: today.getFullYear(),
  })
  const [operationsOptions, setOperationsOptions] = useState([])
  const [customersDates, setCustomersDates] = useState(null)

  const [consumption, setConsumption] = useState({
    idleConsumption: "",
    movementConsumption: "",
  });

  const [vehicles, setVehicles] = useState([]);
  const [indicators, setIndicators] = useState([]);
  const [indicatorsByOperation, setIndicatorsByOperation] = useState([])
  const [indicatorsPressure, setIndicatorsPressure] = useState([])
  const [statistics, setStatistics] = useState([])
  const [selectedVehicles, setSelectedVehicles] = useState([])
  const [selectedDates, setSelectedDates] = useState({
    initialDate: state
      ? format(new Date(state.startDate), dateFormatTemplate)
      : format(new Date(), "yyyy-MM-dd'T'00:00:00XX"),
    finalDate: state
      ? format(new Date(state.finishDate), dateFormatTemplate)
      : format(new Date(), "yyyy-MM-dd'T'23:59:59XX"),
  })
  const [loading, setLoading] = useState(true)
  const [loadingData, setLoadingData] = useState({
    consolidatedScore: false,
    consolidatedStatistics: false,
    consolidatedIndicators: false,
    consolidatedBrand: false,
    consolidatedProductivity: false,
    consolidatedRisk: false,
    consolidatedOperation: false,
    consolidatedVehicles: false,
  })

  const linkPrint = () => {
    const token = require('crypto').randomBytes(256).toString('base64');
    const dataToPrint = {
      consumption: consumption,
      indicators: indicators,
      indicatorsByOperation: indicatorsByOperation,
      indicatorsPressure: indicatorsPressure,
      selectedVehicles: selectedVehicles,
      statistics: statistics,
      vehicleProps: {
        vehicleIdentification: selectedVehicles[0].identification,
        startDate: selectedDates.initialDate,
        finishDate: selectedDates.finalDate,
      }
    };
    const jwtData = sign(dataToPrint, token);
    localStorage.setItem('printData', jwtData);
  };

  const PrintButton = React.forwardRef(({ navigate, ...props }, ref) => {
    return (
      <Button
        fullWidth
        variant="contained"
        className={classnames(classes.btnPrint, {
          [classes.btBarginTop]: state
        })}
        onClick={() => linkPrint()}
        ref={ref}
        {...props}
      >
        {props.children}
      </Button>
    )
  })

  const handleCutOffDates = useCallback((customerOptions, year, isInitialRender = true) => {
    const { starting_day, period, finishing_day } = customerOptions
    const currentYear = year.value
    if (starting_day && finishing_day && !isNaN(period) && currentYear) {
      const cutOffDate = generateRange(starting_day, finishing_day, period, currentYear);
      setCutOffDatesOptions(cutOffDate);
        const selectedMonth = today.getDate() > finishing_day ? today.getMonth() + 1 : today.getMonth()
        dispatch(setFilters({ selectedOption: isInitialRender ? cutOffDate[selectedMonth] : null, name: 'cutoffDate' }))
    } else {
      setCutOffDatesOptions(null);
      dispatch(setFilters({ selectedOption: null, name: 'cutoffDate' }))
    }
  }, [])

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const operationsResponse = await getOperations(currentCustomer);
      const customersResponse = await getCustomersById(currentCustomer);

      if (operationsResponse.status !== 200) {
        throw new Error("Error fetching operations");
      }

      if (customersResponse.status !== 200) {
        throw new Error("Error fetching customers");
      }

      const operationData = operationsResponse?.data?.data?.operation;
      if (operationData) {
        const flatArrayAllVehicles = formatVehicleGroups(operationData)
        .flatMap(item => item.vehicles);
        const newOperation = { ...defaultOperation, vehicles: flatArrayAllVehicles };
        const newArrayGroups = [newOperation, ...formatVehicleGroups(operationData)];
        setOperationsOptions(newArrayGroups);
      }
      const customersOptions = customersResponse.data.customers[0];
      dispatch(saveCustomerProfile(customersOptions))
      handleCutOffDates(customersOptions, filters.year)
    } catch (err) {
      toast.error("Erro ao buscar dados. Contate o suporte.");
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchVehicles = useCallback(async () => {
    setLoading(true)
    try {
      const response = await getVehiclesByOperation(currentCustomer)
      if (state && response.data.customers.length > 0) {
        const returnData = response.data.customers;
        returnData
          .map((o) =>
            o.vehicles.filter((truck) => {
              if (truck.id === state.vehicleId.id) {
                const model = {
                  ...truck,
                  operation: { id: o.id, name: o.name }
                }
                setSelectedVehicles([model])
              }
            }),
          )
      } else {
        setSelectedVehicles([])
      }
      let orderedVehiclesByCustomer = orderVehiclesByCustomer(response.data.customers)
      setVehicles(orderedVehiclesByCustomer)
    } catch (err) {
      setVehicles([])
      toast.error(
        "Erro ao carregar lista de Veículos. Entre em contato com o suporte",
      )
    } finally {
      setLoading(false)
    }
  }, [currentCustomer, state])

  const fetchAccidentRisk = async (vehicleIds, startDate, finishDate) => {
    try {
      setLoadingData(prevState => ({
        ...prevState,
        consolidatedRisk: true
      }))
      const response = await getAccidentRisk(vehicleIds, startDate, finishDate)
      if (response.status !== 200) {
        throw new Error()
      }
      setAccidentRisk(response.data.data)
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingData(prevState => ({
        ...prevState,
        consolidatedRisk: false
      }))
    }
  }

  const fetchFleetBrand = async (vehicleIds, startDate, finishDate) => {
    try {
      setLoadingData(prevState => ({
        ...prevState,
        consolidatedBrand: true
      }))
      const response = await getFleetBrand(vehicleIds, startDate, finishDate)
      if (response.status !== 200) {
        throw new Error()
      }

      setFleetBrand(response.data.data)
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingData(prevState => ({
        ...prevState,
        consolidatedBrand: false
      }))
    }
  }

  const fetchFleetOperation = async (vehicleIds, startDate, finishDate) => {
    try {
      setLoadingData(prevState => ({
        ...prevState,
        consolidatedOperation: true
      }))
      const response = await getFleetOperation(vehicleIds, startDate, finishDate)
      if (response.status !== 200) {
        throw new Error()
      }
      setOperationGroup(response.data.data)
    } catch (error) {

    } finally {
      setLoadingData(prevState => ({
        ...prevState,
        consolidatedOperation: false
      }))
    }
  }

  const fetchFleetVehicle = async (vehicleIds, startDate, finishDate) => {
    try {
      setLoadingData(prevState => ({
        ...prevState,
        consolidatedVehicles: true
      }))
      const response = await getFleetVehicle(vehicleIds, startDate, finishDate)
      if (response.status !== 200) {
        throw new Error()
      }
      setFleetVehicle(response.data.data)
    } catch (error) {

    } finally {
      setLoadingData(prevState => ({
        ...prevState,
        consolidatedVehicles: false
      }))
    }
  }

  const fetchFleetProductivity = async (vehicleIds, startDate, finishDate) => {
    try {
      setLoadingData(prevState => ({
        ...prevState,
        consolidatedProductivity: true
      }))
      const response = await getFleetProductivity(vehicleIds, startDate, finishDate)
      if (response.status !== 200) {
        throw new Error()
      }

      setFleetProductivity(response.data.data)
    } catch (error) {

    } finally {
      setLoadingData(prevState => ({
        ...prevState,
        consolidatedProductivity: false
      }))
    }
  }

  const fetchFleetStatistics = async (vehicleIds, startDate, finishDate) => {
    try {
      setLoadingData(prevState => ({
        ...prevState,
        consolidatedStatistics: true
      }))
      const response = await getFleetStatistics(vehicleIds, startDate, finishDate)
      if (response.status !== 200) {
        throw new Error()
      }
      setStatistics(response.data)
    } catch (error) {

    } finally {
      setLoadingData(prevState => ({
        ...prevState,
        consolidatedStatistics: false
      }))
    }
  }

  const fetchPerformance = async (vehicleIds, startDate, finishDate) => {
    try {
      setLoadingData(prevState => ({
        ...prevState,
        consolidatedIndicators: true
      }))
      const response = await getFleetPerformance(vehicleIds, startDate, finishDate)
      if (response.status !== 200) {
        throw new Error()
      }
      setIndicators(response.data.fleetPerformance)
    } catch (error) {

    } finally {
      setLoadingData(prevState => ({
        ...prevState,
        consolidatedIndicators: false
      }))
    }
  }

  const handleDataByOperation = async (operation, cutoffDate) => {
    const { startDate, finishDate } = JSON.parse(cutoffDate);
    const formattedStartDate = format(new Date(startDate), dateFormatTemplate);
    const formattedFinishDate = format(new Date(finishDate), dateFormatTemplate);
    const listVehicleByOperation = operation.vehicles?.map((elm) => elm.vehicleId)
    const modelFormattedCutoffDates = {
      startDate: formattedStartDate,
      finishDate: formattedFinishDate
    }
    if (operation && cutoffDate) {
      fetchAccidentRisk(listVehicleByOperation, modelFormattedCutoffDates.startDate, modelFormattedCutoffDates.finishDate)
      fetchFleetBrand(listVehicleByOperation, modelFormattedCutoffDates.startDate, modelFormattedCutoffDates.finishDate)
      fetchFleetOperation(listVehicleByOperation, modelFormattedCutoffDates.startDate, modelFormattedCutoffDates.finishDate)
      fetchFleetVehicle(listVehicleByOperation, modelFormattedCutoffDates.startDate, modelFormattedCutoffDates.finishDate)
      fetchFleetProductivity(listVehicleByOperation, modelFormattedCutoffDates.startDate, modelFormattedCutoffDates.finishDate)
      fetchFleetStatistics(listVehicleByOperation, modelFormattedCutoffDates.startDate, modelFormattedCutoffDates.finishDate)
      fetchPerformance(listVehicleByOperation, modelFormattedCutoffDates.startDate, modelFormattedCutoffDates.finishDate)
    }
  };

  const onChangeFilters = (selectedOption, name) => {
    try {
      setLoading(true)
      dispatch(setFilters({ selectedOption, name }))
      const updatedFilters = store.getState().consolidated.filters;
      if (name === 'year') {
        dispatch(setFilters({ selectedOption: null, name: 'cutoffDate' }))
        const isInitialRender = false
        handleCutOffDates(profile, updatedFilters.year, isInitialRender)
      } else if (!!updatedFilters.operation && !!updatedFilters.cutoffDate) {
        handleDataByOperation(updatedFilters.operation, updatedFilters.cutoffDate);
      }
    } catch (error) {

    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    if (selectedYear) {
      customersDates && handleCutOffDates(customersDates);
    }
  }, [selectedYear])

  useEffect(() => {
    if (currentCustomer && hasPermission({ scopes: ['can_view_consolidated'] })) {
      fetchData()
      fetchVehicles()
      dispatch(clearConsolidatedFilters())
    }
  }, [currentCustomer])

  if ((loading) && filters.operation?.id >= 0) return <DriversMonitoringSkeleton />;

  const Filters = () => (
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.spacingContainer}>
      <Grid container justifyContent="space-around">
        <Typography variant="h3" className={classes.title}>Minha Frota</Typography>
        {operationsOptions.lenght === 0 ? (
          <Paper elevation={0} className={classes.center}>
            <Typography variant="h5">
              Não foram encontradas operações disponíveis.
            </Typography>
          </Paper>
        ) : (
          <Paper elevation={0} className={classes.paper}>
            <Select
              name="operation"
              placeholder="Selecione uma operação"
              options={operationsOptions}
              styles={colourStyles}
              onChange={(selectedOption) => onChangeFilters(selectedOption, "operation")}
              value={filters.operation}
              defaultValue={filters.operation}
              components={animatedComponents}
              noOptionsMessage={() => "Nenhum resultado encontrado!"}
            />
          </Paper>
        )}
        <Paper elevation={0} className={classes.paperSelect}>
          <Select
            name="year"
            placeholder="Ano"
            options={yearsOptions.reverse()}
            styles={colourStyles}
            onChange={(selectedOption) => onChangeFilters(selectedOption, "year")}
            value={filters.year}
            defaultValue={filters.year}
            components={animatedComponents}
          />
        </Paper>
        {cutOffDatesOptions === null ? (
          <Paper elevation={0} className={classes.center}>
            <Typography>Não foram encontradas data de corte.</Typography>
          </Paper>
        ) : (
          <Paper elevation={0} className={classes.paper}>
            <Select
              name='cutoffDate'
              placeholder="Selecione a data de corte"
              options={cutOffDatesOptions}
              styles={colourStyles}
              components={animatedComponents}
              onChange={(selectedOption) => onChangeFilters(selectedOption, "cutoffDate")}
              value={JSON.parse(filters.cutoffDate)}
            />
          </Paper>
        )}
      </Grid>
    </Grid>
  )

  const Score = () => (
    <PermissionsGate scopes={['can_view_consolidated_score']}>
      <Grid
        item
        xl={4}
        lg={4}
        md={4}
        sm={12}
        xs={12}
        data-cy="consolidatedScore"
      >
        <Paper elevation={0} className={classes.card}>
          <Grid container alignItems="center">
            {loadingData.consolidatedIndicators ? <ConsolidatedScoreSkeleton /> :
              <ConsolidatedScore data={indicators} />
            }
          </Grid>
        </Paper>
      </Grid>
    </PermissionsGate>
  )

  const Statistics = ({ data, selectedVehicles }) => {
    return (
      <PermissionsGate scopes={['can_view_consolidated_statistics']}>
        <Grid
          item
          xl={8}
          lg={8}
          md={8}
          sm={12}
          xs={12}
          data-cy="consolidatedStatistics"
        >
          <Paper elevation={0} className={classes.card}>
            <Grid container>
              {loadingData.consolidatedStatistics ? <ConsolidatedStatisticsSkeleton /> :
                <ConsolidatedStatistics data={data} selectedVehicles={selectedVehicles} />
              }
            </Grid>
          </Paper>
        </Grid>
      </PermissionsGate>
    )
  }

  const Indicators = ({data}) => (
    <PermissionsGate scopes={['can_view_consolidated_indicators']}>
      <Grid
        item
        xs={12}
        data-cy="consolidatedIndicators"
      >
        <Grid container>
          {loadingData.consolidatedIndicators ? <ConsolidatedIndicatorsSkeleton /> :
            <ConsolidatedIndicators data={data} />
          }
        </Grid>
      </Grid>
    </PermissionsGate>
  )

  const Brand = (data) => (
    <PermissionsGate scopes={['can_view_consolidated_brand']}>
      <Grid
        item
        xs={12}
        data-cy="consolidatedBrand"
      >
        <Paper elevation={0} className={classes.card}>
          <Grid container>
            {loadingData.consolidatedBrand ? <ConsolidatedBrandSkeleton /> :
              <ConsolidatedBrand data={data} />
            }
          </Grid>
        </Paper>
      </Grid>
    </PermissionsGate>
  )

  const Productivity = (data) => (
    <PermissionsGate scopes={['can_view_consolidated_productivity']}>
      <Grid
        item
        xs={12}
        data-cy="consolidatedProductivity"
      >
        <Paper elevation={0} className={classes.card}>
          <Grid container>
            {loadingData.consolidatedProductivity ? <ConsolidatedProductivitySkeleton /> :
              <ConsolidatedProductivity data={data} />
            }
          </Grid>
        </Paper>
      </Grid>
    </PermissionsGate>
  )

  const Risk = (data) => (
    <PermissionsGate scopes={['can_view_consolidated_risk']}>
      <Grid
        item
        xs={12}
        data-cy="consolidatedRisk"
      >
        <Paper elevation={0} className={classes.card}>
          <Grid container>
            {loadingData.consolidatedRisk ? <ConsolidatedRiskSkeleton /> :
              <ConsolidatedRisk data={data} />
            }
          </Grid>
        </Paper>
      </Grid>
    </PermissionsGate>
  )

  const Groups = (data) => (
    <PermissionsGate scopes={['can_view_consolidated_groups']}>
      <Grid
        item
        xs={12}
        data-cy="consolidatedOperation"
      >
        <Paper elevation={0} className={classes.card}>
          <Grid container>
            {loadingData.consolidatedOperation ? <ConsolidatedGroupsSkeleton /> :
              <ConsolidatedGroups data={data} />
            }
          </Grid>
        </Paper>
      </Grid>
    </PermissionsGate>
  )

  const Vehicles = (data) => (
    <PermissionsGate scopes={['can_view_consolidated_vehicles']}>
      <Grid
        item
        xs={12}
        data-cy="consolidatedVehicles"
      >
        <Paper elevation={0} className={classes.card}>
          <Grid container>
            {loadingData.consolidatedVehicles ? <ConsolidatedVehiclesSkeleton /> :
              <ConsolidatedVehicles data={data} />
            }
          </Grid>
        </Paper>
      </Grid>
    </PermissionsGate>
  )

  return (
    <Aux>
      <PermissionsGate scopes={['can_view_consolidated']}>
        <Filters />
        {(filters.operation && filters.cutoffDate && filters.year ) ? (

          <Grid
            container
            spacing={2}
            data-cy="contentConsolidated"
            className={classnames(classes.container, {
              [classes.containerShift]: layoutState.isSidebarOpened,
            })}
          >
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container spacing={2}>
                <Score />
                <Statistics data={statistics} selectedVehicles={[133, 589, 557]} />
                <Indicators data={indicators} />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Grid container spacing={2}>
                    <Brand data={fleetBrand} />
                    <Productivity data={fleetProductivity} />
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Risk data={accidentRisk} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Groups data={operationGroup} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Vehicles data={fleetVehicle} />
            </Grid>
          </Grid>
        ) : <Infobox />}
      </PermissionsGate>
    </Aux>
  );
}

