export function generateRange(startDay, finishDay, period, year) {
  const options = [];
  const monthRelation = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];

  // same month 
  if (period === 0) {
    for (let month = 0; month < 12; month++) {

      const totalDaysMonth = new Date(year, month + 1, 0).getDate();
      const verifiedStartDay = startDay > totalDaysMonth ? totalDaysMonth : startDay;
      const verifiedFinishDay = finishDay > totalDaysMonth ? totalDaysMonth : finishDay;

      const startDate = new Date(year, month, verifiedStartDay);
      const finishDate = new Date(year, month, verifiedFinishDay);

      const option = {
        label: `${verifiedStartDay} de ${monthRelation[month]} de ${startDate.getFullYear().toString().substring(2)} a ${verifiedFinishDay} de ${monthRelation[month]} de ${finishDate.getFullYear().toString().substring(2)}`,
        startDate: startDate,
        finishDate: finishDate
      }

      options.push(option)
    }

    return options;
  }

  // different month
  for (let month = 0; month < 12; month++) {

    const totalDaysMonth = new Date(year, month + 1, 0).getDate();
    const verifiedStartDay = startDay > totalDaysMonth ? totalDaysMonth : startDay;
    const verifiedFinishDay = finishDay > totalDaysMonth ? totalDaysMonth : finishDay;

    if (month === 0) {
      const startDate = new Date(year - 1, 11, verifiedStartDay);
      const finishDate = new Date(year, month, verifiedFinishDay);

      const option = {
        label: `${verifiedStartDay} de ${monthRelation[11]} de ${startDate.getFullYear().toString().substring(2)} a ${verifiedFinishDay} de ${monthRelation[month]} de ${finishDate.getFullYear().toString().substring(2)}`,
        startDate: startDate,
        finishDate: finishDate
      }

      options.push(option)
    }
    else {
      const startDate = new Date(year, month - 1, verifiedStartDay);
      const finishDate = new Date(year, month, verifiedFinishDay);

      const option = {
        label: `${verifiedStartDay} de ${monthRelation[month - 1]} de ${startDate.getFullYear().toString().substring(2)} a ${verifiedFinishDay} de ${monthRelation[month]} de ${finishDate.getFullYear().toString().substring(2)}`,
        startDate: startDate,
        finishDate: finishDate
      }

      options.push(option)
    }
  }
  return options;
}


export const formatVehicleGroups = (list) => {
  let formatedList = [];
  list.map((item) => {
    return formatedList.push({
      ...item,
      label: item.name
    });
  })
  return formatedList;
}


export const allSelectedsVehicles = (data) => {
  const listVehicles = [];
  data.forEach((op) => {
    if (op.vehicles) {
      for (let truck of op.vehicles) {
        listVehicles.push(truck);
      }
    }
  });
  return listVehicles;
};

export const getYearsOptions = () => {
  const today = new Date();
  const yearOptions = [];
  const currentYear = today.getFullYear();
  const lastYearToShow = today.getMonth() > 10 ? currentYear+1 : currentYear;
  for (let i = 2020; i <= lastYearToShow; i++) {
    yearOptions.push({ value: i, label: i });
  }
  return yearOptions;
}