import React from "react";
import TextField from "@material-ui/core/TextField";
import { useController, useFormContext } from "react-hook-form";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

import useStyles from "./styles";

const filter = createFilterOptions();

const AutoComplete = ({ name, control, label, ...rest }) => {
  const classes = useStyles();
  const { control: controlContext } = useFormContext();
  const {
    field: { ref, ...inputPropsForm },
  } = useController({
    name,
    control: control ?? controlContext,
  });

  return (
    <Autocomplete
      {...rest}
      classes={{
        inputRoot: classes.inputText,
      }}
      onChange={(_, data) => inputPropsForm.onChange(data)}
      value={inputPropsForm.value}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        // Suggest the creation of a new value
        if (params.inputValue !== "") {
          const inputValue = rest.uppercase
            ? params.inputValue.toUpperCase()
            : params.inputValue;
          filtered.push({
            inputValue: inputValue,
            name: `Adicionar "${inputValue}"`,
          });
        }
        return filtered;
      }}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.name;
      }}
      renderOption={(option) => option.name}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            fullWidth
            inputRef={ref}
          />
        );
      }}
    />
  );
};

export default AutoComplete;
