import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  textField: {
    '& input': {
      borderRadius: '20px !important',
    },
    [`& fieldset`]: {
      borderRadius: 20,
    },
  }
}));
