import { CircularProgress, Grid } from '@material-ui/core';
import React from 'react';

const LoadingComponent = () => (
    <Grid container alignItems="center" justifyContent="center">
      <CircularProgress color="secondary" />
    </Grid>
  );


export default LoadingComponent;