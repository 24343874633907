import React from "react";
import { Typography, Tooltip } from "@material-ui/core";
import { CheckCircle, EditOutlined, ErrorOutline } from "@material-ui/icons";
import makeStyles from "./styles";
import LoadingLottie from 'assets/lotties/loading/loading3.json';
import LottieComponent from "components/LottieComponent";
import { format } from "date-fns";


export const makeColumns = ({ handleEdit }) => {
  const classes = makeStyles();
  return [
    {
      name: "startDate",
      label: "Data inicial",
      options: {
        customBodyRender: (value) => {
          return (
            <Typography variant="body2" className={classes.text400}>
              {format(new Date(value), "dd/MM/yyyy HH:mm")}
            </Typography>
          );
        },
      },
    },
    {
      name: "endDate",
      label: "Data final",
      options: {
        customBodyRender: (value) => {
          return value ? (
            <Typography variant="body2" className={classes.text400}>
              {format(new Date(value), "dd/MM/yyyy HH:mm")}
            </Typography>
          ) : (
            <Typography variant="body2" className={classes.textInfo}>
              Motorista atual
            </Typography>
          );
        },
      },
    },
    {
      name: "driverName",
      label: "Motorista",
      options: {
        customBodyRender: (value) => {
          return value ? (
            <Typography variant="body2" className={classes.text400}>
              {value}
            </Typography>
          ) : (
            <Typography variant="body2" className={classes.textInfo}>
              Sem vínculo atual
            </Typography>
          );
        },
      },
    },
    {
      name: "assignBy",
      label: "Vínculo feito por",
      options: {
        customBodyRender: (value, tableMeta) => {
          return value ? (
            <Typography variant="body2" className={classes.text400}>
              {value}
            </Typography>
          ) : (
            <Typography variant="body2" className={classes.textInfo}>
              Desconhecido
            </Typography>
          );
        },
      },
    },
    {
      name: "unassignBy",
      label: "Desvínculo feito por",
      options: {
        customBodyRender: (value) => {
          return value ? (
            <Typography variant="body2" className={classes.text400}>
              {value}
            </Typography>
          ) : (
            <Typography variant="body2" className={classes.textInfo}>
              Desconhecido
            </Typography>
          );
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value) => {
          return (
            <div className={classes.center}>
              {Number(value) === 0 ? (
                // <LottieComponent width={60} height={60} animationData={CheckGreenLottie} loop={false} />
                <CheckCircle size={22} className={classes.checkIcon} />
              ) : Number(value) === 1 ? (
                // <LottieComponent animationData={LoadingLottie} width={40} height={40} />
                <LottieComponent animationData={LoadingLottie} width={40} height={40} />
              ) : (
                // <LottieComponent animationData={ErrorLottie} width={60} height={60} loop={false} />
                <ErrorOutline size={22} className={classes.errorIcon} />
              )}
            </div>
          );
        },
      },
    },
    {
      name: "driverId",
      label: "Ações",
      options: {
        sort: false,
        filter: false,
        viewColumns: false,
        customBodyRender: (value, metaData) => {
          return (
            <div className={classes.actionContent}>
              <Tooltip title="Editar">
                <EditOutlined size={22}
                  onClick={() => handleEdit(metaData.rowData)}
                  className={classes.actionButton}
                />
              </Tooltip>
            </div>
          );
        },
      },
    },
  ];
};
