import React, { useEffect, useState } from 'react'
import { Grid, Paper, Typography } from '@material-ui/core'

import { getNameIndicator } from 'helpers/indicators'
import HalfMoonChart from 'components/HalfMoonChart'
import useStyles from './styles'
import colors from '../../../../themes/gobrax'

const ConsolidatedIndicators = ({ data: dataIndicator}) => {
  const classes = useStyles()

  const [data, setData] = useState([])

  const getColor = (value) => {
    let color = ''
    if (value <= 40) {
      color = colors.palette.error.main
    } else if (value <= 80) {
      color = colors.palette.warning.main
    } else {
      color = colors.palette.success.main
    }
    return color
  }

  const ordernateIndicators = (indicatorList) => {
    let ordenateArray = [];
    const ordenateIndicators = [
      "Inicio Faixa Verde",
      "Aproveitamento de Embalo",
      "Pressão do acelerador",
      "Motor Ligado Parado",
      "Acelerando Acima do Verde",
      "Freio Motor",
      "Piloto Automático",
      "Excesso de Velocidade",
    ];

    for (const label of ordenateIndicators) {
      for (const indicator of indicatorList) {
        if (indicator.label === label) {
          ordenateArray.push(indicator);
        }
      }
    }
    return ordenateArray;
  };

  const handleData = () => {
    const formatedData = Object.keys(dataIndicator.indicators).map(key => {
      if (key === 'throttlePressureScore') {
        return {
          key: key,
          label: getNameIndicator(key),
          color: getColor(dataIndicator.indicators[key]),
          value: dataIndicator.indicators[key],
        }
      } else {
        return {
          key: key,
          label: getNameIndicator(key),
          color: getColor(dataIndicator.indicators[key].score),
          value: dataIndicator.indicators[key].score,
        }
      }
    })
    const orderedList = ordernateIndicators(formatedData)
    setData(orderedList)
  }
  
  useEffect(() => {
    dataIndicator.indicators && handleData()
  }, [dataIndicator])

  return (
    <Grid item container spacing={2} xl={12} lg={12} md={12} sm={12} xs={12}>
      {(data.length > 0 && dataIndicator?.indicators) && data.map(item => (
        <Grid item xs={12} sm={6} md={3} lg={3} xl={3} key={item.key}>
          <Paper elevation={0} className={classes.card}>
            <Grid container direction='row' justifyContent='center'>
              <HalfMoonChart data={item} />
              <Typography variant='h6' className={classes.itemTitle}>{item.label}</Typography>
            </Grid>
          </Paper>
        </Grid>
      ))}
    </Grid>
  )
}

export default ConsolidatedIndicators;