import { timeConvert } from "./functions";
import { indicatorsOptions, penalizingOptions } from "domain/selectOptions";

  /*
  Faixa Extra Econômica = Inicio Faixa Verde
  Potência = Acelerando Acima do Verde,
  Aproveitamento de Embalo = Aproveitamento de Embalo
  Direção Defensiva = Direção Defensiva
  Piloto Automático =  Piloto Automático
  Motor Ligado Parado =  Motor Ligado Parado
  Faixa Verde = Total Faixa Verde
  Faixa Econômica = Final Faixa Verde
  Freio Motor = Freio Motor
  Eco-Roll/I-Roll = Eco-roll/I-roll
  Em Movimento(Tempo em movimento) */

export const getNameIndicator = (key) => {
  switch (key) {
    case "greenRange":
      return "Total Faixa Verde";

    case "extraEconomicRange":
      return "Inicio Faixa Verde";

    case "economicRange":
      return "Final Faixa Verde";

    case "leverage":
      return "Aproveitamento de Embalo";

    case "ecoRoll":
      return "Eco-Roll/I-Roll";

    case "cruiseControl":
      return "Piloto Automático";

    case "engineBrake":
      return "Freio Motor";

    case "powerRange":
      return "Acelerando Acima do Verde";

    case "speeding":
      return "Excesso de Velocidade";

    case "idle":
      return "Motor Ligado Parado";

    case "movement":
      return "Em Movimento";

    case "throttlePressureScore":
      return "Pressão do acelerador";

    default:
      return "";
  }
};

export const getIndicatorDuration = (indicators, nameIndicator) => {
  if (indicators.length > 0) {
    let indicator = indicators.find(function (indicator) {
      return indicator.name === nameIndicator;
    });
    return timeConvert(indicator?.duration);
  }
  return null;
};

export const getIndicatorFilterType = (indicators) => {

  let highControll = [];
  let mediumControll = [];
  let lowControll = [];
  let penalizingHighControll = [];
  let penalizingMediumControll = [];
  let penalizingLowControll = [];
  let penalizingCustomControll = [];
  let customControll = [];
  indicators.forEach((ind) => {
    let id = ind.indicator_id;
    let conduction = !ind.penalizing
      ? indicatorsOptions.find((indOpt) => indOpt.value === ind.indicator_id)
      : penalizingOptions.find((indOpt) => indOpt.value === ind.indicator_id);
    let acceptablePercentage = ind.lower_limit;
    let idealPercentage = ind.upper_limit;
    if (ind.requirement_level === "high" && !ind.penalizing) {
      highControll.push({
        ...ind,
        id,
        conduction,
        idealPercentage,
        acceptablePercentage,
      });
    } else if (ind.requirement_level === "high" && ind.penalizing) {
      penalizingHighControll.push({
        ...ind,
        id,
        conduction,
        idealPercentage,
        acceptablePercentage,
      });
    } else if (ind.requirement_level === "medium" && !ind.penalizing) {
      mediumControll.push({
        ...ind,
        id,
        conduction,
        idealPercentage,
        acceptablePercentage,
      });
    } else if (ind.requirement_level === "medium" && ind.penalizing) {
      penalizingMediumControll.push({
        ...ind,
        id,
        conduction,
        idealPercentage,
        acceptablePercentage,
      });
    } else if (ind.requirement_level === "low" && !ind.penalizing) {
      lowControll.push({
        ...ind,
        id,
        conduction,
        idealPercentage,
        acceptablePercentage,
      });
    } else if (ind.requirement_level === "low" && ind.penalizing) {
      penalizingLowControll.push({
        ...ind,
        id,
        conduction,
        idealPercentage,
        acceptablePercentage,
      });
    } else if (ind.requirement_level === "custom" && !ind.penalizing) {
      customControll.push({
        ...ind,
        id,
        conduction,
        idealPercentage,
        acceptablePercentage,
      });
    } else {
      penalizingCustomControll.push({
        ...ind,
        id,
        conduction,
        idealPercentage,
        acceptablePercentage,
      });
    }
  });
  return {
    highControll,
    mediumControll,
    lowControll,
    penalizingHighControll,
    penalizingMediumControll,
    penalizingLowControll,
    customControll,
    penalizingCustomControll,
  };
};

