import React from "react";
import { IconButton, Grid } from "@material-ui/core";
import { DeleteOutline as DeleteIcon, EditOutlined as EditIcon } from "@material-ui/icons";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";

export const makeColumns = ({ onDelete, onEdit }) => {
  let columns = [
    {
      name: "name",
      label: "Empresa",
    },
    {
      name: "document",
      label: "CNPJ",
    },
    /* {
      name: "phone_number",
      label: "Telefone",
    },
    {
      name: "email",
      label: "Email",
    }, */
  ];
  if (hasPermission({scopes: ['can_edit_customers', 'can_remove_customers']})) {
    columns.push({
      name: "id",
      label: "Ações",
      options: {
        customBodyRender: (value) => {
          return (
            <Grid container>
              <PermissionsGate scopes={['can_edit_customers']}>
                <Grid item xs={6}>
                  <IconButton
                    onClick={() => onEdit(value)}
                    aria-label="edit"
                    title="editar"
                  >
                    <EditIcon />
                  </IconButton>
                </Grid>
              </PermissionsGate>
              <PermissionsGate scopes={['can_remove_customers']}>
                <Grid item xs={6}>
                  <IconButton
                    onClick={() => onDelete(value)}
                    aria-label="delete"
                    title="Excluir"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </PermissionsGate>
            </Grid>
          );
        },
      },
    });
  }
  return columns;
};
