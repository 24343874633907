import { makeStyles } from "@material-ui/styles";
import { alpha } from "@material-ui/core";

export default makeStyles((theme) => ({
  table: {
    zIndex: -1
  },
  infoBox: {
    fontSize: theme.typography.h3.fontSize,
  },
  containerInfobox: {
    height: 450, 
    display: 'flex', 
    alignItems: 'center',
  },
  paperInfobox: {
    borderRadius: 20,
    padding: theme.spacing(1),
    maxWidth: "650px",
    margin: "0 auto",
    alignItems: "center",
    display: "flex"
  },
  paper: {
    borderRadius: 20,
    width: 350,
    maxWidth: "100%",
    padding: "4px 8px 4px 20px",
    marginBottom: 20,
    boxShadow: `3px 5px 4px ${theme.palette.background.hoverMultiMenu}`,
    height: "50px"
  },
  paperCalendar: {
    borderRadius: 20,
    width: 350,
    maxWidth: "100%",
    padding: "0",
    marginBottom: 20,
    boxShadow: `3px 5px 4px ${theme.palette.background.hoverMultiMenu}`,
    height: "50px"
  },
  okButton: {
    borderRadius: 20,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.7),
    },
  },
  containerButton: {
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: 32,
    marginBottom: 32,
    marginLeft: 32,
  },
  root: {
    borderRadius: 28
  },
  contentDataTable: {
    zIndex: 0
  },
  spacingContainer: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(2),
    },
  },
  select: {
    backgroundColor: theme.palette.white,
    borderRadius: 20,
    width: 350,
    maxWidth: "100%",
    padding: "4px 8px 4px 20px",
    marginBottom: 20,
    boxShadow: `3px 5px 4px ${theme.palette.background.hoverMultiMenu}`,
  },
}));