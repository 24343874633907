import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import optionsFilters from './optionsFilters';
import Select from 'react-select';
import { CalendarToday } from '@material-ui/icons';
import makeAnimated from 'react-select/animated';
import { colourStyles } from '../MultiSelect/styles';

const SelectMonth = (props) => {
  const makeOptions = (options) => {
    const initalOptions = options.map((item, index) => ({
      value: index,
      label: item,
    }));
    return initalOptions;
  };

  const handleDates = (date) => {
    props.onSelectDate(date);
  }

  return (
    <Paper
      style={{
        borderRadius: 20,
        maxWidth: '350px',
        margin: '0 auto',
        padding: '4px 8px 4px 20px',
      }}
    >
      <Grid container justifyContent='space-around' alignItems='center'>
        <Grid item lg={1} md={2} sm={3} xs={3}>
          <CalendarToday />
        </Grid>
        <Grid item lg={11} md={10} sm={9} xs={9}>
          <Select
            placeholder='Mês'
            isDisabled={props.disabled}
            options={makeOptions(optionsFilters.month)}
            styles={colourStyles}
            onChange={handleDates}
            components={makeAnimated()}
            value={makeOptions(optionsFilters.month).filter(
              (opt) => opt.value === props.value,
            )}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SelectMonth;
