import React from 'react';
import { Typography } from '@material-ui/core';
import makeStyles from './styles';
import classnames from 'classnames';
import { timeConvert, valueToPositiveFloat } from 'helpers/functions';

export const makeColumns = () => {
  const classes = makeStyles();

  const getCell = (data, textBold) => {
    return (
      <Typography
        variant="body2"
        className={classnames({
          [classes.textBold]: textBold,
        })}
        noWrap
      >
        {data}
      </Typography>
    );
  };

  const getCellIndicator = (value) => {
    const data = `${value?.duration ? `${valueToPositiveFloat(value?.percentage)}% (${timeConvert(value.duration)})` : "0% (00:00:00)"}`;
    return getCell(data);
  };

  return [
    {
      name: "customerName",
      label: "Empresa",
      options: {
        customBodyRender: (value) => getCell(value, true),
      },
    },
    {
      name: "identification",
      label: "Frota",
      options: {
        customBodyRender: (value) => getCell(value, true),
      },
    },
    {
      name: "plate",
      label: "Placa",
      options: {
        customBodyRender: (value) => getCell(value, true),
      },
    },
    {
      name: "brand",
      label: "Marca",
      options: {
        customBodyRender: (value) => getCell(value),
      },
    },
    {
      name: "model",
      label: "Modelo do veículo",
      options: {
        customBodyRender: (value) => getCell(value),
      },
    },
    {
      name: "fabricationYear",
      label: "Ano de fabricação",
      options: {
        customBodyRender: (value) => getCell(value),
      },
    },
    {
      name: "motor",
      label: "Motorização",
      options: {
        customBodyRender: (value) => getCell(value),
      },
    },
    {
      name: "wheelDriver",
      label: "Tração",
      options: {
        customBodyRender: (value) => getCell(value),
      },
    },
    {
      name: "operationName",
      label: "Grupo de Veículos",
      options: {
        customBodyRender: (value) => getCell(value, true),
      },
    },
    {
      name: "score",
      label: "Nota Geral",
      options: {
        customBodyRender: (value) => getCell(value, true),
      },
    },
    {
      name: "totalMileage",
      label: "Km Total",
      options: {
        customBodyRender: (value) => getCell(`${valueToPositiveFloat(value, 2)} Km`)
      },
    },
    {
      name: "averageSpeed",
      label: "Velocidade Média",
      options: {
        customBodyRender: (value) => getCell(`${valueToPositiveFloat(value, 2)} Km/h`),
      },
    },
    {
      name: "totalConsumption",
      label: "Consumo Total",
      options: {
        customBodyRender: (value) => getCell(`${valueToPositiveFloat(value, 2)} L`),
      },
    },
    {
      name: "consumptionAverage",
      label: "Média",
      options: {
        customBodyRender: (value) => getCell(`${valueToPositiveFloat(value, 2)} Km/L`),
      },
    },
    {
      name: "odometer",
      label: "Ôdometro",
      options: {
        customBodyRender: (value) => getCell(`${valueToPositiveFloat(value,)} Km`),
      },
    },
    {
      name: "totalBreakingOnHighSpeed",
      label: "Freadas em Alta Veloc.",
      options: {
        customBodyRender: (value) => getCell(value),
      },
    },
    {
      name: "totalBreaking",
      label: "Freadas totais",
      options: {
        customBodyRender: (value) => getCell(value),
      },
    },
    {
      name: "indicators.idle",
      label: "Motor Ligado Parado",
      options: {
        customBodyRender: (value) => {
          return getCellIndicator(value);
        },
        sortCompare: (order) => {
          return (obj1, obj2) =>
            (obj1?.data?.percentage - obj2?.data?.percentage) *
            (order === "asc" ? 1 : -1);
        },
      },
    },
    {
      name: "indicators.movement",
      label: "Tempo em Movimento",
      options: {
        customBodyRender: (value) => {
          return getCellIndicator(value);
        },
        sortCompare: (order) => {
          return (obj1, obj2) =>
            (obj1?.data?.percentage - obj2?.data?.percentage) *
            (order === "asc" ? 1 : -1);
        },
      },
    },
    {
      name: "indicators.greenRange",
      label: "Faixa Verde",
      options: {
        customBodyRender: (value) => {
          return getCellIndicator(value);
        },
        sortCompare: (order) => {
          return (obj1, obj2) =>
            (obj1?.data?.percentage - obj2?.data?.percentage) *
            (order === "asc" ? 1 : -1);
        },
      },
    },
    {
      name: "indicators.extraEconomicRange",
      label: "Início da Faixa Verde",
      options: {
        customBodyRender: (value) => {
          return getCellIndicator(value);
        },
        sortCompare: (order) => {
          return (obj1, obj2) =>
            (obj1?.data?.percentage - obj2?.data?.percentage) *
            (order === "asc" ? 1 : -1);
        },
      },
    },
    {
      name: "indicators.economicRange",
      label: "Faixa Econômica",
      options: {
        customBodyRender: (value) => {
          return getCellIndicator(value);
        },
        sortCompare: (order) => {
          return (obj1, obj2) =>
            (obj1?.data?.percentage - obj2?.data?.percentage) *
            (order === "asc" ? 1 : -1);
        },
      },
    },
    {
      name: "indicators.powerRange",
      label: "Acelerando Acima do Verde",
      options: {
        customBodyRender: (value) => {
          return getCellIndicator(value);
        },
        sortCompare: (order) => {
          return (obj1, obj2) =>
            (obj1?.data?.percentage - obj2?.data?.percentage) *
            (order === "asc" ? 1 : -1);
        },
      },
    },
    {
      name: "indicators.leverage",
      label: "Aproveitamento de Embalo",
      options: {
        customBodyRender: (value) => {
          return getCellIndicator(value);
        },
        sortCompare: (order) => {
          return (obj1, obj2) =>
            (obj1?.data?.percentage - obj2?.data?.percentage) *
            (order === "asc" ? 1 : -1);
        },
      },
    },
    {
      name: "indicators.speeding",
      label: "Excesso de Velocidade",
      options: {
        customBodyRender: (value) => {
          return getCellIndicator(value);
        },
        sortCompare: (order) => {
          return (obj1, obj2) =>
            (obj1?.data?.percentage - obj2?.data?.percentage) *
            (order === "asc" ? 1 : -1);
        },
      },
    },
    {
      name: "indicators.engineBrake",
      label: "Freio Motor",
      options: {
        customBodyRender: (value) => {
          return getCellIndicator(value);
        },
        sortCompare: (order) => {
          return (obj1, obj2) =>
            (obj1?.data?.percentage - obj2?.data?.percentage) *
            (order === "asc" ? 1 : -1);
        },
      },
    },
    {
      name: "indicators.cruiseControl",
      label: "Piloto Automático",
      options: {
        customBodyRender: (value) => {
          return getCellIndicator(value);
        },
        sortCompare: (order) => {
          return (obj1, obj2) =>
            (obj1?.data?.percentage - obj2?.data?.percentage) *
            (order === "asc" ? 1 : -1);
        },
      },
    },
    {
      name: "indicators.ecoRoll",
      label: "Eco-Roll/I-Roll",
      options: {
        customBodyRender: (value) => {
          return getCellIndicator(value);
        },
        sortCompare: (order) => {
          return (obj1, obj2) =>
            (obj1?.data?.percentage - obj2?.data?.percentage) *
            (order === "asc" ? 1 : -1);
        },
      },
    },
    // "Uso do Pedal Ideal", "Uso do Pedal Atenção" e "Uso do Pedal Critico"
    {
      name: "indicators.lowPressure",
      label: "Uso do Pedal Ideal",
      options: {
        customBodyRender: (value) => {
          return getCellIndicator(value);
        },
        sortCompare: (order) => {
          return (obj1, obj2) =>
            (obj1?.data?.percentage - obj2?.data?.percentage) *
            (order === "asc" ? 1 : -1);
        },
      },
    },
    {
      name: "indicators.medPressure",
      label: "Uso do Pedal Atenção",
      options: {
        customBodyRender: (value) => {
          return getCellIndicator(value);
        },
        sortCompare: (order) => {
          return (obj1, obj2) =>
            (obj1?.data?.percentage - obj2?.data?.percentage) *
            (order === "asc" ? 1 : -1);
        },
      },
    },
    {
      name: "indicators.higPressure",
      label: "Uso do Pedal Critico",
      options: {
        customBodyRender: (value) => {
          return getCellIndicator(value);
        },
        sortCompare: (order) => {
          return (obj1, obj2) =>
            (obj1?.data?.percentage - obj2?.data?.percentage) *
            (order === "asc" ? 1 : -1);
        },
      },
    },
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
        hideColumn: true,
      },
    },
  ];
};
