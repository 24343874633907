import axios from "axios";

const login = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_LINK_API_LOGIN}/sessions/whoami`,
    { withCredentials: true },
  );
  const { ok, status, data } = response;

  if (!ok && status !== 200) {
    throw new Error("Unauthorized");
  }

  return data;
};

const me = async () => {
  const resp = await fetch(`${process.env.REACT_APP_LINK_API_LOGIN}/sessions/whoami`,);
  return resp.json;
};

export default {
  login,
  me,
};
