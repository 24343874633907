import { Checkbox, Radio } from '@material-ui/core';
import React from 'react'
import gobrax from "themes/gobrax";

export const CustomCheckbox = (props) => {
  let newProps = Object.assign({}, props);

  if (props['data-description'] === 'row-select') {
    return (<Radio {...newProps} style={{ color: gobrax.palette.secondary.contrastText }} />);
  } else {
    return (<Checkbox {...newProps} style={{ color: gobrax.palette.secondary.main }} />);
  }
};