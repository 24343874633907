export default class Driver {
  constructor({
    name,
    documentNumber,
    phoneNumber,
    email,
    customerId,
    birthDate,
    note,
    canLink,
    id = "",
  }) {
    this.id = id;
    this.name = name;
    this.documentNumber = documentNumber;
    this.phoneNumber = phoneNumber;
    this.email = email;
    this.customerId = customerId;
    this.birthDate = birthDate;
    this.note = note;
    this.canLink = canLink;
  }
}
