import { create } from 'apisauce';
import { clearCustomerProfile } from 'redux/features/customerProfileSlice';
import { logout } from 'redux/globalSlice';
import { store } from 'redux/store';

const baseURL = `${process.env.REACT_APP_LINK_API}`;

// define the api
const api = create({
  baseURL: baseURL,
  timeout: 240000,
});

api.axiosInstance.interceptors.request.use(
  (config) => {
    const { token } = store.getState().global.user;

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.log('error', error);
    Promise.reject(error);
  },
);

api.axiosInstance.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this  to => trigger
    // Do something with response data
    return response;
  },
  (error) => {
    if (error.response.data.code === 401) {
      localStorage.clear();
      store.dispatch(logout());
      store.dispatch(clearCustomerProfile());
      window.location.href = `${process.env.REACT_APP_LINK_API_LOGIN}/self-service/browser/flows/logout`;
    }
    return Promise.reject(error);
  }
);

api.addResponseTransform((response) => {
  if (!response.ok) {
    throw response;
  }
});

export default api;

