import React from 'react'
import { useHistory, useParams } from "react-router-dom"
import { Grid, Button, Box, Typography } from "@material-ui/core";
import { useQuery, useQueryClient } from "react-query"
import { toast } from "react-toastify";
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import { CustomerForm } from "components/CustomerForm";
import FormCustomerSkeleton from 'components/Skeletons/FormCustomerSkeleton';
import BackButton from "components/BackButton";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";
import { getRewardGroupSelecteds, periodTypeOptions } from 'helpers/functions';
import { makeSelectOptions } from 'helpers/makeSelectOptions';
import { errorsMessages } from 'helpers/errors';
import editCustomerValidationForm from "domain/validation/yup/editCustomerValidationForm";
import Aux from 'hoc/auxiliar'
import { getOperationTypes } from 'services/operationType';
import { updateCustomer, getCustomerDetails } from 'services/customers';

import useStyles from './styles';
import { useAppSelector } from 'redux/store';

const EditCustomers = () => {
  const { currentCustomer } = useAppSelector((state) => state.global.user);
  const classes = useStyles()
  const { id } = useParams();
  const history = useHistory()
  const queryClient = useQueryClient()

  const {
    data: dataOperationTypes,
    isLoading: isLoadingOperationTypes,
    isError: isErrorOperationTypes,
  } = useQuery(["operationTypes", currentCustomer], async () => {
    if (hasPermission({ scopes: ['can_edit_customers'] })) {
      const response = await getOperationTypes(currentCustomer)
      return makeSelectOptions({
        data: response.data?.operationTypes,
        keyValue: "id",
        keyLabel: "name",
      });
    }
    return {};
  }, {
    refetchOnMount: true
  });

  const { data: dataCustomerById, isLoading: isLoadingCustomerById, isError: isErrorCustomerById } = useQuery(['getCustomerById', id], async () => {
    const { data } = await getCustomerDetails(id)

    let finalDay = {
      value: (data.finishing_day),
      label: String(data.finishing_day)
    }
    let startDay = {
      value: (data.starting_day),
      label: String(data.starting_day)
    }

    const period = getRewardGroupSelecteds(periodTypeOptions, [{ id: data.period }]);
    const details = {
      id: id,
      name: data.name ?? "",
      document: data.document ?? "",
      /* phone: data.phone_number,
      email: data.email,
      address: data.address, */
      period: period ?? "",
      startingDay: startDay ?? {},
      finishingDay: finalDay ?? {},
      operations: data.operations ?? "",
      note: data.note ?? "",
    }
    return details;
  }, {

  })

  const op = getRewardGroupSelecteds(dataOperationTypes, dataCustomerById?.operations);
  const defaultValues = { ...dataCustomerById, operations: op }
  const methods = useForm({ defaultValues, resolver: yupResolver(editCustomerValidationForm) });

  const onSubmit = async (data) => {
    const onlyOperationsId = data.operations.map((op) => ({ id: op.value }));
    const customerUpdateModel = {
      id: Number(data.id),
      name: data.name,
      document: data.document,
      /* phone_number: data.phone,
      email: data.email,
      address: data.address, */
      note: data.note,
      period: data.period.value,
      starting_day: data.startingDay.value,
      finishing_day: data.finishingDay.value,
      operations: onlyOperationsId
    }
    try {
      const response = await updateCustomer(customerUpdateModel)
      if (response.status !== 200) {
        toast.error("Erro ao atualizar cliente!");
      } else if (response.data && response.data.response[0].errors) {
        response.data.response[0].errors.forEach((error) => {
          if (errorsMessages[error.field]) {
            methods.setError(errorsMessages[error.field].type, { type: 'custom', message: errorsMessages[error.field].message });
          }
        });
      } else {
        toast.success("Cadastro atualizado com sucesso!");
        queryClient.invalidateQueries()
        history.push("/fleet/customer/customer-list")
      }
    } catch (error) {
      console.log(error)
      toast.error("Algo inexperado aconteceu. Contate o suporte!");
    }
  }

  /* if (isLoadingOperationTypes || isLoadingCustomerById) return <FormCustomerSkeleton /> */

  if (isErrorOperationTypes) {
    history.push("/fleet/customer/customer-list");
    return toast.error("Não foi possível buscar o grupo de bonificação. Contate o suporte.");
  }

  if (isErrorCustomerById) {
    history.push("/fleet/customer/customer-list");
    return toast.error("Não foi possível buscar o cliente. Contate o suporte.");
  }

  return (
    <Aux>
      <PermissionsGate scopes={['can_edit_customers']}>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box>
              <Grid item xl={12} xs={12} className={classes.table}>
                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Typography variant="h1" className={classes.title}>Edição de Cliente</Typography>
                    {(isLoadingOperationTypes || isLoadingCustomerById) ? <FormCustomerSkeleton /> : (
                      <CustomerForm
                        isEdit
                        defaultValues={defaultValues}
                        operationTypeOptions={dataOperationTypes}
                      />
                    )}
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Grid container justifyContent="flex-end" spacing={2}>
                        <Grid container item justifyContent="flex-end" xl={12} lg={12} md={12} sm={12} xs={12}>
                          <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                            <Box sx={{ m: 2 }}>
                              <BackButton className={classes.backButton} />
                            </Box>
                          </Grid>
                          <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                            <Box sx={{ m: 2 }}>
                              <Button
                                fullWidth
                                variant="contained"
                                type="submit"
                                className={classes.btConfirm}
                              >
                                Salvar
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </FormProvider>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </PermissionsGate>
    </Aux>
  );
};

export default EditCustomers;
