import { format } from "date-fns";

export default function getChartSettings(
  data,
  seriesColors,
  handleTooltipData,
) {
  return (theme) => {
    return {
      chart: {
        id: "full-chart",
        toolbar: {
          show: true,
        },
        fontFamily: theme.typography.fontFamily,
        animations: {
          enabled: false,
        },
      },
      markers: {
        size: 0,
      },
      dataLabels: { enabled: false },
      stroke: {
        width: 2,
      },
      xaxis: {
        min: new Date(data.dates.initialDate).getTime(),
        max: new Date(data.dates.finalDate).getTime(),
        type: "datetime",
        labels: {
          style: {
            color: theme.palette.text.hint,
            fontSize: "0.6rem",
          },
          formatter: function (_value, timestamp) {
            return format(new Date(timestamp), "dd/MM HH:mm");
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      yaxis: [
        {
          seriesName: "rpm",
          title: {
            text: "RPM",
            style: {
              color: seriesColors.rpm,
              fontSize: "0.8rem",
            },
          },
          labels: {
            offsetX: -30,
            style: {
              color: theme.palette.text.hint,
              fontSize: "0.6rem",
            },
            formatter: (value) => {
              return value ? value.toFixed(0) : 0;
            },
          },
        },
        {
          seriesName: "altitude",
          opposite: true,
          title: {
            text: "Altitude",
            style: {
              color: seriesColors.altitude,
              fontSize: "0.8rem",
            },
          },
          labels: {
            offsetX: 0,
            style: {
              color: theme.palette.text.hint,
              fontSize: "0.6rem",
            },
            formatter: (value) => {
              return value ? value.toFixed(0) : 0;
            },
          },
        },
        {
          seriesName: "fuel",
          title: {
            text: "Combustível",
            style: {
              color: seriesColors.fuel,
              fontSize: "0.8rem",
            },
          },
          labels: {
            offsetX: -25,
            style: {
              color: theme.palette.text.hint,
              fontSize: "0.6rem",
            },
            formatter: (value) => {
              return value ? value.toFixed(0) : 0;
            },
          },
        },
        {
          seriesName: "speed",
          opposite: true,
          title: {
            text: "Velocidade",
            style: {
              color: seriesColors.speed,
              fontSize: "0.8rem",
            },
          },
          labels: {
            offsetX: 10,
            style: {
              color: theme.palette.text.hint,
              fontSize: "0.6rem",
            },
            formatter: (value) => {
              return value ? value.toFixed(0) : 0;
            },
          },
        },
      ],
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex }) {
          handleTooltipData(series, dataPointIndex);
          return "";
        },
        x: {
          formatter: (date) => {
            return format(date, "dd/MM/yyyy HH:mm:ss");
          },
        },
      },
      fill: {
        opacity: [1, 0.25, 1, 1],
        gradient: {
          inverseColors: false,
          shade: "light",
          type: "vertical",
          opacityFrom: 0.25,
          opacityTo: 0.85,
          stops: [0, 100, 100, 100],
        },
      },
      colors: [
        seriesColors.rpm,
        seriesColors.altitude,
        seriesColors.fuel,
        seriesColors.speed,
      ],
      legend: {
        show: false,
      },
      annotations: {
        position: "front",
        yaxis: [
          {
            id: "maxFuelCapacity",
            y: data.limits.maxFuelCapacity,
            yAxisIndex: 2,
            borderColor: theme.palette.primary.light,
            strokeDashArray: 0,
            label: {
              position: "left",
              offsetX: 120,
              borderColor: theme.palette.primary.light,
              style: {
                color: theme.palette.white,
                background: theme.palette.primary.light,
                fontSize: "0.6rem",
              },
              text: `Tanque Total - ${data.limits.maxFuelCapacity}L`,
            },
          },
          {
            id: "maxSpeedAllowed",
            y: data.limits.maxSpeedAllowed,
            yAxisIndex: 3,
            borderColor: theme.palette.primary.main,
            strokeDashArray: 0,
            label: {
              position: "left",
              offsetX: 168,
              offsetY: 17,
              borderColor: theme.palette.primary.main,
              style: {
                color: theme.palette.white,
                background: theme.palette.primary.main,
                fontSize: "0.6rem",
              },
              text: `Vel Máx Permitida - ${data.limits.maxSpeedAllowed} Km/h`,
            },
          },
          {
            id: "greenRpmRangeEnd",
            y: data.rpmrange.extra[1],
            yAxisIndex: 0,
            borderColor: theme.palette.teal,
            strokeDashArray: 0,
            label: {
              position: "left",
              offsetX: 168,
              offsetY: 17,
              borderColor: theme.palette.teal,
              style: {
                color: theme.palette.white,
                background: theme.palette.teal,
                fontSize: "0.6rem",
              },
              text: `Final do Inicio da Faixa Verde - ${data.rpmrange.extra[1]} RPM`,
            },
          },
        ],
      },
      grid: {
        padding: {
          top: 30,
          bottom: 10,
          right: 15,
          left: -15,
        },
      },
    };
  };
}