import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  spacingContainer: {
    marginTop: theme.spacing(2),
    margin: 5,
  },
  paper: {
    borderRadius: 20,
    width: "97%",
    maxWidth: "100%",
    padding: "4px 8px 4px 20px",
    marginBottom: 20,
    boxShadow: `3px 5px 4px ${theme.palette.background.hoverMultiMenu}`,
    zIndex: 110,
  },
  paperSelect: {
    borderRadius: 20,
    width: "97%",
    maxWidth: "100%",
    padding: "4px 8px 4px 20px",
    marginBottom: 20,
    boxShadow: `3px 5px 4px ${theme.palette.background.hoverMultiMenu}`,
    zIndex: 110,
  },
  followTitle: {
    fontSize: theme.typography.h4.fontSize,
    color: theme.palette.text.title,
    fontWeight: "500",
    marginBottom: 10,
  },
  roundedContainer: {
    padding: "10px 20px",
    borderRadius: 20,
    display: "flex",
  },
  itemContent: {
    maxHeight: 200,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      maxHeight: 80,
      marginBottom: 0,
    },
  },
  fullHeight: {
    [theme.breakpoints.up("md")]: {
      height: "100%",
    },
  },
  itemTitle: {
    fontSize: "1rem",
    color: theme.palette.text.title,
    fontWeight: 500,
    [theme.breakpoints.up("lg")]: {
      fontSize: "2rem",
    },
  },
  reward: {
    color: theme.palette.success.dark,
    textAlign: "right",
    fontWeight: 500,
    fontSize: "1.5rem",
    [theme.breakpoints.up("lg")]: {
      fontSize: "2rem",
    },
  },
  goal: {
    color: theme.palette.text.title,
    textAlign: "right",
    fontWeight: 500,
    fontSize: "1.5rem",
    [theme.breakpoints.up("lg")]: {
      fontSize: "2rem",
    },
  },
  infoBarItens: {
    display: "flex",
    alignItems: "center",
  },
  meta: {
    fontSize: 12,
    color: theme.palette.text.title,
  },
  titleMeta: {
    color: theme.palette.text.primary,
    fontSize: "1rem",
    fontWeight: "500",
    textAlign: "right",
  },
  valueMeta: {
    color: theme.palette.text.primary,
    fontSize: "1.3em",
    textAlign: "right",
  },
  textItensContainer: {
    textAlign: "right",
  },
  center: {
    borderRadius: 20,
    width: 350,
    maxWidth: "100%",
    padding: "4px 8px 4px 20px",
    marginBottom: 20,
    boxShadow: `3px 5px 4px ${theme.palette.background.hoverMultiMenu}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  legendRight: {
    paddingRight: theme.spacing(3),
  },
  calendarPaper: {
    width: "auto",
    height: 46,
  },
  buttonDetail: {
    textTransform: "inherit",
    fontSize: 16,
    fontWeight: "bold",
    borderRadius: 22,
    boxShadow: "none",
  },
}));
