import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import { toast } from 'react-toastify'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import Swal from 'sweetalert2';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Input,
  FormHelperText,
  Typography,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useTheme } from '@material-ui/styles';
import DateFnsUtils from '@date-io/date-fns';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { assignDriverToVehicle, updateAssignDriver } from 'services/vehicle';
import { calendarLanguage } from '../Calendar/calendarLanguage';
import { verifyAssignVehicle } from 'helpers/vehicles';

import { useAppSelector } from 'redux/store';
// styles
import useStyles from './styles';
import { format } from 'date-fns';

const ModalAssignDriverToVehicle = React.forwardRef(({ currentVehicle, drivers, isEdit, data, ...props }, ref) => {
  const { email } = useAppSelector((state) => state.global.user);

  moment.defineLocale('pt-br', calendarLanguage);
  const classes = useStyles();
  const theme = useTheme()
  const formatDate = 'dd/MM/yyyy HH:mm';
  const [isChecked, setIsChecked] = useState(true);
  const [loading, setLoading] = useState(false);
  const [dates, setDates] = useState({
    initialDate: format(new Date(), formatDate),
    finalDate: format(new Date(), formatDate),
  });

  const submitSchema = yup.object().shape({
    drivers: yup.string().required('Selecione um motorista')
  });

  const { control, handleSubmit, formState: { errors } } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(submitSchema)
  });

  const handleInitialDate = (startDate) => {
    const finalDateFormatted = moment(dates.finalDate, 'DD/MM/YYYY HH:mm').toDate()
    if (startDate) {
      if (startDate > finalDateFormatted) {
        setDates({
          initialDate: format(startDate, formatDate),
          finalDate: format(startDate, formatDate),
        });
      } else {
        setDates({
          ...dates,
          initialDate: format(startDate, formatDate)
        });
      }
    }
  };

  const handleFinalDate = (finalDate) => {
    if (finalDate) {
      setDates({
        ...dates,
        finalDate: format(finalDate, formatDate)
      });
    }
  };

  const swalConflictAlert = (text, modelSubmit) => {
    props.handleClose()
    Swal.fire({
      title: 'Atenção!',
      text: text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: theme.palette.secondary.main,
      cancelButtonColor: theme.palette.error.main,
      confirmButtonText: 'Sim, atualizar!',
      cancelButtonText: 'Cancelar',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await updateAssignDriver(modelSubmit)
          if (response.status === 200 || response.status === 201) {
            toast.success('Novo vínculo criado com sucesso.')
          } else {
            toast.error('Algo inexperado aconteceu. Contate o suporte')
          }
        } catch (error) {
          toast.error('Erro ao criar vínculo, contate o suporte!')
        } finally {
          props.handleClose()
          props.fetchDriversHistory(currentVehicle.params[5])
        }
      }
    });
  };

  const onSubmit = async (data) => {
    const model = {
      source: Number(1),
      vehicleId: Number(currentVehicle.params[5]),
      execEmail: email,
      driverId: Number(data.drivers),
      startDate: moment(dates.initialDate, "DD/MM/yyyy HH:mm").format("yyyy-MM-DDTHH:mm:ssZZ")
    }

    try {
      setLoading(true)
      const driverNameFiltered = drivers.filter((item) => Number(item.value) === Number(data.drivers))
      const { label: driverName } = driverNameFiltered.shift()
      const { hasConflict } = await verifyAssignVehicle(Number(currentVehicle.params[5]), Number(data.drivers), moment(dates.initialDate, "DD/MM/yyyy HH:mm").format("yyyy-MM-DDTHH:mm:ssZZ"), moment(dates.finalDate, "DD/MM/yyyy HH:mm").format("yyyy-MM-DDTHH:mm:ssZZ"))
      if (!hasConflict.length) {
        const newModel = isChecked ? model : { ...model, endDate: moment(dates.finalDate, "DD/MM/yyyy HH:mm").format("yyyy-MM-DDTHH:mm:ssZZ") }
        const response = await assignDriverToVehicle(newModel)
        if (response.status === 200 || response.status === 201) {
          toast.success('Vínculo realizado com sucesso.')
          props.handleClose()
          props.fetchDriversHistory(currentVehicle.params[5])
        } else {
          toast.error('Algo inexperado aconteceu. Contate o suporte.')
          props.handleClose()
        }
      } else {
        let isVehicle = {
          identification: [],
          driver: [],
          source: false
        }

        let isDriver = {
          identification: [],
          driver: [],
          source: false
        }

        hasConflict.map(conflict => {
          if (conflict.source === 'vehicle') {
            isVehicle.identification.push(' ' + conflict.obj.vehicleIdentification)
            isVehicle.driver.push(' ' + conflict.obj.driverName)
            isVehicle.source = true
          } else if (conflict.source === 'driver') {
            isDriver.identification.push(' ' + conflict.obj.vehicleIdentification)
            isDriver.driver.push(' ' + conflict.obj.driverName)
            isDriver.source = true
          }
        })
        if (isVehicle.source) {
          swalConflictAlert(`Para o período informado, o veículo ${currentVehicle.params[0]} - ${currentVehicle.params[3]} já se encontra-se vinculado no(as) motorista(s): ${[...new Set(isVehicle.driver)]}. Deseja retirar os vinculos e atualizar para os novos vínculos?`, isChecked ? model : { ...model, endDate: moment(dates.finalDate, "DD/MM/yyyy HH:mm").format("yyyy-MM-DDTHH:mm:ssZZ") })
        } else if (isDriver.source) {
          swalConflictAlert(`Para o período informado, o(a) motorista ${driverName} já se encontra-se vinculado no(s) veículo(s): ${[...new Set(isDriver.identification)]}. Deseja retirar os vinculos e atualizar para os novos vínculos?`, isChecked ? model : { ...model, endDate: moment(dates.finalDate, "DD/MM/yyyy HH:mm").format("yyyy-MM-DDTHH:mm:ssZZ") })
        }
      }
    } catch (error) {
      toast.error('Algo inexperado aconteceu, contate o suporte.')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (isEdit) {
      setDates({
        initialDate: moment(data.startDate, 'yyyy-MM-DDTHH:mm:ssZZ').format('DD/MM/yyyy HH:mm'),
        finalDate: moment(data.endDate ? data.endDate : new Date(), 'yyyy-MM-DDTHH:mm:ssZZ').format('DD/MM/yyyy HH:mm')
      })
    }
  }, [data, isEdit])

  useEffect(() => {
    if (!isEdit) {
      setDates({
        initialDate: format(new Date(), formatDate),
        finalDate: format(new Date(), formatDate),
      })
    }
  }, [isEdit])

  useEffect(() => {
    if (isEdit && !!data.endDate) {
      setIsChecked(false)
    } else {
      setIsChecked(true)
    }
  }, [data, isEdit])

  useEffect(() => {
    var dateStart = moment(dates.initialDate, 'DD/MM/YYYY HH:mm');
    var dateEnd = moment(dates.finalDate, 'DD/MM/YYYY HH:mm');
    if (moment(dateStart).isAfter(dateEnd)) {
      setDates({
        ...dates,
        finalDate: dates.initialDate
      });
    }
  }, [dates]);

  const FinalDateRangePicker = ({
    startDate,
    finalDate,
    checked,
  }) => {
    return (
      <DateRangePicker
        initialSettings={{
          startDate: finalDate,
          singleDatePicker: true,
          timePicker24Hour: true,
          timePicker: true,
          maxDate: new Date(),
          minDate: moment(startDate, 'DD/MM/YYYY HH:mm').toDate(),
          locale: {
            format: 'DD/MM/YYYY HH:mm:ss',
            applyLabel: 'Aplicar',
            cancelLabel: 'Cancelar',
          },
          opens: 'center'
        }}
        autoApply={true}
        onApply={(event, picker) => {
          handleFinalDate(picker.startDate._d);
        }}
      >
        <Input data-cy='inputCalendar'
          className={classes.inputCalendar}
          value={!checked ? finalDate : ''}
        />
      </DateRangePicker>
    );
  };

  const InitialDateRangePicker = ({
    startDate,
  }) => {
    return (
      <DateRangePicker
        initialSettings={{
          startDate: startDate,
          singleDatePicker: true,
          timePicker24Hour: true,
          timePicker: true,
          maxDate: new Date(),
          locale: {
            format: 'DD/MM/YYYY HH:mm',
            applyLabel: 'Aplicar',
            cancelLabel: 'Cancelar',
          },
          opens: 'center'
        }}
        autoApply={true}
        onApply={(event, picker) => {
          handleInitialDate(picker.startDate._d);
        }}
      >
        <div>
          <Input data-cy='inputCalendar'
            className={classes.inputCalendar}
            value={startDate}
          />
        </div>
      </DateRangePicker>
    );
  };
  return (
    <Dialog
      fullWidth={true}
      ref={ref}
      onClose={props.handleClose}
      aria-labelledby='customized-dialog-title'
      open={props.open}
      className={classes.contentDialog}
      maxWidth='md'
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id='customized-dialog-title' onClose={props.handleClose}>
            <Typography className={classes.title}>{!isEdit ? 'Dia de Vínculo' : 'Editar vínculo'}</Typography>
            <IconButton
              aria-label='close'
              className={classes.closeButton}
              onClick={props.handleClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Box mt={2}>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Typography variant='h5' className={classes.legendTitle}>
                      Motorista
                    </Typography>
                    <Controller
                      control={control}
                      render={({ field: { onChange, value, name, ref } }) => (
                        <Select
                          placeholder='Selecione um motorista'
                          inputRef={ref}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              borderRadius: 20,
                              zIndex: 9999,
                              height: 50
                            }),
                            menu: (provided) => ({ ...provided, zIndex: 9999 }),
                          }}
                          value={drivers.find((driver) => driver.value === value)}
                          defaultValue={isEdit && drivers.find((driver) => driver.value === data.driverId)}
                          name={'drivers'}
                          options={drivers}
                          onChange={(selectedOption) => {
                            onChange(selectedOption.value);
                          }}
                        />
                      )}
                      name={'drivers'}
                    />
                    <FormHelperText className={classes.formHelperText} error={true}>
                      {errors.drivers?.message}
                    </FormHelperText>
                  </Grid>
                </Box>
                <Box mt={10} mb={4}>
                  <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                      <Typography variant='h5' className={classes.legendTitle}>
                        Data Inicial do Vínculo
                      </Typography>
                      <Button>
                        <InitialDateRangePicker
                          startDate={dates.initialDate}
                          checked={isChecked}
                        />
                      </Button>
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                      <Grid
                        container
                        direction='row'
                        justifyContent='center'
                        alignItems='center'
                        className={classes.containerCheckbox}
                      >
                        <Checkbox
                          className={classes.checkbox}
                          name={`check`}
                          checked={isChecked}
                          tabIndex={-1}
                          onChange={() => setIsChecked(!isChecked)}
                        />
                        <Typography variant='h5' className={classes.textDriver}>
                          O motorista vai continuar vinculado
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                      <Typography variant='h5' className={classes.legendTitle}>
                        Data Final
                      </Typography>
                      <Button disabled={isChecked}>
                        <FinalDateRangePicker
                          startDate={dates.initialDate}
                          finalDate={dates.finalDate}
                          checked={isChecked}
                        />
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <Grid container justifyContent='center' item>
              <Typography>
                *Ao desmarcar a opção “O motorista vai continuar vinculado”,
                é possível definir um período fixo em que o motorista selecionado
                dirigiu.
              </Typography>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant='contained'
              onClick={props.handleClose}
              className={classes.buttonBack}
            >
              Voltar
            </Button>
            <Button
              variant='contained'
              type='submit'
              className={classes.btConfirm}
              disabled={loading}
            >
              {loading ? 'Carregando...' : 'Vincular Motorista'}
            </Button>
          </DialogActions>
        </form>
      </MuiPickersUtilsProvider>
    </Dialog>
  );
});

export default ModalAssignDriverToVehicle;
