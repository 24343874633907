import React, { useCallback, useEffect, useState, useRef } from 'react'
import { useFormContext, useFormState, useWatch } from "react-hook-form";
import { Box, FormHelperText, Grid, Paper, Typography } from "@material-ui/core";
import Input from '../react-hook-form/input';
import Select from "../react-hook-form/select";
import Aux from "../../hoc/auxiliar";
import { finishingDaysOptions, startingDaysOptions } from '../../domain/selectOptions'
import { getOperations } from "services/operations";
import { periodTypeOptions } from '../../helpers/functions';
import FormCustomerSkeleton from "../Skeletons/FormCustomerSkeleton";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

import { useTheme } from '@material-ui/styles';
import useStyles from "./styles";
import { useAppSelector } from 'redux/store';

export const CustomerForm = ({ defaultValues, operationTypeOptions, isEdit = false, loading = false }) => {
  const { currentCustomer } = useAppSelector((state) => state.global.user);

  const classes = useStyles()
  const theme = useTheme()
  const { control, setValue } = useFormContext();
  const { errors } = useFormState({ control });
  const [disabledfinishingDayDay, setDisabledfinishingDayDay] = useState(true)
  const [daysOptions, setDaysOptions] = useState(startingDaysOptions)
  const [message, setMessage] = useState(false)
  const [periodOption, setPeriodOption] = useState(null)
  const startingDay = useWatch('startingDay')
  const finishingDay = useWatch('finishingDay')
  const period = useWatch('period')
  const operations = useWatch('operations')
  const [operationsList, setOperationsList] = useState([])
  let totalOperations = useRef(0)

  useEffect(() => {
    if (isEdit && defaultValues) {
      setValue("id", defaultValues?.id);
      setValue("name", defaultValues?.name ? defaultValues?.name : "");
      setValue("document", defaultValues?.document ? defaultValues?.document : "");
      setValue("period", defaultValues?.period ? defaultValues?.period[0] : "");
      setValue("startingDay", defaultValues?.startingDay ? defaultValues?.startingDay : "");
      setValue("finishingDay", defaultValues?.finishingDay ? defaultValues?.finishingDay : "");
      setValue("operations", defaultValues?.operations ?? []);
      setValue("note", defaultValues?.note ?? "");
    }
  }, [isEdit, defaultValues, setValue])

  useEffect(() => {
    if (startingDay) {
      setDisabledfinishingDayDay(false)
      if (startingDay && finishingDay) {
        if (startingDay.value > finishingDay.value) {
          return setMessage(true)
        } else {
          return setMessage(false)
        }
      }
    } else {
      setDisabledfinishingDayDay(true)
    }
  }, [startingDay, finishingDay])

  useEffect(() => {
    if (period) {
      if (period.value === 1) {
        setDaysOptions(finishingDaysOptions)
      } else {
        setDaysOptions(startingDaysOptions)
      }
    }
  }, [period])

  const fetchOperations = useCallback(async () => {
    try {
      const response = await getOperations(currentCustomer)
      if (response.status === 200 || response.status === 201) {
        let listOperation = []
        if (response.data.operation) {
          response.data.operation.forEach(item => {
            listOperation.push({
              ...item,
              name: item?.name,
              vehiclesAmount: item.vehicles ? item.vehicles.length : 0,
            });
          })
        } else {
          return null
        }
        setOperationsList(listOperation)
      } else {
        return toast.error(
          "Erro estranho aconteceu. Entre em contato com o suporte",
        );
      }
    } catch (error) {
      toast.error(
        "Erro ao buscar operações. Entre em contato com o suporte",
      );
    }
  }, [])

  useEffect(() => {
    const total = operations?.operations?.length || 0;
    if (totalOperations.current !== total) {
      totalOperations.current = total;
    }
  }, [operations, operationsList])

  useEffect(() => {
    fetchOperations();
  }, [fetchOperations])

  const handleChange = (selected) => {
    setPeriodOption(selected.value)
    setValue('period', selected)
    if (periodOption !== selected.value) {
      setMessage(false)
      setValue('startingDay', '')
      setValue('finishingDay', '')
    }
  }

  const handleOperationsChange = (e, action) => {
    if (isEdit && e.length < totalOperations.current) {
      const removedId = action.removedValue.value;
      const founded = operationsList.filter((item) => (item.operationType === removedId && item.vehicles.length > 0));
      if (founded.length > 0) {
        Swal.fire({
          title: 'Atenção! Você está prestes a remover um tipo de bonificação com veículos vinculados.',
          text: 'Isso poderá causar inconsistência nos dados destes veículos. Tem certeza que deseja continuar?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: theme.palette.error.main,
          cancelButtonColor: theme.palette.primary.main,
          confirmButtonText: 'Sim, remover!',
          cancelButtonText: 'Cancelar',
          customClass: {
            container: 'swalAlert'
          }
        }).then(async (result) => {
          if (result.isConfirmed) {
            setValue("operations", e)
          }
        });
        return;
      }
    }
    setValue("operations", e)
  }

  if (loading) {
    return <FormCustomerSkeleton />;
  }

  return (
    <Aux>
      <Paper elevation={2} className={classes.container}>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid container>
              <Typography className={classes.title}>Dados do cliente</Typography>
              <Grid container>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Box sx={{ m: 2 }}>
                    <Input
                      name="name"
                      variant="outlined"
                      fullWidth
                      label="Nome do cliente *"
                      helperText={errors.name?.message}
                      error={errors.name && true}
                    />
                  </Box>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Box sx={{ m: 2 }}>
                    <Input
                      name={'document'}
                      variant="outlined"
                      fullWidth
                      useMask="99.999.999/9999-99"
                      label="CNPJ *"
                      helperText={errors.document?.message}
                      error={errors.document && true}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid container>
              <Typography className={classes.title}>Data de corte/pagamento</Typography>
              <Grid container>
                <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                  <Box sx={{ m: 2 }}>
                    <Select
                      onChange={handleChange}
                      options={periodTypeOptions}
                      name="period"
                      placeholder="Selecione um período"
                      className="basic-multi-select"
                      classNamePrefix="select"
                    /><FormHelperText style={{ marginLeft: 15 }} error={true}>
                      {errors.period?.message}
                    </FormHelperText>
                  </Box>
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                  <Box sx={{ m: 2 }}>
                    <Select
                      options={startingDaysOptions}
                      name="startingDay"
                      placeholder="Selecione a data inicial"
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                    <FormHelperText style={{ marginLeft: 15 }} error={true}>
                      {errors.startingDay?.message}
                    </FormHelperText>
                  </Box>
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                  <Box sx={{ m: 2 }}>
                    <Select
                      isDisabled={disabledfinishingDayDay}
                      options={daysOptions}
                      name="finishingDay"
                      placeholder="Selecione a data final"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      required={true}
                    />
                    <FormHelperText style={{ marginLeft: 15 }} error={true}>
                      {errors.finishingDay?.message}
                    </FormHelperText>
                    {message && (
                      <FormHelperText style={{ marginLeft: 15 }} error={true}>
                        A data final não pode ser menor que a data inicial
                      </FormHelperText>
                    )}

                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Typography className={classes.title}>Tipos de Bonificação para ser liberado</Typography>
              <Grid container>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Box sx={{ m: 2 }}>
                    <Select
                      options={operationTypeOptions}
                      name="operations"
                      placeholder="Selecione um Grupo de bonificação"
                      isMulti
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={handleOperationsChange}
                    />
                    <FormHelperText style={{ marginLeft: 15 }} error={true}>
                      {errors.operations?.message}
                    </FormHelperText>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Typography className={classes.title}>Anotações sobre o cliente</Typography>
              <Grid container>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Box sx={{ m: 2 }}>
                    <Input
                      fullWidth
                      label="Observações..."
                      multiline
                      minRows={2}
                      maxRows={4}
                      name="note"
                      variant='outlined'
                      className={classes.textField}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Box sx={{ mt: 4 }}>
              <Typography className={classes.explanation}>*Os campos que estão marcados com o asterisco(*) são os que precisam obrigatoriamente serem preenchidos.</Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Aux>
  );
};