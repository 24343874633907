import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  table: {
    paddingTop: theme.spacing(2)
  },
  textBold: {
    fontWeight: 'bold'
  },
  textInfo: {
    fontStyle: 'italic',
    color: theme.palette.text.secondary
  },
}));
