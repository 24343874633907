import React, { useCallback, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useQuery, useQueryClient, useMutation } from "react-query";
import Aux from "hoc/auxiliar";
import Table from "components/Table/Table";
import Widget from "components/Widget/Widget";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";
import { deleteCustomer, getCustomersById } from "services/customers";
import { makeColumns } from "../tableColumns";
import colors from "themes/gobrax";
import useStyles from "../styles";
import { useAppSelector } from "redux/store";

const CustomerList = () => {
  const { listCustomers } = useAppSelector((state) => state.global.user);

  const history = useHistory();
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const queryClient = useQueryClient()
  const {
    data,
    isError
  } = useQuery("customers", async () => {
    const { data } = (hasPermission({ scopes: ['can_view_customers'] })) ?
      await getCustomersById(listCustomers) : { data: null };
    return data;
  })
  const { mutateAsync } = useMutation(deleteCustomer);

  const classes = useStyles();
  const handleEdit = (id) => history.push(`/fleet/customer/edit-customer/${id}`);

  const handleDelete = (id) => {
    Swal.fire({
      title: "Tem certeza que deseja excluir?",
      text: "Essa ação é irreverssível!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: colors.palette.primary.main,
      cancelButtonColor: colors.palette.error.main,
      confirmButtonText: "Sim, excluir!",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await mutateAsync(id)
        queryClient.invalidateQueries('customers')
        toast.success("Cliente deletado com sucesso!");
        Swal.fire(
          "Excluído!",
          "O registro foi excluído com sucesso",
          "success",
        );
      }
    });
  };

  const columns = makeColumns({ onDelete: handleDelete, onEdit: handleEdit });

  const handleClickAddCustomer = () => history.push("/fleet/customer/create-customer");

  if (isError) {
    toast.error(
      "Erro buscar lista de clientes. Entre em contato com o suporte",
    );
  }

  const renderTable = useCallback(() => (
    <Table
      columns={columns}
      data={data?.customers}
      tableName="list-customers"
      options={{ rowsPerPage }}
      setRowsPerPage={setRowsPerPage}
    />
  ), [columns, data, rowsPerPage]);

  return (
    <Aux>
      <PermissionsGate scopes={['can_view_customers']}>
        <Grid container spacing={4}>
          <Grid item xs={12} className={classes.tableContent}>
            <PermissionsGate scopes={['can_create_customers']}>
              <Grid container justifyContent="flex-end">
                <Grid item xl={2} lg={3} md={4} sm={12} xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.btnNew}
                    onClick={handleClickAddCustomer}
                  >
                    Novo Cliente
                  </Button>
                </Grid>
              </Grid>
            </PermissionsGate>
            <Widget disableWidgetMenu title="Gerenciamento de Clientes">
              {renderTable()}
            </Widget>
          </Grid>
        </Grid>
      </PermissionsGate>
    </Aux>
  );
};

export default CustomerList;
