import { Button } from "@material-ui/core";
import React from 'react';
import { useHistory } from 'react-router';

//styles
import useStyles from './styles';

function BackButton() {
  const classes = useStyles();
  const history = useHistory()
  return (
    <Button
      fullWidth
      color="primary"
      variant="contained"
      className={classes.btnBack}
      onClick={() => history.goBack() }
    >
      Voltar
    </Button>
  )
}

export default BackButton;