import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Button, Grid, Paper, Typography } from '@material-ui/core'
import { toast } from 'react-toastify';
import { format } from "date-fns";
import Aux from 'hoc/auxiliar';
import Widget from 'components/Widget/Widget';
import Table from 'components/Table/Table';
import BackButton from 'components/BackButton';
import LinkDriversSkeleton from 'components/Skeletons/LinkDriversSkeleton';
import ModalAssignDriverToVehicle from 'components/ModalAssignDriverToVehicle';
import { getDrivers, getDriversHistory } from 'services/driver';
import { makeColumns } from './tableColumns';
import useStyles from './styles';
import { useAppSelector } from 'redux/store';
import { getAssignType } from 'helpers/drivers';

function HistoricLinkDrivers() {
  const { currentCustomer } = useAppSelector((state) => state.global.user);
  const { state } = useLocation()
  const classes = useStyles()
  const [rowData, setRowData] = useState({});
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openModalAssignDriverToVehicle, setOpenModalAssignDriverToVehicle] = useState(false);
  const assignModalRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [drivers, setDrivers] = useState([]);
  const [driversHistory, setDriversHistory] = useState([]);
  const [download, setDownload] = useState({
    link: "",
    fileName: "",
    params: {
      vehicleId: null,
    }
  });

  const mergeFieldsAssignUnassign = (registers) => {
    let list = registers
    list.forEach((vehicle, index) => {
      vehicle.operations.forEach((item) => {
        if (!item.operation) {
          return false;
        }
        if (item.operation === 'ASSIGN') {
          list[index].assignBy = `${getAssignType(item.sourceId)} | ${item.userName}`;
        }
        if (item.operation === 'UNASSIGN') {
          list[index].unassignBy = `${getAssignType(item.sourceId)} | ${item.userName}`
        }
      })
    })
    return list
  }

  const fetchDriversHistory = useCallback(async (param) => {
    try {
      setLoading(true)
      const response = await getDriversHistory(param)
      if (response.status === 200 || response.status === 201) {
        const { registers } = response.data
        if (registers) {
          const model = mergeFieldsAssignUnassign(registers)
          setDriversHistory(model)
        }
        setDownload({
          link: `/vehicles/exportDriverHistory`,
          fileName: `historico-vinculos-motoristas_${format(new Date(), "dd-MM-yyyy_HH-mm")}.xlsx`,
          params: {
            vehicleId: param,
          }
        });
      } else {
        toast.error('Algo inexperado aconteceu. Contate o suporte')
      }
    } catch (error) {
      toast.error('Erro ao buscar histórico dos motoristas. Contate o suporte')
    } finally {
      setLoading(false)
    }
  }, [])

  const fetchDrivers = useCallback(async () => {
    try {
      const response = await getDrivers(currentCustomer)
      if (response.status === 200 || response.status === 201) {
        const options = response.data.drivers.map((item) => ({
          label: item.name,
          value: item.id,
          vehicleId: item.customerId
        }));
        setDrivers(options)
      } else {
        toast.error('Algo inexperado aconteceu. Contate o suporte')
      }
    } catch (error) {
      toast.error('Erro ao carregar lista de motoristas. Contate o suporte')
    } finally {
    }
  }, [currentCustomer])

  useEffect(() => {
    currentCustomer && fetchDrivers()
  }, [currentCustomer, fetchDrivers])

  useEffect(() => {
    state && fetchDriversHistory(state.params[5])
  }, [fetchDriversHistory, state])

  const handleCloseModalAssignDriverToVehicle = () => setOpenModalAssignDriverToVehicle(false);

  const editHandler = (params) => {
    const dataModel = {
      startDate: params[0] || null,
      endDate: params[1] || null,
      driverName: params[2] || '',
      driverId: params[6] || '',
    }
    setRowData(dataModel);
    setOpenEditModal(true);
    setOpenModalAssignDriverToVehicle(true)
  };
  const handleOpenAssignDriverToVehicleModal = (param) => {
    setRowData(param)
    setOpenEditModal(false);
    setOpenModalAssignDriverToVehicle(true)
  }

  const columns = makeColumns({
    handleEdit: editHandler
  });

  if (loading) return <LinkDriversSkeleton />

  return (
    <Aux>
      <ModalAssignDriverToVehicle
        ref={assignModalRef}
        open={openModalAssignDriverToVehicle}
        isEdit={openEditModal}
        handleClose={handleCloseModalAssignDriverToVehicle}
        data={rowData}
        drivers={drivers}
        driversHistory={driversHistory}
        currentVehicle={state}
        fetchDriversHistory={fetchDriversHistory}
        handleOpen={handleOpenAssignDriverToVehicleModal}
      />
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>

          <Box>
            <Grid container item xl={12} xs={12} className={classes.table}>
              <Grid container item lg={10} md={12} sm={12} xs={12}>
                <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                  <Paper className={classes.paperText}>
                    <Typography className={classes.titleText}>Veículo: </Typography>
                    <Typography>{state.params[0]}</Typography>
                  </Paper>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <Paper className={classes.paperText}>
                    <Typography className={classes.titleText}>Placa: </Typography>
                    <Typography>{state.params[3]} </Typography>
                  </Paper>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <Paper className={classes.paperText}>
                    {state.params[4].driverName ? (
                      <>
                        <Typography className={classes.titleText}>Motorista atual: </Typography>
                        <Typography>{state.params[4].driverName}</Typography>
                      </>
                    ) : <Typography>Sem motorista vinculado.</Typography>}
                  </Paper>
                </Grid>
              </Grid>

              <Grid container alignItems='center' item lg={2} md={3} sm={6} xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  className={classes.btnNew}
                  onClick={() => handleOpenAssignDriverToVehicleModal()}
                >
                  Novo Vínculo
                </Button>
              </Grid>
            </Grid>
            <Widget disableWidgetMenu title="Histórico de Vínculos de Motorista">
              <Grid item xl={12} xs={12} className={classes.table}>
                <Table
                  columns={columns}
                  data={driversHistory}
                  download={download}
                  tableName="historic-link-drivers"
                />
              </Grid>
            </Widget>
          </Box>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid container item justifyContent="flex-end" xl={12} lg={12} md={12} sm={12} xs={12}>
                <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                  <Box sx={{ m: 2 }}>
                    <BackButton className={classes.backButton} />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Aux>
  )
}

export default HistoricLinkDrivers;
