import React from 'react'
import { Grid, List, ListItem, Typography } from '@material-ui/core'
import classNames from 'classnames'

import useStyles from './styles'

const ConsolidatedGroups = ({data}) => {

  const classes = useStyles()

  return (
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
      <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.header}>
        <Grid container justifyContent='center' item xl={3} lg={3} md={3} sm={3} xs={3}>
          <Typography align='center' className={classes.itemTitle}>Grupos</Typography>
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
          <Typography align='center' className={classes.itemTitle}>Km rodado/Litros consumidos</Typography>
        </Grid>  
        <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
          <Typography align='center' className={classes.itemTitle}>Nota</Typography>
        </Grid>   
        <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
          <Typography align='center' className={classes.itemTitle}>Média</Typography>
        </Grid>                
      </Grid>
      <Grid container spacing={2} className={classes.contentBox}>
        <Grid item className={classes.itemBox}>
          <List dense={false} className={classes.list}>
            {data?.data.length ? data?.data.map((item, index) => {
              return (
                <ListItem key={`${item.id}-${index}`} className={classNames({
                  [classes.odd]: index % 2 === 0,
                })}>
                  <Grid container>
                    <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                      <Typography align='center' className={classes.itemText}>{item.operation_name}</Typography>
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                      <Typography align='center' className={classes.itemTextLong}>{item.statistics.totalMileage || '0'} Km / {item.statistics.totalConsumption || '0'} L</Typography>
                    </Grid>  
                    <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                      <Typography align='center' className={classes.itemTextRed}>{item.score}</Typography>
                    </Grid>         
                    <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                      <Typography align='center' className={classes.itemText}>{item.statistics.consumptionAverage} Km/L</Typography>
                    </Grid>        
                  </Grid>
                </ListItem>
              )
            }) : (
              <ListItem>
              <Grid container justifyContent='space-between'>
                <Grid item>
                  <Typography className={classes.itemName}>Não foram encontrados dados para esse período.</Typography>
                </Grid>
              </Grid>
            </ListItem>
            )}
          </List>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ConsolidatedGroups;
