import { alpha } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  tableContent: {
    marginTop: theme.spacing(2)
  },
  btnNew: {
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    borderRadius: 10,
    backgroundColor: theme.palette.secondary.main,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.8),
      fontWeight: 600
    },
  },
  infoDriverContainer: {
    borderRadius: 20,
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(1),
  },
  backButton: {
    backgroundColor: theme.palette.background.backgroundButton
  },
  label: {
    color: theme.palette.text.title,
    fontSize: 16,
    fontWeight: "bold",
  },
  driverName: {
    color: theme.palette.text.title,
    fontSize: 14,
    fontWeight: '400',
    marginLeft: theme.spacing(1 * 0.5)
  },
  contentDriver: {
    display: 'flex',
    alignItems: 'center'
  },
  btnNewCustomer: {
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.8),
      fontWeight: 600
    },
  },
}));
