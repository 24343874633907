import React from "react";
import { Typography } from "@material-ui/core";
import { valueToPositiveFloat } from "../../helpers/functions";
import makeStyles from "./styles";
import classnames from "classnames";

export const makeColumns = () => {
  const classes = makeStyles();

 /*  const getBreakPositionLabel = (value) => {
    switch (value) {
      case 0:
        return "Não Pressionado";
      case 1:
        return "Pressionado";
      default:
        return "";
    }
  }; */

  const getCell = (data, textBold) => {
    return (
      <Typography
        variant="body2"
        className={classnames({
          [classes.textBold]: textBold,
        })}
        noWrap={true}
      >
        {data}
      </Typography>
    );
  };

  return [
    {
      name: "date",
      label: "Data",
      options: {
        customBodyRender: (value) => getCell(value, true),
      },
    },
    {
      name: "rpm",
      label: "RPM",
      options: {
        customBodyRender: (value) => getCell(value, true),
      },
    },
    {
      name: "altitude",
      label: "Altitude",
      options: {
        customBodyRender: (value) => getCell(value, true),
      },
    },
    {
      name: "fuel",
      label: "Combustível",
      options: {
        customBodyRender: (value) => getCell(`${value} L`),
      },
    },
    {
      name: "speed",
      label: "Velocidade",
      options: {
        customBodyRender: (value) => getCell(`${value} Km/h`),
      },
    },
    /* {
      name: "brake_pedal",
      label: "Marcha",
      options: {
        customBodyRender: (value) => {
          let brakePedal = getBreakPositionLabel(value);
          return getCell(brakePedal);
        },
      },
    }, */
    {
      name: "acceleration_pedal",
      label: "Posição Acelerador",
      options: {
        customBodyRender: (value) => getCell(`${valueToPositiveFloat(value)} %`),
      },
    },
  ];
};
