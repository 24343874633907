import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Button, Grid } from "@material-ui/core";
import { format } from "date-fns";
import { toast } from "react-toastify";
import moment from "moment";
import { useSelect } from "context/useSelect";

// components
import Aux from "hoc/auxiliar";
import BackButton from "components/BackButton";
import Calendar from "components/Calendar";
import ModalRecalculationDriver from "components/ModalRecalculationDriver";
import Table from "components/Table";
import Widget from "components/Widget/Widget";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";

// services
import { getAllRecalculations } from "services/recalculationDrivers";
import { getDrivers } from "services/driver";

// styles
import useStyles from "./styles";
import { makeColumns } from "./tableColumns";
import { useAppSelector } from "redux/store";

const ListRecalculationDriver = () => {
  const { currentCustomer } = useAppSelector((state) => state.global.user);
  const classes = useStyles();
  const history = useHistory();
  const { drivers, setDrivers } = useSelect();
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const dbDateTemplateFormat = "yyyy-MM-dd'T'HH:mm:ssXX";
  const humanDateTemplateFormat = "DD/MM/yyyy HH:mm";
  const humanPeriodDateTemplateFormat = "DD/MM/yyyy";
  const [selectedDates, setSelectedDates] = useState({
    initialDate: format(new Date(), "yyyy-MM-dd'T'00:00:00XX"),
    finalDate: format(new Date(), "yyyy-MM-dd'T'23:59:59XX"),
  });

  const [openModal, setOpenModal] = useState(false);
  const [recalculations, setRecalculations] = useState([]);

  const fetchRecalculation = async () => {
    try {
      const response = await getAllRecalculations(selectedDates.initialDate, selectedDates.finalDate);
      if (response.data.data) {
        let data = response.data.data;
        const responseData = data.map((item) => ({
          ...item,
          user: item.data[0].user || '',
          process_date: moment(item.process_date).format(humanDateTemplateFormat),
          period: `${moment(item.start_date).format(humanPeriodDateTemplateFormat)} 
                    até ${moment(item.end_date).format(humanPeriodDateTemplateFormat)}`,
        }));
        setRecalculations(responseData);
      }
    } catch (err) {
      console.log(err);
      toast.error(
        "Erro ao carregar lista de motoristas. Entre em contato com o suporte",
      );
    }
  };

  const fetchDrivers = useCallback(async () => {
    try {
      const response = await await getDrivers(currentCustomer);
      if (response.status !== 200) {
        throw new Error("Error fetching drivers");
      }
      setDrivers(response.data.drivers);
    } catch (err) {
      console.log('err', err);
      setDrivers([]);
      toast.error(
        "Erro ao carregar lista de motoristas. Entre em contato com o suporte",
      );
    }
  }, [currentCustomer, setDrivers])

  const handleSelectDate = (initialDate, finalDate) => {
    setSelectedDates({
      initialDate: format(initialDate, dbDateTemplateFormat),
      finalDate: format(finalDate, dbDateTemplateFormat),
    });
  };

  const handleRecalculation = (value) => history.push(`/fleet/driver/recalculation/${value}`);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    fetchRecalculation();
  };

  const columns = makeColumns({
    showRecalculation: handleRecalculation,
  });

  useEffect(() => {
    if (currentCustomer && hasPermission({ scopes: ['can_recalculate_drivers'] })) {
      fetchDrivers();
    }
  }, [currentCustomer, fetchDrivers]);

  useEffect(() => {
    fetchRecalculation();
  }, [selectedDates]);

  const renderTable = useCallback(() => (
    <Table
      columns={columns}
      data={recalculations}
      options={{ rowsPerPage }}
      setRowsPerPage={setRowsPerPage}
      tableName='list-recalculations-drivers'
    />
  ), [columns, recalculations, rowsPerPage]);

  return (
    <Aux>
      <PermissionsGate scopes={['can_recalculate_drivers']}>
        <Grid container spacing={4}>
          <ModalRecalculationDriver
            open={openModal}
            handleClose={handleCloseModal}
            drivers={drivers}
          />
          <Grid item xs={12} className={classes.tableContent}>
            <Grid container justifyContent="flex-end" className={classes.toolbar}>
              <Grid item xl={8} lg={8} md={6} sm={12} xs={12}>
                <Calendar
                  selectedDates={selectedDates}
                  handleCalendar={handleSelectDate}
                />
              </Grid>
              <Grid item xl={2} lg={2} md={3} sm={12} xs={12} className={classes.btItem}>
                <BackButton className={classes.backButton} />
              </Grid>
              <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  className={classes.btnNew}
                  onClick={handleOpenModal}
                >
                  Novo Recálculo
                </Button>
              </Grid>
            </Grid>
            <Widget
              disableWidgetMenu
              title="Recalcular Dados de Bonificação dos Motoristas"
              subtitle="Selecione um período diferente caso queira ver recálculos mais antigos"
              subtitleClass={classes.subtitle}
            >
              {recalculations && renderTable()}
            </Widget>
          </Grid>
        </Grid>
      </PermissionsGate>
    </Aux>
  );
};

export default ListRecalculationDriver;
