import * as yup from "yup";

export default yup.object().shape({
  /* PERFIL DO VEÍCULO */
  brandId: yup.object().required("Campo obrigatório").nullable(),
  modelId: yup.object().required("Campo obrigatório").nullable(),
  whiteRangeStart: 
    yup.number()
      .required("Campo obrigatório")
      .transform((cv, ov) => ov === "" ? undefined : cv)
      .moreThan(yup.ref("neutralRangeStart"), "O valor deve ser maior que o Fim da Faixa Lenta")
      .nullable().notRequired(),
  extraEconomicRangeStart: 
    yup.number()
      .required("Campo obrigatório")
      .transform((cv, ov) => ov === "" ? undefined : cv)
      .moreThan(yup.ref("whiteRangeStart"), "O valor deve ser maior que o Fim da Faixa Branca")
      .nullable().notRequired(),
  economicRangeStart: 
    yup.number()
      .required("Campo obrigatório")
      .transform((cv, ov) => ov === "" ? undefined : cv)
      .moreThan(yup.ref("extraEconomicRangeStart"), "O valor deve ser maior que o Fim da Faixa Extra Econômica")
      .nullable().notRequired(),
  powerRangeStart: 
    yup.number()
      .required("Campo obrigatório")
      .transform((cv, ov) => ov === "" ? undefined : cv)
      .moreThan(yup.ref("economicRangeStart"), "O valor deve ser maior que o Fim da Faixa Econômica")
      .nullable().notRequired(),
  engineBrakeRangeStart: 
    yup.number()
      .required("Campo obrigatório")
      .transform((cv, ov) => ov === "" ? undefined : cv)
      .moreThan(yup.ref("powerRangeStart"), "O valor deve ser maior que o Fim da Faixa Potência")
      .nullable().notRequired(),
  redRangeStart: 
    yup.number()
      .required("Campo obrigatório")
      .transform((cv, ov) => ov === "" ? undefined : cv)
      .moreThan(yup.ref("engineBrakeRangeStart"), "O valor deve ser maior que o Fim da Faixa Freio Motor")
      .nullable().notRequired(),
  redRangeEnd: 
    yup.number()
      .required("Campo obrigatório")
      .transform((cv, ov) => ov === "" ? undefined : cv)
      .moreThan(yup.ref("redRangeStart"), "O valor deve ser maior que o Início da Faixa Vermelha")
      .nullable().notRequired(),
});
