import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import { Box, FormControl, Grid, MenuItem, Paper, Select, Typography } from '@material-ui/core';
import { rewardTypeOptions } from 'domain/selectOptions';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import BackButton from 'components/BackButton';
import EditCustom from 'components/RewardGroupForms/EditCustom';
import EditStandard from 'components/RewardGroupForms/EditStandard';
import RewardGroupSkeleton from 'components/Skeletons/RewardGroupSkeleton';
import PermissionsGate, { hasPermission } from 'components/PermissionsGate';
import { getOperationTypeById } from 'services/operationType';
import Aux from 'hoc/auxiliar';
import { useCallback } from 'react';

const EditRewardGroup = () => {
  const { id } = useParams();
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [rewardType, setRewardType] = useState('');
  const [operations, setOperations] = useState([]);

  const handleRewardType = (value) => {
    setRewardType(value)
  }

  const fetchOperationTypeDetails = useCallback(async (id) => {
    try {
      const response = await getOperationTypeById(id);
      const { operationTypes } = response.data
      setOperations(operationTypes)
      handleRewardType(operationTypes.type)
    } catch (err) {
      toast.error("Erro ao carregar Grupo de Bonificação");
      console.log(err);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (hasPermission({ scopes: ['can_edit_rewardgroup'] })) {
      fetchOperationTypeDetails(id);
    }
  }, [fetchOperationTypeDetails, id]);

  if (loading) {
    return <RewardGroupSkeleton />;
  }

  return (
    <Aux>
      <PermissionsGate scopes={['can_edit_rewardgroup']}>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box>
              <Grid item xl={12} xs={12}>
                <Typography variant='h1' className={classes.title}>Edição de Grupo de Bonificação</Typography>
                <Paper elevation={2} className={classes.container}>
                  <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
                    <Typography>Selecione o Tipo *</Typography>
                    <FormControl
                      variant="outlined"
                      classes={{
                        root: classes.formControl,
                      }}
                    >
                      <Select
                        id='rewardType'
                        onChange={(event) => handleRewardType(event.target.value)}
                        value={rewardType}
                        placeholder='Selecione o Tipo *'
                      >
                        {rewardTypeOptions.map(item => <MenuItem key={item.label} value={item.value}>{item.label}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xl={12} xs={12}>
                    {rewardType === 1 ? 
                      <EditStandard operations={operations} id={id} /> 
                    : rewardType === 2 && 
                    <EditCustom operations={operations} id={id} /> }
                  </Grid>
                </Paper>
              </Grid>
            </Box>
          </Grid>
          {!rewardType && <Grid container justifyContent="flex-end" spacing={2}>
            <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
              <Box sx={{ m: 2 }}>
                <BackButton className={classes.backButton} />
              </Box>
            </Grid>
          </Grid>}
        </Grid>
      </PermissionsGate>
    </Aux>
  );
};

export default EditRewardGroup;
