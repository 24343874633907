import React, { useCallback, useEffect, useState } from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Brightness1Icon from "@material-ui/icons/Brightness1";

import { infoRpmRange } from "../../helpers/fleetChart";

import FullChart from "../FullChart";
/* import MarkerChart from "../MarkerChart"; */
import TooltipChart from "../TooltipChart";

// styles
import useStyles from "./styles";

export default function FleetChart(props) {
  const classes = useStyles();

  const { data } = props;
  const [rpmRange, setRpmRange] = useState({});
  const [tootltipData, setTooltipData] = useState({
    date: "",
    rpm: "",
    altitude: "",
    fuel: "",
    speed: "",
  });
  /* const [markerData, setMarkerData] = useState([]); */

  const [series, setSeries] = useState([]);

  const handleTooltipData = useCallback(
    (data) => {
      setTooltipData(data);
    },
    [setTooltipData],
  );

  /* const handleMarkerData = useCallback(
    (data) => {
      setMarkerData((oldData) => [...oldData, data]);
    },
    [setMarkerData],
  ); */

  const fetchData = (data) => {
    const seriesData = {
      altitudeData: [],
      fuelData: [],
      rpmData: [],
      speedData: [],
    };

    if (data && data.rpmvstime) {
      data.rpmvstime.map((item) => {
        seriesData.altitudeData.push({ x: item.time, y: item.height });
        seriesData.rpmData.push({ x: item.time, y: item.rpm });
        seriesData.speedData.push({ x: item.time, y: item.speed });
        return null;
      });
      data.fuelvstime.map((item) => {
        seriesData.fuelData.push({ x: item.time, y: item.total });
        return null;
      });
      setRpmRange(data.rpmrange);
      setSeries([
        {
          name: "RPM",
          type: "line",
          data: seriesData.rpmData ?? [],
        },
        {
          name: "Altitude",
          type: "area",
          data: seriesData.altitudeData ?? [],
        },
        {
          name: "Combustível",
          type: "line",
          data: seriesData.fuelData ?? [],
        },
        {
          name: "Velocidade",
          type: "line",
          data: seriesData.speedData,
        },
      ]);
    }
  };

  /* const removeSelectedPoint = (id) => {
    setMarkerData((prevState) => prevState.filter((mark) => mark.id !== id));
  }; */

  useEffect(() => {
    if (data) {
      fetchData(data);
    }
  }, [data]);

  return (
    <Grid container spacing={2}>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Paper elevation={0} className={classes.root}>
          <Grid container spacing={2}>
            {series.length > 0 && (
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Grid container alignContent="center" justifyContent="center">
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <FullChart
                      series={series}
                      /* getMarkerData={handleMarkerData} */
                      getTooltipData={handleTooltipData}
                      data={data}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <TooltipChart data={tootltipData} />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
        {rpmRange && (
          <Paper elevation={0} className={classes.legend}>
            <Typography className={classes.legendTitle}>Legendas</Typography>
            <List>
              {Object.entries(rpmRange).map((range) => {
                let rpmStart = range[1][0];
                let rpmEnd = range[1][1];
                return (
                  <ListItem key={range[0]} className={classes.listItem}>
                    <Brightness1Icon
                      style={{ color: infoRpmRange[range[0]].color }}
                      className={classes.listItemIcon}
                    />
                    <Typography
                      variant="caption"
                      className={classes.listItemTitle}
                    >
                      {infoRpmRange[range[0]].title}
                    </Typography>
                    <Typography
                      variant="caption"
                      className={classes.listItemSubTitle}
                    >
                      {rpmStart} - {rpmEnd} RPM
                    </Typography>
                  </ListItem>
                );
              })}
            </List>
          </Paper>
        )}
      </Grid>

      {/* <Grid
        item
        xl={8}
        lg={8}
        md={6}
        sm={12}
        xs={12}
        style={{ minHeight: "100%" }}
      >
        <Paper elevation={0} className={classes.root}>
          <Typography className={classes.legendTitle}>
            Comparação de Pontos
          </Typography>
          <MarkerChart data={markerData} onRemovePoint={removeSelectedPoint} />
        </Paper>
      </Grid> */}
    </Grid>
  );
}
