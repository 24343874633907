export default class UserModel {
  constructor(customer, name, email, password = null, isAllCustomers = false) {
    this.customerId = customer;
    this.name = name;
    this.email = email;
    if (!!password) {
      this.password = password;
    };
    this.isAllCustomers = isAllCustomers;
  }
}