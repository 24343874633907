import { Box, Button, Checkbox, FormControlLabel, Grid, Paper } from '@material-ui/core';
import LottieComponent from 'components/LottieComponent';
import React from 'react';
import { useState } from 'react';

import lottieSearch from './../../assets/lottieFiles/lottieSearch.json'
import useStyles from './styles';

function Infobox({ title, nameLocalStorage }) {
  const classes = useStyles()
  const [checkboxInfo, setCheckboxInfo] = useState(false)

  return (
    <Grid container className={classes.containerInfobox}>
      <Paper elevation={0} className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box
              fontFamily="fontFamily"
              justifyContent="center"
              fontSize="h3.fontSize"
              textAlign="center"
              lineHeight={2}
              className={classes.infoBox}
              p={4}
            >
              Para gerar as informações da sua frota{" "}
              <strong>
              selecione os filtros
              </strong>
            </Box>
            <LottieComponent animationData={lottieSearch} width={150} height={'auto'} />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}

export default Infobox;