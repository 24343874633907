import React from "react";
import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const TelemetryDataSkeleton = () => {

  return (
    <Box>
      <Skeleton
        variant="rect"
        width={260}
        height={25}
        style={{ marginTop: "15px" }}
      />
      <Skeleton
        variant="rect"
        width='100%'
        height={35}
        style={{ marginTop: "2px" }}
      />
      <Skeleton
        variant="rect"
        width='100%'
        height={625}
        style={{ marginTop: "2px" }}
      />
      <Skeleton
        variant="rect"
        width='100%'
        height={35}
        style={{ marginTop: "2px" }}
      />
    </Box>
  );
};

export default TelemetryDataSkeleton;
