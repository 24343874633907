import React from "react";
import { IconButton, Grid, Typography } from "@material-ui/core";
import { CheckCircle as CheckIcon, Edit as EditIcon } from "@material-ui/icons";
import { mdiShieldLinkVariantOutline } from "@mdi/js";
import Icon from "@mdi/react";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";

import makeStyles from "./styles";
import { Link } from "react-router-dom";

export const makeColumns = ({ onChangeStatus, onEdit, onAddBond }) => {
  const classes = makeStyles();
  let columns = [
    {
      name: "customerName",
      label: "Empresa",
    },
    {
      name: "identification",
      label: "Frota",
    },
    {
      name: "plate",
      label: "Placa",
    },
    {
      name: "model",
      label: "Perfil",
    },
    {
      name: "brand",
      label: "Marca",
    },
    {
      name: "chassi",
      label: "Chassi",
    },
    {
      name: "currentDevice.identification",
      label: "ID do Tracker",
      options: {
        customBodyRender: (value) => {
          return value ? (
            <Typography variant="body2">{value}</Typography>
          ) : (
            <Typography variant="body2" className={classes.textInfo}>
              Nenhuma Unidade Vinculada
            </Typography>
          );
        },
      },
    },
  ];

  if (hasPermission({ scopes: ['can_edit_vehicles', 'can_activate_vehicles', 'can_edit_vehicledevice',] })) {
    columns.push({
      name: "id",
      label: "Ações",
      options: {
        sort: false,
        customBodyRender: (value) => {
          return (
            <Grid container>
              <PermissionsGate scopes={['can_edit_vehicles']}>
                <Grid item xs={3}>
                  <IconButton
                    onClick={() => onEdit(value)}
                    aria-label="edit"
                    title="Editar"
                  >
                    <EditIcon />
                  </IconButton>
                </Grid>
              </PermissionsGate>
              <PermissionsGate scopes={['can_activate_vehicles']}>
                <Grid item xs={3}>
                  <IconButton
                    onClick={() => onChangeStatus(value)}
                    aria-label="change-status"
                    title="Ativar/Inativar"
                  >
                    <CheckIcon />
                  </IconButton>
                </Grid>
              </PermissionsGate>
              <PermissionsGate scopes={['can_edit_vehicledevice']}>
                <Grid container justifyContent="center" alignItems="center" item xs={3}>
                  <IconButton
                    component={Link}
                    to={`/fleet/device/vehicle-devices?vehicleId=${value}`}
                  >
                    <Icon
                      path={mdiShieldLinkVariantOutline}
                      size={1}
                      title="Vincular Unidade Eletrônica"
                    />
                  </IconButton>
                </Grid>
              </PermissionsGate>
            </Grid>
          );
        },
      },
    });
  }

  return columns;
};
