import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { useForm, FormProvider, useFormState } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DoneAll } from "@material-ui/icons";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

// components
import Aux from "hoc/auxiliar";
import UserForm from "components/UserForm";
import BackButton from "components/BackButton";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";

// helpers
import makeDefaultValues from "helpers/makeDefaultValues";

// domain
import fields from "domain/forms/formUserInitialValues";
import schemaValidation from "domain/validation/yup/formEditUserValidation";

// services
import { getUserById, updateUser } from "services/user";
import { getUserPermissions, updateUserRole } from "services/roles";

// models
import UserModel from "models/User";
import UserRoleModel from "models/UserRole";

// style
import useStyles from "./styles";
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";

const FormUser = () => {
  const classes = useStyles();
  const { userID } = useParams();
  const history = useHistory()
  const defaultValues = makeDefaultValues({ fields });
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schemaValidation),
  });
  const { isSubmitting } = useFormState({ control: methods.control });

  const [isAllCustomers, setIsAllCustomers] = useState(null)
  const resetForm = () => {
    toast.success("Usuário atualizado com sucesso")
    methods.reset(defaultValues);
    history.push("/fleet/user/list-users")
  }

  const onSubmit = async (data) => {
    try {
      const userModel = new UserModel(
        data.customer.map(item => item.value),
        data.name,
        data.email,
        data.password,
        data.isAllCustomers
      );
      const response = await updateUser(userID, userModel);
      if (response.status === 200) {
        if (!hasPermission({ scopes: ['can_update_users_roles'] })) {
          resetForm()
          return
        }
        const roleModel = new UserRoleModel(
          data.role.value,
          data.email
        );
        const responseRole = await updateUserRole(roleModel);
        if (responseRole.status === 200 && !responseRole.err) {
          resetForm()
        }
      }
    } catch (error) {
      toast.error("Erro ao atualizar usuário. Verifique com o suporte");
    }
  };

  const fetchUserById = async () => {
    try {
      const response = await getUserById(userID);
      setIsAllCustomers(response.data.data.user.isAllCustomers);
      methods.setValue("customerOption", response.data.data.user.customerId);
      methods.setValue("name", response.data.data.user.name);
      methods.setValue("email", response.data.data.user.email);
      fetchUserRole(response.data.data.user.email) 
    } catch (error) {
      toast.error(
        "Erro ao buscar dados do usuário. Verifique com o suporte",
      );
    };
  };

  const fetchUserRole = async (email) => {
    try {
      const response = await getUserPermissions(email);
      if (response.data?.data) {
        methods.setValue("roleOption", response.data.data[0].role);
      }
    } catch (error) {
      toast.error(
        "Erro ao buscar dados do usuário. Verifique com o suporte",
      );
    };
  };

  useEffect(() => {
    if (hasPermission({ scopes: ['can_edit_users'] })) {
      fetchUserById();
    }
  }, []);

  if (!defaultValues) {
    return "...carregando";
  }

  return (
    <Aux>
      <PermissionsGate scopes={['can_edit_users']}>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box>
              <Grid item xl={12} xs={12}>
                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Typography variant="h1" className={classes.title}>Edição de Usuário</Typography>
                    <UserForm isEdit isAllCustomers={isAllCustomers} />
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Grid container justifyContent="flex-end" spacing={2}>
                        <Grid item xs={12} md={3} sm={4} xl={1} lg={2}>
                          <Box sx={{ m: 2 }}>
                            <BackButton />
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={3} sm={4} xl={1} lg={2}>
                          <Box sx={{ m: 2 }}>
                            <Button
                              fullWidth
                              variant="contained"
                              type="submit"
                              startIcon={<DoneAll />}
                              className={classes.btConfirm}
                              disabled={isSubmitting}
                            >
                              Atualizar
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </FormProvider>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </PermissionsGate>
    </Aux>
  );
};

export default FormUser;
