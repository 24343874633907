import { makeStyles } from "@material-ui/styles";
import { alpha } from "@material-ui/core/styles/colorManipulator";

export default makeStyles((theme) => ({
  title: {
    fontSize: 24,
    fontWeight: 500,
    color: theme.palette.text.title,
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(1),
  },
  wrapperContent: {
    height: '100vh'
  },
  titleUnlink: {
    fontSize: 20,
    textAlign: 'center',
    fontWeight: 400,
    color: theme.palette.text.title,
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(1),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  btConfirm: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: theme.spacing(1),
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.8),
      fontWeight: 600
    },
  },
  backButton: {
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.background.backgroundButton
  },
  containerInputCalendar: {
    width: 330,
    border: '1px solid '+theme.palette.text.hint,
    padding: 2,
    borderRadius: 20,
  },
  inputCalendar: {
    fontSize: theme.typography.h5.fontSize,
    color: theme.palette.gray.default,
    '& .MuiInputBase-input': {
      textAlign: 'center',
      width: '250px',
      borderRadius: '20px',
      padding: '15px',
    },
    '&:before': {
      transition: 'none',
      border: 'transparent'
    },
    '&:after': {
      transition: 'none',
      border: 'transparent'
    },
    '& input': {
      cursor: 'pointer',
    },
    '&:hover:not(.Mui-disabled):before': {
      border: 'transparent'
    }
  },
  explanation: {
    fontSize: 16,
    fontWeight: 400
  },
  alert: { 
    borderRadius: "22px",
    marginTop: 10, 
    marginBottom: 10,
    fontSize: 14,
  },
  btnBack: {
    backgroundColor: theme.palette.background.backgroundButton,
    boxShadow: "none",
    borderRadius: "10px",
    padding: "8px 24px",
    color: theme.palette.text.title,
    "&:hover": {
      backgroundColor: alpha(theme.palette.background.backgroundButton, 0.8),
    },
  },
}));
