import api from "./api";

const vehiclesUrl = (vehicles = "") => {
  return vehicles ? 
    Array.isArray(vehicles) ? vehicles.filter(x => x).join() : vehicles
  : "";
};

export const getFleetPerformance = async (vehicles, startDate, endDate) => {
  const partialUrl = vehiclesUrl(vehicles);
  let params = `vehicles=${partialUrl}&startDate=${startDate}&endDate=${endDate}`;
  try {
    const response = await api.get(`/fleet-performance?${params}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getFleetLocations = async (vehicle, startDate, endDate) => {
  const partialUrl = vehiclesUrl(vehicle);
  let params = `vehicle=${partialUrl}&startDate=${startDate}&endDate=${endDate}`;
  try {
    const response = await api.get(`/fleet-locations?${params}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getFleetLocationsCompact = async (vehicle, startDate, endDate, zip = true) => {
  const partialUrl = vehiclesUrl(vehicle);
  let params = `vehicle=${partialUrl}&startDate=${startDate}&endDate=${endDate}&zip=${zip}`;
  try {
    const response = await api.get(`/fleet-locations?${params}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getFleetStatistics = async (vehicles, startDate, endDate) => {
  const partialUrl = vehiclesUrl(vehicles);
  let params = `vehicles=${partialUrl}&startDate=${startDate}&endDate=${endDate}`;
  try {
    const response = await api.get(`/fleet-statistics?${params}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getFleetPercentages = async (vehicles, startDate, endDate) => {
  const partialUrl = vehiclesUrl(vehicles);
  let params = `vehicle=${partialUrl}&startDate=${startDate}&endDate=${endDate}`;
  try {
    const response = await api.get(`/fleet-percentages?${params}`);
    return response;
  } catch (err) {
    return err;
  }
};

export const getFleetRanking = async (vehicles, startDate, endDate) => {
  const partialUrl = vehiclesUrl(vehicles);
  let params = `devices=${partialUrl}&startDate=${startDate}&endDate=${endDate}`;
  try {
    const response = await api.get(`/fleet-percentages?${params}`);
    return response;
  } catch (err) {
    return err;
  }
};

export const getVehiclesPerformance = async (vehicles, startDate, endDate) => {
  const partialUrl = vehiclesUrl(vehicles);
  let params = `vehicles=${partialUrl}&startDate=${startDate}&endDate=${endDate}`;
  try {
    const response = await api.get(`/vehicles-performances?${params}`);
    return response;
  } catch (err) {
    return err;
  }
};

export const getVehiclesPerformanceDaily = async ({ vehicleList, initialDate }) => {
  const partialUrl = vehiclesUrl(vehicleList);
  let params = `startDate=${initialDate}&vehicles=${partialUrl}`;
  try {
    const response = await api.get(`/daily_performance_by_vehicle?${params}`);
    return response;
  } catch (err) {
    return err;
  }
};

export const getVehiclesPerformanceWeekly = async ({ vehicleList, initialDate }) => {
  const partialUrl = vehiclesUrl(vehicleList);
  let params = `startDate=${initialDate}&vehicles=${partialUrl}`;
  try {
    const response = await api.get(`/weekly_performance_by_vehicle?${params}`);
    return response;
  } catch (err) {
    return err;
  }
};

export const getVehiclesPerformanceMonthly = async ({ vehicleList, initialDate }) => {
  const partialUrl = vehiclesUrl(vehicleList);
  let urlParams = `startDate=${initialDate}&vehicles=${partialUrl}`;
  try {
    const response = await api.get(`/monthly_performance_by_vehicle?${urlParams}`);
    return response;
  } catch (err) {
    return err;
  }
};

export const getVehiclesPerformancePack = async (vehicles, startDate, endDate) => {
  const partialUrl = vehiclesUrl(vehicles);
  let params = `vehicles=${partialUrl}&startDate=${startDate}&endDate=${endDate}`
  try {
    const response = await api.get(`/vehicles-performancesPack?${params}`);
    return response;
  } catch (err) {
    return err;
  }
};

export const getVehiclesPerformancePackLimitPage = async (vehicles, startDate, endDate, limit, page) => {
  const partialUrl = vehiclesUrl(vehicles);
  let params = `vehicles=${partialUrl}&startDate=${startDate}&endDate=${endDate}&limit=${limit}&page=${page}`
  try {
    const response = await api.get(`/vehicles-performancesPack?${params}`);
    return response;
  } catch (err) {
    return err;
  }
};

export const getVehiclesPerformanceTrips = async (vehicles, startDate, endDate) => {
  const partialUrl = vehiclesUrl(vehicles);
  let params = `vehicles=${partialUrl}&startDate=${startDate}&endDate=${endDate}`;
  try {
    const response = await api.get(`/vehicles-performances-trips?${params}`);
    return response;
  } catch (err) {
    return err;
  }
};

export const getRPMRange = async (vehicleId) => {
  try {
    const response = await api.get(`/vehicles/vehicleProfile?vehicleId=${vehicleId}`);
    return response;
  } catch (err) {
    return err;
  }
}

export const getFleetChartData = async (deviceId, startDate, endDate) => {
  try {
    const response = await api.get(`/telemetry-data?deviceId=${deviceId}&startDate=${startDate}&endDate=${endDate}&fields=time,height,rpm,ignition_key,total_fuel_consumption_high_resolution,can_speed,can_fuel_level,ignition_key,acceleration_pedal_position,service_brake_pedal_status,gear,gps_speed&fix-invalid-points=true&start=0&length=999999999999999`);
    return response;
  } catch (err) {
    return err;
  }
};

export const getVehicleProfile = async (vehicleId) => {
  try {
    const response = await api.get(`/vehicles/vehicleProfile?vehicleId=${vehicleId}`);
    return response;
  } catch (err) {
    return err;
  }
}