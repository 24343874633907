import React, { useState, useEffect, useCallback } from "react";
import { Box, Grid } from "@material-ui/core";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";

// styles
import useStyles from "./styles";
import { makeColumns } from "./tableColumns";

//services
import {
  getVehicles,
  unassignDriverFromVehicle,
} from "services/vehicle";

// components
import Aux from "hoc/auxiliar";
import LinkDriversSkeleton from "components/Skeletons/LinkDriversSkeleton";
import ModalUnassignDriverToVehicle from "components/ModalUnassignDriverToVehicle";
import PageToolbar from "components/PageToolbar";
import Table from "components/Table";
import Widget from "components/Widget";
import NotFoundWithLottie from "components/NotFoundWithLottie";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";
import { useAppSelector } from "redux/store";

export default function LinkDrivers() {
  const { currentCustomer } = useAppSelector((state) => state.global.user);
  const classes = useStyles();

  const history = useHistory()
  const [loading, setLoading] = useState(true);
  const [vehicles, setVehicles] = useState([]);
  const [rowData, setRowData] = useState({});
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [download, setDownload] = useState({
    link: "",
    fileName: ""
  });

  const handleUnlink = (params) => {
    setRowData(params);
    setConfirmOpen(true);
  };

  const handleConfirmClose = () => setConfirmOpen(false);
  const handleHistoric = (params) => {
    history.push({
      pathname: "/fleet/maintenance/historicLinkDrivers",
      state: {
        params: params
      },
    });
  }

  const columns = makeColumns({
    handleHistory: handleHistoric,
    handleUnlink: handleUnlink,
  });

  const fetchVehicles = useCallback(async () => {
    try {
      setLoading(true)
      const response = await getVehicles(currentCustomer);
      if (response.status === 200 || response.status === 201) {
        setVehicles(response.data.customers)
      } else {
        toast.error('Um erro inexperado aocnteceu. Contate o suporte.')
      }
    } catch (error) {
      toast.error('Erro ao buscar veículos. Contate o suporte.')
    } finally {
      setLoading(false)
    }
  }, [currentCustomer])


  const handleUnassignDriverToVehicle = async (data) => {
    try {
      setLoading(true)
      const response = await unassignDriverFromVehicle(data);
      if (response.status === 200) {
        toast.success("Desvinculado com sucesso!");
        fetchVehicles(currentCustomer);
        handleConfirmClose();
      } else {
        toast.error("Um erro inexperado aconteceu. Verifique com o suporte");
      }
    } catch (error) {
      toast.error("Erro ao desvincular veículo. Verifique com o suporte");
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    if (currentCustomer && hasPermission({ scopes: ['can_view_linkdrivers'] })) {
      fetchVehicles()
      setDownload({
        link: `/vehicles/exportVehiclesDrivers?customers=${currentCustomer}`,
        fileName: `vinculo-veiculo-motorista_${format(new Date(), "dd-MM-yyyy_HH-mm")}.xlsx`
      });
    }
  }, [currentCustomer, fetchVehicles]);

  const renderTable = useCallback(() => (
    <Table
      columns={columns}
      data={vehicles}
      download={download}
      tableName="link-drivers"
      options={{ rowsPerPage }}
      setRowsPerPage={setRowsPerPage}
    />
  ), [columns, vehicles, download, rowsPerPage]);

  if (loading) return <LinkDriversSkeleton />
  if (!loading && (!vehicles && !vehicles?.customers)) {
    return (
      <NotFoundWithLottie
        description={'Não foi encontrado vínculo para essa empresa.'}
        strongDescription={''}
      />
    )
  }

  return (
    <Aux>
      <PermissionsGate scopes={['can_view_linkdrivers']}>
        <ModalUnassignDriverToVehicle
          confirmOpen={confirmOpen}
          handleConfirmClose={handleConfirmClose}
          handleUnassignDriverToVehicle={handleUnassignDriverToVehicle}
          data={rowData}
        />
        <PageToolbar
          menuBreadcrumb
          onlyBreadcrumb
          breadcrumb="Manutenção"
          title="Vínculo de Motorista"
        />
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box>
              <Widget disableWidgetMenu title="Vínculo Veículo/Motorista">
                <Grid item xl={12} xs={12} className={classes.table}>
                  {renderTable()}
                </Grid>
              </Widget>
            </Box>
          </Grid>
        </Grid>
      </PermissionsGate>
    </Aux>
  );
}
