import React from "react";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import Aux from "../../hoc/auxiliar";

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Dashboard from "../../pages/dashboard/general";
import Comparison from "../../pages/dashboard/comparison";

import { CreateOperation, EditOperation, OperationList, OperationListHistory } from "../../pages/maintenance/operations";

import LinkDrivers from "../../pages/maintenance/linkdrivers";
import HistoricLinkDrivers from "../../pages/maintenance/historicLinkDrivers";
import TelemetryData from "../../pages/telemetry-data";
import Follow from "../../pages/bonus/follow";
import RewardDetails from "../../pages/bonus/Details";
import Co2Emission from "../../pages/co2";

import AddDriver from "../../pages/driver/addDriver/addDriver";
import AllDrivers from "../../pages/driver/allDrivers/AllDrivers";
import EditDriver from "../../pages/driver/addDriver/editDriver";
import MassAddDriver from "../../pages/driver/addDriver/massAddDriver";
import ListRecalculationDriver from "../../pages/driver/recalculation/ListRecalculationDriver";
import ListRecalculationDriverDetails from "../../pages/driver/recalculation/ListRecalculationDriverDetails";

import { CustomerList, CreateCustomer, EditCustomer } from "../../pages/customers";

import AddDevice from "../../pages/device/formDevice/AddDevice";
import EditDevice from "../../pages/device/formDevice/EditDevice";
import ListDevices from "../../pages/device/listDevices/ListDevices";
import MassAddDevice from "../../pages/device/formDevice/MassAddDevice";

import AddVehicle from "../../pages/vehicle/formVehicle/AddVehicle";
import EditVehicle from "../../pages/vehicle/formVehicle/EditVehicle";
import ListVehicles from "../../pages/vehicle/listVehicles/ListVehicles";
import MassAddVehicle from "../../pages/vehicle/formVehicle/MassAddVehicle";
import ListRecalculation from "../../pages/vehicle/recalculation/ListRecalculation";
import ListRecalculationDetails from "../../pages/vehicle/recalculation/ListRecalculationDetails";

import AddProfile from "../../pages/profile/formProfile/AddProfile";
import ListProfiles from "../../pages/profile/listProfiles/ListProfiles";

import AddRewardGroup from "../../pages/rewardGroup/addRewardGroup";
import ListRewardGroup from "../../pages/rewardGroup/RewardGroupList";
import EditRewardGroup from "../../pages/rewardGroup/editRewardGroup";

import ListUsers from "../../pages/users/listUsers/ListUsers";
import AddUser from "../../pages/users/formUser/AddUser";
import EditUser from "../../pages/users/formUser/EditUser";

import ListRoles from "../../pages/role/listRoles";
import AddRole from "../../pages/role/form/AddRole"
import EditRole from "../../pages/role/form/EditRole";

import DriversMonitoring from '../../pages/driversMonitoring/follow';
import DriversMonitoringDetails from '../../pages/driversMonitoring/details';
import DriversMonitoringTripList from '../../pages/driversMonitoring/tripList';

// context
import { useLayoutState } from "../../context/LayoutContext";
import { GuardedRoute, GuardProvider } from "react-router-guards";
import VehicleDevices from "pages/vehicle-device";
import HistoricDevices from "pages/device/historicDevices";
import Evolutive from "pages/dashboard/evolutive";
import Consolidated from "pages/consolidated";

function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  const requireLogin = (to, from, next) => {
    if (to.meta.auth) {
      fetch(`${process.env.REACT_APP_LINK_API_LOGIN}/sessions/whoami`, {
        credentials: "include",
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            next.redirect("/login");
          }
          next();
        });
    } else {
      next();
    }
  };

  return (
    <div className={classes.root}>
      <Aux>
        <Header history={props.history} />
        <Sidebar />
        <div
          id="drawer-container"
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
          style={{ paddingTop: 80 }}
        >
          <GuardProvider guards={[requireLogin]} error={Error}>
            <GuardedRoute
              path="/fleet/dashboard/general"
              meta={{ auth: true }}
              component={Dashboard}
            />
            <GuardedRoute
              path="/fleet/dashboard/evolutive"
              meta={{ auth: true }}
              component={Evolutive}
            />
            <GuardedRoute
              path="/fleet/consolidated"
              meta={{ auth: true }}
              component={Consolidated}
            />
            <GuardedRoute
              path="/fleet/c02-emission"
              meta={{ auth: true }}
              component={Co2Emission}
            />
            <GuardedRoute
              path="/fleet/dashboard/comparison"
              meta={{ auth: true }}
              component={Comparison}
            />
            <GuardedRoute
              path="/fleet/maintenance/operations/operationList"
              exact
              component={OperationList}
              meta={{ auth: true }}
            />
            <GuardedRoute
              path="/fleet/maintenance/operations/operationListHistory"
              exact
              component={OperationListHistory}
              meta={{ auth: true }}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/fleet/maintenance/operations/createOperation"
              component={CreateOperation}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/fleet/maintenance/operations/editOperation/:id"
              component={EditOperation}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/fleet/maintenance/linkdrivers"
              component={LinkDrivers}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/fleet/maintenance/historicLinkDrivers"
              component={HistoricLinkDrivers}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/fleet/bonus/follow"
              component={Follow}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/fleet/bonus/follow/details"
              component={RewardDetails}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/fleet/telemetrydata"
              component={TelemetryData}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/fleet/driver/all-drivers"
              component={AllDrivers}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/fleet/driver/add-driver"
              component={AddDriver}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/fleet/driver/edit/:driverId"
              component={EditDriver}
            />

            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/fleet/driver/mass-add-driver"
              component={MassAddDriver}
            />

            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/fleet/driver/recalculation"
              component={ListRecalculationDriver}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/fleet/driver/recalculation/:id"
              component={ListRecalculationDriverDetails}
            />

            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/fleet/customer/customer-list"
              component={CustomerList}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/fleet/customer/create-customer"
              component={CreateCustomer}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/fleet/customer/edit-customer/:id"
              component={EditCustomer}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/fleet/device/form-device"
              component={AddDevice}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/fleet/device/form-device/:deviceId"
              component={EditDevice}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/fleet/device/list-devices"
              component={ListDevices}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/fleet/device/historic-devices/:deviceId"
              component={HistoricDevices}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/fleet/device/vehicle-devices"
              component={VehicleDevices}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/fleet/device/mass-add-device"
              component={MassAddDevice}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/fleet/vehicle/form-vehicle"
              component={AddVehicle}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/fleet/vehicle/form-vehicle/:vehicleId"
              component={EditVehicle}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/fleet/vehicle/list-vehicles"
              component={ListVehicles}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/fleet/vehicle/mass-add-vehicle"
              component={MassAddVehicle}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/fleet/vehicle/recalculation"
              component={ListRecalculation}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/fleet/vehicle/recalculation/:id"
              component={ListRecalculationDetails}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/fleet/profile/form-profile"
              component={AddProfile}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/fleet/profile/form-profile/:profileId" 
              component={AddProfile}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/fleet/profile/list-profiles"
              component={ListProfiles}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/fleet/rewardGroup/add-rewardGroup"
              component={AddRewardGroup}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/fleet/rewardGroup/list-rewardGroup"
              component={ListRewardGroup}
            />
            {/* Route Users */}
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/fleet/user/list-users"
              component={ListUsers}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/fleet/user/form-user"
              component={AddUser}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/fleet/user/form-user/:userID"
              component={EditUser}
            />

            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/fleet/rewardGroup/edit/:id"
              component={EditRewardGroup}
            />

            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/fleet/role/list-roles"
              component={ListRoles}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/fleet/role/form-role"
              component={AddRole}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/fleet/role/form-role/:id"
              component={EditRole}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/fleet/driversMonitoring"
              component={DriversMonitoring}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/fleet/driversMonitoring/details"
              component={DriversMonitoringDetails}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/fleet/driversMonitoring/tripList"
              component={DriversMonitoringTripList}
            />
          </GuardProvider>
        </div>
      </Aux>
    </div>
  );
}

export default withRouter(Layout);
