import React from "react";
import { IconButton, Grid } from "@material-ui/core";
import { Delete as DeleteIcon, Edit as EditIcon } from "@material-ui/icons";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";

export const makeColumns = ({ onDelete, onEdit }) => {
  let columns = [
    {
      name: "modelName",
      label: "Perfis",
    }
  ];
  if (hasPermission({scopes: ['can_edit_profiles', 'can_remove_profiles']})) {
    columns.push({
      name: "id",
      label: "Ações",
      options: {
        sort: false,
        filter: false,
        viewColumns: false,
        customBodyRender: (value) => {
          return (
            <Grid container>
              <PermissionsGate scopes={['can_edit_profiles']}>
                <Grid item xs={6}>
                  <IconButton
                    onClick={() => onEdit(value)}
                    aria-label="edit"
                    title="editar"
                  >
                    <EditIcon />
                  </IconButton>
                </Grid>
              </PermissionsGate>
              <PermissionsGate scopes={['can_remove_profiles']}>
                <Grid item xs={6}>
                  <IconButton
                    onClick={() => onDelete(value)}
                    aria-label="delete"
                    title="Excluir"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </PermissionsGate>
            </Grid>
          );
        },
      },
    });
  }
  return columns;
};
