import React, { useState, useRef } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Input, Typography } from "@material-ui/core/";
import { Close as CloseIcon, CalendarToday as CalendarIcon } from "@material-ui/icons";
import { format } from "date-fns";
import { toast } from "react-toastify";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import makeDefaultValues from "helpers/makeDefaultValues";
import fields from "domain/forms/formRecalculationInitialvalues";
import MultiSelect from "../MultiSelect";
import { calendarLanguage } from "../Calendar/calendarLanguage";
import { createRecalculation } from "services/recalculation";

import useStyles from "./styles";
import { multiSelectStyles } from "./multiSelectStyles";
import { useAppSelector } from "redux/store";

const ModalRecalculation = (props) => {
  moment.defineLocale('pt-br', calendarLanguage);

  const classes = useStyles();
  const { email } = useAppSelector((state) => state.global.user);
  const { vehicles } = props;

  const defaultValues = makeDefaultValues({ fields });
  const methods = useForm({
    defaultValues,
  });

  const formatDate = 'dd/MM/yyyy HH:mm';
  const formatTemplateDB = "yyyy-MM-dd'T'HH:mm:ssXX";
  const [dates, setDates] = useState({
    initialDate: format(new Date(), 'dd/MM/yyyy 00:00'),
    finalDate: format(new Date(), 'dd/MM/yyyy 23:59'),
    dbInitialDate: format(new Date(), "yyyy-MM-dd'T'00:00:00XX"),
    dbFinalDate: format(new Date(), "yyyy-MM-dd'T'23:59:59XX"),
  });
  const selectedDevices = useRef([]);

  const [showBox, setShowBox] = useState(true);
  const handleShowBox = () => setShowBox(!showBox);

  const handleSelectedDate = (startDate, finalDate) => {
    setDates({
      initialDate: format(startDate, formatDate),
      finalDate: format(finalDate, formatDate),
      dbInitialDate: format(startDate, formatTemplateDB),
      dbFinalDate: format(finalDate, formatTemplateDB),
    });
  };

  const handleSelectedData = (data) => {
    let errors = [];
    let selected = [];
    selectedDevices.current = [];
    data.forEach((item) => {
      if (!item?.id) {
        errors.push(item.id);
      } else {
        selected.push(item.id);
      }
    });
    if (selected.length > 0) {
      selectedDevices.current = selected;
    }
    if (errors.length > 0) {
      let errorMsg = `Os seguintes veículos não possuem unidade eletrônica cadastrada e não serão recalculados: ${errors.join(', ')}`;
      return toast.error(errorMsg);
    }
  };

  const onSubmit = async () => {
    if (selectedDevices.current.length === 0) {
      return toast.error(
        "Selecione um ou mais veículos para efetuar o recálculo.",
      );
    }
    try {
      const response = await createRecalculation(
        selectedDevices.current,
        email,
        dates.dbInitialDate,
        dates.dbFinalDate,
      );
      if (response.data?.err?.status === 401 && response.data?.err?.msg !== "") {
        return toast.error(response.data.err.msg);
      }
      toast.success("Recálculo iniciado com sucesso!");
      props.handleClose();
      setShowBox(true);
      setDates({
        initialDate: format(new Date(), formatDate),
        finalDate: format(new Date(), formatDate),
        dbInitialDate: format(new Date(), formatTemplateDB),
        dbFinalDate: format(new Date(), formatTemplateDB),
      });
      selectedDevices.current = [];
    } catch (error) {
      console.log(error);
      return toast.error(
        "Não foi possível iniciar o recálculo. Contate o suporte",
      );
    }
  };

  const BoxData = () => {
    return (
      <Box
        className={classes.boxContainer}
        flexDirection="column"
        justifyContent="space-between"
      >
        <Grid container spacing={2}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography variant="h6" className={classes.label}>
              Por favor, selecione o período de recálculo
            </Typography>
            <DateRangePicker
              initialSettings={{
                startDate: dates.initialDate,
                endDate: dates.finalDate,
                maxSpan: { days: 30 },
                locale: {
                  format: "DD/MM/YYYY HH:mm:ss",
                  applyLabel: "Confirmar",
                  cancelLabel: "Cancelar",
                  customRangeLabel: "Personalizado",
                },
                ranges: {
                  'Hoje': [moment().startOf('day'), moment().endOf('day')],
                  'Ontem': [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
                  '7 Dias': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
                  '1 Mês': [moment().subtract(29, 'days').startOf('day'), moment().endOf('day')],
                },
                alwaysShowCalendars: true,
                showCustomRangeLabel: true,
                opens: "right",
              }}
              placeholderText="Período"
              onApply={(event, picker) => {
                handleSelectedDate(picker.startDate._d, picker.endDate._d);
              }}
              autoApply={true}
            >
              <div className={classes.divInput}>
                <CalendarIcon
                  fontSize="medium"
                  data-cy="svgButtonCalendar"
                />
                <Input data-cy="inputCalendar"
                  value={`${dates.initialDate} - ${dates.finalDate}`}
                  onChange={(e, picker) => {
                    handleSelectedDate(picker.startDate._d, picker.endDate._d);
                  }}
                  className={classes.inputCalendar}
                  disabled={true}
                />
              </div>
            </DateRangePicker>
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.step}>
              Etapa 1 de 2
            </Typography>
          </Grid>
          <Grid item container justifyContent="flex-end" xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
              <Box sx={{ m: 2 }}>
                <Button
                  fullWidth
                  onClick={props.handleClose}
                  className={classes.backButton}
                >
                  Voltar
                </Button>
              </Box>
            </Grid>
            <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
              <Box sx={{ m: 2 }}>
                <Button
                  fullWidth
                  onClick={handleShowBox}
                  variant="contained"
                  className={classes.btConfirm}
                >
                  Avançar
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const BoxRecalc = () => {
    return (
      <Box
        className={classes.boxContainer}
        flexDirection="column"
        justifyContent="space-between"
      >
        <Grid container spacing={2}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <MultiSelect
              isSingleMode={false}
              listData={vehicles}
              handleSelectedListData={handleSelectedData}
              customStyles={multiSelectStyles}
            />
          </Grid>
        </Grid>
        <DialogActions>
          <Grid container justifyContent="flex-end" spacing={2}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography className={classes.step}>
                Etapa 2 de 2
              </Typography>
            </Grid>
            <Grid item container justifyContent="flex-end" xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                <Box sx={{ m: 2 }}>
                  <Button
                    fullWidth
                    onClick={handleShowBox}
                    className={classes.backButton}
                  >
                    Voltar
                  </Button>
                </Box>
              </Grid>
              <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                <Box sx={{ m: 2 }}>
                  <Button
                    fullWidth
                    variant="contained"
                    type="submit"
                    className={classes.btConfirm}
                  >
                    Recalcular
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </Box>
    );
  };

  return (
    <Dialog
      fullWidth
      maxWidth={"md"}
      onClose={props.handleClose}
      aria-labelledby="customized-dialog-title"
      open={props.open}
      PaperProps={{
        style: { borderRadius: 22 }
      }}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={props.handleClose}
        disableTypography
        className={classes.dialogTitle}
      >
        <Typography variant="h6" className={classes.title}>
          Recalcular Veículo
        </Typography>
        <Typography variant="h6" className={classes.subtitle}>
          Com base no período selecionado serão recalculados os dados do(s) veículo(s) até o período atual.
        </Typography>
        {!showBox && <Typography variant="h6" className={classes.subtitle}>
          ATENÇÃO: Todos os motoristas que se vincularam nesse veículo sofrerão atualizações em sua bonificação.
        </Typography>}
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={props.handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {showBox ? <BoxData /> : <BoxRecalc />}
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};

export default ModalRecalculation;
