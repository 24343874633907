import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  textBold: {
    fontWeight: "bold",
  },
  text: {
    textTransform: "capitalize"
  },
  success: {
    color: theme.palette.success.main
  },
  error: {
    color: theme.palette.error.main
  }
}));
