import React from 'react'

import { LayoutProvider } from './LayoutContext'
import { SelectProvider } from './useSelect'
import { SelectDriversProvider } from './useSelectDrivers'
import { SelectComparisonProvider } from './useSelectComparison'
import { SelectCustomersProvider } from './selectCustomersContext'
import { InputsProfileProvider } from './useInputsProfile'

const AppProvider = ({ children }) => (
  <LayoutProvider>
    <SelectCustomersProvider>
      <InputsProfileProvider>
        <SelectProvider>
          <SelectDriversProvider>
            <SelectComparisonProvider>
              {children}
            </SelectComparisonProvider>
          </SelectDriversProvider>
        </SelectProvider>
      </InputsProfileProvider>
    </SelectCustomersProvider>
  </LayoutProvider>
)

export default AppProvider