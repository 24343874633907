import { alpha } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  containerShift: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  spacingContainer: {
    marginTop: theme.spacing(2),
  },
  title: {
    fontWeight: 'bold',
    fontSize: theme.typography.h4.fontSize,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '40px'
  },
  paper: {
    borderRadius: 20,
    width: 350,
    maxWidth: '100%',
    padding: '4px 8px 4px 20px',
    marginBottom: 20,
    boxShadow: `3px 5px 4px ${theme.palette.background.hoverMultiMenu}`,
  },
  paperSelect: {
    borderRadius: 20,
    width: 150,
    maxWidth: '100%',
    padding: '4px 8px 4px 20px',
    marginBottom: 20,
    boxShadow: `3px 5px 4px ${theme.palette.background.hoverMultiMenu}`,
  },
  center: {
    borderRadius: 20,
    width: 350,
    maxWidth: '100%',
    padding: '4px 8px 4px 20px',
    marginBottom: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: `3px 5px 4px ${theme.palette.background.hoverMultiMenu}`,
  },
  card: {
    borderRadius: 28,
    height: '100%', 
    display: 'flex'
  }, 


  
  containerInfobox: {
    height: 450, 
    display: 'flex', 
    alignItems: 'center',
  },
  infoBox: {
    fontSize: theme.typography.h3.fontSize,
  },
  paperMessage: {
    borderRadius: 20,
    padding: theme.spacing(1),
    maxWidth: '850px',
    margin: '0 auto',
  },
  okButton: {
    borderRadius: 20,
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.secondary.main, 0.7),
    },
  },
  containerButton: {
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: 32,
    marginBottom: 32,
    marginLeft: 32,
  },
  performance: {
    borderRadius: 28,
    height: '90%', 
    display: 'flex'
  },  
  indicatorCard: {
    marginHorizontal: 20,
  },
  btnPrint: {
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    borderRadius: 22,
    backgroundColor: theme.palette.secondary.main,
    boxShadow: 'none',
    '&:hover': {
      color: theme.palette.black,
      backgroundColor: alpha(theme.palette.secondary.main, 0.8),
      fontWeight: 600
    },
    zIndex: 1200,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    maxWidth: 150
  },
  btBarginTop: {
    [theme.breakpoints.up('md')]: {
      marginTop: '90px',
      marginLeft: theme.spacing(2)
    }
  }
}));
