import React, { useCallback, useState, useEffect } from "react";
import { Grid, Typography, Paper, FormHelperText, Box } from "@material-ui/core";
import { useWatch, useFormState, useFormContext } from "react-hook-form";
import { toast } from "react-toastify";
import { Checkbox, Input, Select } from "components/react-hook-form";
import { cpfMask, dateMask, phoneMask,removeSpecialCharactersAndNumbersMask } from "helpers/masks";
import { makeSelectOptions } from "helpers/makeSelectOptions";
import { orderByName } from "helpers/functions";
import useStyles from "./styles";
import FormDriverSkeleton from "../Skeletons/FormDriverSkeleton";
import { getCustomersById } from "services/customers";
import Aux from "hoc/auxiliar";
import { useAppSelector } from "redux/store";

const DriverForm = () => {
  const { currentCustomer } = useAppSelector((state) => state.global.user);
  const classes = useStyles();
  const [customers, setCustomers] = useState(null);
  const { control, setValue } = useFormContext();
  const { errors } = useFormState({ control });

  const fetchCustomerList = async () => {
    try {
      const response = await getCustomersById(currentCustomer);
      const customerOptions = makeSelectOptions({
        data: response.data?.customers ?? [],
        keyLabel: "name",
        keyValue: "id",
      });
      const orderOptions = orderByName(customerOptions);
      setCustomers(orderOptions);
    } catch (err) {
      toast.error(
        "Erro ao carregar lista de clientes. Entre em contato com o suporte!",
      );
      console.log(err);
    }
  };

  const birthDateValue = useWatch({ control, name: "birthDate" });
  const customerOption = useWatch({ control, name: "customerOption" });
  const documentNumber = useWatch({ control, name: "documentNumber" });
  const phoneValue = useWatch({ control, name: "phone" });
  const nameValue = useWatch({ control, name: "name" });
  const canLink = useWatch({ control, name: "canLink"})

  useEffect(() => {
    if (customers?.length > 0) {
      setValue(
        "customer",
        customers.find((clt) => clt.value === customerOption),
      );
    }
  }, [customers, customerOption]);

  useEffect(() => {
    if (birthDateValue) {
      setValue("birthDate", dateMask(birthDateValue));
    }
  }, [birthDateValue]);

  useEffect(() => {
    if (nameValue) {
      setValue("name", removeSpecialCharactersAndNumbersMask(nameValue));
    }
  }, [nameValue, setValue]);

  useEffect(() => {
    if (documentNumber) {
      setValue("documentNumber", cpfMask(documentNumber));
    }
  }, [documentNumber]);

  useEffect(() => {
    if (phoneValue) {
      setValue("phone", phoneMask(phoneValue));
    }
  }, [phoneValue]);

  useEffect(() => {
    if (canLink) {
      setValue("canLink", canLink);
    }
  }, [canLink]);

  useEffect(() => {
    currentCustomer && fetchCustomerList();
  }, [currentCustomer]);

  if (!customers) {
    return <FormDriverSkeleton />;
  }

  return (
    <Aux>
      <Paper elevation={2} className={classes.container}>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.title}>Empresa</Typography>
            <Grid container>
              <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                <Box sx={{ m: 2 }}>
                  <Select
                    options={customers}
                    name="customer"
                    placeholder="Selecione uma Empresa *"
                  />
                  <FormHelperText style={{ marginLeft: 15 }} error={true}>
                    {errors.customer?.message}
                  </FormHelperText>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.title}>Dados do motorista</Typography>
            <Grid container>
              <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                <Box sx={{ m: 2 }}>
                  <Input
                    name="name"
                    variant="outlined"
                    fullWidth
                    label="Nome *"
                    helperText={errors.name?.message}
                    error={errors.name && true}
                  />
                </Box>
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                <Box sx={{ m: 2 }}>
                  <Input
                    name="documentNumber"
                    variant="outlined"
                    fullWidth
                    label="CPF *"
                    helperText={errors.documentNumber?.message}
                    error={errors.documentNumber && true}
                  />
                </Box>
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                <Box sx={{ m: 2 }}>
                  <Input
                    name="birthDate"
                    variant="outlined"
                    fullWidth
                    label="Data de nascimento"
                    placeholder="__/__/____"
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.title}>Formatos de acesso ao aplicativo</Typography>
            <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                <Box sx={{ m: 2 }}>
                  <Input 
                    name="phone" 
                    variant="outlined" 
                    fullWidth 
                    label="Telefone Celular *" 
                    helperText={errors.phone?.message}
                    error={errors.phone && true}
                  />
                </Box>
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                <Box sx={{ m: 2 }}>
                  <Input
                    name="email"
                    variant="outlined"
                    fullWidth
                    label="E-mail"
                    helperText={errors.email?.message}
                    error={errors.email && true}
                  />
                </Box>
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                <Box sx={{ m: 2 }}>
                  <Checkbox checked={canLink} name="canLink" label="Permitir vínculo pelo aplicativo?" />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.title}>Anotações sobre o motorista</Typography>
            <Grid container>
              <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                <Box sx={{ m: 2 }}>
                  <Input
                    name="obs"
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={1}
                    label="Observações"
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Box sx={{ mt: 4 }}>
              <Typography className={classes.explanation}>*Os campos que estão marcados com o asterisco(*) são os que precisam obrigatoriamente serem preenchidos.</Typography>
              <Typography className={classes.explanation}>*É possível cadastrar o motorista sem informar o Telefone e/ou E-mail, mas ele não tera acesso ao aplicativo, será apenas para controle do gestor via web. Caso desejar que os motoristas utilizem o aplicativo, informe o telefone e/ou e-mail.</Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Aux>
  );
};

export default DriverForm;
