import React from "react";
import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const OperationListHistorySkeleton = () => {

  return (
    <Box
      style={{
        display: "grid",
        paddingLeft: "16px",
        paddingRight: "16px",
      }}>
      <Skeleton
        variant="rect"
        width='100%'
        height={35}
        style={{ marginTop: "2px" }}
      />
      <Skeleton
        variant="rect"
        width='100%'
        height={300}
        style={{
          marginTop: "10px",
          borderRadius: "10px"
        }}
      />
    </Box>
  );
};

export default OperationListHistorySkeleton;
