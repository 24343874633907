import React from "react";
import Grid from "@material-ui/core/Grid";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeColumns } from "../config/makeColumns";
import { DataGrid } from "@material-ui/data-grid";
import RequirementLevel from "../requirementLevel";
import { useFormState, useFormContext } from "react-hook-form";
import { ptBR } from "../../../Table/config/locale";

const RequirementContainer = (props) => {
  const { control } = useFormContext();
  const { errors } = useFormState({ control });

  return (
    <Grid container spacing={2}>
      <RequirementLevel
        penalizing={props.penalizing}
        title={props.title}
        name={props.name}
        controllArrayName={props.controllArrayName}
        requirementLevelField={props.requirementLevelField}
      />
      <Grid item xl={8} lg={8} md={8} sm={7} xs={12}>
        <Grid container>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <DataGrid
              localeText={ptBR}
              style={{ minHeight: "380px" }}
              rows={props.dataTable ?? []}
              columns={makeColumns(props.onDelete)}
              pageSize={6}
              disableSelectionOnClick
            />
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid item xl={12}>
              <FormHelperText
                style={{ marginLeft: 15, marginBottom: 20 }}
                error={true}
              >
                {errors[props.controllArrayName]?.message}
              </FormHelperText>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RequirementContainer;
