import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  container: {
    flexDirection: "column",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
  itemContainer: {
    display: "flex",
  },
  title: {
    marginLeft: theme.spacing(1),
    fontWeight: "bold",
  },
  icon: {
    color: theme.palette.text.main
  }
}));
