import React from "react";
import { Grid, IconButton } from "@material-ui/core";
import { Delete as DeleteIcon, Edit as EditIcon } from "@material-ui/icons";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";

export const makeColumns = (onDelete, onEdit) => {
  let columns = [
    {
      name: "name",
      label: "Grupo de Bonificação",
    }
  ];

  if (hasPermission({scopes: ['can_edit_rewardgroup', 'can_remove_rewardgroup']})) {
    columns.push({
      name: "id",
      label: "Ações",
      options: {
        sort: false,
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableData) => {
          return (
            <Grid container>
              <PermissionsGate scopes={['can_edit_rewardgroup']}>
                <Grid item xs={6}>
                  <IconButton
                    onClick={() => onEdit(value)}
                    aria-label="edit"
                    title="editar"
                  >
                    <EditIcon />
                  </IconButton>
                </Grid>
              </PermissionsGate>
              <PermissionsGate scopes={['can_remove_rewardgroup']}>
                <Grid item xs={6}>
                  <IconButton
                    onClick={() => onDelete(value, tableData.rowData[0])}
                    aria-label="delete"
                    title="Excluir"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </PermissionsGate>
            </Grid>
          );
        },
      },
    });
  }

  return columns;
};
