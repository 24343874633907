import api from "./api";

export const createDevice = async (data) => {
  try {
    const response = await api.post(`/devices`, data);
    return response;
  } catch (err) {
    return err;
  }
};

export const LinkDeviceToVehicle = async (vehicleId, deviceId) => {
  try {
    const response = await api.post(`/vehicles-devices/${vehicleId}/devices`, deviceId);
    return response;
  } catch (err) {
    return err;
  }
};

export const LinkDevice = async (data) => {
  try {
    const response = await api.post(`/vehicles-devices/${data.vehicleId}/devices`, data);
    return response;
  } catch (err) {
    return err;
  }
};

export const unLinkDevice = async (vehicleId, deviceId) => {
  try {
    const response = await api.delete(`/vehicles-devices/${vehicleId}/devices/${deviceId}`);
    return response;
  } catch (err) {
    return err;
  }
};

export const getDevice = async (id) => {
  try {
    const response = await api.get(`/devices/${id}`);
    return response;
  } catch (err) {
    return err;
  }
};

export const getDevices = async () => {
  try {
    const response = await api.get(`/devices`);
    return response;
  } catch (err) {
    return err;
  }
};

export const updateDevice = async (deviceId, data) => {
  try {
    const response = await api.put(`/devices/${deviceId}`, data);
    return response;
  } catch (err) {
    return err;
  }
};

export const deleteDevice = async (deviceId) => {
  try {
    const response = await api.delete(`/devices/${deviceId}`);
    return response;
  } catch (err) {
    return err;
  }
};

export const vehicleDeviceHistory = async (deviceId) => {
  try {
    return await api.get(`/deviceHistory?deviceId=${deviceId}`)
  } catch (error) {
    return error;
  }
}

export const deviceHistory = async (deviceId) => {
  try {
    return await api.get(`/devices/deviceHistory?deviceId=${deviceId}`)
  } catch (error) {
    return error;
  }
}

export const importTemplateMassDevices = async () => {
  try {
    const response = await api.get(`/devices/importDeviceTemplate`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const createMassDevices = async (data) => {
  try {
    const response = await api.post(`/devices/import`, data);
    return response;
  } catch (err) {
    throw err;
  }
};
