import { format } from "date-fns";

export const makeColumns = () => {
  return [
    /* {
      name: "customerName",
      label: "Empresa",
    },
    {
      name: "vehicleName",
      label: "Frota",
    }, */
    {
      name: "plate",
      label: "Placa",
    },
    {
      name: "startDate",
      label: "Início do vínculo",
      options: {
        customBodyRender: (value) => {
          return format(new Date(value), "dd/MM/yyyy HH:mm")
        },
      },
    },
    {
      name: "endDate",
      label: "Fim do vínculo",
      options: {
        customBodyRender: (value) => {
          return value ? format(new Date(value), "dd/MM/yyyy HH:mm") : 'Vínculo atual'
        },
      }
    },
  ];
};
