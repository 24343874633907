import React from "react";
import { InputAdornment, OutlinedInput } from "@material-ui/core";
import { useController, useFormContext } from "react-hook-form";

import useStyles from "./styles";

const ControlledInputAdornment = ({ adornment, control, name, ...rest }) => {
  const classes = useStyles();
  const { control: controlContext } = useFormContext();
  const {
    field: { ref, ...inputProps },
  } = useController({
    name,
    control: control ?? controlContext,
  });

  return (
    <OutlinedInput
      className={classes.outlinedInput}
      endAdornment={
        adornment && (
          <InputAdornment position="end" className={classes.label}>
            {adornment}
          </InputAdornment>
        )
      }
      {...inputProps}
      inputRef={ref}
      {...rest}
      fullWidth
    />
  );
};

export default ControlledInputAdornment;
