import React, { useState, useEffect, useCallback } from "react";
import { Button, Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { format } from "date-fns";
import Swal from "sweetalert2";
import Aux from "hoc/auxiliar";
import Table from "components/Table";
import Widget from "components/Widget/Widget";
import { getDrivers, deleteDriver } from "services/driver";
import { makeColumns } from "./tableColumns";
import useStyles from "./styles";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";
import { useAppSelector } from "redux/store";

const AllDrivers = () => {
  const { currentCustomer } = useAppSelector((state) => state.global.user);
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const [dataTable, setDataTable] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [download, setDownload] = useState({
    link: "",
    fileName: ""
  });

  const handleEdit = (id) => history.push(`/fleet/driver/edit/${id}`);
  const handleDelete = (value) => {
    Swal.fire({
      title: "Tem certeza que deseja excluir?",
      text: "Essa ação é irreverssível!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: theme.palette.secondary.main,
      cancelButtonColor: theme.palette.error.main,
      confirmButtonText: "Sim, excluir!",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await deleteDriver(value);
          if (response.status === 200) {
            setDataTable((oldState) =>
              oldState.filter((driver) => driver.id !== value),
            );
            toast.success("Motorista deletado com sucesso! ");
            Swal.fire(
              "Excluído!",
              "O registro foi excluído com sucesso",
              "success",
            );
          } else {
            throw new Error();
          }
        } catch (error) {
          toast.error(
            "Erro ao excluir motorista. Entre em contato com o suporte",
          );
        }
      }
    });
  };

  const columns = makeColumns({ onDelete: handleDelete, onEdit: handleEdit });

  const handleClickAddDriver = () => history.push("/fleet/driver/add-driver");
  const handleClickMassAddDriver = () => history.push("/fleet/driver/mass-add-driver");
  const handleRecalculation = () => history.push("/fleet/driver/recalculation");

  const fetchDrivers = useCallback(async () => {
    try {
      setDownload({
        link: `/drivers/export?customers=${currentCustomer}`,
        fileName: `motoristas_${format(new Date(), "dd-MM-yyyy_HH-mm")}.xlsx`
      });
      const response = await getDrivers(currentCustomer);
      setDataTable(response.data?.drivers ?? []);
    } catch (error) {
      console.log(error);
      toast.error(
        "Erro buscar lista de motoristas. Entre em contato com o suporte",
      );
    }
  }, [currentCustomer]);

  useEffect(() => {
    if (hasPermission({ scopes: ['can_view_drivers'] })) {
      fetchDrivers();
    }
  }, [fetchDrivers]);

  const renderTable = useCallback(() => (
    <Table
      columns={columns}
      data={dataTable}
      download={download}
      tableName="list-drivers"
      options={{ rowsPerPage }}
      setRowsPerPage={setRowsPerPage}
    />
  ), [dataTable, download, columns, rowsPerPage]);

  return (
    <Aux>
      <PermissionsGate scopes={['can_view_drivers']}>
        <Grid container spacing={4}>
          <Grid item xs={12} className={classes.tableContent}>
            <Grid container justifyContent="flex-end">
              <PermissionsGate scopes={['can_recalculate_drivers']}>
                <Grid item xl={2} lg={3} md={4} sm={12} xs={12} className={classes.btItem}>
                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.btnNew}
                    onClick={handleRecalculation}
                  >
                    Recalcular Bonificação
                  </Button>
                </Grid>
              </PermissionsGate>
              <PermissionsGate scopes={['can_import_drivers']}>
                <Grid item xl={2} lg={3} md={4} sm={12} xs={12} className={classes.btItem}>
                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.btnNew}
                    onClick={handleClickMassAddDriver}
                  >
                    Importar em Massa
                  </Button>
                </Grid>
              </PermissionsGate>
              <PermissionsGate scopes={['can_create_drivers']}>
                <Grid item xl={2} lg={3} md={4} sm={12} xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.btnNew}
                    onClick={handleClickAddDriver}
                  >
                    Novo Motorista
                  </Button>
                </Grid>
              </PermissionsGate>
            </Grid>
            <Widget disableWidgetMenu title="Gerenciamento de Motoristas">
              {renderTable()}
            </Widget>
          </Grid>
        </Grid>
      </PermissionsGate>
    </Aux>
  );
};

export default AllDrivers;
