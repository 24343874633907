import * as yup from "yup";

export default yup.object().shape({
  brand: yup.object().required("Campo obrigatório").nullable(),
  identification: yup.string().required("Campo obrigatório"),
  simcard: 
    yup
    .string()
    .transform((value) => {
      return value.replace(/[^0-9]/g, "");
    })
    .required("Campo obrigatório"),
  status: yup.object().required("Campo obrigatório").nullable(),
});
