import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { Close as CloseIcon } from "@material-ui/icons";
import fields from "../../domain/forms/formLinkDeviceInitialvalues";
import { statusOptions } from "../../helpers/deviceStatus";
import { objIsEmpty } from "../../helpers/functions";
import Select from "../react-hook-form/select";

// styles
import useStyles from "./styles";
import { FormProvider, useForm } from "react-hook-form";
import makeDefaultValues from "../../helpers/makeDefaultValues";
import Table from "../Table/Table";
import LinkDeviceForm from "../LinkDeviceForm";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { Alert } from "@material-ui/lab";
import { LinkDevice } from "../../services/device";

const ModalDevice = ({ 
  data,
  device, 
  handleClose, 
  loading, 
  open,
  tableName, 
  vehiclesDevices,
}) => {
  const classes = useStyles();

  const defaultValues = makeDefaultValues({ fields });
  const methods = useForm({
    defaultValues,
  });

  const columns = [
    {
      name: "identification",
      label: "Nome",
    },
    {
      name: "startDate",
      label: "Data inicial",
      options: {
        customBodyRender: (value) => (
          <Typography variant="body2">
            {format(new Date(value), "dd/MM/yyyy HH:mm")}
          </Typography>
        ),
      },
    },
    {
      name: "endDate",
      label: "Data Final",
      options: {
        customBodyRender: (value) => {
          let content = "";
          if (value) {
            content = format(new Date(value), "dd/MM/yyyy HH:mm");
          }
          return <Typography variant="body2">{content}</Typography>;
        },
      },
    },
  ];

  let hasDataTable = (
    <Table 
      columns={columns} 
      data={vehiclesDevices} 
      title="Informações sobre Unidade Eletrônica" 
      tableName={tableName}
    />
  );

  const onSubmit = async (dataSubmit) => {
    try {
      if (!dataSubmit.devices) {
        return toast.warning("Selecione uma unidade eletrônica para vincular.");
      }
      const responseData = {
        vehicleId: data,
        deviceId: dataSubmit.devices.value,
        date: dataSubmit.initialDate,
      };

      const responseCreateLink = await LinkDevice(responseData);
      if (!responseCreateLink) {
        return toast.error(
          "Não foi possível vincular unidade eletrônica. Contate o suporte",
        );
      }

      toast.success("Vínculo realizado com sucesso!");
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth={"md"}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      PaperProps={{
        style: { borderRadius: 22 }
      }}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose} disableTypography>
        <Typography variant="h6" className={classes.title}>Vincular Unidade Eletrônica</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} style={{ marginBottom: 10 }}>
          {loading ? (
            <></>
          ) : !vehiclesDevices ? (
            <Grid item xs={12}>
              <Alert style={{ margin: 10 }} severity="info">
                Ainda não existe histórico para esse veículo.
              </Alert>
            </Grid>
          ) : (
            <Grid item xs={12}>
              {hasDataTable}
            </Grid>
          )}
        </Grid>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <LinkDeviceForm />

            {!objIsEmpty(device.status) && (
              <>
                <Alert
                  className={classes.alert}
                  severity="warning"
                >
                  {" "}
                  <strong>Já existe uma Unidade Eletrônica em uso.</strong>{" "}
                  Selecione o status da antiga Unidade Eletrônica.
                </Alert>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                  <Select
                    required
                    options={statusOptions.updateOldDevice}
                    fullWidth
                    name="statusDevices"
                    placeholder="Selecione o status da unidade eletrônica antiga"
                  />
                </Grid>
              </>
            )}
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Box sx={{ mt: 4 }}>
                <Typography className={classes.explanation}>* Os campos que estão marcados com o asterisco(*) são os que precisam obrigatoriamente serem preenchidos.</Typography>
              </Box>
            </Grid>
            <DialogActions>
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item container justifyContent="flex-end" xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                    <Box sx={{ m: 2 }}>
                      <Button 
                        fullWidth
                        onClick={handleClose}
                        className={classes.buttonClose}
                        >
                        Cancelar
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                    <Box sx={{ m: 2 }}>
                      <Button
                        fullWidth
                        variant="contained"
                        type="submit"
                        className={classes.btConfirm}
                      >
                        Salvar
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </DialogActions>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};

export default ModalDevice;
