import React, { useEffect, createContext, useContext, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setGlobalUser } from 'redux/globalSlice';
import { useAppSelector } from 'redux/store';
import { getCustomersList } from 'services/customers';

const SelectCustomerContext = createContext();

const SelectCustomersProvider = ({ children }) => {
  const dispatch = useDispatch()
  const { token, email, listCustomers, currentCustomer } = useAppSelector((state) => state.global.user);

  const getCustomers = useCallback(async () => {
    const responseCustomer = await getCustomersList(email)
    if (responseCustomer.status === 200 || responseCustomer === 201) {
      let customerList = responseCustomer.data.data
        ? responseCustomer.data.data.sort((a, b) => a - b)
        : [];
      let currentCustomer = customerList && customerList[0];
      dispatch(setGlobalUser({ listCustomers: customerList }))
      dispatch(setGlobalUser({ currentCustomer: currentCustomer }))
    }
  }, [dispatch, email])

  useEffect(() => {
    token && !listCustomers && !currentCustomer && getCustomers()
  }, [token, getCustomers, listCustomers, currentCustomer])

  return (
    <SelectCustomerContext.Provider>
      {children}
    </SelectCustomerContext.Provider>
  );
};

function useSelectCustomers() {
  const context = useContext(SelectCustomerContext);
  return context;
}

export { SelectCustomersProvider, useSelectCustomers };
