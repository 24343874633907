import React from "react";
import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const OperationsListSkeleton = () => {

  return (
    <Box 
      style={{ 
        display: "grid", 
        paddingLeft: "16px", 
        paddingRight: "16px",
      }}>
      <Skeleton
        variant="rect"
        width={120}
        height={40}
        style={{ 
          marginTop: "15px", 
          display: "flex", 
          justifySelf: "flex-end",
          borderRadius: "10px"
        }}
      />
      <Skeleton
        variant="rect"
        width={200}
        height={35}
        style={{ marginTop: "5px" }}
      />
      <Skeleton
        variant="rect"
        width={270}
        height={35}
        style={{ 
          marginTop: "10px", 
          display: "flex", 
          justifySelf: "flex-end",
          borderRadius: "10px"
        }}
      />
      <Skeleton
        variant="rect"
        width='100%'
        height={300}
        style={{ 
          marginTop: "10px", 
          borderRadius: "10px"
        }}
      />
      <Skeleton
        variant="rect"
        width='100%'
        height={35}
        style={{ marginTop: "2px" }}
      />
    </Box>
  );
};

export default OperationsListSkeleton;
