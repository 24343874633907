import React, { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { rewardTypeOptions } from 'domain/selectOptions';
import fields from 'domain/forms/standardRewardGroup';
import schemaValidation from 'domain/validation/yup/standardRewardGroup';
import makeDefaultValues from 'helpers/makeDefaultValues';
import { getIndicatorFilterType } from 'helpers/indicators';
import IndicatorModel from 'models/Indicator';
import RewardGroupModel from 'models/RewardGroup';
import { updateOperationType } from 'services/operationType';
import { updateIndicators } from 'services/indicators';
import BackButton from 'components/BackButton';
import Standard from '../components/standard';
import Aux from 'hoc/auxiliar';
import useStyles from './styles';
import { useCallback } from 'react';

const EditStandard = (props) => {
  const classes = useStyles();
  const { id } = props;
  const { operations } = props;
  const history = useHistory();
  const customer = localStorage.getItem('customers')

  const defaultValues = makeDefaultValues({
    fields,
    data: {
      highRequirementList: [
        {
          conduction: '',
          idealPercentage: '',
          acceptablePercentage: '',
          weight: '',
          id: 'x-s',
        },
      ],
      highPenalizingRequirementList: [
        {
          conduction: '',
          idealPercentage: '',
          acceptablePercentage: '',
          weight: '',
          id: 'highPenalizingRequirementList',
          penalizing: true
        },
      ],
      mediumRequirementList: [
        {
          conduction: '',
          idealPercentage: '',
          acceptablePercentage: '',
          weight: '',
          id: 'x-s',
        },
      ],
      mediumPenalizingRequirementList: [
        {
          conduction: '',
          idealPercentage: '',
          acceptablePercentage: '',
          weight: '',
          id: 'mediumPenalizingRequirementList',
          penalizing: true
        },
      ],
      lowRequirementList: [
        {
          conduction: '',
          idealPercentage: '',
          acceptablePercentage: '',
          weight: '',
          id: 'x-s',
        },
      ],
      lowPenalizingRequirementList: [
        {
          conduction: '',
          idealPercentage: '',
          acceptablePercentage: '',
          weight: '',
          id: 'lowPenalizingRequirementList',
          penalizing: true
        },
      ],
      highControll: [],
      mediumControll: [],
      lowControll: [],
      penalizingHighControll: [],
      penalizingMediumControll: [],
      penalizingLowControll: [],
      penalty_score: ''
    },
  });

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schemaValidation),
    mode: 'onChange',
  });

  const fetchOperationTypeDetails = useCallback(async () => {
    try {
      let { highControll, mediumControll, lowControll, customControll, penalizingHighControll, penalizingMediumControll, penalizingLowControll } = getIndicatorFilterType(operations.indicators);
      methods.setValue('highControll', highControll);
      methods.setValue('mediumControll', mediumControll);
      methods.setValue('lowControll', lowControll);
      methods.setValue('penalizingHighControll', penalizingHighControll);
      methods.setValue('penalizingMediumControll', penalizingMediumControll);
      methods.setValue('penalizingLowControll', penalizingLowControll);
      methods.setValue('customControll', customControll);
      methods.setValue('name', operations.name);
      methods.setValue('penalty_score', operations.penalty_score || '');
      methods.setValue(
        'rewardType',
        rewardTypeOptions.find(
          (opType) => opType.value === operations.type,
        ),
      );
    } catch (err) {
      toast.error("Erro ao carregar Grupo de Bonificação");
      console.log(err);
    }
  }, [methods, operations]);

  const onSubmit = async () => {
    const result = await methods.trigger([
      "name",
      "rewardType",
      "highControll",
      "mediumControll",
      "lowControll",
      'penalizingHighControll',
      'penalizingMediumControll',
      'penalizingLowControll'
    ]);
    if (result) {
      try {
        const data = methods.getValues();
        const rewardGroup = new RewardGroupModel({
          name: data.name,
          type: data.rewardType.value,
          description: "",
          penalty_score: Number(data.penalty_score),
          customer_id: Number(customer)
        });
        await updateOperationType({ id: id, data: rewardGroup });
        let indicatorsList = [
          ...data.highControll,
          ...data.mediumControll,
          ...data.lowControll,
          ...data.penalizingHighControll,
          ...data.penalizingMediumControll,
          ...data.penalizingLowControll
        ];
        const indicators = indicatorsList.map(
          (ind) =>
            new IndicatorModel({
              id: ind.id,
              lowerLimit: ind.acceptablePercentage,
              upperLimit: ind.idealPercentage,
              weight: ind.weight,
              requirementLevel: ind.requirement_level,
              penalizing: ind.penalizing || false
            }),
        );
        await updateIndicators({
          id: id,
          data: { indicators },
        });
        toast.success("Grupo editado com sucesso!");
        history.push("/fleet/rewardGroup/list-rewardGroup");
      } catch (err) {
        toast.error("Error ao editar Grupo!");
        console.log(err);
      }
    }
  };

  useEffect(() => {
    fetchOperationTypeDetails()
  }, [fetchOperationTypeDetails, operations])

  return (
    <Aux>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Box sx={{ mt: 4 }}>
                  <Typography className={classes.explanation}>
                    * Os campos que estão marcados com o asterisco(*) são os que precisam obrigatoriamente serem preenchidos.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.reward}>
                <Standard />
              </Grid>
              <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                <Box sx={{ m: 2 }}>
                  <BackButton className={classes.backButton} />
                </Box>
              </Grid>
              <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                <Box sx={{ m: 2 }}>
                  <Button
                    fullWidth
                    variant="contained"
                    type="submit"
                    className={classes.btConfirm}
                  >
                    Salvar
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Aux>
  );
};

export default EditStandard;
