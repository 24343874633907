import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Button, Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

// components
import Aux from "hoc/auxiliar";
import Table from "components/Table";
import Widget from "components/Widget/Widget";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";

// services
import {
  deleteVehicleProfile,
  getVehicleProfiles,
} from "services/profiles";

import { errorsMessages } from 'helpers/errors';

// styles
import useStyles from "./styles";
import { makeColumns } from "./tableColumns";

const ListProfiles = () => {
  var theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const [profiles, setProfiles] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const fetchProfiles = async () => {
    try {
      const response = await getVehicleProfiles();
      setProfiles(response.data.data?.profiles ?? []);
    } catch (err) {
      toast.error(
        "Erro ao carregar lista de Perfils. Entre em contato com o suporte",
      );
    }
  };

  const handleClickAddVehicleProfile = () => history.push("/fleet/profile/form-profile");
  const handleEdit = (value) => history.push(`/fleet/profile/form-profile/${value}`);
  const handleDelete = (value) => {
    Swal.fire({
      title: "Tem certeza que deseja excluir?",
      text: "Essa ação é irreverssível!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: theme.palette.primary.main,
      cancelButtonColor: theme.palette.error.main,
      confirmButtonText: "Sim, excluir!",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await deleteVehicleProfile(value);
          if (response.status !== 200) {
            toast.error("Erro ao atualizar cliente!");
          } else if (response.data) {
            if (response.data.resp) {
              toast.error(
                errorsMessages[response.data.resp].message,
              );
              return;
            }
          }
          if (response.status === 200 || response.status === 201) {
            Swal.fire(
              "Excluído!",
              "O registro foi excluído com sucesso",
              "success",
            );
            fetchProfiles();
          } else {
            throw new Error();
          }
        } catch (error) {
          toast.error(
            "Erro ao excluir veículo. Entre em contato com o suporte",
          );
        }
      }
    });
  };

  const columns = makeColumns({ onDelete: handleDelete, onEdit: handleEdit });

  useEffect(() => {
    if (hasPermission({ scopes: ['can_view_profiles'] })) {
      fetchProfiles();
    }
  }, []);

  const renderTable = useCallback(() => (
    <Table
      columns={columns}
      data={profiles}
      tableName="list-profiles"
      options={{ rowsPerPage }}
      setRowsPerPage={setRowsPerPage}
    />
  ), [columns, profiles, rowsPerPage]);

  return (
    <Aux>
      <PermissionsGate scopes={['can_view_profiles']}>
        <Grid container spacing={4}>
          <Grid item xs={12} className={classes.tableContent}>
            <PermissionsGate scopes={['can_create_profiles']}>
              <Grid container justifyContent="flex-end">
                <Grid item xl={2} lg={3} md={4} sm={12} xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.btnNew}
                    onClick={handleClickAddVehicleProfile}
                  >
                    Novo Perfil
                  </Button>
                </Grid>
              </Grid>
            </PermissionsGate>
            <Widget disableWidgetMenu title="Gerenciamento de Perfis">
              {renderTable()}
            </Widget>
          </Grid>
        </Grid>
      </PermissionsGate>
    </Aux>
  );
};

export default ListProfiles;
