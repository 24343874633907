import { alpha } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(5),
    borderRadius: 28
  },
  paper: {
    borderRadius: 20,
    width: 350,
    maxWidth: "100%",
    padding: "4px 8px 4px 20px",
    marginBottom: 20,
    boxShadow: `3px 5px 4px ${theme.palette.background.backgroundButton}`,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250,
  },
  title: {
    fontSize: 24,
    fontWeight: 500,
    color: theme.palette.text.title,
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(1),
  },
  titleIndicators: {
    fontSize: 20,
    fontWeight: 500,
    color: theme.palette.text.title,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  containerInputs: {
    margin: '8px 0px 8px 0px',
    [`& fieldset`]: {
      borderRadius: 20,
    },
  },
  btnNew: {
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    borderRadius: 10,
    backgroundColor: theme.palette.secondary.main,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.8),
      fontWeight: 600
    },
  },
}));
