import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { toast } from "react-toastify";
import { format } from "date-fns";
import Swal from "sweetalert2";

// components
import Aux from "hoc/auxiliar";
import Table from "components/Table";
import Widget from "components/Widget/Widget";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";

// helpers
import { phoneMask, removeCountryCode } from "helpers/masks";

// services
import { deleteDevice, getDevices } from "services/device";
import { getDeviceStatus } from "helpers/deviceStatus";

import useStyles from "./styles";
import { makeColumns } from "./tableColumns";
import FormRoleSkeleton from "components/Skeletons/FormRoleSkeleton";

const ListDevices = () => {
  var theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const [dataTable, setDataTable] = useState([]);
  const [download,] = useState({
    link: '/devices/export',
    fileName: `unidades-eletronicas_${format(new Date(), "dd-MM-yyyy_HH-mm")}.xlsx`
  });
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [loading, setLoading] = useState(true);

  const handleClickAddDevice = () => history.push("/fleet/device/form-device");
  const handleClickMassAddDevice = () => history.push("/fleet/device/mass-add-device");
  const handleEdit = (deviceId) => {
    history.push(`/fleet/device/form-device/${deviceId}`);
  };
  const handleDelete = (row) => {
    const textAlert = row.currentVehicle
      ? "Existe um veículo vinculado com essa Unidade Eletrônica. "
      : "";
    Swal.fire({
      title: "Tem certeza que deseja excluir?",
      text: `${textAlert}Essa ação é irreverssível!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: theme.palette.primary.main,
      cancelButtonColor: theme.palette.error.main,
      confirmButtonText: "Sim, excluir!",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await deleteDevice(row.id);
          if (response.status === 200) {
            Swal.fire(
              "Excluído!",
              "O registro foi excluído com sucesso",
              "success",
            );
            fetchDevices();
          } else {
            throw new Error();
          }
        } catch (error) {
          toast.error(
            "Erro ao excluir Unidade Eletrônica. Entre em contato com o suporte",
          );
        }
      }
    });
  };

  const handleHistory = (deviceId) => {
    history.push(`/fleet/device/historic-devices/${deviceId}`);
  }

  const columns = makeColumns({ onEdit: handleEdit, onDelete: handleDelete, onHistory: handleHistory });

  const fetchDevices = async () => {
    try {
      setLoading(true)
      const response = await getDevices();
      const formatedResponse = response.data.devices.map((item) => ({
        ...item,
        customerName: item.currentVehicle ? item.currentVehicle.customerName : '',
        brand: item.brand.charAt(0).toUpperCase() + item.brand.slice(1),
        simcard: item.simcard
          ? phoneMask(removeCountryCode(item.simcard))
          : " - ",
        status: getDeviceStatus(item.status),
        created_at: format(new Date(item.created_at), "dd/MM/yyyy HH:mm"),
      }));
      setDataTable(formatedResponse);
    } catch (err) {
      toast.error(
        "Erro ao carregar lista de Unidades Eletrônicas. Entre em contato com o suporte",
      );
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    if (hasPermission({ scopes: ['can_view_devices'] })) {
      fetchDevices();
    }
  }, []);

  return (
    <Aux>
      <PermissionsGate scopes={['can_view_devices']}>
        <Grid container spacing={4}>
          <Grid item xs={12} className={classes.tableContent}>
            <Grid container justifyContent="flex-end">
              <PermissionsGate scopes={['can_import_devices']}>
                <Grid item xl={2} lg={3} md={4} sm={12} xs={12} className={classes.btItem}>
                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.btnNew}
                    onClick={handleClickMassAddDevice}
                  >
                    Importar em Massa
                  </Button>
                </Grid>
              </PermissionsGate>
              <PermissionsGate scopes={['can_create_devices']}>
                <Grid item xl={2} lg={3} md={4} sm={12} xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.btnNew}
                    onClick={handleClickAddDevice}
                  >
                    Nova Unidade Eletrônica
                  </Button>
                </Grid>
              </PermissionsGate>
            </Grid>
            <Widget disableWidgetMenu title="Gerenciamento de Unidades Eletrônicas">
              {loading ? (
                <FormRoleSkeleton />
              ) : (
                <Table
                  columns={columns}
                  data={dataTable}
                  download={download}
                  tableName="list-devices"
                  options={{ rowsPerPage }}
                  setRowsPerPage={setRowsPerPage}
                />
              )}
            </Widget>
          </Grid>
        </Grid>
      </PermissionsGate>
    </Aux>
  );
};

export default ListDevices;
