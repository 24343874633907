import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { Box, Typography } from "@material-ui/core";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import fields from "domain/forms/addDriverInitialValues";
import schemaValidation from "domain/validation/yup/addDriverValidation";
import DriverForm from "components/DriverForm";
import BackButton from "components/BackButton";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";
import { getDriverDetail } from "services/driver";
import { updateDriver } from "services/driver";
import DriverModel from "models/Driver";
import makeDefaultValues from "helpers/makeDefaultValues";
import { onlyNumberMask, removeAllSpecialCharacters } from "helpers/masks";
import useStyles from "./styles";

const EditDriver = () => {
  const [driverEmail, setDriverEmail] = useState({
    currentEmail: null,
    defaultEmail: null,
  });

  const classes = useStyles();
  const history = useHistory();
  const { driverId } = useParams();
  const defaultValues = makeDefaultValues({ fields });

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schemaValidation),
  });

  const fetchDriverDetail = async (id) => {
    try {
      const response = await getDriverDetail(id);
      setDriverEmail({
        currentEmail: response?.data?.email,
        defaultEmail: `${response?.data?.documentNumber}@gobrax.com.br`,
      });
      methods.setValue("id", response.data.id);
      methods.setValue("customerId", response.data.customerId);
      methods.setValue("birthDate", response.data.birthDate);
      methods.setValue("customerOption", response.data.customerId);
      methods.setValue("documentNumber", response.data.documentNumber);
      methods.setValue("name", response.data.name);
      methods.setValue("phone", response.data.phoneNumber);
      methods.setValue("obs", response.data.note);
      methods.setValue("canLink", response.data.canLink);
    } catch (error) {
      console.log(error);
    }
  };

  useMemo(() => {
    if (
      driverEmail?.currentEmail &&
      driverEmail?.currentEmail !== driverEmail?.defaultEmail
    ) {
      methods.setValue("email", driverEmail?.currentEmail);
    }
  }, [driverEmail]);

  const onSubmit = async (data) => {
    const cpfNumbers = removeAllSpecialCharacters(data?.documentNumber);
    try {
      const driverModel = new DriverModel({
        birthDate: data.birthDate || null,
        customerId: data.customer.value,
        documentNumber: onlyNumberMask(data.documentNumber),
        email:
          data.email || `${cpfNumbers}@gobrax.com.br`,
        name: data.name,
        note: data.obs || null,
        phoneNumber: onlyNumberMask(data.phone) || null,
        id: data.id,
        canLink: data.canLink,
      });
      await updateDriver(driverModel);
      const response = await updateDriver(driverModel);
      if (response.status !== 200) {
        toast.error("Erro ao atualizar motorista!");
      } else {
        toast.success("Motorista atualizado com sucesso");
        history.push("/fleet/driver/all-drivers");
      }
    } catch (error) {
      console.log(error);
      toast.error("Error ao atualizar motorista! Verifique com o suporte!");
    }
  };

  useEffect(() => {
    if (hasPermission({ scopes: ["can_edit_drivers"] })) {
      fetchDriverDetail(driverId);
    }
  }, []);

  if (!defaultValues) {
    return "...loading";
  }
  return (
    <>
      <PermissionsGate scopes={["can_edit_drivers"]}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Typography variant="h1" className={classes.title}>
              Edição de Motorista
            </Typography>
            <DriverForm />
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid
                  container
                  item
                  justifyContent="flex-end"
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                    <Box sx={{ m: 2 }}>
                      <BackButton className={classes.backButton} />
                    </Box>
                  </Grid>
                  <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                    <Box sx={{ m: 2 }}>
                      <Button
                        fullWidth
                        variant="contained"
                        type="submit"
                        className={classes.btConfirm}
                      >
                        Atualizar
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </PermissionsGate>
    </>
  );
};

export default EditDriver;
