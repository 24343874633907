import { groupBy } from 'lodash';
import moment from 'moment';

export const permissions = [
  {
    title: "Geral",
    id: "general",
    permissions: [{
      id: "can_select_customers",
      title: "Selecionar Empresa"
    }],
    children: null,
  }, {
    title: "Premiação",
    id: "bonus",
    permissions: [{
      id: "can_view_bonus",
      title: "Visualizar Premiação"
    }],
    children: null,
  }, {
    title: "Emissão de CO²",
    id: "co2",
    permissions: [{
      id: "can_view_co2",
      title: "Visualizar Emissão de CO²"
    }],
    children: null,
  }, {
    title: "Frota",
    id: "fleet",
    permissions: [],
    children: [{
      title: "Análise Individual",
      id: "dashboard",
      permissions: [{
        id: "can_view_dashboard",
        title: "Visualizar Análise Individual"
      }, {
        id: "can_view_dashboard_performance",
        title: "Visualizar Desempenho na Análise Individual"
      }, {
        id: "can_view_dashboard_indicators",
        title: "Visualizar Indicadores na Análise Individual"
      }, {
        id: "can_view_dashboard_pressure",
        title: "Visualizar Uso Pressão do Acelerador"
      }, {
        id: "can_view_dashboard_statistics",
        title: "Visualizar Estatísticas na Análise Individual"
      }, {
        id: "can_view_dashboard_map",
        title: "Visualizar Trajeto de Viagem na Análise Individual"
      }, {
        id: "can_view_dashboard_print",
        title: "Imprimir Análise Individual"
      }, {
        id: "can_view_dashboard_chart",
        title: "Visualizar Gráfico na Análise Individual"
      }],
      children: null,
    }, {
      title: "Comparativo de Veículos",
      id: "comparison",
      permissions: [{
        id: "can_view_comparison",
        title: "Visualizar Comparativo de Veículos"
      }],
      children: null,
    }, {
      title: "Evolutivo",
      id: "evolutive",
      permissions: [{
        id: "can_view_evolutive",
        title: "Visualizar Evolutivo"
      }],
      children: null,
    }, {
      title: "Minha Frota",
      id: "consolidated",
      permissions: [{
        id: "can_view_consolidated",
        title: "Visualizar Minha Frota"
      }, {
        id: "can_view_consolidated_score",
        title: "Visualizar Minha Frota - Nota Geral"
      }, {
        id: "can_view_consolidated_statistics",
        title: "Visualizar Minha Frota - Estatísticas"
      }, {
        id: "can_view_consolidated_indicators",
        title: "Visualizar Minha Frota - Indicadores"
      }, {
        id: "can_view_consolidated_groups",
        title: "Visualizar Minha Frota - Grupos"
      }, {
        id: "can_view_consolidated_brand",
        title: "Visualizar Minha Frota - Média por Marca"
      }, {
        id: "can_view_consolidated_risk",
        title: "Visualizar Minha Frota - Maiores Riscos de Sinistro"
      }, {
        id: "can_view_consolidated_productivity",
        title: "Visualizar Minha Frota - Baixa Produtividade"
      }, {
        id: "can_view_consolidated_vehicles",
        title: "Visualizar Minha Frota - Média por Veículo"
      }],
      children: null,
    }],
  }, {
    title: "Gerenciamento",
    id: "maintenance",
    permissions: [],
    children: [{
      title: "Grupo de Veículo",
      id: "operations",
      permissions: [{
        id: "can_view_operations",
        title: "Visualizar Grupo de Veículo"
      }, {
        id: "can_create_operations",
        title: "Criar Grupo de Veículo"
      }, {
        id: "can_edit_operations",
        title: "Editar Grupo de Veículo"
      }, {
        id: "can_remove_operations",
        title: "Remover Grupo de Veículo"
      }, {
        id: "can_view_minscore",
        title: "Visualizar Nota Mínima"
      }, {
        id: "can_create_minscore",
        title: "Criar Nota Mínima"
      }, {
        id: "can_edit_minscore",
        title: "Editar Nota Mínima"
      }],
      children: null,
    }, {
      title: "Vínculo veículo/motorista",
      id: "linkdrivers",
      permissions: [{
        id: "can_view_linkdrivers",
        title: "Visualizar Vínculo veículo/motorista"
      }, {
        id: "can_view_linkdrivershistory",
        title: "Visualizar Histórico de Vínculo veículo/motorista"
      }, {
        id: "can_edit_linkdrivers",
        title: "Desvincular Motorista no Vínculo veículo/motorista"
      }],
      children: null,
    }],
  }, {
    title: "Motorista",
    id: "drivers_follow",
    permissions: [],
    children: [{
      title: "Motorista",
      id: "drivers_follow_data",
      permissions: [{
        id: "can_view_drivers_follow",
        title: "Acompanhamento dos Motoristas"
      }, {
        id: "can_view_drivers_follow_details",
        title: "Visualizar Veículos que o Motorista Dirigiu"
      }, {
        id: "can_view_drivers_follow_trip_list",
        title: "Visualizar Relatório das Viagens Detalhadas"
      }],
    }],
  }, {
    title: "Área Administrativa",
    id: "admin",
    permissions: [],
    children: [{
      title: "Área Administrativa",
      id: "admin_area",
      permissions: [{
        id: "can_view_admin_area",
        title: "Visualizar Área Administrativa"
      }, {
        id: "view_super_admin_option",
        title: "Visualizar Opção Perfil Super Admin"
      }],
      children: null,
    }, {
      title: "Área Administrativa - Clientes",
      id: "customers",
      permissions: [{
        id: "can_view_customers",
        title: "Área Administrativa - Visualizar Clientes"
      }, {
        id: "can_create_customers",
        title: "Área Administrativa - Criar Clientes"
      }, {
        id: "can_edit_customers",
        title: "Área Administrativa - Editar Clientes"
      }, {
        id: "can_remove_customers",
        title: "Área Administrativa - Remover Clientes"
      }],
      children: null,
    }, {
      title: "Área Administrativa - Motoristas",
      id: "drivers",
      permissions: [{
        id: "can_view_drivers",
        title: "Área Administrativa - Visualizar Motoristas"
      }, {
        id: "can_create_drivers",
        title: "Área Administrativa - Criar Motoristas"
      }, {
        id: "can_edit_drivers",
        title: "Área Administrativa - Editar Motoristas"
      }, {
        id: "can_remove_drivers",
        title: "Área Administrativa - Remover Motoristas"
      }, {
        id: "can_import_drivers",
        title: "Área Administrativa - Importar Motoristas em Massa"
      }, {
        id: "can_recalculate_drivers",
        title: "Área Administrativa - Recalcular Bonificação de Motoristas"
      }],
      children: null,
    }, {
      title: "Área Administrativa - Veículos",
      id: "vehicles",
      permissions: [{
        id: "can_view_vehicles",
        title: "Área Administrativa - Visualizar Veículos"
      }, {
        id: "can_create_vehicles",
        title: "Área Administrativa - Criar Veículos"
      }, {
        id: "can_edit_vehicles",
        title: "Área Administrativa - Editar Veículos"
      }, {
        id: "can_remove_vehicles",
        title: "Área Administrativa - Remover Veículos"
      }, {
        id: "can_import_vehicles",
        title: "Área Administrativa - Importar Veículos em Massa"
      }, {
        id: "can_recalculate_vehicles",
        title: "Área Administrativa - Recalcular Veículos"
      }, {
        id: "can_activate_vehicles",
        title: "Área Administrativa - Ativar/Inativar Veículos"
      }, {
        id: "can_edit_vehicledevice",
        title: "Área Administrativa - Vincular Unidade Eletrônica em Veículos"
      }],
      children: null,
    }, {
      title: "Área Administrativa - Perfis",
      id: "profiles",
      permissions: [{
        id: "can_view_profiles",
        title: "Área Administrativa - Visualizar Perfis"
      }, {
        id: "can_create_profiles",
        title: "Área Administrativa - Criar Perfis"
      }, {
        id: "can_edit_profiles",
        title: "Área Administrativa - Editar Perfis"
      }, {
        id: "can_remove_profiles",
        title: "Área Administrativa - Remover Perfis"
      }],
      children: null,
    }, {
      title: "Área Administrativa - Usuários",
      id: "users",
      permissions: [{
        id: "can_view_users",
        title: "Área Administrativa - Visualizar Usuários"
      }, {
        id: "can_create_users",
        title: "Área Administrativa - Criar Usuários"
      }, {
        id: "can_edit_users",
        title: "Área Administrativa - Editar Usuários"
      }, {
        id: "can_remove_users",
        title: "Área Administrativa - Remover Usuários"
      }, {
        id: "can_update_users_roles",
        title: "Área Administrativa - Atualizar Perfil de Usuários"
      }],
      children: null,
    }, {
      title: "Área Administrativa - Perfil de Usuários",
      id: "role",
      permissions: [{
        id: "can_view_user_role",
        title: "Área Administrativa - Visualizar Perfil de Usuários"
      }, {
        id: "can_create_user_role",
        title: "Área Administrativa - Criar Perfil de Usuários"
      }, {
        id: "can_edit_user_role",
        title: "Área Administrativa - Editar Perfil de Usuários"
      }, {
        id: "can_remove_user_role",
        title: "Área Administrativa - Remover Perfil de Usuários"
      }],
      children: null,
    }, {
      title: "Área Administrativa - Unidades Eletrônicas",
      id: "devices",
      permissions: [{
        id: "can_view_devices",
        title: "Área Administrativa - Visualizar Unidades Eletrônicas"
      }, {
        id: "can_view_history_devices",
        title: "Área Administrativa - Visualizar Histórico de Unidades Eletrônicas"
      }, {
        id: "can_create_devices",
        title: "Área Administrativa - Criar Unidades Eletrônicas"
      }, {
        id: "can_edit_devices",
        title: "Área Administrativa - Editar Unidades Eletrônicas"
      }, {
        id: "can_remove_devices",
        title: "Área Administrativa - Remover Unidades Eletrônicas"
      }, {
        id: "can_import_devices",
        title: "Área Administrativa - Importar Unidades Eletrônicas em Massa"
      }],
      children: null,
    }, {
      title: "Área Administrativa - Grupo de Bonificação",
      id: "rewardgroup",
      permissions: [{
        id: "can_view_rewardgroup",
        title: "Área Administrativa - Visualizar Grupo de Bonificação"
      }, {
        id: "can_create_rewardgroup",
        title: "Área Administrativa - Criar Grupo de Bonificação"
      }, {
        id: "can_edit_rewardgroup",
        title: "Área Administrativa - Editar Grupo de Bonificação"
      }, {
        id: "can_remove_rewardgroup",
        title: "Área Administrativa - Remover Grupo de Bonificação"
      }],
      children: null,
    }, {
      title: "Área Administrativa - Conectividade",
      id: "telemetrydata",
      permissions: [{
        id: "can_view_telemetrydata",
        title: "Área Administrativa - Visualizar Conectividade"
      }],
      children: null,
    }],
  }, {
    title: "Beta Tester",
    id: "beta",
    permissions: [{
      id: "can_access_beta",
      title: "Liberar Versão Beta Tester"
    }],
    children: null,
  }
];

export const findPermissionName = (id) => {
  let founded = [];
  permissions.forEach((obj) => {
    if (obj.children && obj.children.length > 0) {
      obj.children.forEach(el => {
        if(el.permissions.length > 0) {
          let item = el.permissions.find(item => item.id === id);
          if(item !== undefined) {
            founded.push(item.title);
          }
        }
      })
    } else if (obj.permissions.length > 0) {
      let item = obj.permissions.find(item => item.id === id);
      if(item !== undefined) {
        founded.push(item.title);
      }
    }
  });
  if (founded.length > 0){
    return founded;
  }
  return id;
};

export const permissionsDefaultValues = () => {
  let itensValue = {};
  permissions.forEach((element) => {
    if(element.permissions.length > 0) {
      let newItem = element.permissions.map(item => item.id);
      itensValue[newItem] = false;
    }
    if(element.children && element.children.length > 0) {
      element.children.forEach(item => {
        let newItem = item.permissions.map(elm => elm.id);
        newItem.forEach((e) => itensValue[e] = false)
      })
    }
  });
  return itensValue;
};

export const getGroupedRoles = (data) => {
  if (!data || data.length === 0) {
    return [];
  }
  // ordena pela role
  data.sort((a, b) => a.role - b.role);
  // agrupa pela role
  const grouped = groupBy(data, 'role');
  // monta o objeto para listar na tabela
  const rolesArray = Object.keys(grouped).map((objectKey) => {
    let permissionsArray = grouped[objectKey].map(value => findPermissionName(value.permission));
    // agrupa as datas para pegar mais antiga (creationDate) e recente (updateDate)
    let moments = grouped[objectKey].map(d => moment(d.date));
    return {
      name: grouped[objectKey][0].name,
      role: grouped[objectKey][0].role,
      creationDate: moment.min(moments)._d,
      updateDate: moment.max(moments)._d,
      permissions: permissionsArray.join(', '),
    }
  });
  return rolesArray;
};
