import React, { useState, useCallback } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { format } from "date-fns";

// components
import Table from "../Table";
import { makeColumns } from "./tableColumns";
// styles
import useStyles from "./styles";

export default function ComparisonTripListDetail({ selectedComparisonTripListDetail, data, open, handleClose }) {
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const classes = useStyles();
  const columns = makeColumns();
  const download = {
    link: "/drivers-performances-by-vehicle-XLS",
    fileName: `detalhamento-motoristas-veiculo_${format(new Date(), "dd-MM-yyyy_HH-mm")}.xlsx`,
    params: selectedComparisonTripListDetail
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle disableTypography className={classes.titleRoot}>
        <Typography variant="h5">
          Detalhamento de motoristas do veículo
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Table
          columns={columns}
          data={data}
          download={download}
          options={{ rowsPerPage }}
          setRowsPerPage={setRowsPerPage}
          tableName="comparison-trip-list-details"
        />
      </DialogContent>
      <DialogActions>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid container justifyContent="flex-end" spacing={2}>
            <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
              <Box sx={{ m: 2 }}>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  className={classes.btnBack}
                  onClick={handleClose}
                >
                  Voltar
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
