import React from "react";
import { Grid, Paper } from "@material-ui/core";

// components
import Calendar from "../Calendar";
import OperationFilters from "../OperationFilters";
import SelectMonth from "../SelectMonth";
import MultiSelect from "../MultiSelect";

// styles
import useStyles from "./styles";
import DriverPerformanceDetailComponent from "components/DriverPerformanceDetailComponent";

const PageToolbar = ({
  isEmission,
  listData,
  menuCalendar,
  menuOperations,
  selectedDates,
  vehiclesMenuButton,
  vehicleProps,
  isDetail,
  handleSelectDate,
  isSingleMode,
  handleSelectedData,
  selectedData,
  handleSelectedOperation,
  calendarMonthValue,
  isCalendarMonthDisabled,
  calendarMaxRanges
}) => {
  const classes = useStyles();
  const handleCalendar = (startDate, endDate) => {
    handleSelectDate(startDate, endDate);
  };
  return (
    <div>
      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="space-around"
        className={classes.pageToolbarContainer}
      >
        {vehiclesMenuButton && (
          <Grid item xs={12} sm={6} className={classes.spacingContainer}>
            <Paper elevation={0} className={classes.multiselect}>
              <MultiSelect
                isDetail={isDetail}
                isSingleMode={isSingleMode}
                listData={listData}
                handleSelectedListData={handleSelectedData}
                selectedData={selectedData}
                vehicleProps={vehicleProps}
              />
            </Paper>
          </Grid>
        )}
        {isDetail && (
          <Grid item xs={12} sm={12} xl={12} lg={12} className={classes.spacingContainer}>
            <DriverPerformanceDetailComponent vehicleProps={vehicleProps} />
          </Grid>
        )}
        {menuOperations && (
          <Grid item xs={12} sm={12} className={classes.spacingContainer}>
            <OperationFilters
              handleCalendar={handleCalendar}
              handleSelectedOperation={handleSelectedOperation}
            />
          </Grid>
        )}
        {(menuCalendar && !isDetail) && (
          <Grid item xs={12} sm={6} className={classes.spacingContainer}>
            <Paper elevation={0} className={classes.multiselect}>
              <Calendar
                selectedDates={selectedDates}
                handleCalendar={handleCalendar}
                calendarMaxRanges={calendarMaxRanges}
              />
            </Paper>
          </Grid>
        )}
        {isEmission && (
          <Grid item xs={12} sm={6}>
            <SelectMonth
              title="Mês"
              value={calendarMonthValue}
              onSelectDate={handleSelectDate}
              disabled={isCalendarMonthDisabled}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default PageToolbar;
