import { makeStyles } from "@material-ui/styles";
import { alpha } from "@material-ui/core/styles/colorManipulator";

export default makeStyles((theme) => ({
  title: {
    fontSize: 24,
    fontWeight: 500,
    color: theme.palette.text.title,
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(1),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  btConfirm: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: theme.spacing(1),
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.8),
      fontWeight: 600
    },
  },
  backButton: {
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.background.backgroundButton
  },
  inputCalendar: {
    '& .MuiInputBase-input': {
      textAlign: 'center',
      width: '150px',
      border: '1px solid '+theme.palette.text.hint,
      borderRadius: '5px',
      padding: '15px'
    }
  },
  explanation: {
    fontSize: 16,
    fontWeight: 400
  },
  alert: { 
    borderRadius: "22px",
    marginTop: 10, 
    marginBottom: 10,
    fontSize: 14,
  }
}));
