import React from 'react';
import { Button, Grid, Paper, Typography } from '@material-ui/core';
import moment from 'moment'

import EventIcon from '@material-ui/icons/Event';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import PersonIcon from '@material-ui/icons/Person';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';

import useStyles from './styles';
import { setStepDeduct } from 'redux/features/driversMonitoringSlice';
import { setStepDeductBonus } from 'redux/features/bonusFollowSlice';
import { useAppDispatch } from 'redux/store';

function DriverPerformanceDetailComponent({ vehicleProps }) {
  const dispatch = useAppDispatch()
  const { detail, vehicleId, startDate, finishDate, screen } = vehicleProps
  const classes = useStyles()
  const history = useHistory()
  const formatDate = "DD/MM/yyyy HH:mm";
  return (
    <>
      <Grid container>
        <Typography className={classes.title}>Performance do motorista selecionado</Typography>
      </Grid>
      <Paper className={classes.paper}>
        <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid className={classes.wrapperContent} container item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid className={classes.flex}>
              <LocalShippingIcon className={classes.icon} />
              <Typography>{vehicleId.vehicleIdentification} / {vehicleId.plate}</Typography>
            </Grid>
            <Grid className={classes.flex}>
              <PersonIcon className={classes.icon} />
              <Typography>{detail.name}</Typography>
            </Grid>
            <Grid className={classes.flex}>
              <EventIcon className={classes.icon} />
              <Typography className={classes.icon}>{moment(startDate).format(formatDate)} ({vehicleId.origin.name}) - </Typography>
              <Typography className={classes.icon}>{moment(finishDate).format(formatDate)} ({vehicleId.destination.name})</Typography>
            </Grid>
          </Grid>
          <Grid className={classes.wrapperButton} container item xl={4} lg={4} md={4} sm={4} xs={4}>
            <Button
              onClick={() => {
                if (screen !== 'bonus') {
                  dispatch(setStepDeduct())
                } else {
                  dispatch(setStepDeductBonus())
                } 
                history.goBack();

              }}
              variant="contained"
              className={classes.backButton}
            >
              <ArrowBackIcon fontSize="small" />{" "}Voltar
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}

export default DriverPerformanceDetailComponent;
