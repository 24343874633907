import { Box, Grid, Paper } from '@material-ui/core';
import LottieComponent from 'components/LottieComponent';
import React from 'react';
import useStyles from './styles';
import notFound from 'assets/lottie/sad.json'

function NotFoundWithLottie({lottieFiles, description, strongDescription}) {
  const classes = useStyles()
  return (
    <Grid container justifyContent="center">
      <Paper elevation={2} className={classes.paper}>
          <Grid container spacing={3}>
            <Grid justifyContent="center" container item>
              <LottieComponent
                animationData={lottieFiles || notFound}
                height={300}
                width={300}
              />            
            </Grid>
            <Grid item xs={12}>
              <Box
                fontFamily="fontFamily"
                justifyContent="center"
                fontSize="h4.fontSize"
                textAlign="center"
                lineHeight={2}
                className={classes.infoBox}
                p={4}

              >
                {description}
                <strong>{strongDescription}</strong>
              </Box>
            </Grid>
          </Grid>
        </Paper>
    </Grid>
  )
}

export default NotFoundWithLottie;