import tinycolor from "tinycolor2";

const primary = "#294F94";
const primaryAlmostWhite = "#0095ff0a";
const primaryExtraLight= "#A6DAFC";
const primaryLight = "#3694D7";
const primaryDark = "#082D47";
const primaryExtraDark = "#062338";
const secondary = "#F7DF36";
const secondaryLight = "#ffea66";
const secondaryDark = "#d6bd1d";
const black = "#000000";
const white = "#FFFFFF";
const gray = "#818894";
const grayExtraLight = "#F1F1F1";
const grayLight = "#B5BCC3";
const grayExtraDark = "#424448";
const grayAlmostLight = "#f8f9fa";
const background = "#F4F4F6";
const background2 = "#F6F7F9";
const warning = "#FFBB0E";
const success = "#40D81B";
const info = "#3694D7";
const error = "#EF2C2C";
const tertriary = "#F178B6";
const title = "#666666";
const hoverMultiMenu = "#e6e6e6";
const backgroundBackButton = "#DADADA";
const scrollBar = "#A6A6A6";
const scrollBarBack = "#FFFEFE";
const orange = "#ff7a06";
const teal = "#008080";

const lightenRate = 7.5;
const darkenRate = 15;

export default {
  palette: {
    primary: {
      main: primary,
      almostWhite: primaryAlmostWhite,
      extraLight: primaryExtraLight,
      light: primaryLight,
      dark: primaryDark,
      extraDark: primaryExtraDark,
      contrastText: white,
    },
    secondary: {
      main: secondary,
      light: secondaryLight,
      dark: secondaryDark,
      contrastText: black,
    },
    tertriary: {
      main: tertriary,
      light: tinycolor(tertriary)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(tertriary)
        .darken(darkenRate)
        .toHexString(),
    },
    error: {
      main: error,
      light: tinycolor(error)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(error)
        .darken(darkenRate)
        .toHexString(),
    },
    warning: {
      main: warning,
      light: tinycolor(warning)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(warning)
        .darken(darkenRate)
        .toHexString(),
    },
    success: {
      main: success,
      light: tinycolor(success)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(success)
        .darken(darkenRate)
        .toHexString(),
      contrastText: white,
    },
    info: {
      main: info,
      light: tinycolor(info)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(info)
        .darken(darkenRate)
        .toHexString(),
    },
    text: {
      primary: grayExtraDark,
      secondary: gray,
      hint: grayLight,
      title: title
    },
    textPrimary: {
      main: primary,
      light: primaryLight,
      dark: primaryDark,
    },
    background: {
      default: background,
      background2: background2,
      backgroundButton:backgroundBackButton,
      hoverMultiMenu: hoverMultiMenu
    },
    gray: {
      default: gray,
      grayAlmostLight: grayAlmostLight,
      extraLight: grayExtraLight,
      light: grayLight,
      extraDark: grayExtraDark 
    },
    teal: teal,
    orange: orange,
    white: white,
    black: black,
    scrollBar: scrollBar,
    scrollBarBack: scrollBarBack
  },
  customShadows: {
    widget:
      "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetDark:
      "0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetWide:
      "0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    menu: "0px 2px 3px -1px #C4C4C4",
    scrollBar: "0px 2px 4px rgba(0, 0, 0, 0.05)"
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: "#4A4A4A1A",
      },
    },
    MuiMenu: {
      paper: {
        boxShadow:
          "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
      },
    },
    MuiSelect: {
      icon: {
        color: "#B9B9B9",
      },
    },
    MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: `${background} !important`,
          "&:focus": {
            backgroundColor: background,
          },
        },
      },
      button: {
        "&:hover, &:focus": {
          backgroundColor: background,
        },
      },
    },
    MuiTouchRipple: {
      child: {
        backgroundColor: white,
      },
    },
    MuiTableRow: {
      root: {
        height: 56,
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: "1px solid rgba(224, 224, 224, .5)",
        paddingLeft: 24
      },
      head: {
        fontSize: "0.95rem",
      },
      body: {
        fontSize: "0.95rem",
      },
    },
    PrivateSwitchBase: {
      root: {
        marginLeft: 0
      }
    },
    MuiListSubheader: {
      root: {
        lineHeight: "40px"
      }
    }
  },
};
