import React from 'react';
import { Typography } from '@material-ui/core'
import useStyles from './styles'

const Copyright = () => {
  const classes = useStyles()
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      className={classes.copyright}
      align="center"
    >
      © 2020-{new Date().getFullYear()}{" "}
      <a
        style={{ textDecoration: "none", color: "inherit" }}
        href="https://gobrax.com.br"
        rel="noopener noreferrer"
        target="_blank"
      >
        Gobrax
      </a>
      . Todos os direitos reservados.
    </Typography>
  )
};


export default Copyright