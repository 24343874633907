import React, { useEffect, useState } from 'react'
import { Box, Button, Grid, Paper, Typography } from '@material-ui/core'
/* import { format } from 'date-fns' */
import useStyles from './styles'
import Aux from 'hoc/auxiliar'
import Table from 'components/Table'
import Widget from 'components/Widget/Widget'
import { makeColumns } from './tableColumns'
import { useHistory, useLocation } from 'react-router-dom'
import { CustomCheckbox } from 'components/Table/components/CustomCheckbox'

// contexts 
import { useSelectDrivers } from "context/useSelectDrivers"
import { setStepAdd, setStepDeduct } from 'redux/features/driversMonitoringSlice'
import { useAppDispatch } from 'redux/store'

const DriversMonitoringTripList = () => {
  const dispatch = useAppDispatch()
  const { state } = useLocation()
  const history = useHistory()
  const classes = useStyles()
  const columns = makeColumns()
  const [selectedRowData, setSelectedRowData] = useState(null)
  const [tripListDetailsData, setTripListDetailsData ] = useState(null)
  const { 
    tripListDetails
  } = useSelectDrivers()
  /* const download = {
    link: "/driver-performance-XLS",
    fileName: `relatorio-viagens_${format(new Date(), "dd-MM-yyyy_HH-mm")}.xlsx`,
    params: {
      driverId: state.driverId,
      startDate: state.startDate,
      endDate: state.endDate,
      limit: 1000
    }
  };*/

  const handleClickDetail = () => {
    dispatch(setStepAdd())
     history.push({
      pathname: "/fleet/dashboard/general/driversMonitoring",
      state: {
        startDate: selectedRowData.origin.date,
        finishDate: selectedRowData.destination.date,
        vehicleId: selectedRowData,
        detail: {
          ...state, 
          data: [selectedRowData],
          endDate: selectedRowData.destination.date,
          driverId: state.driverId
        },
      },
    })
  }

  useEffect(() => {
    setTripListDetailsData(tripListDetails?.lenght === 0 || state.tripList)
  }, [])

  return (
    <Aux>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item lg={11} md={11} sm={12} xs={12}>
          <Box className={classes.marginTop}>
            <Widget
              disableWidgetMenu
              title="Relatório de Viagens Detalhadas">
              <Grid item xl={12} xs={12}>
                <Grid container spacing={2}>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Grid
                      container
                      component={Paper}
                      spacing={2}
                      className={classes.infoDriverContainer}
                    >
                      <Grid className={classes.contentDriver} container item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Typography className={classes.label}>Motorista: </Typography>
                        <strong className={classes.driverName}>{state.name}</strong>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Grid
                      container
                      component={Paper}
                      spacing={2}
                      className={classes.infoDriverContainer}
                    >
                      <Grid className={classes.contentDriver} container item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Typography className={classes.label}>Veículo: </Typography>
                        <strong className={classes.driverName}>{state.vehicle}</strong>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xl={12} xs={12} className={classes.marginTop}>
                {tripListDetailsData && <Table
                  data={tripListDetailsData}
                  columns={columns}
                  /* download={download} */
                  options={{
                    rowsPerPage: 100,
                    customToolbarSelect: () => {},
                    selectableRows: "single",
                    onRowSelectionChange: (data) => {
                      const newSelectedRowData = tripListDetailsData.find(
                        (_info, index) => index === data[0].dataIndex,
                      );
                      setSelectedRowData(newSelectedRowData);
                    }
                  }}
                  components={{
                    Checkbox: CustomCheckbox,
                  }}
                  tableName="drivers-monitoring-tripList"
                />}
              </Grid>
            </Widget>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                  <Box sx={{ m: 2 }}>
                    <Button
                      fullWidth
                      variant="contained"
                      className={classes.buttonBack}
                      onClick={() => {
                        dispatch(setStepDeduct());
                        history.goBack();
                        }
                      }
                    >
                      Voltar
                    </Button>
                  </Box>
                </Grid>
                <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                  <Box sx={{ m: 2 }}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="secondary"
                      onClick={handleClickDetail}
                      disabled={!selectedRowData}
                      className={classes.buttonDetail}
                    >
                      Ver detalhes
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Aux>
  )
}

export default DriversMonitoringTripList
