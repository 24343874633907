import api from "./api";

const arrayDataUrl = (arrayData = "") => {
  let partialUrl = "";
  if (arrayData) {
    partialUrl = Array.isArray(arrayData) ? arrayData.join() : arrayData;
  }
  return partialUrl;
};

export const getDriversMonitoring = async (drivers, vehicles, startDate, endDate, limit, page) => {
  const driversUrl = arrayDataUrl(drivers);
  const vehiclesUrl = arrayDataUrl(vehicles);
  const params = `drivers=${driversUrl}&vehicles=${vehiclesUrl}&startDate=${startDate}&endDate=${endDate}&limit=${limit}&page=${page}`;
  try {
    const response = await api.get(`/drivers-monitoring?${params}`);
    return response;
  } catch (err) {
    throw err;
  }
};