import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from "@material-ui/core/CircularProgress";
import { CloudDownload as Download } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from "react-toastify";
import { getDownload, getDownloadWithParams } from '../../../../services/files';
import { downloadFile, getIdListUrl } from '../../../../helpers/functions';

const CustomToolbar = (props) => {
  const classes = useStyles();
  const { download } = props;
  const [loading, setLoading] = useState(false)

  const getDownloadFile = async (response) => {
    let responseData = response.data.XLSX || response.data.XLS;
    downloadFile(responseData, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", download.fileName);
  };

  const handleDownload = async () => {
    setLoading(true)
    try {
      const response = await getDownload(download.link);
      getDownloadFile(response);
    } catch (err) {
      toast.error("Erro ao carregar relatório. Entre em contato com o suporte");
    } finally {
      setLoading(false)
    }
  };

  const handledownloadWithParams = async () => {
    setLoading(true)
    let params = {};
    for(let key in download.params) {
      if(Array.isArray(download.params[key])){
        let itens = getIdListUrl(download.params[key]);
        params[key] = itens;
      } else {
        params[key] = download.params[key];
      }
    }
    try {
      const response = await getDownloadWithParams(download.link, params);
      getDownloadFile(response);
    } catch (err) {
      toast.error("Erro ao carregar relatório. Entre em contato com o suporte");
    } finally {
      setLoading(false)
    }
  };

  const handleClick = async () => {
    if(!download.params) {
      handleDownload();
    } else {
      handledownloadWithParams();        
    }      
  };

  return (
    <>
      {download.link && download.fileName && <Tooltip title={"Download Excel"}>
        {loading ? (
          <IconButton disabled>
            <CircularProgress color="inherit" size={20} thickness={6} />
          </IconButton>
        ) : (
          <IconButton className={classes.iconButton} onClick={handleClick}>
            <Download className={classes.deleteIcon} />
          </IconButton>
        )}
      </Tooltip>}
    </>
  );
}

const useStyles = makeStyles(theme => ({
  iconButton: {
    width: "34px",
    height: "34px",
  },
}));

export default CustomToolbar;