import React, { useState, useEffect, useCallback } from "react";
import { Box, Button, Checkbox, FormControlLabel, Grid, Paper, Typography } from "@material-ui/core";
import { useDebouncedCallback } from 'use-debounce';
import { format } from "date-fns";
import { toast } from "react-toastify";
import { Alert, AlertTitle } from "@material-ui/lab";
import Select from "react-select"
import makeAnimated from "react-select/animated";

// hoc
import Aux from "hoc/auxiliar";

// components
import ComparisonTripListDetail from "components/CoparisonTripListDetail";
import ListVehiclesCompareSkeleton from "components/Skeletons/ListVehiclesCompareSkeleton";
import MultiSelect from "components/MultiSelect";
import Table from "components/Table";
import Widget from "components/Widget";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";
import Calendar from "components/Calendar";
import { useSelect } from "context/useSelect";

// redux
import { useAppDispatch, useAppSelector, store } from 'redux/store';
import { clearComparisonPagination, setPaginationReducer } from 'redux/features/comparisonSlice';

// services
import { getCustomersById } from "services/customers";
import { getDriversPerformance } from "services/driver";
import {
  getVehiclesByOperation,
  getDriversHistory,
  getVehicleValidation
} from "services/vehicle";
import { 
  getVehiclesPerformancePackLimitPage,
} from "services/fleetPerformance";

// helpers
import { orderVehiclesByCustomer } from 'helpers/vehicles';
import { makeColumns } from './tableColumns';
import { generateRange } from 'helpers/operations';

// styles
import useStyles from "./styles";
import { colourStyles } from "./colourStyles";
import { ValidationMessageComponent } from "components/ValidationMessageComponent";

export default function Comparison() {
  const { currentCustomer } = useAppSelector((state) => state.global.user);
  const { pagination } = useAppSelector((state) => state.comparison);
  const dispatch = useAppDispatch()
  const classes = useStyles();
  const columns = makeColumns();
  const animatedComponents = makeAnimated();
  const today = new Date(new Date().setHours(0,0,0,0));
  const dateFormatTemplate = "yyyy-MM-dd'T'HH:mm:ssXX";

  const [infoBox, setInfoBox] = useState(false)
  const [checkboxInfo, setCheckboxInfo] = useState(false)
  const [loading, setLoading] = useState(true);
  const [selectedComparisonTripListDetail, setSelectedComparisonTripListDetail] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedDataRow, setSelectedDataRow] = useState(null);
  const firstOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const [selectedDates, setSelectedDates] = useState({
    initialDate: format(firstOfMonth, dateFormatTemplate),
    finalDate: format(new Date(), "yyyy-MM-dd'T'23:59:59XX"),
  });
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [selectedOperations,] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [vehiclesPerformance, setVehiclesPerformance] = useState([]);
  const [download, setDownload] = useState({
    link: "",
    fileName: "",
    params: {
      vehicles: [],
      startDate: null,
      endDate: null,
    }
  });

  const selectFilterOptions = [{
    id: 0,
    label: "Data de Corte",
    name: "Data de Corte",
    value: 0
  }, {
    id: 1,
    label: "Calendário",
    name: "Calendário",
    value: 1
  }]

  const [validationMessageComponent, setValidationMessageComponent] = useState(false)
  const [validationMessage, setValidationMessage] = useState(false)
  const [isCalendarFilter, setIsCalendarFilter] = useState(selectFilterOptions[0]);
  const [customersDates, setCustomersDates] = useState(null);
  const {
    selectedCutOffDate,
    setSelectedCutOffDate,
    cutOffDatesOptions,
    setCutOffDatesOptions,
    onChangeCutOffDate,
  } = useSelect()
  const [selectedYear, setSelectedYear] = useState({
    value: today.getFullYear(),
    label: today.getFullYear(),
  });

  const yearOptions = [];
  const currentYear = today.getFullYear();
  const lastYearToShow = today.getMonth() > 10 ? currentYear+1 : currentYear;
  for (let i = 2020; i <= lastYearToShow; i++) {
    yearOptions.push({ value: i, label: i });
  }
  
  const fetchVehiclesPerformance = async (startDate, endDate) => {
    setLoading(true)
    dispatch(setPaginationReducer({ isLoading: true }));
    try {
      const indentificationList = selectedVehicles.map((vehicle) => vehicle.currentDevice.identification);
      const vehicleList = selectedVehicles.map((vehicle) => vehicle.id);
      let response = null;
      if (!isCalendarFilter) {
        return;
      }
      const updatedPagination = store.getState().comparison.pagination;
      response = await getVehiclesPerformancePackLimitPage(
        vehicleList, 
        startDate, 
        endDate,
        updatedPagination.rowsPerPage,
        updatedPagination.page,
      );
      if (!response || !response.data?.vehiclesPerformances) {
        setVehiclesPerformance([]);
        return; 
      }
      const formatedResponse = response.data.vehiclesPerformances.map(
        (performance, index) => {
          return {
            ...performance
          };
        },
      );
      
      dispatch(setPaginationReducer({ isLoading: false, count: response.data.total_items || 0 }))
      setVehiclesPerformance(formatedResponse);
      setDownload({
        link: `/vehicles-performances/XLS`,
        fileName: `comparativo_${format(
          new Date(selectedDates.initialDate),
          "dd-MM-yyyy-HH-mm",
        )}_${format(new Date(selectedDates.finalDate), "dd-MM-yyyy-HH-mm")}.xlsx`,
        params: {
          vehicles: vehicleList,
          startDate: selectedDates.initialDate,
          endDate: selectedDates.finalDate,
        }
      });
    } catch (err) {
      toast.warning(err.message || "Erro ao carregar lista de Veículos. Entre em contato com o suporte");
    } finally {
      setLoading(false);
    }
  };

  const handleModalOpen = async (data, row) => {
    const vehicleId = vehiclesPerformance[row.dataIndex].id
    try {
      setLoading(true)
      const responseDriversHistory = await getDriversHistory(
        vehicleId,
        selectedDates.initialDate,
        selectedDates.finalDate,
      );
      if (!responseDriversHistory.data.drivers) {
        toast.warning("Não há nenhum motorista vinculado no período selecionado.");
        return;
      } else {
        const uniqueDrivers = [
          ...responseDriversHistory.data.drivers
            .reduce(
              (map, obj) => map.set(obj.driverId, obj.driverId),
              new Map(),
            )
            .values(),
        ];
        const responseDriversPerformance = await getDriversPerformance(
          uniqueDrivers,
          vehicleId,
          selectedDates.initialDate,
          selectedDates.finalDate,
        );

        const { driversPerformances } = responseDriversPerformance.data

        if (!driversPerformances) {
          toast.error("Veículo sem viagens registradas.");
          return;
        }
        const { performances } = driversPerformances
        const trips = performances.map((driver) =>
          driver.tripsScores.map((trip, index) => ({
            ...trip,
            driverName: driver.driverName,
            id: index,
          })),
        );

        setSelectedComparisonTripListDetail({
          drivers: uniqueDrivers,
          vehicleId: vehicleId,
          startDate: selectedDates.initialDate,
          endDate: selectedDates.finalDate,
          limit: 1000
        });

        setSelectedDataRow(trips[0]);
        setModalOpen(true);
      }
    } catch (err) {
      toast.error("Erro ao carregar lista. Entre em contato com o suporte");
    } finally {
      setLoading(false);
    }
  };

  const handleModalClose = () => setModalOpen(false);

  const checkVehicles = async (initialDate, finalDate) => {
    let vehicles = selectedVehicles.map((vehicle) => vehicle.id);
    const validation = await getVehicleValidation(
      vehicles,
      initialDate,
      finalDate
    )
    const { response: vehicleValidationConfig } = validation.data
    setValidationMessage(vehicleValidationConfig)
    setValidationMessageComponent(vehicleValidationConfig !== 'ok')
    return vehicleValidationConfig !== 'ok';
  }

  // Responsavel por pegar as datas no componente pageToolbar.
  const handleSelectDate = async (initialDate, finalDate) => {
    setValidationMessageComponent(false)
    setSelectedDates({
      initialDate: initialDate,
      finalDate: finalDate,
    });
  };

  const setOperationVehicles = () => {
    if (selectedOperations) {
      const allVehiclesOperationArray = [];
      vehicles.map(
        (operation) =>
          selectedOperations.includes(operation.id) &&
          operation.vehicles.forEach((vehicle) => {
            allVehiclesOperationArray.push({
              identification: vehicle.currentDevice.identification,
              id: vehicle.id,
            });
          }),
      );
      setSelectedVehicles(allVehiclesOperationArray);
    }
  };

  const handleSelectedVehicles = async (selected) => {
    setValidationMessageComponent(false)
    setSelectedVehicles(selected);
    if (selected.length > 0) {
      try {
        let vehicles = []
        vehicles = selected.map((vehicle) => vehicle.id);
        const validation = await getVehicleValidation(vehicles,
          selectedDates.initialDate,
          selectedDates.finalDate)
        const { response: messageResponse } = validation.data
        setValidationMessage(messageResponse)
        if (messageResponse !== 'ok') {
          setInfoBox(true)
          setValidationMessageComponent(true)
        }
      } catch (error) {
        toast.error('Erro ao validar configurações do veículo. Por favor, entre em contato com o suporte.')
      }
    } else {
      setSelectedVehicles([])
      setInfoBox(false)
      setIsCalendarFilter(selectFilterOptions[0])
      return false
    }
  };

  const getLocalStorageInfo = () => {
    setInfoBox(localStorage.getItem('@infobox_comparison'))
  }

  const handleChangeVisibleInfoBox = () => {
    if (!checkboxInfo) return setInfoBox(true)
    localStorage.setItem('@infobox_comparison', checkboxInfo)
    getLocalStorageInfo()
  }

  const handleCheckboxInfoIsVisible = () => {
    setCheckboxInfo(!checkboxInfo)
  }

  const handleCutOffDates = (customersOptions) => {
    const startingDay = customersOptions.starting_day;
    const finishingDay = customersOptions.finishing_day;
    const period = customersOptions.period;
    if (startingDay && finishingDay && !isNaN(period)) {
      const cutOffDate = generateRange(startingDay, finishingDay, period, selectedYear.value);
      const selectedMonth = today.getDate() > finishingDay ? today.getMonth() + 1 : today.getMonth()
      setCutOffDatesOptions(cutOffDate);
      setSelectedCutOffDate(cutOffDate[selectedMonth]);
      const initialDate = format(cutOffDate[selectedMonth].startDate, dateFormatTemplate)
      const finalDate = format(cutOffDate[selectedMonth].finishDate, dateFormatTemplate)
      setSelectedDates({
        initialDate: initialDate,
        finalDate: finalDate
      })
      handleSelectDate(initialDate, finalDate)
    } else {
      setCutOffDatesOptions(null);
      setSelectedCutOffDate(null);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      // get customers
      const responseCustomers = await getCustomersById(currentCustomer);
      if (responseCustomers.status !== 200) {
        throw new Error("Error fetching customers");
      }
      const customersOptions = responseCustomers.data.customers[0];
      setCustomersDates(customersOptions);
      handleCutOffDates(customersOptions);

      // get vehicles
      const response = await getVehiclesByOperation(currentCustomer);
      if (response.data.customers) {
        const orderedVehicles = orderVehiclesByCustomer(response.data?.customers);
        setVehicles(orderedVehicles);
      }
    } catch (error) {
      toast.error("Erro ao buscar dados. Contate o suporte.");
      setCutOffDatesOptions(null);
      setSelectedCutOffDate(null);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const handleTablePagination = (action, tableState) => {
    switch (action) {
      case 'changePage':
        dispatch(setPaginationReducer({ isLoading: true, page: tableState.page+1 }))
        fetchVehiclesPerformance(selectedDates.initialDate, selectedDates.finalDate)
        break;
      case 'changeRowsPerPage':
        dispatch(setPaginationReducer({ isLoading: true, rowsPerPage: tableState.rowsPerPage }))
        fetchVehiclesPerformance(selectedDates.initialDate, selectedDates.finalDate)
        break;
      default:
        break;
    }
  }

  const handleVehicleListRender = useCallback(() => {
    if (selectedVehicles.length === 0 || (!isCalendarFilter && !selectedDates.startDate && !checkboxInfo)) {
      return getInfoBox();
    }
    if (loading) {
      return <ListVehiclesCompareSkeleton />
    }
    if (!loading && (!vehiclesPerformance || vehiclesPerformance.length === 0)) {
      return (
        <Alert severity="info">
          <AlertTitle>Atenção!</AlertTitle>
          Não foram encontrados dados para o período selecionado! -{" "}
          <strong>verifique os filtros!</strong>
        </Alert>
      );
    }
    return (
      <Grid>
        {validationMessageComponent && <ValidationMessageComponent message={validationMessage} isComparison />}
        <Grid container data-cy="tableContent">
          <Grid item xs={12} className={classes.contentDataTable}>
            <Box>
              <Widget disableWidgetMenu title="Comparativo">
                <Grid item xs={12} className={classes.table}>
                  {vehiclesPerformance.length > 0 && 
                    <Table
                      columns={columns}
                      data={vehiclesPerformance}
                      options={{
                        onRowClick: handleModalOpen,
                        rowsPerPage: pagination.rowsPerPage,
                        serverSide: true,
                        count: pagination.count,
                        onTableChange: handleTablePagination,
                        isLoading: pagination.isLoading,
                        page: pagination.page-1
                      }}
                      download={download}
                      tableName="comparison"
                    />}
                </Grid>
              </Widget>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    );
  }, [loading, selectedVehicles, infoBox, vehiclesPerformance]);

  const getInfoBox = () => (
    <Grid container className={classes.containerInfobox}>
      <Paper elevation={2} className={classes.paperInfobox}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box
              fontFamily="fontFamily"
              justifyContent="center"
              fontSize="h3.fontSize"
              textAlign="center"
              lineHeight={2}
              className={classes.infoBox}
              p={4}
            >
              Para gerar as informações da sua frota, selecione um
              veículo{" "}
              <strong>
                clicando no filtro localizado no menu acima.
              </strong>
            </Box>
            <Grid item className={classes.containerButton}>
              <FormControlLabel control={
                <Checkbox value={checkboxInfo} checked={checkboxInfo} onChange={() => handleCheckboxInfoIsVisible()} />
              } label="Não mostrar essa mensagem novamente"
              />
              <Button onClick={() => handleChangeVisibleInfoBox()} variant="contained" className={classes.okButton}>Ok</Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );

  const debounced = useDebouncedCallback(() => {
    const checkedVehicles = checkVehicles(selectedDates.initialDate, selectedDates.finalDate)
    if (checkedVehicles) {
      fetchVehiclesPerformance(selectedDates.initialDate, selectedDates.finalDate);
    } else {
      setInfoBox(true)
    }
  }, 1000);

  useEffect(() => {
    if (hasPermission({ scopes: ['can_view_comparison'] })) {
      getLocalStorageInfo();
    }
  }, []);

  useEffect(() => {
    if (currentCustomer > 0) {
      fetchData();
      setOperationVehicles();
    }
  }, [currentCustomer]);

  useEffect(() => {
    if (selectedVehicles.length > 0 && selectedCutOffDate) {
      handleSelectDate(format(selectedCutOffDate.startDate, dateFormatTemplate), format(selectedCutOffDate.finishDate, "yyyy-MM-dd'T'23:59:59XX"))
    }
  }, [selectedVehicles, selectedCutOffDate]) 

  useEffect(() => {
    if (selectedVehicles.length > 0 && selectedDates) {
      debounced()
    }
  }, [selectedVehicles, selectedDates]) 

  useEffect(() => {
    if (selectedYear) {
      customersDates && handleCutOffDates(customersDates);
    }
  }, [selectedYear]);

  useEffect(() => {
    dispatch(clearComparisonPagination())
  }, [dispatch]);

  return (
    <Aux>
      <PermissionsGate scopes={['can_view_comparison']}>
        <ComparisonTripListDetail
          open={modalOpen}
          handleClose={handleModalClose}
          data={selectedDataRow}
          selectedComparisonTripListDetail={selectedComparisonTripListDetail}
        />
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.spacingContainer}>
          <Grid container justifyContent="space-around">
            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
              <MultiSelect
                isDetail={false}
                isSingleMode={false}
                listData={vehicles}
                handleSelectedListData={handleSelectedVehicles}
                selectedData={selectedVehicles}
              />
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={6} xs={12}>
              <Grid container justifyContent="space-around">
                <Paper elevation={0} className={classes.paper}>
                  <Select
                    placeholder="Tipo de Filtro de data"
                    options={selectFilterOptions}
                    styles={colourStyles}
                    onChange={(item) => setIsCalendarFilter(item)}
                    value={isCalendarFilter}
                    defaultValue={selectFilterOptions[0]}
                    components={animatedComponents}
                  />
                </Paper>
                {isCalendarFilter?.label !== "Calendário" && (
                  <Paper elevation={0} className={classes.paper}>
                    <Select
                      placeholder="Ano"
                      options={yearOptions.reverse()}
                      styles={colourStyles}
                      onChange={(item) => setSelectedYear(item)}
                      value={selectedYear}
                      defaultValue={selectedYear}
                      components={animatedComponents}
                    />
                  </Paper>
                )}
                {isCalendarFilter?.label === "Calendário" ? (
                  <Paper elevation={0} className={classes.paperCalendar}>
                    <Calendar
                      selectedDates={{ 
                        initialDate: selectedDates?.initialDate,
                        finalDate: selectedDates?.finalDate
                      }}
                      handleCalendar={(startDate, endDate) => {
                        handleSelectDate(format(startDate, dateFormatTemplate), format(endDate, dateFormatTemplate))
                      }}
                    />
                  </Paper>) : (
                  <>
                    {cutOffDatesOptions === null ? (
                      <Paper elevation={0} className={classes.paper}>
                        <Typography>Não foram encontradas data de corte.</Typography>
                      </Paper>
                    ) : (
                      <Paper elevation={0} className={classes.paper}>
                        <Select
                          placeholder="Data de corte"
                          options={cutOffDatesOptions}
                          styles={colourStyles}
                          components={animatedComponents}
                          onChange={(date) => onChangeCutOffDate(date)}
                          value={selectedCutOffDate}
                        />
                      </Paper>)}
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {handleVehicleListRender()}
      </PermissionsGate>
    </Aux>
  );
}
