import { Box, Grid, Paper } from '@material-ui/core';
import LottieComponent from 'components/LottieComponent';
import React from 'react'
import useStyles from './styles';
import errorLottie from 'assets/lottieFiles/lottieError.json'
import Typography from '@material-ui/core/es/Typography/Typography';

export const ValidationMessageComponent = ({ message, isComparison }) => {
  const classes = useStyles();

  return (
    <>
      {isComparison ? (
        <Grid className={classes.containerIsComparison} >
          <Typography className={classes.message}>
            {message}
          </Typography>
        </Grid>
      ) : (
        <Grid container className={classes.containerInfobox}>
          <Paper elevation={0} className={classes.paper}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <LottieComponent animationData={errorLottie} loop={false} width={100} height={100} />
                <Box
                  fontFamily="fontFamily"
                  justifyContent="center"
                  fontSize="h3.fontSize"
                  textAlign="center"
                  lineHeight={1.5}
                  className={classes.infoBox}
                  p={4}
                >
                  {message}
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      )}

    </>
  )
}