import * as yup from "yup";
import Utils from "helpers/utils";

export default yup.object().shape({
  customer: yup.object().required("Campo obrigatório").nullable(),
  documentNumber: yup
    .string()
    .required("Campo obrigatório")
    .min(14, "Preencha corretamente o CPF")
    .test(
      'documentNumber',
      'CPF inválido',
      (cpf) => Utils.validateCPF(cpf)),
  name: yup.string().required("Campo obrigatório"),
  phone: 
    yup.string()
    .matches(/^\([1-9]{2}\) (?:9[1-9])[0-9]{3}-[0-9]{4}$/, "Celular inválido")
    .nullable(),
});
