import React from "react";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { format } from "date-fns";
import Rating from "@material-ui/lab/Rating";
import { makeValueStars } from "helpers/functions";
import { Tooltip } from "@material-ui/core";
import makeStyles from "./styles";

export const makeColumns = () => {
  const classes = makeStyles();
  return [
    {
      name: "origin",
      label: "Origem",
      options: {
        customBodyRender: (value) => {
          if (value.date) {
            return (
              <Typography
                variant="body2"
                className={classes.textBold}
                noWrap={true}
              >
                {format(new Date(value.date), "dd/MM/yyyy HH:mm")} -{" "}
                {value.name}
              </Typography>
            );
          }
        },
      },
    },
    {
      name: "destination",
      label: "Destino",
      options: {
        customBodyRender: (value) => {
          return (
            <Typography
              variant="body2"
              className={classes.textBold}
              noWrap={true}
            >
              {format(new Date(value.date), "dd/MM/yyyy HH:mm")} - {value.name}
            </Typography>
          );
        },
      },
    },
   /*  {
      name: "consumptionAvg",
      label: "Média"
    }, */
    {
      name: "achievedMileage",
      label: "Dist. Percorrida (Km)",
      options: {
        customBodyRender: (value) => {
          return <Typography variant="body2">{value.toFixed(2)}</Typography>;
        },
      },
    },
    /* {
      name: "consumption",
      label: "Consumo (L)"
    }, */
    {
      name: "score",
      label: "Nota Geral",
      options: {
        customBodyRender: (value) => {
          return <Typography variant="body2">{value}</Typography>;
        },
      },
    },
    {
      name: "idleScore",
      label: "Motor Ligado Parado",
      options: {
        customBodyRender: (value) => {
          return (
            <Tooltip title={`Nota: ${value}`}>
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "extraEconomicDrivingScore",
      label: "Início da Faixa Verde",
      options: {
        customBodyRender: (value) => {
          return (
            <Tooltip title={`Nota: ${value}`}>
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "leveragingScore",
      label: "Aproveitamento de Embalo",
      options: {
        customBodyRender: (value) => {
          return (
            <Tooltip title={`Nota: ${value}`}>
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "powerScore",
      label: "Acelerando Acima do Verde",
      options: {
        customBodyRender: (value) => {
          return (
            <Tooltip title={`Nota: ${value}`}>
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "cruiseControlScore",
      label: "Piloto Automático",
      options: {
        customBodyRender: (value) => {
          return (
            <Tooltip title={`Nota: ${value}`}>
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "defensiveDrivingScore",
      label: "Direção Defensiva",
      options: {
        customBodyRender: (value) => {
          return (
            <Tooltip title={`Nota: ${value}`}>
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "id",
      label: "id",
      options: {
        display: false,
        searchable: false,
        filter: false,
        viewColumns: false,
        hideColumn: true,
      },
    },
  ];
};
