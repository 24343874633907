import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  cardTitle: {
    fontWeight: 'bold',
    padding: '10px 0 0 20px'
  },
  list: {
    width: '100%',
  },
  contentBox: {
    padding: theme.spacing(2),
    minHeight: '185px'
  },
  itemText: {
    fontSize: '14px',
    fontWeight: 500,
    color: theme.palette.text.primary
  }
}));
