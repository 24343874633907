import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import {
  useWatch,
  useFormContext,
  useFieldArray,
  useFormState,
} from "react-hook-form";
import { Input, Select } from "components/react-hook-form";
import useStyles from "./styles";
import { indicatorsOptions, penalizingOptions } from "domain/selectOptions";
import { removeSpecialCharactersNumberMask } from "helpers/functions";

const RequirementLevel = (props) => {
  const classes = useStyles();
  const { control, getValues, setValue, trigger, setError } = useFormContext();
  const { errors } = useFormState({ control });

  const controllArray = useWatch({ control, name: props.controllArrayName });

  const { fields, append, remove } = useFieldArray({
    control,
    name: props.name,
  });

  const hasErrors = ({ currentValue, allIndicators }) => {
    let hasIndicator = allIndicators.find(
      (ind) => ind.conduction.value === currentValue.conduction.value,
    );
    let hasMaxWeight = allIndicators.reduce(
      (accum, currentValueReducer) => accum + +currentValueReducer.weight,
      +currentValue.weight,
    );

    if (hasMaxWeight > 100) {
      const limit = 100 - (hasMaxWeight - +currentValue.weight);

      if (limit === 0) {
        hasMaxWeight = "0";
      } else {
        hasMaxWeight = limit;
      }
    } else {
      hasMaxWeight = false;
    }
    return {
      hasIndicator: hasIndicator ? true : false,
      maxWeight: hasMaxWeight,
    };
  };

  const handleAddIndicator = async (index) => {
    const value = getValues(props.name)[index];
    if (!value.conduction || !value.idealPercentage || !value.weight || !value.acceptablePercentage) {
      if (!value.conduction) {
        setError(`${props.name}.${index}.conduction`, {
          type: "manual",
          message: "Campo obrigatório!",
        });
      }
      if (!value.conduction) {
        setError(`${props.name}.${index}.idealPercentage`, {
          type: "manual",
          message: "Campo obrigatório!",
        });
      }
      if (!value.conduction) {
        setError(`${props.name}.${index}.weight`, {
          type: "manual",
          message: "Campo obrigatório!",
        });
      }
      if (!value.conduction) {
        setError(`${props.name}.${index}.acceptablePercentage`, {
          type: "manual",
          message: "Campo obrigatório!",
        });
      }
      return false
    }
    const result = await trigger(props.name);
    const { hasIndicator, maxWeight } = hasErrors({
      currentValue: value,
      allIndicators: controllArray ?? [],
    });
    if (hasIndicator) {
      setError(`${props.name}.${index}.conduction`, {
        type: "manual",
        message: "Indicador já cadastrado!",
      });
      return;
    }
    if (maxWeight) {
      if (maxWeight === "0") {
        setError(`${props.name}.${index}.weight`, {
          type: "manual",
          message: "Limite Máximo Alcançado",
        });
        return;
      }
      setError(`${props.name}.${index}.weight`, {
        type: "manual",
        message: `Valor Máximo: ${maxWeight}`,
      });
      return;
    }
    if (result) {
      if (!controllArray) {
        setValue(props.controllArrayName, [
          {
            ...value,
            penalizing: props.penalizing,
            requirement_level: props.requirementLevelField,
            id: value.conduction.value,
          },
        ]);
      } else {
        setValue(props.controllArrayName, [
          ...controllArray,
          {
            ...value,
            penalizing: props.penalizing,
            id: value.conduction.value,
            requirement_level: props.requirementLevelField,
          },
        ]);
      }
      append({
        conduction: "",
        idealPercentage: "",
        acceptablePercentage: "",
        weight: "",
      });
      remove(index);
    }
  };

  return fields.map((field, index) => {
    return (
      <Grid item xl={4} lg={4} md={4} sm={5} xs={12} key={field.id}>
        <Paper variant="outlined" className={classes.paperForm}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className={classes.inputTitle}>
                {props.title}
              </Typography>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Select
                name={`${props.name}.${index}.conduction`}
                placeholder={props.penalizing ? "Penalizador" : "Indicador de Condução"}
                options={props.penalizing ? penalizingOptions : indicatorsOptions}
              />
              <FormHelperText style={{ marginLeft: 15 }} error={true}>
                {errors?.[props.name]?.[index]?.conduction?.message}
              </FormHelperText>
            </Grid>


            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.formControl}>
              <Input
                name={`${props.name}.${index}.acceptablePercentage`}
                variant="outlined"
                fullWidth
                label="Percentagem Aceitável"
                type="number"
                helperText={
                  errors?.[props.name]?.[index]?.acceptablePercentage?.message
                }
                error={
                  errors?.[props.name]?.[index]?.acceptablePercentage && true
                }
                onKeyPress={removeSpecialCharactersNumberMask}
              />
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.formControl}>
              <Input
                name={`${props.name}.${index}.idealPercentage`}
                variant="outlined"
                fullWidth
                type="number"
                label="Percentagem Ideal"
                helperText={
                  errors?.[props.name]?.[index]?.idealPercentage?.message
                }
                error={errors?.[props.name]?.[index]?.idealPercentage && true}
                onKeyPress={removeSpecialCharactersNumberMask}
              />
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.formControl}>
              <Input
                name={`${props.name}.${index}.weight`}
                variant="outlined"
                fullWidth
                type="number"
                label="Peso do Indicador na Nota Geral"
                helperText={errors?.[props.name]?.[index]?.weight?.message}
                error={errors?.[props.name]?.[index]?.weight && true}
                onKeyPress={removeSpecialCharactersNumberMask}
              />
            </Grid>
            <FormHelperText style={{ marginLeft: 15 }} error={true}>
              {errors?.[props.name]?.[index]?.custom?.message}
            </FormHelperText>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.formControl}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                size="small"
                onClick={handleAddIndicator.bind(null, index)}
              >
                Adicionar
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    )
  });
};

export default RequirementLevel;
