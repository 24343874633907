import React from "react";
import { IconButton, Grid } from "@material-ui/core";
import { DeleteOutline, EditOutlined, HistoryOutlined } from "@material-ui/icons";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";
import { format } from "date-fns";

export const makeColumns = ({ onDelete, onEdit, onNavigateToHistory }) => {
  let columns = [
    {
      name: "name",
      label: "Nome",
    },
    {
      name: "vehiclesAmount",
      label: "Qtde Veículos",
    },
    {
      name: "maxAmount",
      label: "Premiação máxima",
      options: {
        customBodyRender: (value) => (
          <p style={{ margin: 0 }}>
            {value
              ? value.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })
              : 0}
          </p>)
      }
    },
    {
      name: "operationTypeName",
      label: "Tipo de premiação",
    },
    {
      name: "requirementLevel",
      label: "Exigência",
    }
  ];

  if (hasPermission({scopes: ['can_view_minscore']})) {
    columns.push({
      name: "minScore",
      label: "Nota Mínima",
    });
  }

  columns.push({
    name: "created_at",
    label: "Data de criação",
    options: {
      customBodyRender: (value) => value ? format(new Date(value), "dd/MM/yyyy HH:mm") : '-',
    },
  });
  
  if (hasPermission({ scopes: ['can_edit_operations', 'can_remove_operationsa'] })) {
    columns.push({
      name: "id",
      label: "Ações",
      options: {
        sort: false,
        filter: false,
        viewColumns: false,
        customBodyRender: (value) => {
          return (
            <Grid container>
              <PermissionsGate scopes={['can_edit_operations']}>
                <Grid
                  style={{ marginRight: 10 }}
                  item
                  xl={4}
                  lg={4}
                  md={6}
                  sm={2}
                  xs={1}
                >
                  <IconButton
                    onClick={() => onEdit(value)}
                    aria-label="edit"
                    title="Editar"
                  >
                    <EditOutlined />
                  </IconButton>
                </Grid>
              </PermissionsGate>
              <PermissionsGate scopes={['can_remove_operations']}>
                <Grid
                  style={{ marginRight: 10 }}
                  item
                  xl={3}
                  lg={3}
                  md={2}
                  sm={2}
                  xs={1}
                >
                  <IconButton
                    onClick={() => onDelete(value)}
                    aria-label="delete"
                    title="Excluir"
                  >
                    <DeleteOutline />
                  </IconButton>
                </Grid>
              </PermissionsGate>
              <Grid
                item
                xl={3}
                lg={3}
                md={2}
                sm={2}
                xs={1}
              >
                <IconButton
                  onClick={() => onNavigateToHistory(value)}
                  aria-label="group history"
                  title="Histórico do Grupo"
                >
                  <HistoryOutlined />
                </IconButton>
              </Grid>
            </Grid>
          );
        },
      },
    });
  }
  return columns;
};

export function currencyFormat(num) {
  return 'R$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
}