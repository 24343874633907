import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import Icon from '@mdi/react'
import { 
  mdiMapMarkerDistance,
  mdiGasStation,
  mdiFinance,
  mdiGaugeFull
} from '@mdi/js'

import useStyles from './styles'

const ConsolidatedStatistics = ({data, selectedVehicles}) => {
  const classes = useStyles()

  return (
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
      <Typography variant="h4" className={classes.cardTitle}>Estatísticas</Typography>
      <Grid container spacing={2} className={classes.contentBox}>
        <Grid 
          item container 
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={classes.cardContent}
        >
          <Grid className={classes.cardIcon}><Icon path={mdiMapMarkerDistance} size={1} /></Grid>
          <Grid className={classes.cardText}>
            <Grid className={classes.cardValue}>{data?.totalMileage} Km</Grid>
            <Grid className={classes.cardLabel}>Km rodado</Grid>
          </Grid>
        </Grid>
        <Grid 
          item container 
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={classes.cardContent}
        >
          <Grid className={classes.cardIcon}><Icon path={mdiGasStation} size={1} /></Grid>
          <Grid className={classes.cardText}>
            <Grid className={classes.cardValue}>{data?.totalConsumption} L</Grid>
            <Grid className={classes.cardLabel}>Litros consumidos</Grid>
          </Grid>
        </Grid>
        <Grid 
          item container 
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={classes.cardContent}
        >
          <Grid className={classes.cardIcon}><Icon path={mdiFinance} size={1} /></Grid>
          <Grid className={classes.cardText}>
            <Grid className={classes.cardValue}>{(data?.totalMileage / selectedVehicles.length).toFixed(2)} Km</Grid>
            <Grid className={classes.cardLabel}>Produtividade</Grid>
          </Grid>
        </Grid>
        <Grid 
          item container 
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={classes.cardContent}
        >
          <Grid className={classes.cardIcon}><Icon path={mdiGaugeFull} size={1} /></Grid>
          <Grid className={classes.cardText}>
            <Grid className={classes.cardValue}>{data?.consumptionAverage} Km/L</Grid>
            <Grid className={classes.cardLabel}>Média km por litro</Grid>
          </Grid>
        </Grid> 
      </Grid>
    </Grid>
  )
}

export default ConsolidatedStatistics;