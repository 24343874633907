import React, { useState } from 'react';
import { Box, Button, Grid, Paper, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import useStyles from './styles';
import Aux from 'hoc/auxiliar';
import TableResponsive from 'components/TableResponsive';
import Widget from 'components/Widget/Widget';
import { makeColumns } from './tableColumns';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { CustomCheckbox } from 'components/Table/components/CustomCheckbox';
import { setStepAdd, setStepDeductBonus } from "redux/features/bonusFollowSlice";
import { useWindowSize } from "hooks/useWindowsSize";

const RewardDetails = () => {
  const { filters: { calendar, cutoffDate } } = useAppSelector((state) => state.bonusFollow);
  const dispatch = useAppDispatch()
  const { state } = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const size = useWindowSize();
  const columns = makeColumns(size);
  const [selectedRowData, setSelectedRowData] = useState();
  const [disabledButton, setDisabledButton] = useState(false);
  const download = {
    link: "/driver-performance-XLS",
    fileName: `relatorio-viagens_${format(new Date(), "dd-MM-yyyy_HH-mm")}.xlsx`,
    params: {
      driverId: state.driverId,
      startDate: format(new Date((!!state.isCalendarFilter.id) ? JSON.parse(calendar).startDate : JSON.parse(cutoffDate).startDate), "yyyy-MM-dd'T'00:00:00XX"),
      endDate: format(new Date((!!state.isCalendarFilter.id) ? JSON.parse(calendar).finishDate : JSON.parse(cutoffDate).finishDate), "yyyy-MM-dd'T'00:00:00XX"),
      limit: 1000
    }
  }

  const handleClickDetail = () => {
    dispatch(setStepAdd())
    history.push({
      pathname: "/fleet/dashboard/general/bonus",
      state: {
        vehicleId: selectedRowData,
        screen: 'bonus',
        detail: state,
        startDate: selectedRowData.origin.date,
        finishDate: selectedRowData.destination.date,
      },
    });
  };

  const handleBack = (e) => {
      setDisabledButton(true)
      history.goBack()
      dispatch(setStepDeductBonus())
      setTimeout(() => {
        setDisabledButton(false)
      }, 5000)
  }
  return (
    <Aux>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item lg={11} md={11} sm={12} xs={12}>
          <Box>
            {/* title */}
            <Grid item xl={12} xs={12} style={{ marginBottom: 15 }}>
              <Typography variant="h3">Relatório de Viagens</Typography>
            </Grid>
            {/* motorista */}
            <Grid item xl={12} xs={12}>
              <Grid container>
                <Grid item xl={4} lg={4} md={5} sm={12} xs={12}>
                  <Grid
                    container
                    component={Paper}
                    spacing={3}
                    className={classes.infoDriverContainer}
                  >
                    <Grid
                      className={classes.contentDriver}
                      container
                      item
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                    >
                      <Typography className={classes.label}>
                        Motorista:{" "}
                      </Typography>
                      <strong className={classes.driverName}>
                        {state.name}
                      </strong>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* table */}
            <Grid item xl={12} xs={12} className={classes.marginTop}>
              {size.mobile ? (
                <TableResponsive
                  data={state.data}
                  columns={columns}
                  download={download}
                  options={{
                    rowsPerPage: 10,
                    onRowClick: (data, data2) => {
                      const newSelectedRowData = state.data.find(
                        (_info, index) => index === data2.dataIndex,
                      );
                      setSelectedRowData(newSelectedRowData);
                      dispatch(setStepAdd());
                      history.push({
                        pathname: "/fleet/dashboard/general/bonus",
                        state: {
                          vehicleId: newSelectedRowData,
                          screen: "bonus",
                          detail: state,
                          startDate: newSelectedRowData.origin.date,
                          finishDate: newSelectedRowData.destination.date,
                        },
                      });
                    },
                  }}
                  pointer={true}
                  tableName="bonus-details"
                />
              ) : (
                <TableResponsive
                  data={state.data}
                  columns={columns}
                  download={download}
                  options={{
                    rowsPerPage: 100,
                    onRowClick: (data, data2) => {
                      const newSelectedRowData = state.data.find(
                        (_info, index) => index === data2.dataIndex,
                      );
                      setSelectedRowData(newSelectedRowData);
                      dispatch(setStepAdd());
                      history.push({
                        pathname: "/fleet/dashboard/general/bonus",
                        state: {
                          vehicleId: newSelectedRowData,
                          screen: "bonus",
                          detail: state,
                          startDate: newSelectedRowData.origin.date,
                          finishDate: newSelectedRowData.destination.date,
                        },
                      });
                    },
                    //customToolbarSelect: () => {},
                    //selectableRows: "single",
                    //   onRowSelectionChange: (data) => {
                    //     const newSelectedRowData = state.data.find(
                    //       (_info, index) => index === data[0].dataIndex,
                    //     );
                    //     setSelectedRowData(newSelectedRowData);
                    //     dispatch(setStepAdd());
                    //     history.push({
                    //       pathname: "/fleet/dashboard/general/bonus",
                    //       state: {
                    //         vehicleId: newSelectedRowData,
                    //         screen: "bonus",
                    //         detail: state,
                    //         startDate: newSelectedRowData.origin.date,
                    //         finishDate: newSelectedRowData.destination.date,
                    //       },
                    //     });
                    //   },
                  }}
                  // components={{
                  //   Checkbox: CustomCheckbox,
                  // }}
                  tableName="bonus-details"
                />
              )}
            </Grid>
            {/* botoes */}
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid container justifyContent="flex-end" spacing={2}>
                {/* {size.mobile ? null : (
                  <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
                    <Box marginTop={size.mobile ? "25px" : 0}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        onClick={handleClickDetail}
                        disabled={!selectedRowData}
                        className={classes.buttonDetail}
                      >
                        Ver detalhes
                      </Button>
                    </Box>
                  </Grid>
                )} */}
                <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
                  <Box>
                    <Button
                      fullWidth
                      disabled={disabledButton}
                      variant="contained"
                      className={classes.buttonBack}
                      onClick={(e) => (disabledButton ? null : handleBack(e))}
                    >
                      {disabledButton ? "Aguarde..." : "Voltar"}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Aux>
  );
};

export default RewardDetails;
