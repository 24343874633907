import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  cardTitle: {
    fontWeight: 'bold',
    padding: '10px 0 0 20px'
  },
  contentBox: {
    padding: theme.spacing(2)
  },
  cardContent: {
    flexDirection: 'row',
    alignItems: 'center',
    // justifyContent: 'space-between',
  },
  cardIcon: {
    width: '40px',
    height: '40px',
    boxShadow: `3px 5px 4px ${theme.palette.background.hoverMultiMenu}`,
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '8px'
  },
  cardText: {
    minWidth: '100px'
  },
  cardValue: {
    fontWeight: '500',
    fontSize: theme.typography.h4.fontSize
  },
  cardLabel: {
    color: theme.palette.gray.default,
    fontSize: theme.typography.h6.fontSize
  }
}));
