import React from "react";
import useStyles from "./styles";
import { useHistory } from "react-router-dom";
import { Grid, Button, Box, Typography } from "@material-ui/core";
import { useForm, FormProvider } from "react-hook-form";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { errorsMessages } from "helpers/errors";
import makeDefaultValues from "helpers/makeDefaultValues";
import { onlyNumberMask, removeAllSpecialCharacters } from "helpers/masks";
import fields from "domain/forms/addDriverInitialValues";
import schemaValidation from "domain/validation/yup/addDriverValidation";
import DriverForm from "components/DriverForm";
import BackButton from "components/BackButton";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";
import DriverModel from "models/Driver";
import { createDriver } from "services/driver";
import Aux from "hoc/auxiliar";

const AddDriver = () => {
  const defaultValues = makeDefaultValues({ fields });
  const classes = useStyles();
  const history = useHistory();
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schemaValidation),
  });

  const onSubmit = async (data) => {
    try {
      const cpfNumbers = removeAllSpecialCharacters(data?.documentNumber);
      const driverModel = new DriverModel({
        birthDate: data.birthDate || null,
        customerId: data.customer.value,
        documentNumber: onlyNumberMask(data.documentNumber),
        email: data.email || `${cpfNumbers}@gobrax.com.br`,
        name: data.name,
        note: data.obs || null,
        phoneNumber: onlyNumberMask(data.phone) || null,
        canLink: !(typeof data.canLink === 'string')
      });
      delete driverModel.id;
      const response = await createDriver(driverModel);
      if (response.status !== 200) {
        toast.error("Erro ao criar motorista!");
      } else if (response.data.response && response.data.response[0].errors) {
        response.data.response[0].errors.forEach((error) => {
          methods.setError(errorsMessages[error.field].type, {
            type: "custom",
            message: errorsMessages[error.field].message,
          });
        });
      } else {
        toast.success("Motorista criado com sucesso!");
        methods.reset(defaultValues);
        history.push("/fleet/driver/all-drivers");
      }
    } catch (err) {
      toast.error("Error ao criar motorista. Entre em contato com o suporte!");
    }
  };

  return (
    <Aux>
      <PermissionsGate scopes={["can_create_drivers"]}>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box>
              <Grid item xl={12} xs={12} className={classes.table}>
                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Typography variant="h1" className={classes.title}>
                      Cadastro de Motorista
                    </Typography>
                    <DriverForm />
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Grid container justifyContent="flex-end" spacing={2}>
                        <Grid
                          container
                          item
                          justifyContent="flex-end"
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                        >
                          <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                            <Box sx={{ m: 2 }}>
                              <BackButton className={classes.backButton} />
                            </Box>
                          </Grid>
                          <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                            <Box sx={{ m: 2 }}>
                              <Button
                                fullWidth
                                variant="contained"
                                type="submit"
                                className={classes.btConfirm}
                              >
                                Salvar
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </FormProvider>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </PermissionsGate>
    </Aux>
  );
};

export default AddDriver;
