import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  CssBaseline,
  FormControlLabel,
  Grid,
  Link,
  Paper,
  Typography,
  TextField,
} from "@material-ui/core";
import Copyright from '../../components/Copyright'

import AuthClient from "../../infra/Auth/Auth";

// styles
import useStyles from "./styles";

// logo
import GoBraxLogo from "../../images/gobrax.svg";
import { useAppDispatch } from "redux/store";
import { logout } from "redux/globalSlice";

function Login(props) {
  const classes = useStyles();

  // local
  const [isLoading, setIsLoading] = useState(false);
  const [emailLostPassword, setEmailLostPassword] = useState("");
  const [nameValue, setNameValue] = useState("");
  const [loginValue, setLoginValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [activeTabId, setActiveTabId] = useState(0);

  const [
    formUsernamePasswordAction,
    setFormUsernamePasswordAction,
  ] = useState();
  const [csrfUsernamePasswordToken, setCsrfUsernamePasswordToken] = useState("");
  const [loginMessages, setLoginMessages] = useState();
  const dispatch = useAppDispatch()
  const auth = new AuthClient({ basePath: process.env.REACT_APP_LINK_API_LOGIN });

  const clearFields = () => {
    setEmailLostPassword("");
    setLoginValue("");
    setNameValue("");
    setPasswordValue("");
  };

  useEffect(() => {
    const fetchAuth = async () => {
      try {
        const flow = await auth.getService({ urlService: `${process.env.REACT_APP_LINK_API_LOGIN}/self-service/login/browser`, urlRedirectError: `${process.env.LINK_API_LOGIN}self-service/login/browser` });
        setFormUsernamePasswordAction(JSON.stringify(flow.methods.password.config.action).replaceAll('"', ""));
        setCsrfUsernamePasswordToken(JSON.stringify(flow.methods.password.config.fields[2].value).replaceAll('"', ""));
        setLoginMessages(flow.methods.password.config.messages && flow.methods.password.config.messages[0].text);
      } catch (err) {
        console.log(err);
      }
    };
    fetchAuth();
  }, []);

  useEffect(() => {
    dispatch(logout())
  }, [dispatch])

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <video autoPlay loop muted className={classes.bgVideo}>
        <source src={require("../../images/1134952923.mp4")} type="video/mp4" />
      </video>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
        className={classes.image}
      >
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3} component={Paper} elevation={3} className={classes.loginForm}>
          <div className={classes.paper}>
            <div className={classes.logotype}>
              <img className={classes.logotypeIcon} src={GoBraxLogo} alt="logo" />
            </div>
            {activeTabId === 0 && (
              <form
                className={classes.form}
                action={formUsernamePasswordAction}
                method="POST"
              >
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="identifier"
                  label="E-mail"
                  name="identifier"
                  autoComplete="email"
                  onChange={(e) => setNameValue(e.target.value)}
                  value={nameValue}
                  autoFocus
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Senha"
                  type="password"
                  id="password"
                  onChange={(e) => setPasswordValue(e.target.value)}
                  value={passwordValue}
                  autoComplete="current-password"
                />
                <TextField
                  name="csrf_token"
                  id="csrf_token"
                  type="hidden"
                  required
                  fullWidth
                  variant="outlined"
                  label="Csrf token"
                  value={csrfUsernamePasswordToken}
                  className={classes.csrf}
                />
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Lembrar"
                />
                {loginMessages
                  ? (
                    <>
                      <Typography color="error" className={classes.errorMessage}>
                        Os dados informados de login ou senha são inválidos.
                      </Typography>
                    </>
                  )
                  : (<>
                  </>)
                }
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    type="submit"
                    fullWidth
                    className={classes.submit}
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    Entrar
                  </Button>
                )}
                <Box mt={5}>
                  <Copyright />
                </Box>
              </form>
            )}
            {activeTabId === 1 && (
              <form className={classes.form} method="POST" noValidate>
                <Typography
                  component="h1"
                  variant="h5"
                  align="center"
                  color="primary"
                >
                  Recuperação de senha
                </Typography>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="E-mail"
                  name="email"
                  autoComplete="email"
                  value={emailLostPassword}
                  onChange={(e) => setEmailLostPassword(e.target.value)}
                  autoFocus
                />
                <Button
                  className={classes.submit}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Enviar
                </Button>
                <Button
                  onClick={() => {
                    setActiveTabId(0);
                    clearFields();
                  }}
                  className={classes.cancel}
                  variant="contained"
                  color="default"
                  size="large"
                >
                  Cancelar
                </Button>
              </form>
            )}
            {activeTabId === 2 && (
              <form className={classes.form} method="POST" noValidate>
                <Typography
                  component="h1"
                  variant="h5"
                  align="center"
                  color="primary"
                >
                  Cadastro
                </Typography>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="name"
                  label="Nome"
                  name="name"
                  autoComplete="name"
                  value={nameValue}
                  onChange={(e) => setNameValue(e.target.value)}
                  autoFocus
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="E-mail"
                  name="email"
                  autoComplete="email"
                  value={emailLostPassword}
                  onChange={(e) => setEmailLostPassword(e.target.value)}
                  autoFocus
                />
                <Button
                  type="submit"
                  className={classes.submit}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Registrar
                </Button>
                <Button
                  onClick={() => {
                    setActiveTabId(0);
                    clearFields();
                  }}
                  className={classes.cancel}
                  variant="contained"
                  color="default"
                  size="large"
                >
                  Cancelar
                </Button>
              </form>
            )}
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Login;
