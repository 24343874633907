import { customersUrl } from "helpers/customerUrl";
import api from "./api";

export const getVehicles = async (customers) => {
  const partialUrl = customersUrl(customers);
  let params = `customers=${partialUrl}`;
  try {
    const response = await api.get(`/vehicles?${params}&timestamp=${new Date().getTime()}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getVehiclesByOperation = async (customers) => {
  const partialUrl = customersUrl(customers);
  let params = `customers=${partialUrl}`;
  try {
    const response = await api.get(`/vehicles?${params}&operation=true`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getDriversHistory = async (vehicleId, startDate, endDate) => {
  try {
    const response = await api.get('/vehicles/assignedDrivers', {
      vehicleId: vehicleId,
      startDate: startDate,
      endDate: endDate,
    });
    return response;
  } catch (err) {
    return err;
  }
};

export const getVehicleDeviceHistory = async (vehicleId) => {
  try {
    const response = await api.get(`vehicles-devices/${vehicleId}/devices`);
    return response;
  } catch (err) {
    return err;
  }
};

export const getVehicle = async (vehicleID) => {
  try {
    const resp = await api.get(`/vehicles/${vehicleID}`);
    return resp;
  } catch (err) {
    return err;
  }
};

export const assignDriverToVehicle = async (data) => {
  try {
    const response = await api.post(`/assignDriverToVehicle`, data);
    return response;
  } catch (err) {
    return err;
  }
};

export const updateAssignDriver = async (data) => {
  try {
    const response = await api.put(`/vehicles/updateAssignDriver`, data);
    return response;
  } catch (err) {
    return err;
  }
};

export const unassignDriverFromVehicle = async (data) => {
  try {
    const response = await api.post(`/unassignDriverFromVehicle`, data);
    return response
  } catch (err) {
    return err;
  }
};

export const deleteVehicle = async (vehicleID) => {
  try {
    const response = await api.delete(`/vehicles/${vehicleID}`);
    return response;
  } catch (err) {
    return err;
  }
};

export const createBrand = async (data) => {
  try {
    const response = await api.post(`/brands`, data);
    return response;
  } catch (err) {
    return err;
  }
};

export const getBrands = async () => {
  try {
    const response = await api.get(`/brands`);
    return response;
  } catch (err) {
    return err;
  }
};

export const createModel = async (data) => {
  try {
    const response = await api.post(`/models`, data);
    return response;
  } catch (err) {
    return err;
  }
};

export const getModels = async () => {
  try {
    const response = await api.get(`/models`);
    return response;
  } catch (err) {
    return err;
  }
};

export const getModelsByBrand = async (brandId) => {
  try {
    const response = await api.get(`/models?brandId=${brandId}`);
    return response;
  } catch (err) {
    return err;
  }
};

export const createWheeldriver = async (data) => {
  try {
    const response = await api.post(`/wheeldrivetypes`, data);
    return response;
  } catch (err) {
    return err;
  }
};

export const getWheeldrivers = async () => {
  try {
    const response = await api.get(`/wheeldrivetypes`);
    return response;
  } catch (err) {
    return err;
  }
};

export const createFueltype = async (data) => {
  try {
    const response = await api.post(`/fueltypes`, data);
    return response;
  } catch (err) {
    return err;
  }
};

export const getFueltypes = async () => {
  try {
    const response = await api.get(`/fueltypes`);
    return response;
  } catch (err) {
    return err;
  }
};

export const createVehicle = async (data) => {
  try {
    const response = await api.post(`/vehicles`, data);
    return response;
  } catch (err) {
    return err;
  }
};

export const updateVehicle = async (vehicleId, data) => {
  try {
    const response = await api.put(`/vehicles/${vehicleId}`, data);
    return response;
  } catch (err) {
    return err;
  }
};

export const linkVehicleOperation = async (operationId, data) => {
  try {
    const response = await api.post(`/operations/${operationId}/vehicles`, data);
    return response;
  } catch (err) {
    return err;
  }
};

export const importTemplateMassVehicles = async () => {
  try {
    const response = await api.get(`/vehicles/importVehicleTemplate`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const createMassVehicles = async (data) => {
  try {
    const response = await api.post(`/vehicles/import`, data);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getVehicleValidation = async (vehicles, startDate, endDate) => {
  try {
    const response = await api.get(`vehicles/getVehicleValidation?vehicles=${vehicles}&startDate=${startDate}&endDate=${endDate}`);
    return response;
  } catch (err) {
    throw err;
  }
};