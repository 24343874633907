import React from "react";
import Typography from "@material-ui/core/Typography";
import format from "date-fns/format";

export default [
  {
    name: "time",
    label: "Data",
    options: {
      customBodyRender: (value) => {
        return (
          <Typography variant="body2" noWrap>
            {value ? format(new Date(value), "dd/MM/yyyy HH:mm:ss") : value}
          </Typography>
        );
      },
    },
  },
  {
    name: "satellite_quatity",
    label: "Qtde. Satélites",
    options: {
      align: "center",
      customBodyRender: (value) => {
        return <Typography variant="body2" noWrap>{value}</Typography>;
      },
    },
  },
  {
    name: "latitude",
    label: "Latitude",
    options: {
      customBodyRender: (value) => {
        return <Typography variant="body2" noWrap>{value}</Typography>;
      },
    },
  },
  {
    name: "longitude",
    label: "Longitude",
    options: {
      customBodyRender: (value) => {
        return <Typography variant="body2" noWrap>{value}</Typography>;
      },
    },
  },
  {
    name: "gps_course",
    label: "Direção GPS",
    options: {
      customBodyRender: (value) => {
        return <Typography variant="body2" noWrap>{value}</Typography>;
      },
    },
  },
  {
    name: "height",
    label: "Altitude",
    options: {
      customBodyRender: (value) => {
        return <Typography variant="body2" noWrap>{value}</Typography>;
      },
    },
  },
  {
    name: "gps_speed",
    label: "Velocidade GPS",
    options: {
      customBodyRender: (value) => {
        return <Typography variant="body2" noWrap>{value}</Typography>;
      },
    },
  },
  {
    name: "mileage",
    label: "Milhagem",
    options: {
      customBodyRender: (value) => {
        return <Typography variant="body2" noWrap>{value}</Typography>;
      },
    },
  },
  {
    name: "rpm",
    label: "RPM",
    options: {
      customBodyRender: (value) => {
        return (
          <Typography variant="body2" noWrap>
            {value ? +value.toFixed(2) : value}
          </Typography>
        );
      },
    },
  },
  {
    name: "acceleration_pedal_position",
    label: "Posição do Pedal de Aceleração",
    options: {
      customBodyRender: (value) => {
        return (
          <Typography variant="body2" noWrap>
            {" "}
            {value ? +value.toFixed(2) : value}
          </Typography>
        );
      },
    },
  },
  {
    name: "total_mileage",
    label: "Odômetro Total",
    options: {
      customBodyRender: (value) => {
        return (
          <Typography variant="body2" noWrap>
            {" "}
            {value ? +value.toFixed(2) : value}
          </Typography>
        );
      },
    },
  },
  {
    name: "total_mileage_filled",
    label: "Odômetro Preenchido",
    options: {
      customBodyRender: (value) => {
        return (
          <Typography variant="body2" noWrap>
            {" "}
            {value ? +value.toFixed(2) : value}
          </Typography>
        );
      },
    },
  },
  {
    name: "mileage_virt",
    label: "Odômetro Virtual",
    options: {
      customBodyRender: (value) => {
        return (
          <Typography variant="body2" noWrap>
            {value ? +value.toFixed(2) : value}
          </Typography>
        );
      },
    },
  },
  {
    name: "total_fuel_consumption",
    label: "Consumo Total de Combustível",
    options: {
      customBodyRender: (value) => {
        return (
          <Typography variant="body2" noWrap>
            {" "}
            {value ? +value.toFixed(2) : value}
          </Typography>
        );
      },
    },
  },
  {
    name: "total_fuel_consumption_high_resolution",
    label: "Consumo Total de Combustível - Alta Resolução",
    options: {
      customBodyRender: (value) => {
        return (
          <Typography variant="body2" noWrap>
            {" "}
            {value ? +value.toFixed(2) : value}
          </Typography>
        );
      },
    },
  },
  {
    name: "service_brake_pedal_position",
    label: "Posição do Pedal de Freio",
    options: {
      customBodyRender: (value) => {
        return <Typography variant="body2" noWrap>{value}</Typography>;
      },
    },
  },
  {
    name: "service_brake_pedal_status",
    label: "Status do Pedal de Freio",
    options: {
      customBodyRender: (value) => {
        return <Typography variant="body2" noWrap>{value}</Typography>;
      },
    },
  },
  {
    name: "cruise_control_status",
    label: "Status do Cruise Control",
    options: {
      customBodyRender: (value) => {
        return <Typography variant="body2" noWrap>{value}</Typography>;
      },
    },
  },
  {
    name: "adblue",
    label: "Arla",
    options: {
      customBodyRender: (value) => {
        return (
          <Typography variant="body2" noWrap>
            {value ? +value.toFixed(2) : value}
          </Typography>
        );
      },
    },
  },
  {
    name: "gear",
    label: "Marcha",
    options: {
      customBodyRender: (value) => {
        return <Typography variant="body2" noWrap>{value}</Typography>;
      },
    },
  },
  {
    name: "can_fuel_level",
    label: "Nível Comb. CAN(%)",
    options: {
      customBodyRender: (value) => {
        return <Typography variant="body2" noWrap>{value}</Typography>;
      },
    },
  },
  {
    name: "can_speed",
    label: "Velocidade CAN",
    options: {
      customBodyRender: (value) => {
        return <Typography variant="body2" noWrap>{value}</Typography>;
      },
    },
  },
  {
    name: "ignition_key",
    label: "Chave",
    options: {
      customBodyRender: (value) => {
        return <Typography variant="body2" noWrap>{value}</Typography>;
      },
    },
  },
];
