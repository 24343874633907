import * as yup from "yup";

export default yup.object().shape({
  /* address: yup.string().max(50, "Máximo de 50 caracteres"), */
  document: yup.string().test("document", "CNPJ incompleto", function (value) {
    if (value) {
      const schema = yup.string().min(18, "CNPJ inválido");
      return schema.isValidSync(value);
    }
    return true;
  }).required("Campo obrigatório"),
  
  /* email: yup.string().email("Email inválido").required("Campo obrigatório"), */
  name: yup.string().required("Campo obrigatório"),
  note: yup.string().max(254, "Máximo de 254 caracteres"),
  operations: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string(),
        value: yup.number(),
      }),
    )
    .required("Campo obrigatório")
    .min(1, "Selecione ao menos uma opção"),
    
  period: yup.object().required("Campo obrigatório").nullable(),
  startingDay: yup.object().required("Campo obrigatório").nullable(),
  finishingDay: yup.object().required("Campo obrigatório").nullable(),
  /* phone: yup.string().required("Campo obrigatório"), */
});
