import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import Aux from "../../hoc/auxiliar";
import { makeStyles } from "@material-ui/core/styles";

const ListVehiclesCompareSkeleton = () => {
  const classes = useStyles();
  return (
    <Aux>
      <Grid container spacing={4}>
        <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
          <Typography variant="h1">
            <Skeleton height={45} />
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        className={classes.tableButtons}>
          <Skeleton
            variant="rect"
            width={270}
            height={35}
            className={classes.buttons}
          />
      </Grid>
      <Grid container spacing={4}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Skeleton 
            variant="rect" 
            width={"100%"} 
            height={650} 
            className={classes.bordered} 
          />
        </Grid>
      </Grid>
    </Aux>
  );
};

const useStyles = makeStyles(theme => ({
  bordered: {
    borderRadius: "22px",
  },
  tableButtons: {
    marginTop: theme.spacing(1),
  },
  buttons: { 
    marginBottom: "10px", 
    display: "flex", 
    justifySelf: "flex-end",
    borderRadius: "10px"
  }
}));

export default ListVehiclesCompareSkeleton;
