import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import {
  Input,
  Paper,
  Grid, Typography,
} from "@material-ui/core";
import { TextField } from '@mui/material'
import { CalendarToday as CalendarIcon } from "@material-ui/icons";
import { calendarLanguage } from "./calendarLanguage";
import useStyles from "./styles";
import {useWindowSize} from "../../hooks/useWindowsSize";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker} from "@mui/x-date-pickers";
import {ptBR} from "date-fns/locale";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";

export default function Calendar({selectedDates, handleCalendar, calendarMaxRanges, maxSpanDays}) {
  const classes = useStyles();
  moment.defineLocale('pt-br', calendarLanguage);
  const size = useWindowSize()

  const formatDate = "DD/MM/yyyy HH:mm";

  // Utilizar a Ref é obrigatório para forçar a atualização do componente e calendário (initialSettings), 
  // pois ele não é um componente criado na DOM pelo React (a biblioteca é um wrapper)
  const keyRef = useRef(Date.now());

  const [dates, setDates] = useState({
    initialDate: moment(new Date()).format("DD/MM/yyyy 00:00"),
    finalDate: moment(new Date()).format("DD/MM/yyyy 23:59")
  });
  const [datesMobile, setDatesMobiles] = useState({
    initialDate: new Date(),
    finalDate: new Date()
  });
  const [maxDates, setMaxDates] = useState({});
  
  useEffect(()=>{
    const date = new Date()
    const dateFim = new Date()
    date.setHours(0,0,0)
    dateFim.setHours(23,59,59)
    setDatesMobiles({
      initialDate: date,
      finalDate: dateFim
    })
  },[])
  
  
  const handleSetCalendarDates = (startDate, endDate) => {
    if (startDate && endDate) {
      setDates({
        initialDate: moment(startDate).format(formatDate),
        finalDate: moment(endDate).format(formatDate)
      });
      handleCalendar(startDate, endDate);
    }
  };
  

  useEffect(() => {
    keyRef.current = Date.now();
    setMaxDates({
      ...(calendarMaxRanges?.maxDate && { maxDate: calendarMaxRanges.maxDate }),
      ...(calendarMaxRanges?.minDate && { minDate: calendarMaxRanges.minDate }),
    });
  }, [calendarMaxRanges]);

  useEffect(() => {
    setDates({
      initialDate: selectedDates
        ? moment(selectedDates.initialDate).format(formatDate)
        : moment(new Date()).format(formatDate),
      finalDate: selectedDates
        ? moment(selectedDates.finalDate).format(formatDate)
        : moment(new Date()).format(formatDate)
    });
    
    if(selectedDates.initialDate !== undefined){
      setDatesMobiles({
        initialDate: new Date(selectedDates.initialDate),
        finalDate:  new Date(selectedDates.finalDate)
      })
    }
  }, [selectedDates]);

  return (
      size.mobile?
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
          <Paper elevation={0} className={classes.paper2}>
          <Grid container style={{display:'flex'}}>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={6} style={{paddingLeft:10}}>
              <MobileDateTimePicker
                ampm={false}
                InputProps={{
                  disableUnderline: true
                }}
                slotProps={{ textField: { fullWidth: false, size:'small', variant: 'standard',  } }}
                label=""
                value={datesMobile.initialDate}
                onChange={(value)=>{
                  setDatesMobiles({
                    ...datesMobile,
                    initialDate: value,
                  });
                  handleSetCalendarDates(value,datesMobile.finalDate)
                }}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={6} style={{paddingRight:10}}>
              <MobileDateTimePicker
                ampm={false}
                slotProps={{ textField: { fullWidth: false, size:'small', variant: 'standard',  } }}
                label=""
                value={datesMobile.finalDate}
                onChange={(value)=>{
                  setDatesMobiles({
                    ...datesMobile,
                    finalDate: value,
                  });
                  handleSetCalendarDates(datesMobile.initialDate,value)
                }}
                
              />
          </Grid>
          </Grid>
          </Paper>
        </LocalizationProvider>
        : 
        <Paper elevation={0} className={classes.paper}>
      <DateRangePicker
        key={keyRef.current}
        initialSettings={{
          startDate: dates.initialDate,
          endDate: dates.finalDate,
          ...maxDates,
          ...(maxSpanDays && { maxSpan: { days: maxSpanDays } }),
          timePicker24Hour: true,
          timePicker: true,
          locale: {
            format: "DD/MM/YYYY HH:mm",
            applyLabel: "Aplicar",
            cancelLabel: "Cancelar",
            customRangeLabel: "Personalizado",
          },
          ranges: {
            'Hoje': [moment().startOf('day'), moment().endOf('day')],
            'Ontem': [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
            '7 Dias': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
            '1 Mês': [moment().subtract(29, 'days').startOf('day'), moment().endOf('day')],
          },
          alwaysShowCalendars: !size.mobile,
          showCustomRangeLabel: true,
          opens: "center"
        }}
        onApply={(event, picker) => {
          handleSetCalendarDates(picker.startDate._d, picker.endDate._d);
        }}
        autoApply={true}
      >
        <Grid>
          <Input data-cy="inputCalendar"
            value={`${dates.initialDate} - ${dates.finalDate}`}
            onChange={(e, picker) => {
              if (picker) {
                handleSetCalendarDates(picker.startDate._d, picker.endDate._d);
              }
            }}
            className={classes.inputCalendar}
          />
        </Grid>
      </DateRangePicker>
    </Paper>
  );
}
