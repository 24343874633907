import React from 'react'
import { Grid, List, ListItem, Typography } from '@material-ui/core'
import classNames from 'classnames'

import useStyles from './styles'

const ConsolidatedRisk = ({data}) => {
  const classes = useStyles()
  return (
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
      <Typography variant="h4" className={classes.cardTitle}>Maiores riscos de sinistro</Typography>
      <Grid container spacing={2} className={classes.contentBox}>
        <Grid item className={classes.itemBox}>
          <List dense={false} className={classes.list}>
            {data?.data.length ? data?.data.slice(0, 10).map((item, index) => {
              return (
                <ListItem key={item.vehicle_identification} className={classNames({
                  [classes.odd]: index % 2 === 0,
                })}>
                  <Grid container justifyContent='space-between'>
                    <Grid item>
                      <Typography className={classes.itemText}>{item.vehicle_identification} - {item.vehicle_plate}</Typography>
                    </Grid>
                    <Grid item>
                      {item.average_speed && <Typography className={classes.itemText}>{item.average_speed.toFixed(2)} Km/h</Typography>}
                    </Grid>                
                  </Grid>
                </ListItem>
              )
            }) : (
              <ListItem>
              <Grid container justifyContent='space-between'>
                <Grid item>
                  <Typography className={classes.itemName}>Não foram encontrados dados para esse período.</Typography>
                </Grid>
              </Grid>
            </ListItem>
            )}
          </List>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ConsolidatedRisk;
