import { FormControl, InputLabel, MenuItem } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import { useController, useFormContext, useFormState } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    fontSize: 40,
    borderRadius: 20
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  borderRadius20: {
    borderRadius: 20
  }
}));

const SelectMUI = ({ name, control, options, register, label, fullWidth = false, ...rest }) => {
  const classes = useStyles();
  const { control: controlContext } = useFormContext();
  const { errors } = useFormState({ control: control ?? controlContext });
  const { field } = useController({
    name,
    control: control ?? controlContext,
  });
  return (
    <FormControl fullWidth={fullWidth} variant="outlined" className={classes.formControl}>
      <InputLabel id="demo-simple-select-outlined-label">{label}</InputLabel>
      <Select
        className={classes.borderRadius20}
        {...field}
        {...rest}
        name={name}
        ref={register}
        label={label}
      >
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SelectMUI;