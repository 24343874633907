import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  infoBox: {
    fontSize: theme.typography.h4.fontSize,
    display: 'flex',
    flexDirection: 'column',
  },
  paper: {
    marginTop: "8%"
  },
}));
