import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { Box, Grid } from "@material-ui/core";
import { toast } from "react-toastify";
import { format } from "date-fns";

//services
import { getVehiclesByOperation } from "services/vehicle";
import { getTelemetryData } from "services/telemetry";

// components
import Aux from "hoc/auxiliar";
import TelemetryDataSkeleton from "components/Skeletons/TelemetryDataSkeleton";
import PageToolbar from "components/PageToolbar";
import Table from "components/Table";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";

import tableColumns from "./config/tableColumns";

// styles
import useStyles from "./styles";
import { useAppSelector } from "redux/store";

export default function TelemetryData() {
  const { currentCustomer } = useAppSelector((state) => state.global.user);
  const { state } = useLocation();
  const classes = useStyles();

  const dateFormatTemplate = "yyyy-MM-dd'T'HH:mm:ssXX";

  const [vehicles, setVehicles] = useState([]);

  const [loadingVehicles, setLoadingVehicles] = useState(false);
  const [loading, setLoading] = useState({
    vehicles: true,
    data: true,
  });

  const [vehiclesId, setVehiclesId] = useState([]);
  const [selectedDates, setSelectedDates] = useState({
    initialDate: format(new Date(), "yyyy-MM-dd'T'00:00:00XX"),
    finalDate: format(new Date(), "yyyy-MM-dd'T'23:59:59XX"),
  });

  const [data, setData] = useState([]);

  // Responsavel por pegar as datas no componente pageToolbar.
  const handleSelectDate = (initialDate, finalDate) => {
    setSelectedDates({
      initialDate: format(initialDate, dateFormatTemplate),
      finalDate: format(finalDate, dateFormatTemplate),
    });
  };

  const getVehicleIdFilter = (selectedVehicle) => {
    let selected = [];
    vehicles.forEach((operation) => {
      operation.vehicles.forEach((ident) => {
        if (ident.id === selectedVehicle[0].id) {
          selected = [{
            identification: ident.currentDevice?.identification,
            id: ident.id,
          }];
        }
      });
    });
    setVehiclesId(selected);
  };

  //Função esta colocada no useEffect. Toda vez que a lista de veiculos ou data forem alterados, irar fazer uma nova busca na api conforme dados selecionados.
  const fetchData = async (vehicleList, initialDate, finalDate) => {
    const devices = vehicleList.map((vehicle) => vehicle.identification);

    setLoading((oldState) => ({ ...oldState, data: true }));
    const response = await getTelemetryData(devices[0], initialDate, finalDate);
    if (response.status === 200) {
      if (response.data.data) {
        const mappedData = response.data.data.map((row, idx) => {
          return {
            id: idx,
            ...row,
          };
        });
        mappedData.splice(-1)
        setData(mappedData);
      }
    } else {
      toast.error(
        "Erro ao carregar dados de conectividade. Entre em contato com o suporte",
      );
    }
    setLoading((oldState) => ({ ...oldState, data: false }));
  };

  const fetchVehicles = useCallback(async () => {
    setLoading((oldState) => ({ ...oldState, vehicles: true }));
    try {
      const response = await getVehiclesByOperation(currentCustomer);

      if (state && response.data.customers) {
        const returnData = response.data.customers;
        const foundIdVehicle = returnData
          .map((o) =>
            o.vehicles.filter((truck) => truck.id === state.vehicleId.id),
          )
          .reduce((last, now) => last.concat(now))
          .reduce((_last, now) => now);
        setVehiclesId([
          {
            identification: foundIdVehicle.currentDevice.identification,
            driverName: foundIdVehicle.currentDriver.driverName,
            id: foundIdVehicle.id,
          },
        ]);
      } else {
        setVehiclesId([]);
      }
      setVehicles(response.data.customers);
    } catch (err) {
      toast.error(
        "Erro ao carregar lista de Veículos. Entre em contato com o suporte",
      );
    } finally {
      setLoading((oldState) => ({ ...oldState, vehicles: false }));
    }
  }, [state, currentCustomer]);

  useEffect(() => {
    if (currentCustomer && hasPermission({ scopes: ['can_view_telemetrydata'] })) {
      fetchVehicles();
    }
  }, [currentCustomer, fetchVehicles]);

  useEffect(() => {
    setLoadingVehicles(vehiclesId.length > 0);
    if (vehiclesId.length > 0) {
      fetchData(vehiclesId, selectedDates.initialDate, selectedDates.finalDate);
    }
  }, [vehiclesId, selectedDates]);

  const renderTable = useCallback(() => {
    if (loading?.data) {
      return <TelemetryDataSkeleton />;
    }

    return (
      <Table
        data={data}
        columns={tableColumns}
        tableName="list-telemetry"
      />
    )
  }, [data, loading.data]);


  return (
    <Aux>
      <PermissionsGate scopes={['can_view_telemetrydata']}>
        <PageToolbar
          handleSelectDate={handleSelectDate}
          selectedDates={selectedDates}
          listData={vehicles}
          selectedData={vehiclesId}
          handleSelectedData={getVehicleIdFilter}
          menuCalendar
          vehiclesMenuButton
          isSingleMode
          initialDate={selectedDates.initialDate}
          finalDate={selectedDates.finalDate}
        />
        {!loadingVehicles ? (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box
                fontFamily="fontFamily"
                bgcolor="primary.main"
                color="primary.contrastText"
                fontSize="h3.fontSize"
                textAlign="center"
                lineHeight={2}
                className={classes.infoBox}
                p={4}
              >
                Atenção, por favor selecione pelo menos um veículo e do período de
                data inicial / final para gerar as informações. <br />
                Você pode selecionar no menu acima os veículos{" "}
                <strong>clicando no ícone do caminhão</strong> e o período{" "}
                <strong>clicando nas datas</strong> para abrir o calendário.
              </Box>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={4} className={classes.container}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box>
                <Grid item xl={12} xs={12} className={classes.table}>
                  {renderTable()}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        )}
      </PermissionsGate>
    </Aux>
  );
}
