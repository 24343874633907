import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  card: {
    borderRadius: 28,
    height: '125px', 
    display: 'flex'
  },
  itemTitle: {
    position: 'relative',
    top: '-60px',
    textAlign: 'center',
    lineHeight: '15px'
  }
}));
