import React from "react";
import { IconButton, Grid } from "@material-ui/core";
import { DeleteOutline, EditOutlined } from "@material-ui/icons";
import { cpfMask } from "helpers/masks";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";

export const makeColumns = ({ onDelete, onEdit }) => {
  let columns = [
    {
      name: "customerName",
      label: "Empresa",
    },
    {
      name: "name",
      label: "Motorista",
    },
    {
      name: "documentNumber",
      label: "CPF",
      options: {
        customBodyRender: (value) => cpfMask(value),
      },
    },
    {
      name: "activationCode",
      label: "Último código de ativação enviado",
    },
    {
      name: "activatedAt",
      label: "Status",
      options: {
        customBodyRender: (value) => {
          if(!value){
            return 'Cadastrado'
          }
          return 'Ativo'
        },
      },
    },
    {
      name: "canLink",
      label: "Pode Vincular",
      options: {
        customBodyRender: (value) => {
          return value ? "SIM" : "NÃO"
        },
      },
    },
  ];

  if (hasPermission({scopes: ['can_edit_drivers', 'can_remove_drivers']})) {
    columns.push({
      name: "id",
      label: "Ações",
      options: {
        sort: false,
        filter: false,
        viewColumns: false,
        customBodyRender: (value) => {
          return (
            <Grid container>
              <PermissionsGate scopes={['can_edit_drivers']}>
                <Grid
                  style={{ marginLeft: 8 }}
                  item
                  xl={6}
                  lg={6}
                  md={4}
                  sm={2}
                  xs={1}
                >
                  <IconButton
                    onClick={() => onEdit(value)}
                    aria-label="edit"
                    title="Editar"
                  >
                    <EditOutlined />
                  </IconButton>
                </Grid>
              </PermissionsGate>
              <PermissionsGate scopes={['can_remove_drivers']}>
                <Grid
                  style={{ marginRight: 8 }}
                  item
                  xl={2}
                  lg={2}
                  md={4}
                  sm={2}
                  xs={1}
                >
                  <IconButton
                    onClick={() => onDelete(value)}
                    aria-label="delete"
                    title="Excluir"
                    >
                    <DeleteOutline />
                  </IconButton> 
                </Grid>
              </PermissionsGate>
            </Grid>
          );
        },
      },
    });  
  }  

  return columns;
};
