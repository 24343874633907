import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { toast } from "react-toastify";
import moment from "moment";

// components
import Aux from "hoc/auxiliar";
import BackButton from "components/BackButton";
import Table from "components/Table";
import Widget from "components/Widget/Widget";

// services
import { getRecalculationById } from "services/recalculationDrivers";
import { getDrivers } from "services/driver";

// styles
import useStyles from "./styles";
import { makeColumns } from "./tableColumnsDetails";
import { useAppSelector } from "redux/store";
import { useCallback } from "react";

const ListRecalculationDriverDetails = (props) => {
  const { currentCustomer } = useAppSelector((state) => state.global.user);
  const classes = useStyles();
  const columns = makeColumns();

  const { id } = props.match.params;

  const humanDateTemplateFormat = "DD/MM/yyyy HH:mm";

  const [recalculations, setRecalculations] = useState([]);
  const [period, setPeriod] = useState('');

  const fetchRecalculations = useCallback(async () => {
    try {
      const customerDrivers = await getDrivers(currentCustomer);
      const response = await getRecalculationById(id);
      if (response.data.data) {
        const responseData = response.data.data[0].data;
        const driverIds = responseData.map((el) => el.driver_id);
        let founded = customerDrivers.data.drivers.filter((item) => {
          return driverIds.includes(item.id)
        });
        const dataTable = founded.map((item, index) => ({
          driver: item.name,
          status: responseData[index].status,
        }));
        setRecalculations(dataTable);
        setPeriod(`${moment(responseData.process_date).format(humanDateTemplateFormat)}`);
      }
    } catch (err) {
      toast.error(
        "Erro ao carregar lista de recálculos. Entre em contato com o suporte",
      );
    }
  }, [currentCustomer, id])

  useEffect(() => {
    if (currentCustomer) {
      fetchRecalculations();
    }
  }, [currentCustomer, fetchRecalculations]);

  return (
    <Aux>
      <Grid container spacing={4}>
        <Grid item xs={12} className={classes.tableContent}>
          <Grid container justifyContent="flex-end" className={classes.toolbar}>
            <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
              <BackButton className={classes.backButton} />
            </Grid>
          </Grid>
          <Widget
            disableWidgetMenu
            title={`Recálculos Realizados dia: ${period}`}
          >
            {recalculations &&
              <Table
                columns={columns}
                data={recalculations}
                tableName="list-recalculations-drivers-details"
              />
            }
          </Widget>
        </Grid>
      </Grid>
    </Aux>
  );
};

export default ListRecalculationDriverDetails;
