export default [
  "customerId",
  "maxAmount",
  "name",
  "operationType",
  "requirementLevel",
  "targetMileage",
  "minScore",
  "id",
];


