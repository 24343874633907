import React from "react";
import { Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import Aux from "../../hoc/auxiliar";
import { makeStyles } from "@material-ui/core/styles";

const EvolutiveSkeleton = () => {
  const classes = useStyles();
  return (
    <Aux>
      <Grid container>
        <Grid item xs={12} className={classes.wrapperContent}>
          <Grid>
            <div className={classes.container}>
              <Grid className={classes.dropzone} id="charts" height={320}>
                {[1, 2, 3, 4].map((item, index) => {
                  return (
                    <Grid
                      container
                      item
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      key={`${index}-evolutive-skeleton`}
                    >
                      <Grid
                        item
                        xl={4}
                        lg={4}
                        md={4}
                        sm={6}
                        xs={12}
                        style={{ padding: 4 }}
                      >
                        <Skeleton
                          variant="rect"
                          width={"98%"}
                          height={280}
                          className={classes.bordered}
                        />
                      </Grid>
                      <Grid
                        item
                        xl={4}
                        lg={4}
                        md={4}
                        sm={6}
                        xs={12}
                        style={{ padding: 4 }}
                      >
                        <Skeleton
                          variant="rect"
                          width={"98%"}
                          height={280}
                          className={classes.bordered}
                        />
                      </Grid>
                      <Grid
                        item
                        xl={4}
                        lg={4}
                        md={4}
                        sm={6}
                        xs={12}
                        style={{ padding: 4 }}
                      >
                        <Skeleton
                          variant="rect"
                          width={"98%"}
                          height={280}
                          className={classes.bordered}
                        />
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Aux>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
  },
  bordered: {
    borderRadius: "22px",
  },
}));

export default EvolutiveSkeleton;
