import { Grid, Typography } from "@material-ui/core";
import React from "react";

export function TitlePage({ title }) {
  return (
    <Grid>
      <Typography variant="h2" style={{ fontWeight: 600, cursor: "default" }}>
        {title}
      </Typography>
    </Grid>
  );
}
