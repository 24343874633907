import React from "react";
import { IconButton, Grid, Typography, Button, Tooltip } from "@material-ui/core";
import { Delete as DeleteIcon, Edit as EditIcon } from "@material-ui/icons";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";

export const makeColumns = ({ onDelete, onEdit }) => {


  let columns = [
    {
      name: "customerName",
      label: "Empresa",
      options: {
        sort: false,
        customBodyRender: (value) => {
          const shouldTruncate = value.length > 300;
          const truncatedValue = shouldTruncate ? `${value.slice(0, 300)}...` : value;
    
          return (
            
              <Grid container style={{ 
                maxWidth: '300px', 
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'}}>
                {shouldTruncate ? (
                  <>
                    {truncatedValue}
                    <Tooltip title={shouldTruncate ? value : ''}>
                      <Button size="small" color="primary">[...]</Button>
                    </Tooltip>
                  </>
                ) : (
                  truncatedValue
                )}
              </Grid>
          );
        },
      },
    },
    {
      name: "name",
      label: "Nome",
    },
    {
      name: "email",
      label: "E-mail",
    },
    {
      name: "role",
      label: "Tipo de acesso",
    }
  ];
  
  if (hasPermission({scopes: ['can_edit_users', 'can_remove_users']})) {
    columns.push({
      name: "custom",
      label: "Ações",
      options: {
        sort: false,
        customBodyRender: (value) => {
          return (
            <Grid container>
              <PermissionsGate scopes={['can_edit_users']}>
                <Grid item xs={6}>
                  <IconButton
                    onClick={() => onEdit(value.id)}
                    aria-label="edit"
                    title="editar"
                  >
                    <EditIcon />
                  </IconButton>
                </Grid>
              </PermissionsGate>
              <PermissionsGate scopes={['can_remove_users']}>
                <Grid item xs={6}>
                  <IconButton
                    onClick={() => onDelete(value)}
                    aria-label="delete"
                    title="Excluir"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </PermissionsGate>
            </Grid>
          );
        },
      },
    });
  }

  return columns;
};
