import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Container, TextField, Button, Grid } from "@material-ui/core";
import { Configuration, PublicApi } from "@ory/kratos-client";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import Alert from "@material-ui/lab/Alert";

const Recovery = () => {
  const history = useHistory();
  const [formAction, setFormAction] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [csrfToken, setCsrfToken] = useState("");
  const [registerMessage, setRegisterMessage] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState();
  const [token, setToken] = useState(null);
  const captchaRef = useRef(null);

  const translate = (txt) => {
    let arrayTranslate = {
      'An email containing a recovery link has been sent to the email address you provided.': 'Um e-mail contendo um link de recuperação foi enviado para o endereço de e-mail fornecido.',
    };
    return arrayTranslate[txt];
  }

  const kratos = new PublicApi(
    new Configuration({ basePath: `${process.env.REACT_APP_LINK_API_LOGIN}` }),
  );

  useEffect(() => {
    if (
      !new URL(document.location).searchParams.get("flow") &&
      new URL(document.location).href.indexOf("recovery") !== -1
    ) {
      window.location.href = `${process.env.REACT_APP_LINK_API_LOGIN}/self-service/recovery/browser`
    }
    const flowId = new URL(document.location).searchParams.get("flow");
    kratos
      .getSelfServiceRecoveryFlow(flowId)
      .then(({ status, data: flow }) => {
        if (status === 404 || status === 410 || status === 403) {
          return window.location.replace(`${process.env.REACT_APP_LINK_API_LOGIN}/self-service/recovery/browser`);
        }
        if (status !== 200) {
          return Promise.reject(flow);
        }
        setFormAction(
          JSON.stringify(flow.methods.link.config.action).replaceAll(
            '"',
            "",
          ),
        );
        setCsrfToken(
          JSON.stringify(
            flow.methods.link.config.fields[0].value
          ).replaceAll('"', ""),
        );
        if (flow.messages) {
          const messages = flow.messages[0];
          if (messages?.error) {
            setErrorMessage(`${messages.error.reason} ${messages.error.message}`);
          } else {
            setRegisterMessage(translate(messages.text));
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [csrfToken]);

  const onLoad = () => {
    // this reaches out to the hCaptcha JS API and runs the
    // execute function on it. you can use other functions as
    // documented here:
    // https://docs.hcaptcha.com/configuration#jsapi
    captchaRef.current.execute();
  };

  const goToLogin = () => {
    let path = `login`;
    history.push(path);
  }

  return (
    <Container maxWidth="sm" style={{ marginTop: 20 }}>
      <form method="POST" noValidate action={formAction}>
        <TextField
          name="csrf_token"
          id="csrf_token"
          type="hidden"
          required
          value={csrfToken}
        />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="email"
              label="Email"
              type="email"
              name="email"
              autoComplete="email"
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Grid style={{ marginTop: 20 }}>
          <HCaptcha
            sitekey="your-sitekey"
            onLoad={onLoad}
            onVerify={setToken}
            ref={captchaRef}
          />
        </Grid>
        {errorMessage && <Alert severity="error" style={{ margin: 20 }}>{errorMessage}</Alert>}
        {registerMessage && <Alert severity="warning" style={{ margin: 20 }}>{registerMessage}</Alert>}
        <Grid container style={{ marginTop: 20 }}>
          <Grid item xs>
            <Button variant="contained" color="primary" size="large" type="submit" disabled={!token}>
              Recuperar conta
            </Button>
          </Grid>
          <Grid item xs>
            <Button variant="contained" color="default" size="large" onClick={goToLogin}>
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default Recovery;
