import React from "react";
import { Box, Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const IndicatorSkeleton = () => {

  const ItemSkeleton = () => (
    <Skeleton
      variant="rect"
      width={170}
      height={200}
      style={{
        borderRadius: "20px",
      }}
    />
  );

  return (
    <Grid 
      container
      spacing={3}
      direction="row"
      justifyContent="space-between"
      style={{
        padding: "0 0 15px 20px",
      }}
    >
      <Grid item xs={12}>
        <Grid
          container
          justifyContent="flex-end"
          alignItems="flex-end"
          direction="row"
          spacing={0}
        >
          <Grid item>
            <Skeleton
              variant="rect"
              width={115}
              height={40}
              style={{
                borderRadius: "20px",
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Box component={Grid} display={{ 
        xs: "block",
        sm: "block",
        md: "block", 
        lg: "block",
        xl: "block",
      }}>
        <ItemSkeleton />
      </Box>
      <Box component={Grid} display={{ 
        xs: "none",
        sm: "block",
        md: "block", 
        lg: "block",
        xl: "block",
      }}>
        <ItemSkeleton />
      </Box>
      <Box component={Grid} display={{ 
        xs: "none",
        sm: "none",
        md: "block", 
        lg: "block",
        xl: "block",
      }}>
        <ItemSkeleton />
      </Box>
      <Box component={Grid} display={{ 
        xs: "none",
        sm: "none",
        md: "none",
        lg: "block",
        xl: "block",
      }}>
        <ItemSkeleton />
      </Box>
      <Box component={Grid} display={{ 
        xs: "none",
        sm: "none",
        md: "none",
        lg: "block",
        xl: "block",
      }}>
        <ItemSkeleton />
      </Box>
      <Box component={Grid} display={{ 
        xs: "none",
        sm: "none",
        md: "none",
        lg: "none",
        xl: "block",
      }}>
        <ItemSkeleton />
      </Box>
    </Grid>
  );
};

export default IndicatorSkeleton;
