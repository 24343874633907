import React from "react";
import Box from "@material-ui/core/Box";
import Rating from "@material-ui/lab/Rating";
import { makeValueStars } from "helpers/functions";
import { Tooltip } from "@material-ui/core";
import { valueToPositiveFloat } from "helpers/functions";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const makeColumns = (handleExportByDriver) => {
  const classes = makeStyles((theme) => ({
    textBold: {
      fontWeight: "bold",
    },
    primary: {
      color: theme.palette.primary.main,
    },
  }));

  const defaultColumns = [
    {
      name: "driverName",
      label: "Motorista",
      options: {
        customBodyRender: (value) => {
          return (
            <Typography variant="body2" className={classes.textBold}>
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "achievedMileage",
      label: "Km Rodado",
      options: {
        customBodyRender: (value) => {
          return (
            <Typography variant="body2">
              {valueToPositiveFloat(value, 0)}
            </Typography>
          );
        },
      },
    },
    {
      name: "consumption",
      label: "Consumo (L)",
      options: {
        customBodyRender: (value) => {
          return (
            <Typography variant="body2">
              {valueToPositiveFloat(value, 0)}
            </Typography>
          );
        },
      },
    },
    {
      name: "consumptionAvg",
      label: "Média",
      options: {
        customBodyRender: (value) => {
          return <Typography variant="body2">{valueToPositiveFloat(value, 2)}</Typography>;
        },
      },
    },
    {
      name: "monthScore",
      label: "Nota Geral",
      options: {
        customBodyRender: (value) => (
          <Typography variant="body2">{valueToPositiveFloat(value)}</Typography>
        ),
      },
    },
    {
      name: "idleScore",
      label: "Motor ligado parado",
      options: {
        customBodyRender: (value) => {
          return (
            <Tooltip title={`Nota: ${value}`}>
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "extraEconomicScore",
      label: "Início da Faixa Verde",
      options: {
        customBodyRender: (value) => {
          return (
            <Tooltip title={`Nota: ${value}`}>
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "leverageScore",
      label: "Aproveitamento de embalo",
      options: {
        customBodyRender: (value) => {
          return (
            <Tooltip title={`Nota: ${value}`}>
              <Box>
                <Rating
                  name="half-rating-read" 
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "powerScore",
      label: "Acelerando acima do verde",
      options: {
        customBodyRender: (value) => {
          return (
            <Tooltip title={`Nota: ${value}`}>
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "cruiseControlScore",
      label: "Piloto automático",
      options: {
        customBodyRender: (value) => {
          return (
            <Tooltip title={`Nota: ${value}`}>
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "defensiveScore",
      label: "Direção Defensiva",
      options: {
        customBodyRender: (value) => {
          return (
            <Tooltip title={`Nota: ${value}`}>
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "id",
      label: "driverId",
      options: {
        display: false,
        searchable: false,
        filter: false,
        viewColumns: false,
        hideColumn: true,
      },
    },
    /* {
      name: "id",
      label: "Download",
      headerAlign: "center",
      align: "center",
      disableClickEventBubbling: true,
      options: {
        customBodyRender: (value, meta) => {
          return (
            <IconButton
              color="primary"
              variant="contained"
              aria-label="Download"
              component="span"
              style={{ alignItems: "center" }}
              onClick={() => {
                handleExportByDriver(value, meta.rowData[0]);
              }}
            >
              <GetAppIcon />
            </IconButton>
          );
        },
      },
    } */
  ];

  return defaultColumns;
};

export default makeColumns;
