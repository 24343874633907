import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    borderRadius: "20px",
  },
  legend: {
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(2),
    borderRadius: "20px",
  },
  headerTitle: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
    lineHeight: "40px"
  },
  listItem: {
    padding: theme.spacing(0.3, 1, 0.3, 1),
  },
  listItemTitle: {
    paddingLeft: theme.spacing(2),
    fontWeight: "bold",
    minWidth: "180px",
  },
  listItemSubTitle: {
    paddingLeft: theme.spacing(2),
  },
  listItemIcon: {
    fontSize: "1rem",
    border: `1px solid ${theme.palette.gray.light}`,
    borderRadius: "50%",
  },
  legendTitle: {
    fontWeight: "bold", 
    fontSize: 14, 
    marginBottom: theme.spacing(2)
  }
}));
