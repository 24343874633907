import React from "react";
import Aux from "../../hoc/auxiliar";
import { Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/core/styles";

const CardsSkeleton = () => {
  const classes = useStyles();
  const mdSize = useMediaQuery("(max-width:1280px)");

  let numberSkeletons = 8;

  if (mdSize) {
    numberSkeletons = 6;
  }

  return (
    <Aux>
      <Grid container spacing={2}>
        {Array.from(new Array(numberSkeletons)).map((_, idx) => (
          <Grid 
            item 
            xl={3} 
            lg={3} 
            md={3} 
            sm={4} 
            xs={6} 
            key={idx}
            className={classes.item}>
            <Skeleton 
              variant="rect" 
              width="100%" 
              height={100} 
              className={classes.bordered} />
          </Grid>
        ))}
      </Grid>
    </Aux>
  );
};

const useStyles = makeStyles(theme => ({
  item: {
    paddingBottom: "0",
  },
  bordered: {
    borderRadius: "22px",
  },
}));


export default CardsSkeleton;
