import { createSlice } from '@reduxjs/toolkit';
import { defaultOperation, selectFilterOptions } from 'helpers/follow';

const TODAY = new Date()

const initialState = {
  fields: {
    step: null
  },
  filters : {
    operation: defaultOperation,
    year: {
      value: TODAY.getFullYear(),
      label: TODAY.getFullYear(),
    },
    cutoffDate: null,
    calendar: null,
    isCalendarFilter: selectFilterOptions[0]
  },
  reward: {
    performances: null
  },
  totalPercentage: null,
  goal: { total: 0, monthly: 0 },
  drivers: null,
  print: null,
};

export const bonusFollowSlice = createSlice({
  name: 'bonusFollow',
  initialState,
  reducers: {
    setBonusFilters: (state, action) => {
      const { name, selectedOption } = action.payload;
      if (name === 'cutoffDate' || name === 'calendar') {
        state.filters[name] = JSON.stringify(selectedOption);
      } else {
        state.filters[name] = selectedOption;
      }
    },
    setRewardReducer: (state, action) => {
      state.reward = action.payload
    }, 
    setTotalPercentageReducer: (state, action) => {
      state.totalPercentage = action.payload
    },
    setGoalReducer: (state, action) => {
      state.goal = action.payload
    },
    setDriversReducer: (state, action) => {
      state.drivers = action.payload
    },
    clearBonusFilters: (state, action) => {
      state.fields.step = null;
      state.reward = initialState.reward
      state.filters = initialState.filters
      state.totalPercentage = initialState.totalPercentage
      state.goal = initialState.goal
      state.drivers = initialState.drivers
      state.print = initialState.print
    },
    setStepAdd: (state) => {
      if (!state.fields.step) {
        state.fields.step = 1
      } else {
        state.fields.step = state.fields.step + 1
      }
    },
    setStepDeductBonus: (state) => {
      state.fields.step = state.fields.step - 1
    },
    setIsCalendarFilter: (state, action) => {
      state.filters.isCalendarFilter = action.payload
    },
    resetStep: (state) => {
      state.fields = initialState.fields
    },
    resetReward: (state) => {
      state.reward = initialState.reward
      state.totalPercentage = initialState.totalPercentage
    },
    resetYear: (state) => {
      state.filters.year = initialState.filters.year
    },
    resetCutoffDate: (state) => {
      state.filters.cutoffDate = initialState.filters.cutoffDate
    },
    resetCalendar: (state) => {
      state.filters.calendar = initialState.filters.calendar
    },
    setPrintData: (state, action) => {
      state.print = action.payload
    },
    clearPrintData: (state, action) => {
      state.print = initialState.print
    }
  }
});

// Action creators are generated for each case reducer function
export const { 
  setStepAdd, 
  resetStep, 
  setStepDeductBonus, 
  setBonusFilters, 
  clearBonusFilters, 
  setRewardReducer,
  setTotalPercentageReducer,
  setGoalReducer,
  setDriversReducer,
  setIsCalendarFilter,
  resetReward,
  resetYear,
  resetCutoffDate,
  resetCalendar,
  setPrintData,
  clearPrintData,
} = bonusFollowSlice.actions;

export default bonusFollowSlice.reducer;
