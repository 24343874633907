import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  paper: {
    overflowY: 'unset',
    borderRadius: 28
  },
  loading: {
    color: theme.palette.text.secondary,
    paddingTop: theme.spacing(4),
    textAlign: 'center',
    fontStyle: 'italic',
  },
  customizedButton: {
    position: 'absolute',
    left: '97%',
    top: '-3%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,  
      color: theme.palette.primary.main,
    }
  },
}));
