import React from "react";
import Aux from "../../hoc/auxiliar";
import { Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const PressureSkeleton = () => {
  return (
    <Aux>
      <Grid container spacing={2}>
        <Skeleton variant="rect" width="100%" height={200} style={{
          borderRadius: "20px", margin: "10px"
        }} />
      </Grid>
    </Aux>
  );
};

export default PressureSkeleton;
