import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  container: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    height: "100%",
    borderRadius: 28,
  },
  contentIcon: {
    textAlign: "center"
  },
  contentTitle: {
    textAlign: "right"
  },
  title: {
    textAlign: "right",
    color: theme.palette.text.secondary,
    fontSize: "15px",
  },
  label: {
    color: theme.palette.secondary.contrastText,
    fontSize: "18px",
    fontWeight: "bold",
    textAlign: "right",
  },
}));
