import React from "react";
import { Box, Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const OperationRegisterSkeleton = () => {
  return (
    <Grid container spacing={4}>
      <Grid container item lg={12} md={12} sm={12} xs={12}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box sx={{ m: 1, mt: 4 }}>
            <Skeleton variant="rect" width={300} height={30} style={{marginBottom: 10, borderRadius: 10}} />
            <Skeleton variant="rect" width={"100%"} height={380} style={{marginBottom: 10, borderRadius: 10}} />
          </Box>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box sx={{ m: 1, mt: 4 }}>
            <Skeleton variant="rect" width={300} height={30} style={{marginBottom: 10, borderRadius: 10}} />
            <Skeleton variant="rect" width={"100%"} height={380} style={{marginBottom: 10, borderRadius: 10}} />
          </Box>
        </Grid>
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid container item justifyContent="flex-end" xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
              <Box sx={{ m: 2 }}>
                <Skeleton variant="rect" width={300} height={50} style={{marginBottom: 10, borderRadius: 10}} />
              </Box>
            </Grid>
            <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
              <Box sx={{ m: 2 }}>
                <Skeleton variant="rect" width={300} height={50} style={{marginBottom: 10, borderRadius: 10}} />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OperationRegisterSkeleton;
