import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Grid,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

// components
import Aux from "hoc/auxiliar";
import Table from "components/Table";
import Widget from "components/Widget/Widget";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";

// services
import { deleteUser, getUserByCustomers } from "services/user";
import { deleteUserPermissions, getUsersRoles } from "services/roles";

// styles
import useStyles from "./styles";
import { makeColumns } from "./tableColumns";
import ListUsersSkeleton from "components/Skeletons/ListUsersSkeleton";
import { useAppSelector } from "redux/store";

const ListUsers = () => {
  const { listCustomers } = useAppSelector((state) => state.global.user);
  var theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true)
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const fetchUsersRole = useCallback(async () => {
    try {
      const response = await getUsersRoles();
      if (response.status === 200) {
        fetchUsers(response.data.data);
      }
    } catch (err) {
      return err;
    }
  }, []);

  const fetchUsers = useCallback(async (roles) => {
    try {
      const response = await getUserByCustomers(listCustomers);
      if (!response.data || !response.data?.user) {
        return;
      }
      const users = response.data?.user.map(item => {
        const totalCustomers = item.customers.length - 1;
        let customersName = '';
      
        if (item.customers.some(customer => customer.id === 0)) {
          customersName = 'TODAS AS EMPRESAS';
        } else {
          customersName = item.customers.map((customer, index) => index < totalCustomers ? `${customer.name} • ` : customer.name);
        }
      
        const role = roles.find(elm => elm.user === item.email);
        return {
          id: item.id,
          custom: {
            id: item.id,
            email: item.email
          },
          customerName: customersName,
          name: item.name,
          email: item.email,
          role: role ? role.rolename : ''
        }
      });
      setUsers(users);
    } catch (err) {
      toast.error("Erro ao carregar usuários. Entre em contato com o suporte");
    } finally {
      setLoading(false)
    }
  }, [listCustomers]);

  const handleClickAddUser = () => history.push("/fleet/user/form-user");
  const handleEdit = (value) => history.push(`/fleet/user/form-user/${value}`);
  const handleDelete = (value) => {
    Swal.fire({
      title: "Tem certeza que deseja excluir?",
      text: "Essa ação é irreverssível!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: theme.palette.primary.main,
      cancelButtonColor: theme.palette.error.main,
      confirmButtonText: "Sim, excluir!",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await deleteUser(value.id);
          if (response.status === 200) {
            const responsePermission = await deleteUserPermissions(value.email);
            if (responsePermission.status === 200) {
              Swal.fire(
                "Excluído!",
                "O registro foi excluído com sucesso",
                "success",
              );
              fetchUsersRole();
            }
          } else {
            throw new Error();
          }
        } catch (error) {
          toast.error(
            "Erro ao excluir usuário. Entre em contato com o suporte",
          );
        }
      }
    });
  };

  const columns = makeColumns({ onEdit: handleEdit, onDelete: handleDelete });

  useEffect(() => {
    if (listCustomers && hasPermission({ scopes: ['can_view_users'] })) {
      fetchUsersRole();
    }
  }, [fetchUsersRole, listCustomers]);

  const renderTable = useCallback(() => (
    <Table
      columns={columns}
      data={users}
      tableName="list-users"
      options={{ rowsPerPage }}
      setRowsPerPage={setRowsPerPage}
    />
  ), [columns, users, rowsPerPage]);

  return (
    <Aux>
      <PermissionsGate scopes={['can_view_users']}>
        <Grid container spacing={4}>
          <Grid item xs={12} className={classes.tableContent}>
            <PermissionsGate scopes={['can_create_users']}>
              <Grid container justifyContent="flex-end">
                <Grid item xl={2} lg={3} md={4} sm={12} xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.btnNew}
                    onClick={handleClickAddUser}
                  >
                    Novo Usuário
                  </Button>
                </Grid>
              </Grid>
            </PermissionsGate>
            <Widget disableWidgetMenu title="Gerenciamento de Usuários">
              {loading ? <ListUsersSkeleton /> : renderTable()}
            </Widget>
          </Grid>
        </Grid>
      </PermissionsGate>
    </Aux>
  );
};

export default ListUsers;
