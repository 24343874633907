/* eslint-disable no-restricted-globals */
import React, { memo } from "react";
import ApexCharts from "apexcharts";
import Chart from "react-apexcharts";
import { Grid } from "@material-ui/core";
import { format, parseISO } from "date-fns";
import { useTheme } from "@material-ui/styles";
import { v4 as uuidv4 } from 'uuid';
import CheckboxFullChart from "../CheckboxFullChart";
import { infoRpmRange, getColor } from "../../helpers/fleetChart";

// styles
import useStyles from "./styles";
import getChartSettings from "./getChartSettings";

const FleetChart = ({ data, _getMarkerData, getTooltipData, series }) => {
  const classes = useStyles();
  const theme = useTheme();
  const seriesColors = getColor(theme);
  const rpmRange = data.rpmrange;

  const isBetween = (num1, num2, value) => value > num1 && value < num2;

  const getRpmStatus = (rpmValue) => {
    const rpmStatus = Object.entries(rpmRange).find((element) =>
      isBetween(element[1][0], element[1][1], rpmValue),
    );
    return rpmStatus ? infoRpmRange[rpmStatus[0]] : null;
  };

  const handleTooltipData = (item, index) => {
    const rpmItem = item[0][index];
    const tootltipData = {
      date: format(parseISO(data.rpmvstime[index].time), "dd/MM/yyyy HH:mm:ss"),
      rpm: rpmItem ? rpmItem.toFixed(2) : 0,
      altitude: item[1][index] ? item[1][index].toFixed(2) : 0,
      fuel: item[2][index] ? item[2][index].toFixed(2) : 0,
      speed: item[3][index] ? item[3][index].toFixed(2) : 0,
      rpmStatus: getRpmStatus(rpmItem),
      gear: data.rpmvstime[index].gear,
      acceleration_pedal: data.rpmvstime[index].acceleration_pedal,
      brake_pedal: data.rpmvstime[index].brake_pedal,
      id: uuidv4()
    };

    getTooltipData(tootltipData);
  };

  /* const handleMarkerData = (dataSeries, index) => {
    if (dataSeries[0].data[index]) {
      let rpmItem = dataSeries[0].data[index].y;
      let markerData = {
        date: format(
          parseISO(data.rpmvstime[index].time),
          "dd/MM/yyyy HH:mm:ss",
        ),
        rpm: rpmItem ? rpmItem.toFixed(2) : 0,
        altitude: dataSeries[1].data[index].y
          ? dataSeries[1].data[index].y.toFixed(2)
          : 0,
        fuel: dataSeries[2].data[index].y
          ? dataSeries[2].data[index].y.toFixed(2)
          : 0,
        speed: dataSeries[3].data[index].y
          ? dataSeries[3].data[index].y.toFixed(2)
          : 0,
        rpmStatus: getRpmStatus(rpmItem),
        id: uuidv4()
      };
      getMarkerData(markerData);
    }
  }; */

  const chartOptions = getChartSettings(data, seriesColors, handleTooltipData);

  return series && (
    <Grid container alignContent="center" justifyContent="center">
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.chart}>
        <Chart
          options={chartOptions(theme)}
          series={series}
          type="line"
          height={350}
        />
      </Grid>
      <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={classes.legendContent}>
        <CheckboxFullChart
          handleClick={(item, value) => {
            ApexCharts.exec("full-chart", value ? "showSeries" : "hideSeries", [item]);
            /* TODO - adicionar anotações dinamicas para retirar ao desmarcar/marcar nas legendas */
            /* https://apexcharts.com/docs/methods/#render */
            /* if(annotationId){ } 
            */
          }}
        />
      </Grid>
    </Grid>
  );
}

export default memo(FleetChart);

