import React, { useState } from 'react';
import { Box, Grid, FormLabel, Typography, Paper } from '@material-ui/core';
import { useFormContext, useFormState } from 'react-hook-form';

// components
import Aux from '../../hoc/auxiliar';
import { Input } from 'components/react-hook-form';
import PermissionForm from 'components/PermissionForm';

// skeleton
import FormRoleSkeleton from '../Skeletons/FormRoleSkeleton';

import useStyles from './styles';
import 'react-datepicker/dist/react-datepicker.css';

const RoleForm = ({formData}) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  
  const { control } = useFormContext();
  const { errors } = useFormState({ control });

  if (isLoading) {
    return <FormRoleSkeleton />;
  }

  return (
    <Aux>
      <Paper elevation={0} className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xl={5} lg={5} md={5} sm={6} xs={12}>
            <Box sx={{ m: 2 }}>
              <Input
                name='name'
                variant='outlined'
                fullWidth
                label='Nome do Perfil *'
                helperText={errors.name?.message}
                error={errors.name && true}
              />
            </Box>
          </Grid>
          <Grid item xl={5} lg={5} md={5} sm={6} xs={12}>
            <Box sx={{ m: 2 }}>
              <Input
                name='login_redirect'
                variant='outlined'
                fullWidth
                label='URL da Página Inicial'
                helperText={errors.login_redirect?.message}
                error={errors.login_redirect && true}
              />
              <Box>
                <FormLabel component="label" className={classes.legendTitle}>
                  Exemplo de URL: <b>fleet/bonus/follow</b>
                </FormLabel>
                {" "}
                <FormLabel component="label" className={classes.legendTitle}>
                  (Deixar em branco se utilizar a url padrão fleet/bonus/follow)
                </FormLabel>
              </Box>
            </Box>            
          </Grid>
          <PermissionForm formData={formData} />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Box sx={{ mt: 4 }}>
            <Typography className={classes.explanation}>*Os campos que estão marcados com o asterisco(*) são os que precisam obrigatoriamente serem preenchidos.</Typography>
          </Box>
        </Grid>
      </Paper>
    </Aux>
  );
};

export default RoleForm;
