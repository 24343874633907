import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  marginTop: {
    marginTop: theme.spacing(2),
  },
  title: {
    color: theme.palette.text.title,
    fontSize: 18,
  },
  infoDriverContainer: {
    borderRadius: 20,
  },
  label: {
    color: theme.palette.text.title,
    fontSize: 16,
    fontWeight: "bold",
  },
  contentDriver: {
    display: 'flex',
    alignItems: 'center'
  },
  driverName: {
    color: theme.palette.text.title,
    fontSize: 14,
    fontWeight: '400',
    marginLeft: theme.spacing(1 * 0.5)
  },
  buttonDetail: {
    textTransform: "inherit",
    fontSize: 16,
    fontWeight: "bold",
    borderRadius: 22,
    boxShadow: "none",
  },
  buttonBack: {
    textTransform: "inherit",
    background: theme.palette.background.backgroundButton,
    fontSize: 16,
    borderRadius: 22,
    boxShadow: "none",
  },
}));
