import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Button, Grid } from "@material-ui/core";
import { toast } from "react-toastify";
import { format } from "date-fns";

// components
import Aux from "hoc/auxiliar";
import Table from "components/Table";
import ModalDevice from "components/ModalDevice";
import Widget from "components/Widget/Widget";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";

// services
import {
  getVehicleDeviceHistory,
  getVehicles,
} from "services/vehicle";

// styles
import useStyles from "./styles";
import { makeColumns } from "./tableColumns";
import { useAppSelector } from "redux/store";
import FormRoleSkeleton from "components/Skeletons/FormRoleSkeleton";

const ListVehicles = () => {
  const classes = useStyles();
  const history = useHistory();
  const { currentCustomer } = useAppSelector((state) => state.global.user);

  const [openModalDevice, setOpenModalDevice] = useState(false);
  const [currentDevice, setCurrentDevice] = useState({});

  const [loading, setLoading] = useState(true);
  // const [customers, setCustomers] = useState(null);
  const [vehicles, setVehicles] = useState([]);
  const [vehiclesDevices, setVehiclesDevices] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [rowData, setRowData] = useState({});
  const [download, setDownload] = useState({
    link: "",
    fileName: ""
  });

  const fetchVehicles = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getVehicles(currentCustomer);
      setVehicles(response.data.customers);
    } catch (err) {
      toast.error(
        "Erro ao carregar lista de Veículos. Entre em contato com o suporte",
      );
    } finally {
      setLoading(false);
    }
  }, [currentCustomer])

  const handleClickAddVehicle = () => history.push("/fleet/vehicle/form-vehicle");
  const handleClickMassAddVehicle = () => history.push("/fleet/vehicle/mass-add-vehicle");
  const handleRecalculation = () => history.push("/fleet/vehicle/recalculation");
  const handleEdit = (value) => history.push(`/fleet/vehicle/form-vehicle/${value}`);
  const handleCloseModalDevice = () => setOpenModalDevice(false);

  const handleChangeStatus = async (id) => {
    /* TODO - mudar situação */
    toast.info(
      "Essa função será disponibilizada em breve.",
    );
  };

  const handleLinkDevices = async (id) => {
    try {
      setLoading(true);
      const response = await getVehicleDeviceHistory(id);
      if (!response) {
        toast.error(
          "Você não possui histórico ainda. Entre em contato com o suporte",
        );
        setVehiclesDevices();
        return;
      }
      if (!response.data.data.vehiclesDevices) {
        setVehiclesDevices();
      } else {
        const newResponse = response.data.data.vehiclesDevices.map((devices) => {
          const deviceId = devices.deviceId;
          return { ...devices, deviceId };
        });
        setVehiclesDevices(newResponse);
      }
    } catch (error) {
      toast.error(
        "Você não possui histórico ainda. Entre em contato com o suporte",
      );
      return;
    } finally {
      setLoading(false);
    }
  };

  const handleVehicle = async (id) => {
    try {
      let selectedVehicle = vehicles.filter((vehicle) => {
        return vehicle.id === id;
      })[0];
      const data = {
        identification: selectedVehicle.identification,
        brand: selectedVehicle.brand,
        simcard: selectedVehicle.simcard,
        status: selectedVehicle.currentDevice,
      };
      setCurrentDevice(data);
    } catch (error) { }
  };

  const addBondHandler = (id) => {
    setRowData(id);
    handleLinkDevices(id);
    handleVehicle(id);
    setOpenModalDevice(true);
  };

  const columns = makeColumns({
    onEdit: handleEdit,
    onChangeStatus: handleChangeStatus,
    onAddBond: addBondHandler,
  });

  useEffect(() => {
    if (hasPermission({ scopes: ['can_view_vehicles'] }) && currentCustomer) {
    setDownload({
      link: `/vehicles/export?customers=${currentCustomer}`,
      fileName: `veiculos_${format(new Date(), "dd-MM-yyyy_HH-mm")}.xlsx`
    });
      fetchVehicles();
    }
  }, [currentCustomer, fetchVehicles]);

  const renderTable = useCallback(() => (
    <Table
      columns={columns}
      data={vehicles}
      download={download}
      tableName="list-vehicles"
      options={{ rowsPerPage }}
      setRowsPerPage={setRowsPerPage}
    />
  ), [columns, vehicles, download, rowsPerPage]);

  return (
    <Aux>
      <PermissionsGate scopes={['can_view_vehicles']}>
        <Grid container spacing={4}>
          <ModalDevice
            device={currentDevice}
            loading={loading}
            open={openModalDevice}
            handleClose={handleCloseModalDevice}
            data={rowData}
            vehiclesDevices={vehiclesDevices}
            tableName="modal-list-vehicles"
          />
          <Grid item xs={12} className={classes.tableContent}>
            <Grid container justifyContent="flex-end">
              <PermissionsGate scopes={['can_recalculate_vehicles']}>
                <Grid item xl={2} lg={2} md={4} sm={12} xs={12} className={classes.btItem}>
                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.btnNew}
                    onClick={handleRecalculation}
                  >
                    Recalcular
                  </Button>
                </Grid>
              </PermissionsGate>
              <PermissionsGate scopes={['can_import_vehicles']}>
                <Grid item xl={2} lg={3} md={4} sm={12} xs={12} className={classes.btItem}>
                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.btnNew}
                    onClick={handleClickMassAddVehicle}
                  >
                    Importar em Massa
                  </Button>
                </Grid>
              </PermissionsGate>
              <PermissionsGate scopes={['can_create_vehicles']}>
                <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.btnNew}
                    onClick={handleClickAddVehicle}
                  >
                    Novo Veículo
                  </Button>
                </Grid>
              </PermissionsGate>
            </Grid>
            <Widget disableWidgetMenu title="Gerenciamento de Veículos">
      
              {loading ? <FormRoleSkeleton /> : vehicles && renderTable()}
            </Widget>
          </Grid>
        </Grid>
      </PermissionsGate>
    </Aux>
  );
};

export default ListVehicles;
