import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useTheme } from "@material-ui/styles";
import Aux from "hoc/auxiliar";
import Table from "components/Table";
import Widget from "components/Widget/Widget";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";
import { getOperationTypes } from "services/operationType";
import { deleteOperationType } from "services/operationType";
import { makeColumns } from "./tableColumns";
import useStyles from "./styles";
import { useAppSelector } from "redux/store";
import { useCallback } from "react";

const AllRewardsGroups = () => {
  const { currentCustomer } = useAppSelector((state) => state.global.user);
  const classes = useStyles();
  const [dataTable, setDataTable] = useState([]);
  const history = useHistory();
  const theme = useTheme();
  const customer = localStorage.getItem('customers')

  const handleDeleteRewardGroup = async (id, name) => {
    const { isConfirmed } = await Swal.fire({
      title: `Tem certeza que deseja excluir o grupo ${name}?`,
      text: "Essa ação é irreverssível!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: theme.palette.primary.main,
      cancelButtonColor: theme.palette.error.main,
      confirmButtonText: "Sim, excluir!",
      cancelButtonText: "Cancelar",
    });

    if (isConfirmed) {
      try {
        await deleteOperationType(id, customer);
        setDataTable((prevState) => prevState.filter((gp) => gp.id !== id));
        Swal.fire(
          "Excluído!",
          "O registro foi excluído com sucesso",
          "success",
        );
      } catch (err) {
        console.log(err);
        toast.error("Erro ao excluir veículo. Entre em contato com o suporte");
      }
    }
  };

  const fetchAllOperationsType = useCallback(async () => {
    try {
      const response = await getOperationTypes(currentCustomer);
      const formatedDataTable = response.data.operationTypes.map((op) => ({
        name: op.name,
        id: op.id,
      }));
      setDataTable(formatedDataTable);
    } catch (err) {
      toast.error(
        "Erro ao pegar lista de customers! Entre em contato com o suporte",
      );
    }
  }, [currentCustomer]);

  const handleEditRewardGroup = (id) => history.push(`/fleet/rewardGroup/edit/${id}`);

  const columns = makeColumns(handleDeleteRewardGroup, handleEditRewardGroup);

  const handleClickAddRewardGroup = () => history.push("/fleet/rewardGroup/add-rewardGroup");

  useEffect(() => {
    if (currentCustomer && hasPermission({ scopes: ['can_view_rewardgroup'] })) {
      fetchAllOperationsType();
    }
  }, [currentCustomer, fetchAllOperationsType]);

  return (
    <Aux>
      <PermissionsGate scopes={['can_view_rewardgroup']}>
        <Grid container spacing={4}>
          <Grid item xs={12} className={classes.tableContent}>
            <PermissionsGate scopes={['can_create_rewardgroup']}>
              <Grid container justifyContent="flex-end">
                <Grid item xl={2} lg={3} md={4} sm={12} xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.btnNew}
                    onClick={handleClickAddRewardGroup}
                  >
                    Novo Grupo
                  </Button>
                </Grid>
              </Grid>
            </PermissionsGate>
            <Widget disableWidgetMenu title="Gerenciamento de Grupos de Bonificação">
              <Table
                columns={columns}
                data={dataTable}
                tableName="list-group-bonus"
              />
            </Widget>
          </Grid>
        </Grid>
      </PermissionsGate>
    </Aux>
  );
};

export default AllRewardsGroups;
