import React from "react";
import Button from '@material-ui/core/Button';
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import useStyles from "./styles";
import { useAppSelector } from "redux/store";

const ModalUnassignDriverToVehicle = ({ confirmOpen, data, currentDriver, handleConfirmClose, handleUnassignDriverToVehicle }) => {
  const classes = useStyles();
  const { email } = useAppSelector((state) => state.global.user);


  const handleConfirm = () => {
    const model = {
      driverId: data.rowData[1] ? parseInt(data.rowData[1].driverId) : 0,
      vehicleId: data.rowData[5],
      source: 1,
      execEmail: email,
    }
    handleUnassignDriverToVehicle(model)
  }
  return (
    <Dialog
      open={confirmOpen}
      onClose={handleConfirmClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Desvincular motorista?"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Tem certeza que deseja desvincular o motorista <strong>{currentDriver && currentDriver.driverName}</strong> do veículo <strong>{data.identification && data.identification}</strong>?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirmClose} className={classes.btCancel}>
          Cancelar
        </Button>
        <Button onClick={() => handleConfirm()} className={classes.btConfirm} variant="contained" autoFocus>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalUnassignDriverToVehicle;
