import { getAssignedVehicles } from "services/driver";

export const orderVehiclesByCustomer = (data) => {
  return data.sort((a, b) => (a.name > b.name) - (a.name < b.name));
};

export const verifyDateConflict = (startDate, endDate, rangeDatesToCompare) => {
  let hasConflict = false
  if (rangeDatesToCompare) {
    rangeDatesToCompare.map(dates => {
      if (dates.startDate && dates.endDate) {
        const startRangeDate = new Date(dates.startDate);
        const endRangeDate = new Date(dates.endDate);
        if ((startDate >= startRangeDate && startDate <= endRangeDate) ||
          (endDate <= endRangeDate && endDate >= startRangeDate) ||
          (startDate <= startRangeDate && endDate >= endRangeDate)) {
          return hasConflict = true;
        }
      }
    })
  }
  return hasConflict;
};

export const verifyAssignVehicle = async (vehicleId, driverId, startDate, endDate) => {
  try {
    let arr = {
      hasConflict: []
    }
    const response = await getAssignedVehicles(vehicleId, driverId, startDate, endDate);
    if (response.status === 200 || response.status === 201) {
      if (response.data.vehicleDrivers.length) {
        const { vehicleDrivers } = response.data
        vehicleDrivers.map(prop => {
          if (driverId === prop.driverId) {
            return arr.hasConflict.push({ obj: prop, source: 'driver' })
          } else if (vehicleId === prop.vehicleId) {
            return arr.hasConflict.push({ obj: prop, source: 'vehicle' })
          }
        })
      }
      return arr
    }
  } catch (error) {
    return error
  }
};
