import {
  mdiMapMarkerDistance,
  mdiGasStation,
  mdiChartBar,
  mdiCarBrakeAlert,
  mdiTruckFastOutline,
  mdiCounter,
} from "@mdi/js";

export default {
  cards: [
    {
      name: "totalMileage",
      title: "Km Total",
      label: 0,
      unit: "km",
      icon: mdiMapMarkerDistance,
    },
    {
      name: "averageSpeed",
      title: "Velocidade Média",
      label: 0,
      unit: "km/h",
      icon: mdiTruckFastOutline,
    },
    {
      name: "totalConsumption",
      title: "Consumo Total",
      label: 0,
      unit: "L",
      icon: mdiGasStation,
    },
    {
      name: "consumptionAverage",
      title: "Média",
      label: 0,
      unit: "Km/L",
      icon: mdiChartBar,
    },
    {
      name: "odometer",
      title: "Ôdometro",
      label: 0,
      unit: "Km",
      icon: mdiCounter,
    },
    {
      name: "totalBreakingOnHighSpeed",
      title: "Freadas em Alta Veloc.",
      label: 0,
      unit: "",
      icon: mdiCarBrakeAlert,
    },
    {
      name: "totalBreakin",
      title: "Freadas totais",
      label: 0,
      unit: "",
      icon: mdiCarBrakeAlert,
    },
  ]
};
