import React, { useCallback, useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  Grid,
  Paper,
  Divider,
} from "@material-ui/core";
import Select from 'react-select';
import {
  Business as BusinessIcon,
  Menu as MenuIcon,
  Person as AccountIcon,
  ArrowBack as ArrowBackIcon,
  ExitToAppOutlined,
  Settings,
} from "@material-ui/icons";
import classNames from "classnames";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";

// services
import { getCustomersById } from '../../services/customers'

// components
import { Typography } from "../Wrappers";
import PermissionsGate from "components/PermissionsGate";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { useWindowSize } from "hooks/useWindowsSize";

// styles
import useStyles from "./styles";
import { selectStyles } from './selectStyles'
import GoBraxLogo from "../../images/gobrax-header.png";
import { useTheme } from "@material-ui/styles";
import { clearCustomerProfile } from "redux/features/customerProfileSlice";
import { useAppDispatch, useAppSelector } from "redux/store";
import { logout, setGlobalUser } from "redux/globalSlice";
import { clearBonusFilters } from "redux/features/bonusFollowSlice";

export default function Header() {
  const { currentCustomer, listCustomers } = useAppSelector((state) => state.global.user);
  const classes = useStyles();
  const history = useHistory()
  const theme = useTheme();
  const dispatch = useAppDispatch()
  const [customersOptions, setCustomersOptions] = useState([])
  const [selectedCustomer, setSelectedCustomer] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [selectLoading, setSelectLoading] = useState(false)
  const [profileMenu, setProfileMenu] = useState(null);

  const size = useWindowSize();
  const layoutState = useLayoutState();
  const layoutDispatch = useLayoutDispatch();

  
  const handleChangeCustomer = (newCustomer) => {
    setSelectLoading(true);
    dispatch(clearBonusFilters())
    dispatch(setGlobalUser({ currentCustomer: newCustomer.value }));
    localStorage.setItem('currentCustomer', newCustomer.value)
    history.push("/fleet/bonus/follow");
    setSelectLoading(false);
  }

  const handleStorageChange = useCallback((event) => {
    if (event.key === 'currentCustomer') {
      history.push("/fleet/bonus/follow");
      window.location.reload(false);
    }
  }, [history])

  useEffect(() => {
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [handleStorageChange]);
  
  const fetchCustomerById = useCallback(async () => {
    try {
      setIsLoading(true)
      const response = await getCustomersById(listCustomers);
      if (response.status === 200 || response.status === 201) {
        const ordered = response.data.customers.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
        let optionsCustomersOrdered = ordered.map(customer => {
          return {
            color: theme.palette.scrollBar,
            label: customer.name,
            value: customer.id
          }
        })
        setCustomersOptions(optionsCustomersOrdered)
      }
    } catch (error) {
      toast.error('Algo inexperado aconteceu. Contate o suporte.')
    } finally {
      setIsLoading(false)
    }
  }, [listCustomers, theme])

  const fetchCustomer = useCallback(async () => {
    try {
      setIsLoading(true)
      if (currentCustomer && listCustomers.length > 0) {
        const filteredCustomer = customersOptions.filter(res => Number(res.value) === Number(currentCustomer))
        setSelectedCustomer(filteredCustomer)
      }
    } catch (error) {
      toast.error('Algo inexperado aconteceu. Contate o suporte.')
    } finally {
      setIsLoading(false)
    }
  }, [currentCustomer, customersOptions, listCustomers])

  useEffect(() => {
    listCustomers && fetchCustomerById()
  }, [listCustomers, fetchCustomerById])

  useEffect(() => {
    currentCustomer && fetchCustomer()
  }, [currentCustomer, fetchCustomer])

  const SelectCompany = () => (
    <PermissionsGate scopes={["can_select_customers"]}>
      <Grid
        item
        xl={size.grid10}
        lg={size.grid10}
        md={size.grid10}
        sm={size.grid10}
        xs={size.grid10}
      >
        <Paper elevation={0} className={classes.paper}>
          <Grid container justifyContent="space-around" alignItems="center">
            <Grid item xs={2} className={classes.businessIcon}>
              <BusinessIcon />
            </Grid>
            <Grid item xs={10}>
              <h1 className={classes.titleABC}>Selecione a empresa</h1>
              <Select
                className={classes.formControl}
                placeholder="Digite o nome da empresa"
                menuPortalTarget={document.body}
                styles={{ ...selectStyles,menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                value={selectedCustomer}
                isLoading={selectLoading}
                onChange={(e) => handleChangeCustomer(e)}
                isSearchable={true}
                name="list-customers"
                options={customersOptions}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </PermissionsGate>
  );

  return (
    <AppBar position="fixed" className={classes.appBar} elevation={0}>
      <Toolbar className={classes.toolbar}>
        <Grid container style={{ marginTop: 5, marginBottom: 5 }}>
          <Grid container justifyContent="space-between">
            <Grid item style={{ alignSelf: "center" }}>
              <IconButton
                color="inherit"
                onClick={() => toggleSidebar(layoutDispatch)}
                className={classNames(
                  classes.headerMenuButtonSandwich,
                  classes.headerMenuButtonCollapse,
                )}
                data-cy="iconButtonSiderBar"
              >
                {layoutState.isSidebarOpened ? (
                  <ArrowBackIcon
                    classes={{
                      root: classNames(
                        classes.headerIcon,
                        classes.headerIconCollapse,
                      ),
                    }}
                    data-cy="iconOpen"
                  />
                ) : (
                  <MenuIcon
                    classes={{
                      root: classNames(
                        classes.headerIcon,
                        classes.headerIconCollapse,
                      ),
                    }}
                    data-cy="iconClose"
                  />
                )}
              </IconButton>
            </Grid>
            <Grid
              item
              style={
                size.mobile ? {} : { position: "absolute", top: 0, left: "45%" }
              }
            >
              <IconButton
                component={Link}
                to="/"
                className={classes.logoIconButton}
              >
                <img
                  src={GoBraxLogo}
                  alt="Logo Gobrax"
                  className={classes.logoHeader}
                  data-cy="logoGobrax"
                />
              </IconButton>
            </Grid>
            <Grid item style={{ alignSelf: "center" }}>
              <Grid container>
                {size.mobile ? null : <SelectCompany />}
                <IconButton
                  aria-haspopup="true"
                  color="inherit"
                  className={classes.headerMenuButton}
                  aria-controls="profile-menu"
                  onClick={(e) => setProfileMenu(e.currentTarget)}
                  data-cy="profileMenu"
                >
                  {size.mobile ? (
                    <Settings classes={{ root: classes.headerIcon }} />
                  ) : (
                    <AccountIcon classes={{ root: classes.headerIcon }} />
                  )}
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
          style={{ overflowX: "hidden", overflowY: "hidden" }}
        >
          {size.mobile ? <SelectCompany /> : null}
          <Divider style={{ marginTop: 20 }} />
          <div className={classes.profileMenuUser} data-cy="divLogoutButton">
            <Typography
              className={classes.profileMenuLink}
              onClick={() => {
                localStorage.clear();
                window.location.href = `${process.env.REACT_APP_LINK_API_LOGIN}/self-service/browser/flows/logout`;
                dispatch(logout());
                dispatch(clearCustomerProfile());
              }}
              data-cy="logoutButton"
            >
              <ExitToAppOutlined style={{ marginRight: 5 }} /> Sair
            </Typography>
          </div>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}

