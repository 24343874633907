import React, { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";

// components
import Aux from "hoc/auxiliar";
import BackButton from "components/BackButton";
import VehicleForm from "components/VehicleForm";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";

// helpers
import makeDefaultValues from "helpers/makeDefaultValues";

// domain
import fields from "domain/forms/formVehicleInitialValues";
import schemaValidation from "domain/validation/yup/formVehicleValidation";

// services
import {
  getVehicle,
  linkVehicleOperation,
  updateVehicle,
} from "services/vehicle";

// data
import VehicleModel from "models/Vehicle";

import useStyles from "./styles";
import "react-datepicker/dist/react-datepicker.css";
import { odomTypeOptions } from "domain/selectOptions";

const EditVehicle = ({ history }) => {
  const classes = useStyles();
  const { vehicleId } = useParams();

  const defaultValues = makeDefaultValues({ fields });
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schemaValidation),
  });

  const onSubmit = async (data) => {
    const vehicleModel = new VehicleModel(
      data.customer.value,
      data.operation.value,
      data.identification,
      data.plate,
      data.fueltype.value || null,
      data.wheeldriver.value || null,
      data.odomType.value,
      data.initialKm,
      data.maxFuelCapacity,
      data.maxAcceleration,
      data.chassi || null,
      data.distanceCalcMethod.value,
      data.fuelCalcMethod.value,
      data.accelerationIdentificationMethod.value,
      data.hasEcoRoll,
      data.hasDafEcoRoll,
      data.maxSpeedAllowed,
      data.maxSpeedAllowedWithRain,
      data.leveragePeak,
      data.brand.value,
      data.model.value,
      data.observation,
      data.motor,
      data.truckModel,
      Number(data.fabricationYear)
    );

    try {
      const response = await updateVehicle(vehicleId, vehicleModel);
      if (response.status === 200 && !response?.data?.err) {
        toast.success("Veículo editado com sucesso!");
        if(data.operation.value) {
          const linkResponse = await linkVehicleOperation(data.operation.value, {
            vehicleId: parseInt(vehicleId),
          });
          if (linkResponse.status !== 200) {
            toast.error(
              "Erro ao editar vínculo de Veículo com Operação. Verifique com o suporte",
            );
          }
        }
        history.push("/fleet/vehicle/list-vehicles");
      } else {
        throw new Error(response?.data?.err);
      }
    } catch (error) {
      toast.error("Erro ao editar Veículo. Verifique com o suporte");
    }
  };

  const fetchVehicleDetail = useCallback(async () => {
    try {
      const response = await getVehicle(vehicleId);
      methods.setValue("customerOption", response.data.customerId);
      methods.setValue("operationOption", response.data.operationId);
      methods.setValue("identification", response.data.identification);
      methods.setValue("plate", response.data.plate);
      methods.setValue("fueltypeOption", response.data.fuelTypeId);
      methods.setValue("wheeldriverOption", response.data.wheelDriveTypeId);
      methods.setValue("odomType", odomTypeOptions.find((od) => od.value === response.data.odomType));
      methods.setValue("initialKm", response.data.initialKM);
      methods.setValue("maxFuelCapacity", response.data.maxFuelCapacity);
      methods.setValue("maxAcceleration", response.data.maxAcceleration.toString());
      methods.setValue("chassi", response.data.chassi ?? "");
      methods.setValue("distanceCalcMethodOption", response.data.profile.distanceCalcMethod);
      methods.setValue("fuelCalcMethodOption", response.data.profile.fuelCalcMethod);
      methods.setValue("accelerationIdentificationMethodOption", response.data.profile.accelerationIdentificationMethod);
      methods.setValue("hasEcoRoll", response.data.profile.hasEcoRoll);
      methods.setValue("hasDafEcoRoll", response.data.profile.hasDafEcoRoll);
      methods.setValue("maxSpeedAllowed", response.data.maxSpeedAllowed.toString());
      methods.setValue("maxSpeedAllowedWithRain", response.data.maxSpeedAllowedWithRain.toString());
      methods.setValue("leveragePeak", response.data.leveragePeak.toString());
      methods.setValue("brandOption", response.data.brandId);
      methods.setValue("modelOption", response.data.modelId);
      methods.setValue("observation", response.data.observation);
      methods.setValue("modelName", response.data.model);
      methods.setValue("motor", response.data.motor);
      methods.setValue("truckModel", response.data.truckModel);
      methods.setValue("fabricationYear", response.data.fabricationYear);
    } catch (error) {
      toast.error("Erro ao buscar dados do Veículo. Verifique com o suporte");
    }
  }, [methods, vehicleId]);

  useEffect(() => {
    if (hasPermission({scopes: ['can_edit_vehicles']})) {
      fetchVehicleDetail();
    }
  }, [fetchVehicleDetail]);

  if (!defaultValues) {
    return "...carregando";
  }

  return (
    <Aux>
      <PermissionsGate scopes={['can_edit_vehicles']}>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box>
              <Grid item xl={12} xs={12}>
                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Typography variant="h1" className={classes.title}>Edição de Veículo</Typography>
                    <VehicleForm />
                    <Grid justifyContent="flex-end" container spacing={2}>
                      <Grid container justifyContent="flex-end" spacing={2}>
                        <Grid item container justifyContent="flex-end" xl={12} lg={12} md={12} sm={12} xs={12}>
                          <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                            <Box sx={{ m: 2 }}>
                              <BackButton className={classes.backButton} />
                            </Box>
                          </Grid>
                          <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                            <Box sx={{ m: 2 }}>
                              <Button
                                fullWidth
                                variant="contained"
                                type="submit"
                                className={classes.btConfirm}
                              >
                                Atualizar
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </FormProvider>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </PermissionsGate>
    </Aux>
  );
};

export default EditVehicle;
