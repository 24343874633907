import React from "react";
import TextField from "@material-ui/core/TextField";
import { useController, useFormContext } from "react-hook-form";
import classnames from "classnames";
import useStyles from './styles'

import InputMask from "react-input-mask";
const Input = ({ name, useMask, control, inputProps, title, ...rest }) => {
  const classes = useStyles();
  const { control: controlContext } = useFormContext();
  const {
    field: { ref, ...inputPropsForm },
  } = useController({
    name,
    control: control ?? controlContext,
  });

  return (
    <>
    {useMask ? (
      <InputMask
          mask={useMask}
          value={name}
          maskChar=" "
          {...rest}
          {...inputPropsForm}
        >
          {() => 
            <TextField
              {...inputPropsForm}
              inputRef={ref}
              {...rest}
              className={classnames(
                rest.className,
                classes.textField,
              )}
              inputProps={{ ...inputProps }}
            />
          }
        </InputMask>

    ) : (
      <TextField
        {...inputPropsForm}
        inputRef={ref}
        {...rest}
        className={classnames(
          rest.className,
          classes.textField,
        )}
        inputProps={{ ...inputProps }}
      />
    )}

    </>
  );
};

export default Input;
