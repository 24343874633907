import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Paper, Typography } from '@material-ui/core';
/* import { format } from 'date-fns'; */
import useStyles from './styles';
import Aux from 'hoc/auxiliar';
import Table from 'components/Table';
import Widget from 'components/Widget/Widget';
import { makeColumns } from './tableColumns';
import { useHistory, useLocation } from 'react-router-dom';
import { CustomCheckbox } from 'components/Table/components/CustomCheckbox';
import PermissionsGate, { hasPermission } from 'components/PermissionsGate';
import { useAppDispatch } from '../../../redux/store'
import { setStepAdd, setStepDeduct } from "redux/features/driversMonitoringSlice";

// contexts 
import { useSelectDrivers } from 'context/useSelectDrivers';

const DriversMonitoringDetails = () => {
  const dispatch = useAppDispatch()
  const { state } = useLocation()
  const history = useHistory()
  const classes = useStyles()
  const columns = makeColumns()
  const [selectedRowData, setSelectedRowData] = useState(null)
  const [detailsData, setDetailsData] = useState(null)
  const { 
    details,
    setTripListDetails,
    setHistoryGoBack
  } = useSelectDrivers()
  /* const download = {
    link: '/driver-performance-XLS',
    fileName: `relatorio-viagens_${format(new Date(), 'dd-MM-yyyy_HH-mm')}.xlsx`,
    params: {
      driverId: state.driverId,
      startDate: state.startDate,
      endDate: state.endDate,
      limit: 1000
    }
  }; */

  const handleClickDetail = () => {
    if (!hasPermission({ scopes: ['can_view_drivers_follow_trip_list']})) {
      return false;
    }    
    dispatch(setStepAdd())
    history.push({
      pathname: '/fleet/driversMonitoring/tripList',
      state: {
        tripList: selectedRowData,
        name: state.name,
        vehicle: selectedRowData[0].vehicleIdentification + ' - ' +
                 selectedRowData[0].chassis + ' - ' +
                 selectedRowData[0].plate,
        driverId: state.driverId
      },
    });
  };

  useEffect(() => {
    setDetailsData(details?.lenght === 0 || state.data)
  }, [])

  return (
    <Aux>
      <Grid container alignItems='center' justifyContent='center'>
        <Grid item lg={11} md={11} sm={12} xs={12}>
          <Box className={classes.marginTop}>
            <Widget
              disableWidgetMenu
              title='Relatório de Viagens'>
              <Grid item xl={12} xs={12}>
                <Grid container>
                  <Grid item xl={4} lg={4} md={5} sm={8} xs={10}>
                    <Grid
                      container
                      component={Paper}
                      spacing={2}
                      className={classes.infoDriverContainer}
                    >
                      <Grid className={classes.contentDriver} container item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Typography className={classes.label}>Motorista: </Typography>
                        <strong className={classes.driverName}>{state.name}</strong>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xl={12} xs={12} className={classes.marginTop}>
                {detailsData && <Table
                  data={detailsData}
                  columns={columns}
                  /* download={download} */
                  options={{
                    rowsPerPage: 100,
                    customToolbarSelect: () => {},
                    selectableRows: 'single',
                    onRowSelectionChange: (data) => {
                      const newSelectedRowData = detailsData.find(
                        (_info, index) => index === data[0].dataIndex,
                      )
                      setTripListDetails(newSelectedRowData.tripsScores)
                      setSelectedRowData(newSelectedRowData.tripsScores)
                    }
                  }}
                  components={{
                    Checkbox: CustomCheckbox,
                  }}
                  tableName='drivers-monitoring-details'
                />}
              </Grid>
            </Widget>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid container justifyContent='flex-end' spacing={2}>
                <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                  <Box sx={{ m: 2 }}>
                    <Button
                      fullWidth
                      variant='contained'
                      className={classes.buttonBack}
                      onClick={() => {
                        setHistoryGoBack(true)
                        dispatch(setStepDeduct())
                        history.goBack()
                      }}
                    >
                      Voltar
                    </Button>
                  </Box>
                </Grid>
                <PermissionsGate scopes={['can_view_drivers_follow_trip_list']}>
                  <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                    <Box sx={{ m: 2 }}>
                      <Button
                        fullWidth
                        variant='contained'
                        color='secondary'
                        onClick={handleClickDetail}
                        disabled={!selectedRowData}
                        className={classes.buttonDetail}
                      >
                        Ver detalhes
                      </Button>
                    </Box>
                  </Grid>
                </PermissionsGate>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Aux>
  );
};

export default DriversMonitoringDetails;
