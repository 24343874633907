import * as yup from "yup";

export default yup.object().shape({
  maxAmount: yup
    .string()
    .max(14, "Limite máximo excedido. 14 caracteres.")
    .required("Campo obrigatório"),
  name: yup
    .string()
    .max(50, "Máximo de 50 caracteres excedido.")
    .required("Campo obrigatório"),
  operationType: yup.object().required("Campo obrigatório").nullable(),
  requirementLevel: yup.object().required("Campo obrigatório").nullable(),
  targetMileage: yup
    .string()
    .max(14, "Kilometragem máxima excedida.")
    .required("Campo obrigatório"),
});
