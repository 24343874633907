import { makeStyles } from '@material-ui/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';

export default makeStyles((theme) => ({
  dialogTitle: {
    padding: `${theme.spacing(2)}px ${theme.spacing(6)}px`,
  },
  dialogContent: {
    height: '570px',
    padding: `${theme.spacing(2)}px ${theme.spacing(6)}px`,
  },
  boxContainer: {
    display: 'flex',
    height: '530px',
  },
  title: {
    fontSize: 24,
    fontWeight: 500,
    color: theme.palette.text.title,
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(1),
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.title,
    marginLeft: theme.spacing(1),
  },
  label: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.hint,
    marginLeft: theme.spacing(1),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  btConfirm: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: theme.spacing(1),
    '&:hover': {
      backgroundColor: alpha(theme.palette.secondary.main, 0.8),
      fontWeight: 600
    },
  },
  backButton: {
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.background.backgroundButton
  },
  inputCalendar: {
    fontSize: theme.typography.h5.fontSize,
    color: theme.palette.text.secondary,
    width: '350px',
    '& .MuiInputBase-input': {
      textAlign: 'center'
    },
    '&:before': {
      transition: 'none',
      border: 'transparent'
    },
    '&:after': {
      transition: 'none',
      border: 'transparent'
    },
    '& input': {
      cursor: 'pointer',
    },
    '&:hover:not(.Mui-disabled):before': {
      border: 'transparent'
    }
  },
  divInput: {
    border: `1px solid ${theme.palette.gray.extraLight}`,
    width: '420px',
    padding: '10px 20px',
    borderRadius: '22px',
  },
  step: {
    fontSize: 18,
    fontWeight: 500,
    color: theme.palette.text.title,
    textAlign: 'center',
  },
}));
