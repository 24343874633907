import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { GuardProvider, GuardedRoute } from "react-router-guards";

import { useAppDispatch } from '../redux/store'
// custom styles
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import "./custom.css";

import { getUserByEmail } from 'services/user';
import { getUserPermissions } from 'services/roles';

// components
import Layout from "./Layout";

// pages
import Error from "../pages/error";
import Login from "../pages/login";
import DashboardPrint from "../pages/dashboard/print";
import Recovery from "pages/recovery";
import { setGlobalUser } from "redux/globalSlice";
import * as amplitude from "@amplitude/analytics-browser"

export default function App() {
  const [userIdentity, setUserIdentity] = useState(undefined);
  const dispatch = useAppDispatch()

  const requireLogin = async (to, from, next) => {
    if (to.meta.auth) {
      fetch(`${process.env.REACT_APP_LINK_API_LOGIN}/sessions/whoami`, { credentials: "include" })
        .then((response) => response.json())
        .then(async (data) => {
          if (data.error) {
            next.redirect("/login");
          }

          let token = Buffer.from(JSON.stringify(data)).toString('base64')
          const userEmail = data.identity.traits.email;
          const userName = data.identity.traits.name?.first;

          const responseUserByEmail = await getUserByEmail(userEmail);

          if (responseUserByEmail.status === 200 && responseUserByEmail?.data) {
            localStorage.setItem('userId', responseUserByEmail.data.data.userid)
            dispatch(setGlobalUser({ userId: responseUserByEmail.data.data.userid }));
          }
          const responseUserPermissions = await getUserPermissions(userEmail);
          if (responseUserPermissions.status === 200 && responseUserPermissions.data && responseUserPermissions.data.data) {
            const login_redirect = responseUserPermissions.data.data[0].login_redirect || '';
            localStorage.setItem('login_redirect', login_redirect);
            localStorage.setItem('roles', Buffer.from(JSON.stringify(responseUserPermissions.data.data)).toString('base64'));
            dispatch(setGlobalUser({ roles: Buffer.from(JSON.stringify(responseUserPermissions.data.data)).toString('base64') }));
          }
          dispatch(setGlobalUser({ name: userName, email: userEmail, token: token }));
          amplitude.setUserId({ name: userName, email: userEmail })

          setUserIdentity(data);
          next();
        });
    } else {
      next();
    }
  };

  return (
    <Router>
      <ToastContainer />
      <GuardProvider guards={[requireLogin]} error={Error}>
        <Switch>
          <GuardedRoute exact path="/login" component={Login} />
          {/* <GuardedRoute exact path="/register" component={Register} /> */}
          <GuardedRoute exact path="/recovery" component={Recovery} />
          <GuardedRoute path="/fleet" component={Layout} />
          <GuardedRoute exact path="/dashboard/print" component={DashboardPrint} />

          {userIdentity ? (
            <Route path="/" render={() => <Redirect to="fleet/bonus/follow" />} />
          ) : (
            <Route path="/" exact render={() => <Redirect to="/login" />} />
          )}
          <GuardedRoute component={Error} />
        </Switch>
      </GuardProvider>
    </Router>
  );
}
