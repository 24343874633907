import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Alert, AlertTitle } from "@material-ui/lab";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { mapValues, toArray } from "lodash";
import { Link, useHistory, useLocation } from "react-router-dom";
import sign from "jwt-encode";
import classnames from "classnames";

// hoc
import Aux from "hoc/auxiliar";

// components
import CardsSkeleton from "components/Skeletons/CardsSkeleton";
import StatisticsCards from "components/StatisticsCards";
import FleetPerformance from "components/FleetPerformance";
import MapSkeleton from "components/Skeletons/MapSkeleton";
import PerformanceComponent from "components/PerformanceComponent";
import PerformanceSkeleton from "components/Skeletons/PerformanceSkeleton";
import PressureSkeleton from "components/Skeletons/PressureSkeleton";
import Map from "components/Map";
import ModalFleetChart from "components/ModalFleetChart";
import PageToolbar from "components/PageToolbar";
import Pressure from "components/Pressure";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";

// services
import {
  getFleetPerformance,
  getFleetPercentages,
  getFleetLocationsCompact,
  getFleetChartData,
  getVehicleProfile,
  getVehiclesPerformancePack,
  getFleetStatistics,
} from "services/fleetPerformance";
import { getVehiclesByOperation, getVehicleValidation } from "services/vehicle";
import { getOperationById } from "services/operations";
import { getOperationTypeById } from "services/operationType";
import { ValidationMessageComponent } from "components/ValidationMessageComponent";

// helpers
import { getNameIndicator } from "helpers/indicators";
import { normalizeData } from "helpers/fleetChart";
import { orderVehiclesByCustomer } from "helpers/vehicles";
import {
  allRequirementLevelOptions,
  indicatorsAndPenalizingOptionsConcat,
} from "domain/selectOptions";

// context
import { useLayoutState } from "context/LayoutContext";

// styles
import useStyles from "./styles";
import { verifyDateInRange, verifyDatesInRange } from "helpers/functions";
import { useAppDispatch, useAppSelector } from "redux/store";
import {
  setPrintData,
  setStepDeductBonus,
} from "redux/features/bonusFollowSlice";
import { useWindowSize } from "hooks/useWindowsSize";
import { setStepDeduct } from "redux/features/driversMonitoringSlice";

export default function Dashboard() {
  const { currentCustomer } = useAppSelector((state) => state.global.user);
  const dispatch = useAppDispatch()

  const size = useWindowSize();

  //esse state é referente ao objeto vindo da pagina de bonificação. Quando clicado na tabela de viagens, é redirecionado para o dashboard com os parametros passados.
  const { state } = useLocation();
  const classes = useStyles();
  const dateFormatTemplate = "yyyy-MM-dd'T'HH:mm:ssXX";
  const dateFormatTemplateWithGMT = "yyyy-MM-dd'T'HH:mm:ss-0300";
  // global
  const layoutState = useLayoutState();

  const [consumption, setConsumption] = useState({
    idleConsumption: "",
    movementConsumption: "",
  });
  const [validationMessageComponent, setValidationMessageComponent] =
    useState(false);
  const [validationMessage, setValidationMessage] = useState(false);
  const [infoBox, setInfoBox] = useState(false);
  const [checkboxInfo, setCheckboxInfo] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [indicators, setIndicators] = useState([]);
  const [indicatorsByOperation, setIndicatorsByOperation] = useState([]);
  const [indicatorsPerformance, setIndicatorsPerformance] = useState([]);
  const [indicatorsPercentage, setIndicatorsPercentage] = useState([]);
  const [indicatorsPressure, setIndicatorsPressure] = useState([]);
  const [locations, setLocations] = useState([]);
  const [locationsUnique, setLocationsUnique] = useState(false);
  const [statistics, setStatistics] = useState([]);
  const [summary, setSummary] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [showFleetChart, setShowFleetChart] = useState(false);
  const [throttlePressureScore, setThrottlePressureScore] = useState(0);
  const [penalty, setPenalty] = useState(false);
  const [vehicleLastPackTime, setVehicleLastPackTime] = useState([]);
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const history = useHistory();
  const [selectedDates, setSelectedDates] = useState({
    initialDate: state
      ? format(new Date(state.startDate), dateFormatTemplate)
      : format(new Date(), "yyyy-MM-dd'T'00:00:00XX"),
    finalDate: state
      ? format(new Date(state.finishDate), dateFormatTemplate)
      : format(new Date(), "yyyy-MM-dd'T'23:59:59XX"),
  });

  const [loading, setLoading] = useState({
    chart: true,
    drivers: true,
    indicators: true,
    vehicles: true,
    locations: true,
    percentages: true,
    statistics: true,
    lastPack: true,
  });
  const [searchParams, setSearchParams] = useState({
    dates: {},
    vehicles: [],
  });

  const fetchVehicles = useCallback(async () => {
    setLoading((oldState) => ({ ...oldState, vehicles: true }));
    try {
      const response = await getVehiclesByOperation(currentCustomer);
      if (state && response.data.customers.length > 0) {
        const returnData = response.data.customers;
        returnData
          .map((o) =>
            o.vehicles.filter((truck) => {
              const vehicleId = state.vehicleId.id || state.vehicleId.vehicleId || '';
              if (truck.id === vehicleId) {
                const model = {
                  ...truck,
                  operation: { id: o.id, name: o.name },
                };
                setSelectedVehicles([model]);
              }
            }),
          );
      } else {
        setSelectedVehicles([]);
      }
      let orderedVehiclesByCustomer = orderVehiclesByCustomer(
        response.data.customers,
      );
      setVehicles(orderedVehiclesByCustomer);
    } catch (err) {
      console.log("err", err);
      setVehicles([]);
      toast.error(
        "Erro ao carregar lista de Veículos. Entre em contato com o suporte",
      );
    } finally {
      setLoading((oldState) => ({ ...oldState, vehicles: false }));
    }
  },
    [state],
  );


  //Função esta colocada no useEffect. Toda vez que a lista de veiculos ou data forem alterados, irar fazer uma nova busca na api conforme dados selecionados.
  const fetchSearchData = (vehicleList, initialDate, finalDate) => {
    const devicesHistory = vehicleList[0].devicesHistory;

    let params = {
      data: [],
    };
    let vehicle = [];
    let devices = [];

    devicesHistory &&
      devicesHistory.map((elem) => {
        if (!elem.endDate) {
          elem.endDate = format(new Date(), dateFormatTemplate);
        }

        if (
          !verifyDatesInRange(
            elem.startDate,
            elem.endDate,
            initialDate,
            finalDate,
          )
        ) {
          return;
        }

        let vehicleList = {
          deviceId: elem.id,
          startDate: verifyDateInRange(elem.startDate, initialDate, finalDate)
            ? format(new Date(elem.startDate), dateFormatTemplateWithGMT)
            : initialDate,
          endDate: verifyDateInRange(elem.endDate, initialDate, finalDate)
            ? format(new Date(elem.endDate), dateFormatTemplateWithGMT)
            : finalDate,
        };
        params.data.push(vehicleList);
      });

    if (state) {
      vehicle = vehicleList[0][0].id;
      devices = vehicleList[0][0].currentDevice.identification;
    } else {
      vehicle = vehicleList.map((vehicle) => vehicle.id);
      devices = vehicleList.map(
        (vehicle) => vehicle.currentDevice?.identification,
      );
    }

    fetchPerformances(vehicle, initialDate, finalDate);
    fetchVehiclePerformances(vehicle, initialDate, finalDate);
    fetchPercentages(vehicle, initialDate, finalDate);
    fetchStatistics(vehicle, initialDate, finalDate);
    fetchLocations(vehicle, initialDate, finalDate);
    fetchIndicatorsByOperation(vehicleList);
  };

  const fetchVehiclePerformances = async (vehicle, initialDate, finalDate) => {
    try {
      setLoading((oldState) => ({ ...oldState, lastPack: true }));

      const { data } = await getVehiclesPerformancePack(vehicle, initialDate, finalDate)

      if (data) {
        const { vehiclesPerformances } = data;
        if (vehiclesPerformances.length > 0) {
          const firstVehiclePerformance = vehiclesPerformances.shift();
          setVehicleLastPackTime(firstVehiclePerformance.lastPackTime);
        }
      } else {
        toast.warning('Não há data da última comunicação com o veículo.')
      }
    } catch (error) {
      toast.error(
        "Erro ao buscar data de última comunicação. Contate o administrador.",
      );
    } finally {
      setLoading((oldState) => ({ ...oldState, lastPack: false }));
    }
  };

  const fetchPerformances = async (vehicle, initialDate, finalDate) => {
    setLoading((oldState) => ({ ...oldState, indicators: true }));
    try {
      const response = await getFleetPerformance(
        vehicle,
        initialDate,
        finalDate,
      );
      const fleetIndicators = response.data.fleetPerformance;
      setThrottlePressureScore(
        fleetIndicators.indicators.throttlePressureScore,
      );

      const summary = fleetIndicators.summary;

      const formatedIndicators = mapValues(
        fleetIndicators.indicators,
        (indicator, key) => ({
          ...indicator,
          name: key,
          title: getNameIndicator(key),
        }),
      );
      const formatedResponseSummary = {
        globalScore: summary.generalScore,
        pieChartData: [
          {
            value: summary.vehiclesInGoodGroup.length,
          },
          {
            value: summary.vehiclesInWarningGroup.length,
          },
          {
            value: summary.vehiclesInCriticalGroup.length,
          },
        ],
      };

      setIndicatorsPerformance(formatedIndicators);
      setSummary(formatedResponseSummary);
      setConsumption({
        idleConsumption: fleetIndicators.indicators.idle.fuelConsumption,
        movementConsumption: fleetIndicators.indicators.movement
          ? fleetIndicators.indicators.movement.fuelConsumption
          : [],
      });
    } catch (err) {
      setIndicatorsPerformance([]);
      setSummary([]);
      setConsumption({
        idleConsumption: "",
        movementConsumption: "",
      });
      /* TODO - tratar erro */
      console.log(err);
    } finally {
      setLoading((oldState) => ({
        ...oldState,
        indicators: false,
        ranking: false,
      }));
    }
  };

  // Para entender o rolo a seguir, veja o comentário aqui:
  // https://gitlab.com/gobrax-dev/fleet-analytics/-/issues/384#note_897840649
  const fetchIndicatorsByOperation = async (vehicleList) => {
    if (Object.keys(vehicleList[0])?.length > 0) {
      try {
        let operationId =
          vehicleList[0]?.operation?.id || vehicleList[0][0]?.operation?.id;
        if (operationId > 0) {
          const response = await getOperationById(Number(operationId));
          const { operationType, requirementLevel } = response.data.data.operation;
          const responseOperation = await getOperationTypeById(operationType);
          const level = allRequirementLevelOptions.filter(o => o.value === requirementLevel);
          const operationsName = [];
          const penaltyTypes = ["lowPressure", "medPressure", "higPressure"]
          setPenalty(false);
          responseOperation.data.operationTypes.indicators.forEach((indicator) => {
            if (indicator.requirement_level === level[0].type) {
              let indicatorName = indicatorsAndPenalizingOptionsConcat.filter(
                (o) => o.value === indicator.indicator_id,
              );
              operationsName.push(indicatorName[0].type);
              if (penaltyTypes.includes(indicatorName[0].type) && indicator?.penalizing) {
                setPenalty(true);
              }
            }
          });
          setIndicatorsByOperation(operationsName);
        }
      } catch (err) {
        setIndicatorsByOperation([]);
        toast.error("Erro ao buscar indicadores");
      }
    }
  };

  const fetchPercentages = async (vehicle, initialDate, finalDate) => {
    setLoading((oldState) => ({ ...oldState, percentages: true }));
    try {
      const response = await getFleetPercentages(
        vehicle,
        initialDate,
        finalDate,
      );
      if (response.data) {
        const fleetIndicators = response.data;
        const formatedResponseIndicators = mapValues(
          fleetIndicators,
          (indicator, key) => ({
            ...indicator,
            name: key,
            label: getNameIndicator(key),
          }),
        );
        const transformIndicatorsToArray = toArray(formatedResponseIndicators);

        setIndicatorsPercentage(transformIndicatorsToArray)
        setIndicatorsPressure({
          higPressure: fleetIndicators.higPressure,
          lowPressure: fleetIndicators.lowPressure,
          medPressure: fleetIndicators.medPressure,
        });
      }
    } catch (err) {
      setIndicatorsPercentage([]);
      setIndicatorsPressure([]);
      /* TODO - tratar erro */
      console.log(err);
    } finally {
      setLoading((oldState) => ({ ...oldState, percentages: false }));
    }
  };

  const fetchStatistics = async (vehicle, initialDate, finalDate) => {
    setLoading((oldState) => ({ ...oldState, statistics: true }));
    try {
      if (!state && !vehicle.length) {
        setStatistics([]);
        return;
      }

      if (!vehicle) {
        setStatistics([]);
        return;
      }

      const response = await getFleetStatistics(vehicle,
        initialDate,
        finalDate,
      );
      setStatistics(response.data);
    } catch (err) {
      setStatistics([]);
      /* TODO - tratar erro */
      console.log(err);
    } finally {
      setLoading((oldState) => ({ ...oldState, statistics: false }));
      return;
    }
  };

  const fetchLocations = async (vehicleId, initialDate, finalDate) => {
    setLoading((oldState) => ({ ...oldState, locations: true }));
    if (state) {
      vehicleId = [String(vehicleId)];
    }
    try {
      if (vehicleId.length > 1) {
        setLocationsUnique(true);
        return;
      }
      const response = await getFleetLocationsCompact(
        vehicleId,
        initialDate,
        finalDate,
      );

      if (response.data.locations.length) {
        const newLocations = response.data.locations.map(location => {
          return {
            device: location.device,
            locations: location.locations.map(sublocation => {
              return {
                date: sublocation[0],
                lat: sublocation[1],
                lon: sublocation[2]
              };
            })
          };
        });
        const transformed = {
          locations: newLocations
        };
        setLocationsUnique(false);
        setLocations(transformed.locations);
      } else {
        setLocations([]);
      }
    } catch (err) {
      setLocations([]);
    } finally {
      setLoading((oldState) => ({ ...oldState, locations: false }));
    }
  };

  const fetchChart = async (vehicles, devices, initialDate, finalDate) => {
    setLoading((oldState) => ({ ...oldState, chart: true }));
    let chartData = [],
      rpmRanges,
      fuelData = [];
    try {
      let vehicleProfile = await getVehicleProfile(vehicles[0].id);

      if (vehicleProfile.data) {
        const rangeData = vehicleProfile.data;

        rpmRanges = {
          neutral: [0, rangeData.neutral_range_end],
          white: [rangeData.white_range_start, rangeData.white_range_end],
          extra: [
            rangeData.extra_economic_range_start,
            rangeData.extra_economic_range_end,
          ],
          economy: [
            rangeData.economic_range_start,
            rangeData.economic_range_end,
          ],
          power: [rangeData.power_range_start, rangeData.power_range_end],
          engine_brake: [
            rangeData.engine_brake_range_start,
            rangeData.engine_brake_range_end,
          ],
          red: [rangeData.red_range_start, rangeData.red_range_end],
        };
      } else {
        throw new Error("failed to fetch chart data");
      }

      let response = await getFleetChartData(
        devices[0],
        initialDate,
        finalDate,
      );
      if (!response.data || !response.data.data) {
        return;
      }

      const responseData = normalizeData(response.data.data);
      const maxFuelCapacity = selectedVehicles[0].maxFuelCapacity
        ? selectedVehicles[0].maxFuelCapacity
        : 0;
      const distanceCalcMethod =
        vehicleProfile.data?.distance_calc_method ?? "can";
      responseData.map((data) => {
        let totalFuel = data.can_fuel_level
          ? maxFuelCapacity * (data.can_fuel_level / 100)
          : 0;
        chartData = [
          ...chartData,
          {
            acceleration_pedal: data.acceleration_pedal_position,
            speed: data[`${distanceCalcMethod}_speed`],
            gear: data.gear,
            height: data.height,
            ignition_key: data.ignition_key,
            rpm: data.rpm,
            brake_pedal: data.service_brake_pedal_status,
            time: data.time,
            total_fuel_consumption_high_resolution:
              data.total_fuel_consumption_high_resolution,
            potency: false,
            maxSpeed: false,
            total: totalFuel,
          },
        ];
        fuelData = [
          ...fuelData,
          {
            time: data.time,
            total: Number(totalFuel),
            rpm: data.rpm,
            ignition_key: data.ignition_key,
          },
        ];
      });

      chartData = {
        rpmvstime: chartData,
        fuelvstime: fuelData,
        rpmrange: rpmRanges,
        limits: {
          maxFuelCapacity: maxFuelCapacity,
          maxSpeedAllowed: selectedVehicles[0].maxSpeedAllowed
            ? selectedVehicles[0].maxSpeedAllowed
            : 0,
        },
        dates: {
          initialDate: initialDate,
          finalDate: finalDate,
        },
      };

      setChartData(chartData);
    } catch (err) {
      setChartData([]);
      /* TODO - tratar erro */
      console.log(err);
    } finally {
      setLoading((oldState) => ({ ...oldState, chart: false }));
    }
  };

  const handleSelectDate = async (initialDate, finalDate) => {
    setValidationMessageComponent(false);

    setSelectedDates({
      initialDate: format(initialDate, dateFormatTemplate),
      finalDate: format(finalDate, dateFormatTemplate),
    });

    if (selectedVehicles.length > 0 && Object.keys(selectedVehicles[0]).length > 0) {
      let vehicles = []
      vehicles = selectedVehicles.map((vehicle) => vehicle.id);
      try {
        const validation = await getVehicleValidation(
          vehicles,
          format(initialDate, dateFormatTemplate),
          format(finalDate, dateFormatTemplate),
        );

        const { response: vehicleValidationConfig } = validation.data;
        setValidationMessage(vehicleValidationConfig);

        if (vehicleValidationConfig === "ok") {
          fetchSearchData(
            selectedVehicles,
            format(initialDate, dateFormatTemplate),
            format(finalDate, dateFormatTemplate),
          );
        } else {
          setInfoBox(true);
          setValidationMessageComponent(true);
          return false;
        }
      } catch (error) {
        toast.error(
          "Erro ao validar configurações do veículo. Por favor, entre em contato com o suporte.",
        );
      }
    }
  };

  const handleSelectedVehicles = async (selected) => {
    setValidationMessageComponent(false);
    if (Object.keys(selected[0]).length > 0) {
      try {
        let vehicles = [];
        if (!state) {
          vehicles = selected.map((vehicle) => vehicle.id);
          setSelectedVehicles(selected);
        } else {
          vehicles = selected[0][0].id;
        }
        const validation = await getVehicleValidation(
          vehicles,
          selectedDates.initialDate,
          selectedDates.finalDate,
        );

        const { response: messageResponse } = validation.data;
        setValidationMessage(messageResponse);
        if (messageResponse === "ok") {
          fetchSearchData(
            selected,
            selectedDates.initialDate,
            selectedDates.finalDate,
          );
        } else {
          setInfoBox(true);
          setValidationMessageComponent(true);
          return false;
        }
      } catch (error) {
        toast.error(
          "Erro ao validar configuração do veículo. Por favor, entre em contato com o suporte.",
        );
      }
    }
  };

  const handleChangeVisibleInfoBox = () => {
    if (!checkboxInfo) return setInfoBox(true);
    localStorage.setItem("@infobox", checkboxInfo);
    getLocalStorageInfo();
  };

  const handleFleetChart = (open) => {
    setShowFleetChart(open);
  };

  const handleCheckboxInfoIsVisible = () => {
    setCheckboxInfo(!checkboxInfo);
  };

  const ordernateIndicators = (indicatorList) => {
    let ordenateArray = [];
    const ordenateIndicators = [
      "Inicio Faixa Verde",
      "Aproveitamento de Embalo",
      "Motor Ligado Parado",
      "Acelerando Acima do Verde",
      "Piloto Automático",
      "Excesso de Velocidade",
      "Total Faixa Verde",
      "Final Faixa Verde",
      "Freio Motor",
      "Eco-Roll/I-Roll",
      "Em Movimento",
    ];

    for (const label of ordenateIndicators) {
      for (const indicator of indicatorList) {
        if (indicator.label === label) {
          ordenateArray.push(indicator);
        }
      }
    }
    return ordenateArray;
  };

  const mergeIndicatorsInfo = useCallback((indPerformance, indPercentage) => {
    const mergeIndicatorsList = [];
    for (let ind of indPercentage) {
      for (let key in indPerformance) {
        if (indPerformance[key].name === ind.name) {
          mergeIndicatorsList.push({
            ...indPerformance[key],
            ...ind,
            color: indPerformance[key].color,
          });
        }
      }
    }
    const indOrdernated = ordernateIndicators(mergeIndicatorsList);

    setIndicators(indOrdernated);
  }, []);

  const getLocalStorageInfo = () => {
    setInfoBox(localStorage.getItem("@infobox"));
  };

  useEffect(() => {
    let actualSearchParams = {
      dates: selectedDates,
      vehicles: selectedVehicles,
    };
    if (
      showFleetChart &&
      JSON.stringify(actualSearchParams) !== JSON.stringify(searchParams)
    ) {
      const selectedDevices = selectedVehicles.map(
        (vehicle) => vehicle.currentDevice.identification,
      );
      fetchChart(
        selectedVehicles,
        selectedDevices,
        selectedDates.initialDate,
        selectedDates.finalDate,
      );
      setSearchParams(actualSearchParams);
    }
  }, [showFleetChart]);

  useEffect(() => {
    if (currentCustomer && hasPermission({ scopes: ['can_view_dashboard'] })) {
      fetchVehicles();
      getLocalStorageInfo()
    }
  }, [fetchVehicles, currentCustomer]);

  useEffect(() => {
    if (indicatorsPercentage && indicatorsPerformance) {
      mergeIndicatorsInfo(indicatorsPerformance, indicatorsPercentage);
    }
  }, [indicatorsPercentage, indicatorsPerformance]);

  let map = <Map data={locations} />;

  if (!locations || locations.length === 0) {
    map = (
      <Alert severity="info">
        <AlertTitle>Atenção!</AlertTitle>
        Não foram encontrados indicadores para a frota selecionada,{" "}
        <strong>verifique os filtros.</strong>
      </Alert>
    );
  } else if (locationsUnique) {
    map = (
      <Alert severity="info">
        <AlertTitle>Atenção!</AlertTitle>
        Para que possa visualizar o trajeto das rotas do veículo no mapa é
        necessário selecionar <strong>apenas um veículo.</strong>
      </Alert>
    );
  }

  const linkPrint = () => {
    const token = require("crypto").randomBytes(256).toString("base64");
    const dataToPrint = {
      consumption: consumption,
      indicators: indicators,
      indicatorsByOperation: indicatorsByOperation,
      indicatorsPressure: indicatorsPressure,
      selectedVehicles: selectedVehicles,
      statistics: statistics,
      summary: summary,
      vehicleId: selectedVehicles[0], 
      initialDate: selectedDates.initialDate, 
      finalDate: selectedDates.finalDate,
      vehicleProps: {
        vehicleIdentification: selectedVehicles[0].identification,
        startDate: selectedDates.initialDate,
        finishDate: selectedDates.finalDate,
      },
    };
    const location = {
      locations: locations
    }
    const jwtData = sign(dataToPrint, token);
    dispatch(setPrintData(jwtData))
  };

  const PrintButton = React.forwardRef(({ navigate, jwtData, ...props }, ref) => {
    return (
      <Button
        fullWidth
        variant="contained"
        className={classnames(classes.btnPrint, {
          [classes.btBarginTop]: state,
        })}
        onClick={() => linkPrint()}
        ref={ref}
        {...props}
      >
        {props.children}
      </Button>
    );
  });

  return (
    <Aux>
      <PermissionsGate scopes={["can_view_dashboard"]}>
        {showFleetChart && (
          <ModalFleetChart
            showFleetChart={showFleetChart}
            handleFleetChart={handleFleetChart}
            loading={loading.chart}
            chartData={chartData}
          />
        )}
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <PageToolbar
              isDetail={!!state}
              handleSelectDate={handleSelectDate}
              vehicleProps={state}
              selectedDates={selectedDates}
              listData={vehicles}
              selectedData={selectedVehicles}
              handleSelectedData={handleSelectedVehicles}
              menuCalendar
              calendarMaxRanges={{
                ...(selectedVehicles?.[0]?.devicesHistory && {
                  minDate: new Date(
                    selectedVehicles[0].devicesHistory.at(-1).startDate,
                  ),
                }),
                maxDate: new Date(),
              }}
              vehiclesMenuButton
              isSingleMode
              initialDate={
                state ? new Date(state.startDate) : selectedDates.initialDate
              }
              finalDate={
                state ? new Date(state.finishDate) : selectedDates.finalDate
              }
            />
          </Grid>
        </Grid>
        {validationMessageComponent && (
          <ValidationMessageComponent message={validationMessage} />
        )}
        {!selectedVehicles.length > 0 && !infoBox ? (
          <Grid container className={classes.containerInfobox}>
            <Paper elevation={0} className={classes.paper}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box
                    fontFamily="fontFamily"
                    justifyContent="center"
                    fontSize="h3.fontSize"
                    textAlign="center"
                    lineHeight={2}
                    className={classes.infoBox}
                    p={4}
                  >
                    Para gerar as informações da sua frota, selecione um veículo{" "}
                    <strong>
                      clicando no filtro localizado no menu acima.
                    </strong>
                  </Box>
                  <Grid item className={classes.containerButton}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkboxInfo}
                          onChange={() => handleCheckboxInfoIsVisible()}
                        />
                      }
                      label="Não mostrar essa mensagem novamente"
                    />
                    <Button
                      onClick={() => handleChangeVisibleInfoBox()}
                      variant="contained"
                      className={classes.okButton}
                    >
                      Ok
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ) : !selectedVehicles.length > 0 && infoBox ? (
          <Grid />
        ) : (
          <Grid
            container
            spacing={4}
            data-cy="contentAnaliseIndividual"
            className={classnames(classes.container, {
              [classes.containerShift]: layoutState.isSidebarOpened,
            })}
          >
            {validationMessageComponent ? (
              <Grid />
            ) : (
              <>
                {/* desempenho */}
                <PermissionsGate scopes={["can_view_dashboard_performance"]}>
                  <Grid
                    item
                    xl={2}
                    lg={!layoutState.isSidebarOpened ? 2 : 3}
                    md={3}
                    sm={4}
                    xs={12}
                    data-cy="performanceDesempenho"
                  >
                    <Typography variant="h4" className={classes.cardTitle}>
                      Desempenho
                    </Typography>
                    <Paper elevation={0} className={classes.performance}>
                      <Grid container alignItems="center">
                        {loading.indicators ? (
                          <PerformanceSkeleton />
                        ) : (
                          <PerformanceComponent
                            data={summary}
                            selectedVehicles={selectedVehicles}
                            indicators={indicators}
                          />
                        )}
                      </Grid>
                    </Paper>
                  </Grid>
                </PermissionsGate>
                {/* indicadores */}
                <PermissionsGate scopes={["can_view_dashboard_indicators"]}>
                  <Grid
                    item
                    xl={10}
                    lg={!layoutState.isSidebarOpened ? 10 : 9}
                    md={9}
                    sm={8}
                    xs={12}
                    data-cy="performanceIndicadores"
                  >
                    <Typography variant="h4" className={classes.cardTitle}>
                      Indicadores
                    </Typography>
                    <FleetPerformance
                      loading={loading}
                      indicators={indicators}
                      consumption={consumption}
                      summary={summary}
                      setShowFleetChart={setShowFleetChart}
                      indicatorsByOperation={indicatorsByOperation}
                    />
                  </Grid>
                </PermissionsGate>
                {/* pressao do acelerador */}
                {Object.keys(indicatorsPressure).length>0 && indicatorsPressure.higPressure.percentage > 0?
                <PermissionsGate scopes={["can_view_dashboard_pressure"]}>
                  <Grid
                    item
                    xl={3}
                    lg={3}
                    md={3}
                    sm={12}
                    xs={12}
                    data-cy="performancePressaoAcelerador"
                  >
                    
                    <Typography variant="h4" className={classes.cardTitle}>
                      Pressão do Acelerador
                    </Typography>
                    {loading.percentages ? (
                      <PressureSkeleton />
                    ) : (
                      <Pressure
                        data={indicatorsPressure}
                        throttlePressureScore={throttlePressureScore}
                        penalty={penalty}
                      />
                    )}
                  </Grid>
                </PermissionsGate> : null }
                {/* estatisticas */}
                <PermissionsGate scopes={["can_view_dashboard_statistics"]}>
                  <Grid
                    item
                    xl={Object.keys(indicatorsPressure).length>0 && indicatorsPressure.higPressure.percentage > 0?9:12}
                    lg={Object.keys(indicatorsPressure).length>0 && indicatorsPressure.higPressure.percentage > 0?9:12}
                    md={Object.keys(indicatorsPressure).length>0 && indicatorsPressure.higPressure.percentage > 0?9:12}
                    sm={12}
                    xs={12}
                    data-cy="performanceEstatisticas"
                  >
                    <Typography variant="h4" className={classes.cardTitle}>
                      Estatísticas
                    </Typography>
                    {loading.statistics || loading.percentages ? (
                      <CardsSkeleton />
                    ) : (
                      <StatisticsCards indicatorsPressure={Object.keys(indicatorsPressure).length>0 && indicatorsPressure.higPressure.percentage > 0} statistics={statistics} />
                    )}
                  </Grid>
                </PermissionsGate>
                {/* trajeto da viagem */}
                <PermissionsGate scopes={["can_view_dashboard_map"]}>
                  <Grid
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    data-cy="performanceTrajetoViagem"
                  >
                    <Typography variant="h4" className={classes.cardTitle}>
                      Trajeto da Viagem
                    </Typography>
                    {loading.locations ? <MapSkeleton /> : map}
                  </Grid>
                </PermissionsGate>
              </>
            )}
          </Grid>
        )}
        <Grid container justifyContent="flex-end" spacing={2}>
          {/* print */}
          {size.mobile ? null : (
            <PermissionsGate scopes={["can_view_dashboard_print"]}>
              <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                {selectedVehicles.length > 0 && !validationMessageComponent && (
                  <Link
                    target="_blank"
                    component={PrintButton}
                    to={{
                      pathname: "/dashboard/print",
                    }}
                  >
                    Imprimir
                  </Link>
                )}
              </Grid>
            </PermissionsGate>
          )}
          {state?.screen ? (
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
              <Button
                fullWidth
                variant="contained"
                className={classes.btnBack}
                onClick={() => {
                  if (state.screen !== "bonus") {
                    dispatch(setStepDeduct());
                  } else {
                    dispatch(setStepDeductBonus());
                  }
                  history.goBack();
                }}
              >
                <ArrowBackIcon fontSize="small" /> Voltar
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </PermissionsGate>
    </Aux>
  );
}
