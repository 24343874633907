import React, { useState, useEffect, useCallback } from "react";
import { Button, Grid, Typography, Paper } from "@material-ui/core";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { useTheme } from "@material-ui/styles";
import { useHistory } from "react-router-dom";

// helpers
import makeColumns from "./config/tableColumns";
import { downloadFile, valueToPositiveFloat } from "helpers/functions";
import { formatVehicleGroups, generateRange, getYearsOptions } from "helpers/operations";
import { defaultOperation, selectFilterOptions } from "helpers/follow";

// styles
import useStyles from "./styles";
import { colourStyles } from "./colourStyles";
import notFound from "assets/lottie/sad.json";
import classNames from "classnames";

//services
import { getDrivers, getDriversByOperation } from "services/driver";
import { getReward, getRewardXLSByDriver, getRewardXLS, getRewardV2 } from "services/reward";
import { getCustomersById } from "services/customers";
import { getOperations } from "services/operations";

// components
import Aux from "hoc/auxiliar";
import RewardSkeleton from "components/Skeletons/RewardSkeleton";
import FollowComponent from "components/FollowComponent";
import NotFoundWithLottie from "components/NotFoundWithLottie";
import Calendar from "components/Calendar";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";
import CircularProgress from "@material-ui/core/CircularProgress";
import GetAppIcon from '@material-ui/icons/GetApp';

// redux
import { useAppDispatch, useAppSelector, store } from 'redux/store';
import { saveCustomerProfile } from 'redux/features/customerProfileSlice';
import { useSelect } from "context/useSelect";
import {
  clearBonusFilters,
  setBonusFilters,
  setRewardReducer,
  setStepAdd,
  setTotalPercentageReducer,
  setGoalReducer,
  setDriversReducer,
  resetReward,
  setIsCalendarFilter,
  resetYear,
  resetCutoffDate,
  resetCalendar
} from 'redux/features/bonusFollowSlice';
import { useWindowSize } from "hooks/useWindowsSize";
import TableResponsive from "components/TableResponsive";

export default function Follow() {
  const { currentCustomer } = useAppSelector(state => state.global.user)
  const dispatch = useAppDispatch()
  const { profile } = useAppSelector((state) => state.customerProfile);
  useAppSelector((state) => state.bonusFollow);
  const { reward, fields, filters, totalPercentage, goal, drivers } = useAppSelector((state) => state.bonusFollow);
  const TODAY = new Date()
  const history = useHistory()
  const classes = useStyles()
  const theme = useTheme()
  const animatedComponents = makeAnimated()
  const yearsOptions = getYearsOptions()
  const dateFormatTemplate = "yyyy-MM-dd'T'HH:mm:ssXX"
  const size = useWindowSize();

  const [loading, setLoading] = useState(false)
  const [loadingData, setLoadingData] = useState(false)
  const [allDrivers, setAllDrivers] = useState(null)
  const [operationsOptions, setOperationsOptions] = useState([])

  const { cutOffDatesOptions, setCutOffDatesOptions } = useSelect()
  const [loadingDownload, setLoadingDownload] = useState(false)
  const [download, setDownload] = useState({
    link: "",
    fileName: "",
    params: {
      drivers: [],
      startDate: null,
      endDate: null,
      limit: null,
    },
  });

  const handleCalendarFilterType = (filterType) => {
    dispatch(setIsCalendarFilter(filterType))
    dispatch(resetReward())
    dispatch(resetYear())
    dispatch(resetCutoffDate())
    dispatch(resetCalendar())
  }

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);

      const driversResponse = await getDrivers(currentCustomer);
      const operationsResponse = await getOperations(currentCustomer);
      const customersResponse = await getCustomersById(currentCustomer);

      if (driversResponse.status !== 200) {
        throw new Error("Error fetching drivers");
      }

      if (operationsResponse.status !== 200) {
        throw new Error("Error fetching operations");
      }

      if (customersResponse.status !== 200) {
        throw new Error("Error fetching customers");
      }

      setAllDrivers(driversResponse.data.drivers);
      const operationData = operationsResponse?.data?.data?.operation;
      if (operationData) {
        const flatArrayAllVehicles = formatVehicleGroups(operationData)
          .flatMap(item => item.vehicles);
        const newOperation = { ...defaultOperation, vehicles: flatArrayAllVehicles };
        const newArrayGroups = [newOperation, ...formatVehicleGroups(operationData)];
        setOperationsOptions(newArrayGroups);
      } else {
        dispatch(clearBonusFilters())
        setOperationsOptions([])
      }
      const customersOptions = customersResponse.data.customers[0];
      dispatch(saveCustomerProfile(customersOptions))
      handleInitialDates(customersOptions, filters.year)

      //first access
      const selectedDate = !!filters.isCalendarFilter.id ? store.getState().bonusFollow.filters.calendar : store.getState().bonusFollow.filters.cutoffDate
      fields.step === null && handleDataByOperation(filters.operation, selectedDate, driversResponse.data.drivers)
    } catch (err) {
      toast.error("Erro ao buscar dados. Contate o suporte.");
    } finally {
      setLoading(false);
    }
  }, [currentCustomer])

  const fetchBonus = async (driversItens, listVehicleByOperation, modelFormattedCutoffDates) => {
    try {
      const { startDate, finishDate } = modelFormattedCutoffDates
      setLoadingData(true)
      if (driversItens && driversItens.length && modelFormattedCutoffDates) {
        const driverList = driversItens.map(
          (driver) => driver.id || driver.driverId,
        )
        let response = null
        // Novo endpoint para com acesso direto ao banco DT-79
        if (!hasPermission({ scopes: ['can_access_beta'] })) {
          response = await getReward(
            driverList,
            listVehicleByOperation,
            startDate,
            finishDate,
            1000,
          );
        } else {
          response = await getRewardV2(
            driverList,
            listVehicleByOperation,
            startDate,
            finishDate,
            1000,
          );
        }

        if (response.status !== 200) {
          throw new Error("Error fetching reward");
        }

        const performance = hasPermission({ scopes: ['can_access_beta'] }) ? response.data.data : response.data.driversPerformances;
        const totalAchievedMileage = performance.totalAchievedMileage;
        const totalMonthTargetMileage = performance.totalMonthTargetMileage;
        dispatch(setGoalReducer({
          total: totalAchievedMileage,
          monthly: totalMonthTargetMileage,
        }))

        let percentage = valueToPositiveFloat(
          Math.round((totalAchievedMileage / totalMonthTargetMileage) * 100),
        );
        percentage = percentage > 100 ? 100 : percentage;
        dispatch(setTotalPercentageReducer(percentage))
        const missingAchieved = 100 - percentage;
        const pieChartData = [
          { value: percentage, color: theme.palette.secondary.main },
          { value: missingAchieved, color: theme.palette.gray.extraLight },
        ];
        const performances = performance.performances.map((driver) => ({
          ...driver,
          id: driver.driverId,
        }));
        dispatch(setRewardReducer({ ...performance, performances, pieChartData }))

        setDownload({
          link: `/driver-performance-prfc-xls`,
          fileName: `Bonificação_${format(
            new Date(startDate),
            "dd-MM-yyyy-HH-mm",
          )}_${format(
            new Date(finishDate),
            "dd-MM-yyyy-HH-mm",
          )}.xlsx`,
          params: {
            drivers: driverList,
            startDate: startDate,
            endDate: finishDate,
            limit: 1000,
          },
        });
      } else {
        dispatch(setRewardReducer([]))
      }
    } catch (error) {
      toast.error('Erro ao buscar dados. Contate o suporte.')
      console.log(error)
    } finally {
      setLoadingData(false)
    }
  };

  const onChangeFilters = (selectedOption, name) => {
    try {
      setLoading(true)
      dispatch(setBonusFilters({ selectedOption, name }))
      const updatedFilters = store.getState().bonusFollow.filters;
      if (name === 'year') {
        handleChangeYear(profile, updatedFilters.year)
        dispatch(setRewardReducer(null))
      } else if (!!updatedFilters.operation && (!!updatedFilters.cutoffDate || !!updatedFilters.calendar)) {
        let filtersData = (!!filters.isCalendarFilter.id) ? updatedFilters.calendar : updatedFilters.cutoffDate
        handleDataByOperation(updatedFilters.operation, filtersData)
      }
    } catch (error) {

    } finally {
      setLoading(false)
    }
  }

  const handleChangeYear = (customerOptions, year) => {
    const { starting_day, period, finishing_day } = customerOptions
    const currentYear = year.value
    if (starting_day && finishing_day && !isNaN(period) && currentYear) {
      const cutOffDate = generateRange(starting_day, finishing_day, period, currentYear);
      setCutOffDatesOptions(cutOffDate);
      dispatch(setBonusFilters({ selectedOption: null, name: 'cutoffDate' }))
    } else {
      setCutOffDatesOptions(null);
      dispatch(setBonusFilters({ selectedOption: null, name: 'cutoffDate' }))
    }
  }

  const handleInitialDates = (customerOptions, year) => {
    const { starting_day, period, finishing_day } = customerOptions
    const currentYear = year.value
    if (starting_day && finishing_day && !isNaN(period) && currentYear) {
      const cutOffDate = generateRange(starting_day, finishing_day, period, currentYear);
      setCutOffDatesOptions(cutOffDate);
      if (fields.step === 0) {
        return
      } else {
        const selectedMonth = TODAY.getDate() > finishing_day ? TODAY.getMonth() + 1 : TODAY.getMonth()
        dispatch(setBonusFilters({ selectedOption: cutOffDate[selectedMonth], name: 'cutoffDate' }))
      }
    } else {
      setCutOffDatesOptions(null);
      dispatch(setBonusFilters({ selectedOption: null, name: 'cutoffDate' }))
    }
  }

  const handleSelectRow = (data) => {
    const driverId = data[Object.keys(data).length - 1];
    const driverTripList = reward.performances.find(
      (item) => item.id === driverId,
    );
    const driverName = driverTripList.driverName;
    if (
      !driverTripList.tripsScores ||
      driverTripList.tripsScores.lenght === 0
    ) {
      toast.error("Motorista sem viagens registradas.");
      return
    }
    const dataTable = driverTripList.tripsScores.map((trip) => ({
      ...trip,
      id: trip.vehicleId,
      resumeMobile: {
        origin: trip.origin.date,
        destination: trip.destination.date,
      },
    }));
    dispatch(setStepAdd());
    history.push({
      pathname: "/fleet/bonus/follow/details",
      state: {
        data: dataTable,
        name: driverName,
        isCalendarFilter: filters.isCalendarFilter,
        driverId: driverId,
      },
    })
  }

  const handleDataByOperation = async (operation, date, drivers) => {
    setLoading(true);
    try {
      if (date) {
        const { id: operationId, value } = operation;
        const { startDate, finishDate } = JSON.parse(date);
        const formattedStartDate = format(new Date(startDate), dateFormatTemplate);
        const formattedFinishDate = format(new Date(finishDate), dateFormatTemplate);

        let driversItens = [];

        if (value === 0) {
          driversItens = drivers || allDrivers;
        } else {
          const response = await getDriversByOperation(
            operationId,
            formattedStartDate,
            formattedFinishDate
          );

          if (response.status === 200 || response.status === 201) {
            driversItens = response.data.drivers || [];
          } else {
            throw new Error("Error fetching drivers");
          }
        }
        const listVehicleByOperation = operation.vehicles?.map((elm) => elm.vehicleId)

        const modelFormattedCutoffDates = {
          startDate: formattedStartDate,
          finishDate: formattedFinishDate
        }
        dispatch(setDriversReducer(driversItens))
        fetchBonus(driversItens, listVehicleByOperation, modelFormattedCutoffDates)
      }
    } catch (error) {
      console.log(error)
      toast.error("Erro ao carregar motoristas. Entre em contato com o suporte.");
    } finally {
      setLoading(false);
    }
  }

  const handleExportByDriver = async (driverId, driverName) => {
    const name = driverName ? driverName.split(" ")[0] : "Bonificação";
    const fileName = `${name}_${format(
      filters.startDate,
      "yyyy-MM-dd-HH",
    )}_${format(
      filters.finishDate,
      "yyyy-MM-dd-HH",
    )}.xlsx`;
    try {
      const response = await getRewardXLSByDriver(
        driverId,
        filters.startDate,
        filters.finishDate,
      );
      downloadFile(
        response.data.XLS,
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        fileName,
      );
    } catch (err) {
      toast.error("Erro ao carregar relatório. Entre em contato com o suporte");
    }
  }

  const handleExport = async () => {
    setLoadingDownload(true)
    const dates = filters.cutoffDate || filters.calendar
    const { startDate, finishDate } = JSON.parse(dates);
    const fileName = `Bonificação_${format(
      new Date(startDate),
      "dd-MM-yyyy-HH-mm",
    )}_${format(
      new Date(finishDate),
      "dd-MM-yyyy-HH-mm",
    )}.xlsx`;
    try {
      const driverList = drivers.map((driver) => driver.id || driver.driverId);
      const response = await getRewardXLS(
        driverList,
        format(new Date(startDate), dateFormatTemplate),
        format(new Date(finishDate), dateFormatTemplate),
        1000,
      );
      downloadFile(
        response.data.XLS,
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        fileName,
      );
    } catch (err) {
      console.log('err', err)
      toast.error("Erro ao carregar relatório. Entre em contato com o suporte");
    } finally {
      setLoadingDownload(false)
    }
  }

  useEffect(() => {
    if (hasPermission({ scopes: ['can_view_bonus'] }) && currentCustomer) {
      fetchData()
    }
  }, [fetchData, currentCustomer])

  useEffect(() => {
    if (fields.step > 0) {
      dispatch(clearBonusFilters())
    }
  }, [fields, dispatch])

  useEffect(() => {
    const login_redirect = localStorage.getItem('login_redirect')
    login_redirect && login_redirect !== 'fleet/bonus/follow' && history.replace(`/${login_redirect}`)
  }, [history])

  const columns = makeColumns(handleExportByDriver, size);
  
  const styleFormat = filters.isCalendarFilter?.label === "Calendário";

  if ((loading || loadingData) && filters.operation?.id >= 0) return <RewardSkeleton />;

  return (
    <Aux>
      <PermissionsGate scopes={["can_view_bonus"]}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid container justifyContent="space-around">
            {operationsOptions.lenght === 0 ? (
              <Grid
                item
                xl={styleFormat ? size.grid4 :size.grid3}
                lg={styleFormat ? size.grid4 :size.grid3}
                md={styleFormat ? size.grid4 :size.grid3}
                sm={styleFormat ? size.grid4 :size.grid3}
                xs={styleFormat ? size.grid4 :size.grid3}
              >
                <Paper elevation={0} className={classes.center}>
                  <Typography>
                    Não foram encontradas operações disponíveis.
                  </Typography>
                </Paper>
              </Grid>
            ) : (
              <Grid
                item
                xl={styleFormat ? size.grid4 :size.grid3}
                lg={styleFormat ? size.grid4 :size.grid3}
                md={styleFormat ? size.grid4 :size.grid3}
                sm={styleFormat ? size.grid4 :size.grid3}
                xs={styleFormat ? size.grid4 :size.grid3}
              >
                <Paper elevation={0} className={classes.paper}>
                  <Select
                    placeholder="Operação"
                    options={operationsOptions}
                    menuPortalTarget={document.body}
                    styles={{ ...colourStyles,menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    onChange={(selectedOption) =>
                      onChangeFilters(selectedOption, "operation")
                    }
                    value={filters.operation}
                    defaultValue={filters.operation}
                    components={animatedComponents}
                    noOptionsMessage={() => "Nenhum resultado encontrado!"}
                  />
                </Paper>
              </Grid>
            )}
            <Grid
              item
              xl={styleFormat ? size.grid4 :size.grid3}
              lg={styleFormat ? size.grid4 :size.grid3}
              md={styleFormat ? size.grid4 :size.grid3}
              sm={styleFormat ? size.grid4 :size.grid3}
              xs={styleFormat ? size.grid4 :size.grid3}
            >
              
              <Paper elevation={0} className={classes.paper}>
                <Select
                  placeholder="Tipo de Filtro de data"
                  options={selectFilterOptions}
                  onChange={(item) => handleCalendarFilterType(item)}
                  value={filters.isCalendarFilter}
                  defaultValue={selectFilterOptions[0]}
                  components={animatedComponents}
                  menuPortalTarget={document.body}
                  styles={{ ...colourStyles,menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                />
              </Paper>
            </Grid>
            {filters.isCalendarFilter?.label !== "Calendário" && (
              <Grid
                item
                xl={styleFormat ? size.grid4 :size.grid3}
                lg={styleFormat ? size.grid4 :size.grid3}
                md={styleFormat ? size.grid4 :size.grid3}
                sm={styleFormat ? size.grid4 :size.grid3}
                xs={styleFormat ? size.grid4 :size.grid3}
              >
                <Paper elevation={0} className={classes.paperSelect}>
                  <Select
                    name="year"
                    placeholder="Ano"
                    options={yearsOptions.reverse()}
                    menuPortalTarget={document.body}
                    styles={{ ...colourStyles,menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    onChange={(selectedOption) =>
                      onChangeFilters(selectedOption, "year")
                    }
                    value={filters.year}
                    defaultValue={filters.year}
                    components={animatedComponents}
                  />
                </Paper>
              </Grid>
            )}
            {filters.isCalendarFilter?.label === "Calendário" ? (
              <Grid
                item
                xl={styleFormat ? size.grid4 :size.grid3}
                lg={styleFormat ? size.grid4 :size.grid3}
                md={styleFormat ? size.grid4 :size.grid3}
                sm={styleFormat ? size.grid4 :size.grid3}
                xs={styleFormat ? size.grid4 :size.grid3}
              >
                
                  <Calendar
                    selectedDates={{
                      initialDate: JSON.parse(filters.calendar)?.startDate,
                      finalDate: JSON.parse(filters.calendar)?.finishDate,
                    }}
                    handleCalendar={(startDate, endDate) =>
                      onChangeFilters(
                        {
                          startDate: startDate,
                          finishDate: endDate,
                        },
                        "calendar",
                      )
                    }
                  />
                
              </Grid>
            ) : (
              <>
                {cutOffDatesOptions === null ? (
                  <Grid
                    item
                    xl={styleFormat ? size.grid4 :size.grid3}
                    lg={styleFormat ? size.grid4 :size.grid3}
                    md={styleFormat ? size.grid4 :size.grid3}
                    sm={styleFormat ? size.grid4 :size.grid3}
                    xs={styleFormat ? size.grid4 :size.grid3}
                  >
                    <Paper elevation={0} className={classes.center}>
                      <Typography>
                        Não foram encontradas data de corte.
                      </Typography>
                    </Paper>
                  </Grid>
                ) : (
                  <Grid
                    item
                    xl={styleFormat ? size.grid4 :size.grid3}
                    lg={styleFormat ? size.grid4 :size.grid3}
                    md={styleFormat ? size.grid4 :size.grid3}
                    sm={styleFormat ? size.grid4 :size.grid3}
                    xs={styleFormat ? size.grid4 :size.grid3}
                  >
                    <Paper elevation={0} className={classes.paper}>
                      <Select
                        name="cutoffDate"
                        placeholder="Selecione a data de corte"
                        options={cutOffDatesOptions}
                        menuPortalTarget={document.body}
                        styles={{ ...colourStyles,menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        components={animatedComponents}
                        onChange={(selectedOption) =>
                          onChangeFilters(selectedOption, "cutoffDate")
                        }
                        value={JSON.parse(filters.cutoffDate)}
                      />
                    </Paper>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Grid>
        {!loading &&
          !loadingData &&
          (!reward?.performances || reward?.performances?.length === 0) ? (
          <NotFoundWithLottie
            lottieFiles={notFound}
            description="Não foram encontradas premiações."
            strongDescription="Verifique os filtros!"
          />
        ) : (
          <Grid container>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography className={classes.followTitle}>
                Acompanhamento da Premiação
              </Typography>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid container direction="row" justifyContent="space-between">
                {/*Barras de info*/}
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Grid
                    container
                    direction={size.mobile ? "row" : "column"}
                    justifyContent="space-between"
                  >
                    <Grid
                      item
                      xl={size.grid12Mobile6}
                      lg={size.grid12Mobile6}
                      md={size.grid12Mobile6}
                      sm={size.grid12Mobile6}
                      xs={size.grid12Mobile6}
                      style={size.mobile ? {} : { marginRight: 15 }}
                      className={classNames(
                        classes.roundedContainer,
                        classes.itemContent,
                      )}
                      component={Paper}
                      elevation={0}
                    >
                      <Grid
                        container
                        alignItems="center"
                        className={classes.infoBarItens}
                        justifyContent="space-between"
                      >
                        <Typography className={classes.itemTitle}>
                          Premiação Total
                        </Typography>
                        <Typography
                          align={size.mobile ? "left" : "right"}
                          className={classes.reward}
                        >
                          {reward?.totalReward
                            ? reward.totalReward.toLocaleString("pt-br", {
                                style: "currency",
                                currency: "BRL",
                              })
                            : 0}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xl={size.grid12Mobile5}
                      lg={size.grid12Mobile5}
                      md={size.grid12Mobile5}
                      sm={size.grid12Mobile5}
                      xs={size.grid12Mobile5}
                      style={size.mobile ? {} : { marginRight: 15 }}
                      className={classNames(
                        classes.roundedContainer,
                        classes.itemContent,
                      )}
                      component={Paper}
                      elevation={0}
                    >
                      <Grid
                        container
                        alignItems="center"
                        className={classes.infoBarItens}
                        justifyContent="space-between"
                      >
                        <Typography className={classes.itemTitle}>
                          Performance
                        </Typography>
                        <Typography
                          align={size.mobile ? "left" : "right"}
                          className={classes.goal}
                        >
                          {reward?.generalScore}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/*grafico*/}
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Grid container alignItems="center">
                    <Grid
                      item
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      component={Paper}
                      elevation={0}
                      className={classes.roundedContainer}
                    >
                      <Grid container alignItems="center">
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                          <Grid container>
                            <FollowComponent
                              data={reward?.pieChartData}
                              percentage={totalPercentage}
                            />
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                              <Typography
                                align="center"
                                className={classes.meta}
                              >
                                Em relação à meta
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xl={6}
                          lg={6}
                          md={5}
                          sm={6}
                          xs={6}
                          className={classes.legendRight}
                        >
                          <Grid
                            container
                            spacing={2}
                            className={classes.textItensContainer}
                          >
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                              <Grid container>
                                <Grid
                                  item
                                  xl={12}
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                >
                                  <Typography className={classes.titleMeta}>
                                    Km Rodados
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xl={12}
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                >
                                  <Typography className={classes.valueMeta}>
                                    {valueToPositiveFloat(
                                      goal.total,
                                      2,
                                    ).toLocaleString("pt-BR")}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                              <Grid container justifyContent="flex-end">
                                <Grid
                                  item
                                  xl={12}
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                >
                                  <Typography className={classes.titleMeta}>
                                    Meta
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xl={12}
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                >
                                  <Typography className={classes.valueMeta}>
                                    {goal?.monthly.toLocaleString("pt-BR") ?? 0}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* table */}
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <TableResponsive
                columns={columns}
                data={reward?.performances}
                options={{
                  onRowClick: handleSelectRow,
                  rowsPerPage: 10,
                }}
                download={download}
                pointer={true}
                tableName="bonus"
              />
            </Grid>
            {/* button download */}
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={size.mobile ? { marginTop: 20 } : {}}
            >
              <Grid container alignItems="center" justifyContent="flex-end">
                <Button
                  fullWidth={size.mobile}
                  variant="contained"
                  color="primary"
                  className={classes.buttonDetail}
                  onClick={handleExport}
                  disabled={loadingDownload}
                  startIcon={
                    !loadingDownload ? (
                      <GetAppIcon />
                    ) : (
                      <CircularProgress
                        color="inherit"
                        size={20}
                        thickness={6}
                      />
                    )
                  }
                >
                  Download (Exportar)
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </PermissionsGate>
    </Aux>
  );
}
