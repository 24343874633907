import { alpha } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  textBold: {
    fontWeight: "bold",
  },
  primary: {
    color: theme.palette.primary.main,
  },
  titleRoot: {
    margin: 0,
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.gray.extraLight}`,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: '2px',
    color: theme.palette.grey[500],
  },
  legend: {
    margin: `${theme.spacing(2)}px 0`
  },
  btnBack: {
    backgroundColor: theme.palette.background.backgroundButton,
    boxShadow: "none",
    borderRadius: "10px",
    padding: "8px 24px",
    color: theme.palette.text.title,
    "&:hover": {
      backgroundColor: alpha(theme.palette.background.backgroundButton, 0.8),
    },
  }
}));
