import React from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { useController, useFormContext } from "react-hook-form";

import useStyles from "./styles";

export default function ControlledCheckbox({ control, name, checked, ...props }) {
  const classes = useStyles();
  const { control: controlContext } = useFormContext();
  const { field } = useController({
    name,
    control: control ?? controlContext,
  });
  if(typeof checked === 'string') {
    checked = false;
  }
  return (
    <FormControlLabel
      {...props}
      classes={{ label: classes.label }}
      control={
        <Checkbox color="primary" {...field} checked={checked} />
      }      
    />
  );
}
