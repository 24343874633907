import React, { memo } from "react";
import { 
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import FleetChart from "../FleetChart";

// styles
import useStyles from "./styles";

const ModalFleetChart = ({
  showFleetChart,
  handleFleetChart,
  loading,
  chartData
}) => {
  const classes = useStyles();
  const handleClose = () => handleFleetChart(false);

  return (
    <Dialog
      classes={{paper: classes.paper}}
      open={showFleetChart}
      onClose={handleClose}
      fullWidth={true}
      scroll={"body"}
      maxWidth="lg"
      aria-labelledby="Gráfico"
      aria-describedby="Combustível, RPM, Altitude e Velocidade"
    >
      <IconButton 
        className={classes.customizedButton}
        onClick={handleClose}>
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid
          container
          spacing={3}
          data-cy="allElements"
          className={classes.container}
        >
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            {loading || !chartData ? (
              <Grid container spacing={2}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Typography className={classes.loading}>Carregando...</Typography>
                  <Skeleton width="100%" height={250} />
                </Grid>
              </Grid>
            ) : (
              <FleetChart 
                loading={loading} 
                data={chartData} 
              />
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}


export default memo(ModalFleetChart);