import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  table: {
    
  },
  textBold: {
    fontWeight: "bold"
  },
  textInfo: {
    fontStyle: "italic",
    color: theme.palette.text.secondary
  },
  actionContent: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  actionButton: {
    transition: '0.3s',
    "&:hover": {
      cursor: 'pointer',
      fontSize: 28,
    },
  }
}));
