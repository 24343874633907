import React, { useEffect, useRef } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import ReactToPrint from "react-to-print";
import classNames from "classnames";
import jwt_decode from "jwt-decode";
import FleetPerformance from "components/FleetPerformance";
import Map from "components/Map";
import PerformanceComponent from "components/PerformanceComponent";
import Pressure from "components/Pressure";
import StatisticsCards from "components/StatisticsCards";
import VehiclePerformanceDetails from "components/VehiclePerformanceDetails";
import useStyles from "./styles";
import { useAppSelector } from "redux/store";
import { useState } from "react";
import {
  getFleetLocationsCompact
} from "services/fleetPerformance";

export default function DashboardPrint(props) {
  const { print: state } = useAppSelector(state => state.bonusFollow)
  const [locations, setLocations] = useState(null)
  const [loading, setLoading] = useState(false)
  const componentRef = useRef();
  const classes = useStyles();
  const dataToPrint = jwt_decode(state);
  const {
    consumption,
    indicators,
    indicatorsByOperation,
    indicatorsPressure,
    selectedVehicles,
    statistics,
    summary,
    vehicleProps,
    vehicleId,
    initialDate,
    finalDate,
  } = dataToPrint;

  const fetchFleetLocationsCompact = async (vehicleId, initialDate, finalDate) => {
    setLoading(true)
    if (state) {
      vehicleId = [String(vehicleId)];
    }
    try {
      const response = await getFleetLocationsCompact(
        vehicleId,
        initialDate,
        finalDate,
      );

      if (response.data.locations.length) {
        const newLocations = response.data.locations.map(location => {
          return {
            device: location.device,
            locations: location.locations.map(sublocation => {
              return {
                date: sublocation[0],
                lat: sublocation[1],
                lon: sublocation[2]
              };
            })
          };
        });
        const transformed = {
          locations: newLocations
        };
        setLocations(transformed.locations);
      }
    } catch (err) {
      setLocations([]);
      console.log(err);
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    if (state) {
      fetchFleetLocationsCompact(vehicleId.id, initialDate, finalDate)
    }
  }, [state])

  const handleMapReady = () => {
    setTimeout(() => {
      document.getElementById('print-button').click();
    }, 2000)
  }

return (
  <>
    <ReactToPrint
      trigger={() => (
        <Button id="print-button"></Button>
      )}
      content={() => componentRef.current}
      onAfterPrint={() => {
        localStorage.removeItem('printData');
        window.opener = null;
        window.open("", "_self");
        window.close();
      }}
    />
    {loading && (
    <Grid>
      <Typography>Carregando</Typography>
    </Grid>
    )}
    <Grid
      container
      spacing={1}
      ref={componentRef}
    >
      <Grid item xs={12} sm={12} className={classes.spacingContainer}>
        {vehicleProps && <VehiclePerformanceDetails vehicleProps={vehicleProps} />}
      </Grid>
      <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
        <Typography variant="h4" className={classes.cardTitle}>Desempenho</Typography>
        <Grid container alignItems="center" className={classNames(classes.box, classes.boxPadding)}>
          <PerformanceComponent
            data={summary}
            selectedVehicles={selectedVehicles}
            indicators={indicators}
            print={true}
          />
        </Grid>
      </Grid>
      <Grid item xl={9} lg={9} md={9} sm={9} xs={9}>
        <Typography variant="h4" className={classes.cardTitle}>Indicadores</Typography>
        <Grid container alignItems="center" className={classNames(classes.box, classes.boxPadding)}>
          <FleetPerformance
            loading={false}
            indicators={indicators}
            consumption={consumption}
            summary={summary}
            setShowFleetChart={false}
            indicatorsByOperation={indicatorsByOperation}
            print={true}
          />
        </Grid>
      </Grid>
      <Grid item xl={4} lg={4} md={4} sm={4} xs={4} className={classes.height100}>
        <Typography variant="h4" className={classes.cardTitle}>Uso Pressão do Acelerador</Typography>
        <Grid container alignItems="center" className={classes.box}>
          <Pressure
            data={indicatorsPressure}
            print={true}
          />
        </Grid>
      </Grid>
      <Grid item xl={8} lg={8} md={8} sm={8} xs={8} className={classes.height100}>
        <Typography variant="h4" className={classes.cardTitle}>Estatísticas</Typography>
        <Grid container alignItems="center" className={classNames(classes.box, classes.boxPadding)}>
          <StatisticsCards
            statistics={statistics}
            print={true}
          />
        </Grid>
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Typography variant="h4" className={classes.cardTitle}>Trajeto da Viagem</Typography>
        <Grid container alignItems="center">
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            {/* {locations && <Map data={locations} print={true} />} */}
            <Map data={locations} print={true} onReady={handleMapReady} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </>
);
}


