/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from "react";
import {
  GoogleApiWrapper,
  InfoWindow,
  Map,
  Marker,
  Polyline,
} from "google-maps-react";
import Paper from "@material-ui/core/Paper";
import useStyles from "./styles";
import googleMapStyle from "./mapStyle";
import { useTheme } from "@material-ui/styles";
import { v4 as uuidv4 } from 'uuid';

const MapContainer = (props) => {
  const { data } = props;
  var theme = useTheme();
  const classes = useStyles();
  const [mapBounds, setMapBounds] = useState({});
  const [mapLatLng, setMapLatLng] = useState([]);
  const [mapMarkers, setMapMarkers] = useState([]);

  const [configs, setConfigs] = useState({
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
  });

  const onMapClicked = () => {
    if (configs.showingInfoWindow) {
      setConfigs({
        showingInfoWindow: false,
        activeMarker: {},
      });
    }
  };

  const onMarkerClick = (props, marker, e) => {
    setConfigs({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    });
  }

  const _mapLoaded = (mapProps, map) => {
    map.setOptions({
      styles: googleMapStyle,
    });
    let points = mapLatLng.flat();
    var bounds = new props.google.maps.LatLngBounds();
    for (var i = 0; i < points.length; i++) {
      bounds.extend(points[i]);
    }
    setMapBounds(bounds);

    if (props.onReady) {
      props.onReady();
    }
  };

  const fetchLocations = (data) => {
    let latLng = [];
    let markers = [];
    if(data) {
      latLng = data.map((route, index) => {
        const firstPosition = route.locations[route.locations.length - 1];
        const lastPosition = route.locations[0];
        markers.push([{
          content: route.device,
          label: "A",
          lat: lastPosition.lat,
          lng: lastPosition.lon,
        }, {
          content: route.device,
          label: "B",
          lat: firstPosition.lat,
          lng: firstPosition.lon,
        }]);
        return route.locations.map((p) => {
          return { lat: p.lat, lng: p.lon }
        });
      });
      setMapMarkers(markers);
      setMapLatLng(latLng);
    }
  };

  useEffect(() => {
    fetchLocations(data);
  }, [data]);

  return (
    <Paper elevation={0} className={classes.container}>
      {mapLatLng && (
        <Map
          style={{ borderRadius: 28, width: "100%", height: "100%" }}
          google={props.google}
          zoom={6}
          disableDefaultUI={props.print}
          draggable={!props.print}
          onClick={onMapClicked}
          initialCenter={{ lat: -13.702797, lng: -69.6865109 }}
          onReady={(mapProps, map) => _mapLoaded(mapProps, map)}
          bounds={mapBounds}
        >
          {mapMarkers.map((markers, index) => {
            return markers.map((marker, i) => {
              let uuid = uuidv4();
              return (
                <Marker
                  onClick={onMarkerClick}
                  key={`marker-${uuid}-${index}`}
                  title={marker.content}
                  name={marker.content}
                  label={marker.label}
                  position={{ lat: marker.lat, lng: marker.lng }}>
                  <InfoWindow
                    marker={configs.activeMarker}
                    visible={configs.showingInfoWindow}>
                    <div>
                      <h1>{marker.content}</h1>
                    </div>
                  </InfoWindow>
                </Marker>
              );
            });
          })}
          {mapLatLng.map((locations, index) => {
            if (props.data[index]) {
              return (
                <Polyline
                  key={props.data[index].device}
                  path={locations}
                  strokeColor={theme.palette.primary.main}
                  strokeWeight={3} />
              );
            } else {
              return null;
            }
          })}
        </Map>
      )}
    </Paper>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyApnbkideYXxPEwwRnWPEZoIciQynUABJI",
  language: "pt-BR",
})(MapContainer);
