export const defaultOperation = {
  id: 0,
  value: 0,
  name: "TODA MINHA FROTA",
  label: "TODA MINHA FROTA",
};

export const allSelected = {
  id: 0,
  value: 0,
  name: "Selecionar todos",
  label: "Selecionar todos",
};

export const emptyOperation = {
  id: -1,
  value: -1,
  name: "SELECIONE",
  label: "SELECIONE",
};


export const selectFilterOptions = [
  {
    id: 0,
    label: "Data de Corte",
    name: "Data de Corte",
    value: 0,
  },
  {
    id: 1,
    label: "Calendário",
    name: "Calendário",
    value: 1,
  },
];

export const defaultYearOption = {
  value: new Date().getFullYear(),
  label: new Date().getFullYear(),
};

