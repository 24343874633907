import React from 'react';
import { Grid, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AccessTime, CheckCircle, Error, Loop } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  checkIcon: {
    color: theme.palette.success.main,
  },
  errorIcon: {
    color: theme.palette.warning.main,
  },
  running: {
    color: theme.palette.primary.main,
  },
  waitingIcon: {
    color: theme.palette.warning.main,
  },
}));

const RecalculationStatus = (props) => {
  const classes = useStyles();
  const getStatus = (value) => {
    switch (value) {
      case "waiting":
        return (
          <Tooltip title="Recálculo aguardando para ser executado" aria-label="recalculo-aguardando">
            <AccessTime 
              size={22} 
              className={classes.waitingIcon} 
            />
          </Tooltip>
        );
      case "running":
        return (
          <Tooltip title="Recálculo em andamento" aria-label="recalculo-andamento">
            <Loop 
              size={22} 
              className={classes.running} 
            />
          </Tooltip>
        );
      case "finished":
        return (
          <Tooltip title="Recálculo concluído" aria-label="recalculo-concluido">
            <CheckCircle 
              size={22} 
              className={classes.checkIcon} 
            />            
          </Tooltip>
        );
      case "failed":
        return (
          <Tooltip title="Recálculo falhou" aria-label="recalculo-falhou">
            <Error
              size={22} 
              className={classes.errorIcon} 
            />            
          </Tooltip>
        );
      default:
        return false;
    }
  };

  return (
    <Grid container direction="column" alignItems="center">
      {getStatus(props.value)}
    </Grid>
  );
}

export default RecalculationStatus;