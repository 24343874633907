import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import useStyles from './styles';
import theme from 'themes/gobrax';

const CustomTableViewCol = ({ 
  columns, 
  options, 
  components = {}, 
  onColumnUpdate, 
  updateColumns,
  handleSelectAll,
}) => {
  const classes = useStyles();
  const textLabels = options.textLabels.viewColumns;
  const CheckboxComponent = components.Checkbox || Checkbox;
  const [ checkAll, setCheckAll ] = useState(true);
  
  const CustomCheckbox = withStyles({
    root: {
      color: theme.palette.secondary.main,
      '&$checked': {
        color: theme.palette.secondary.main,
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  const handleTotalSelected = () => {
    let totalSelected = columns.filter(obj => obj.display === "true" && !obj.hideColumn).length;
    let totalValidLenght = 0;
    columns.forEach(obj => {
      if(!obj.hideColumn) {
        totalValidLenght += 1;
      }
    })
    setCheckAll(totalSelected === totalValidLenght);
    handleSelectAll(totalSelected === totalValidLenght);
  };

  const handleColChange = useCallback((index) => {
    onColumnUpdate(index);
  }, [onColumnUpdate]);

  const selectAll = () => {
    let newColumns = [];
    columns.forEach(col => {
      newColumns.push(Object.assign({}, col, {display: checkAll ? 'false' : 'true'}));
    });
    setCheckAll(!checkAll);
    handleSelectAll(!checkAll);
    updateColumns(newColumns);
  };

  useEffect(() => {
    handleTotalSelected();
  }, [columns]);

  return (
    <FormControl 
      component={'fieldset'} 
      className={classes.root} 
      aria-label={textLabels.titleAria}
    >
      <Typography variant="caption" className={classes.title}>
        {textLabels.title}
      </Typography>
      <FormGroup className={classes.formGroup}>
        <FormControlLabel
          classes={{
            root: classes.formControl,
            label: classes.label,
          }}
          control={
            <CustomCheckbox
              data-description="table-view-col"
              className={classes.checkbox}
              classes={{
                root: classes.checkboxRoot,
                checked: classes.checked,
              }}
              onChange={() => selectAll()}
              checked={checkAll}
              value={checkAll}
            />
          }
          label="Selecionar Todos"
        />
        {columns.map((column, index) => {
          return (
            column.display !== 'excluded' && 
            column.viewColumns !== false && (
              <FormControlLabel
                key={index}
                classes={{
                  root: classes.formControl,
                  label: classes.label,
                }}
                control={
                  <CheckboxComponent
                    color="primary"
                    data-description="table-view-col"
                    className={classes.checkbox}
                    classes={{
                      root: classes.checkboxRoot,
                      checked: classes.checked,
                    }}
                    onChange={() => handleColChange(index)}
                    checked={column.display === 'true'}
                    value={column.name}
                  />
                }
                label={column.label}
              />
            )
          );
        })}
      </FormGroup>
    </FormControl>
  );
};

CustomTableViewCol.propTypes = {
  /** Columns used to describe table */
  columns: PropTypes.array.isRequired,
  /** Options used to describe table */
  options: PropTypes.object.isRequired,
  /** Callback to trigger View column update */
  onColumnUpdate: PropTypes.func,
  /** Extend the style applied to components */
  classes: PropTypes.object,
};

export default CustomTableViewCol;
