import React, {useState} from "react";
import useStyles from "./styles";
import { Button, Grid, Input, Paper, Typography, } from "@material-ui/core";
import { GetApp, Publish } from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

import Aux from "hoc/auxiliar";
import BackButton from "components/BackButton";
import ErrorsTable from "components/ErrorsTable";
import PermissionsGate from "components/PermissionsGate";
import {downloadFile, getBase64} from 'helpers/functions';

import { importTemplateMassVehicles, createMassVehicles } from "services/vehicle";

import Utils from "helpers/utils";

const MAXNAME_LEN = 23
const DEFAULT_FILENAME = "veiculos-em-massa.xls";

const MassAddVehicle = () => {
  const theme = useTheme();
  const classes = useStyles();
  const [errors, setErrors] = useState([]);
  const [uppingFile, setUppingFile] = useState(false)

  const handleClickDownloadTemplate = async () => {
    try {
      const response = await importTemplateMassVehicles();
      downloadFile(response.data.b64, "vnd.ms-excel", DEFAULT_FILENAME);
    } catch (error) {
      toast.error(
        "Erro ao fazer download do arquivo. Tente novamente dentro de alguns instantes.",
      );
    }
  };

  const handleClickCreateMassVehicles = async (e) => {
    const file = e.target.files[0];
    const base64 = await getBase64(file);
    const fileName = Utils.getFileNameTrunc(file.name, MAXNAME_LEN);

    Swal.fire({
      title: "Importação em Massa",
      text: `Você selecionou o arquivo "${fileName}".\nDeseja iniciar a importação?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: theme.palette.primary.main,
      cancelButtonColor: theme.palette.error.main,
      confirmButtonText: "Iniciar",
      cancelButtonText: "Cancelar",

    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setUppingFile(true);
          const response = await createMassVehicles({
            "b64": base64
          });
          if (response.status === 200 && response.data.response) {
            setErrors(response.data.response);
          } else {
            toast.error("Erro ao criar veículos. Entre em contato com o suporte.");
          }
        } catch (error) {
          toast.error(
            "Erro ao criar veículos em massa. Tente novamente dentro de alguns instantes.",
          );
        }
        setUppingFile(false);
      }
    });
  };

  return (
    <Aux>
      <PermissionsGate scopes={['can_import_vehicles']}>
        <Typography variant="h3" className={classes.title}>Cadastro em Massa de Veículos</Typography>
        {uppingFile && (
        <Grid justifyContent="center" container spacing={4}>
          <Grid item xs={12} md={6} sm={6} xl={6} lg={6}>
            <span>A importação em massa está ocorrendo, aguarde que estamos criando um a um para você.</span>
          </Grid>
        </Grid>
        )}
        <Paper elevation={0} className={classes.container}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid container justifyContent="space-around">
              <Grid item xl={4} lg={4} md={5} sm={6} xs={12}>
                <Button
                  variant="contained"
                  onClick={handleClickDownloadTemplate}
                  startIcon={<GetApp />}
                  className={classes.btItem}
                >
                  Download Template para Importação
                </Button>
              </Grid>
              <Grid item xl={4} lg={4} md={5} sm={6} xs={12}>
                <label htmlFor="contained-button-file">
                  <Input 
                    inputProps={{ accept: '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel' }}
                    id="contained-button-file" 
                    type="file"
                    onChange={handleClickCreateMassVehicles}
                    onClick={(event)=> { 
                      event.target.value = null
                    }}
                    className={classes.inputHidden} />
                  <Button
                    component="span"
                    variant="contained"
                    type="file"
                    startIcon={<Publish />}
                    className={classes.btItem}
                  >
                    Enviar Arquivo Veículos em Massa
                  </Button>
                </label>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        {errors.length > 0 && (
          <Grid container>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <ErrorsTable data={errors} tableName="mass-add-vehicle" />
            </Grid>
          </Grid>
        )}
        <Grid justifyContent="flex-end" container spacing={2} className={classes.buttons}>
          <Grid item xs={12} md={3} sm={4} xl={1} lg={2}>
            <BackButton />
          </Grid>
        </Grid>
      </PermissionsGate>
    </Aux>
  );
};

export default MassAddVehicle;
