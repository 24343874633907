import React, { useState, useEffect } from "react";
import {
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  Typography,
} from "@material-ui/core";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";
import {
  mdiAccountLock,
  mdiAccountMultiple,
  mdiClipboardAccount,
  mdiContactlessPayment,
  mdiDotsGrid,
  mdiFactory,
  mdiTruckCheck,
  mdiTruckOutline,
  mdiPokeball,
} from '@mdi/js';
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import { getMenuStructure } from "./menuStructure";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";
import PermissionsGate from "components/PermissionsGate";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
  closeSidebar,
  openSidebar,
} from "../../context/LayoutContext";
import auth from '../../services/auth'
import { store } from "redux/store";
import { clearFilters } from "redux/features/driversMonitoringSlice";
import { clearComparisonPagination } from "redux/features/comparisonSlice";
import { clearBonusFilters, clearPrintData } from "redux/features/bonusFollowSlice";
import { clearConsolidatedFilters } from "redux/features/consolidatedSlice";
import {useWindowSize} from "../../hooks/useWindowsSize";

auth.me()

const adminStructure = [
  { 
    id: "Customers", 
    label: "Clientes", 
    permission: "can_view_customers", 
    link: "/fleet/customer/customer-list", 
    icon: mdiFactory,
  },
  {
    id: "Drivers",
    label: "Motoristas",
    permission: "can_view_drivers", 
    link: "/fleet/driver/all-drivers",
    icon: mdiClipboardAccount,
  },
  {
    id: "Vehicles",
    label: "Veículos",
    permission: "can_view_vehicles", 
    link: "/fleet/vehicle/list-vehicles",
    icon: mdiTruckCheck,
  },
  {
    id: "Profile",
    label: "Perfis",
    permission: "can_view_profiles", 
    link: "/fleet/profile/list-profiles",
    icon: mdiTruckOutline,
  },
  { 
    id: "Users", 
    label: "Usuários", 
    permission: "can_view_users", 
    link: "/fleet/user/list-users", 
    icon: mdiAccountMultiple,
  },
  { 
    id: "UserProfile", 
    label: "Perfil de Usuários", 
    permission: "can_view_user_role", 
    link: "/fleet/role/list-roles", 
    icon: mdiAccountLock,
  },
  {
    id: "Devices",
    label: "Unidades Eletrônicas",
    permission: "can_view_devices", 
    link: "/fleet/device/list-devices",
    icon: mdiContactlessPayment,
  },
  {
    id: "RewardGroup",
    label: "Grupo de Bonificação",
    permission: "can_view_rewardgroup", 
    link: "/fleet/rewardGroup/list-rewardGroup",
    icon: mdiDotsGrid,
  },
  {
    id: "TelemetryData",
    label: "Conectividade",
    permission: "can_view_telemetrydata", 
    link: "/fleet/telemetrydata",
    icon: mdiPokeball,
  },
];

function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();
  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);
  const pathArray = window.location.pathname.split('/');

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    if (!pathArray.includes('driversMonitoring')) {
      store.dispatch(clearFilters())
    }
    if (!pathArray.includes('comparison')) {
      store.dispatch(clearComparisonPagination())
    }
    if (!pathArray.includes('bonus')) {
      store.dispatch(clearBonusFilters())
    }
    if (!pathArray.includes('consolidated')) {
      store.dispatch(clearConsolidatedFilters())
    }
    if (!pathArray.includes('print')) {
      store.dispatch(clearPrintData())
    }
    handleWindowWidthChange();
    
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });
  
  const size = useWindowSize()

  return (
    <Drawer
      onMouseEnter={size.mobile?()=>{}:() => openSidebar(layoutDispatch)}
      onMouseLeave={size.mobile?()=>{}:() => closeSidebar(layoutDispatch)}
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
      onClose={size.mobile? () => toggleSidebar(layoutDispatch): ()=>{}}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
      >
        <List data-cy="sideBarItens" className={classes.listMenu}>
          {getMenuStructure().map((link, index) => (
            <SidebarLink
              onClose={size.mobile? () => toggleSidebar(layoutDispatch): ()=>{}}
              key={`sidebarLink${index}`}
              location={location}
              isSidebarOpened={isSidebarOpened}
              {...link}
            />
          ))}
        </List>
        <PermissionsGate scopes={["can_view_admin_area"]}>
          <>
            <Divider />
            <Grid container alignItems="center">
              <Grid item xs style={{
                marginTop: theme.spacing(2),
                marginLeft: theme.spacing(2),
              }}>
                {isSidebarOpened && <Typography
                  style={{
                    fontSize: "0.7rem",
                    fontWeight: "bold",
                    color: theme.palette.text.secondary
                  }}>
                  Área Administrativa
                </Typography>}
              </Grid>
            </Grid>
            <List data-cy='sideBarItens' className={classes.listMenu}>
              {adminStructure.map((link) => (
                <PermissionsGate key={`sidebarLink${link.id}`} scopes={[link.permission]}>
                  <SidebarLink
                    onClose={size.mobile? () => toggleSidebar(layoutDispatch): ()=>{}}
                    location={location}
                    isSidebarOpened={isSidebarOpened}
                    {...link}
                  />
                </PermissionsGate>
              ))}
            </List>
          </>
        </PermissionsGate>
      </Grid>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
