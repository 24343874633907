import React from "react";
import Grid from "@material-ui/core/Grid";
import { Skeleton } from "@material-ui/lab"
import { makeStyles } from "@material-ui/core/styles";
import Aux from "../../hoc/auxiliar";

const FormDriverSkeleton = () => {
  const classes = useStyles();
  return (
    <Aux>
      <Grid container spacing={2} className={classes.container}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Skeleton variant="rect" height={700} className={classes.bordered} />
        </Grid>
      </Grid>
    </Aux>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2),
  },
  bordered: {
    borderRadius: "22px",
  },
}));

export default FormDriverSkeleton;
