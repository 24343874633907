import api from "./api";

const itensUrl = (itens) => {
  let partialUrl = "";
  if (itens) {
    for (let i = 0; i < itens.length; i++) {
      if (i + 1 === itens.length) {
        partialUrl += `${itens[i]}`;
      } else {
        partialUrl += `${itens[i]},`;
      }
    }
  }
  return partialUrl;
};

export const getAllRecalculations = async (initialDate, finalDate) => {
  try {
    const response = await api.get(`/get-recalc?startDate=${initialDate}&endDate=${finalDate}`);
    return response;
  } catch (err) {
    return err;
  }
};

export const getRecalculationById = async (recalcId) => {
  try {
    const response = await api.get(`/get-recalc?recalcid=${recalcId}`);
    return response;
  } catch (err) {
    return err;
  }
};

export const createRecalculation = async (devicesList, email, initialDate, finalDate) => {
  const partialUrl = itensUrl(devicesList);
  let params = `vehicles=${partialUrl}&user=${email}&startDate=${initialDate}&endDate=${finalDate}`;
  try {
    const response = await api.get(`/recalc?${params}`);
    return response;
  } catch (err) {
    throw err;
  }
};
