import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage'
import globalSlice, { logout } from './globalSlice';
import bonusFollowSlice, { setBonusFilters } from './features/bonusFollowSlice';
import driversMonitoringSlice, { setFilters } from './features/driversMonitoringSlice';
import customerProfileSlice from './features/customerProfileSlice';
import consolidatedSlice from './features/consolidatedSlice';
import comparisonSlice from './features/comparisonSlice';
import { openDB } from 'idb';

const rootReducer = combineReducers({
  global: globalSlice,
  bonusFollow: bonusFollowSlice,
  driversMonitoring: driversMonitoringSlice,
  customerProfile: customerProfileSlice,
  consolidated: consolidatedSlice,
  comparison: comparisonSlice,
});

const idbAdapter = {
  async getItem(key) {
    return (await dbPromise).get('keyval', key);
  },
  async setItem(key, value) {
    return (await dbPromise).put('keyval', value, key);
  },
  async removeItem(key) {
    return (await dbPromise).delete('keyval', key);
  },
  async getAllKeys() {
    return (await dbPromise).getAllKeys('keyval');
  },
};

const dbPromise = openDB('redux-storage', 1, {
  upgrade(db) {
    db.createObjectStore('keyval');
  },
});

const persistConfig = {
  key: 'root',
  version: 1,
  storage: storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: { warnAfter: 128 },
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, setFilters.type, setBonusFilters.type],
        warnAfter: 128,
      },
    }),
});


export const persistor = persistStore(store);

export const useAppDispatch = () => useDispatch();
export const useAppSelector = useSelector;
export const resetStore = async () => {
  await persistor.purge();
  store.dispatch(logout());
  await persistor.flush();
};
