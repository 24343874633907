import gobraxTheme from "./gobrax";

import { createTheme } from "@material-ui/core";

import { ptBR } from '@material-ui/core/locale';

const overrides = {
  typography: {
    fontFamily: [
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif'
    ].join(','),
    fontFamilySecondary: [
      'Lato',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif'
    ].join(','),
    h1: {
      fontSize: "2rem",
    },
    h2: {
      fontSize: "1.8rem",
    },
    h3: {
      fontSize: "1.5rem",
    },
    h4: {
      fontSize: "1.3rem",
    },
    h5: {
      fontSize: "0.90rem",
    },
    h6: {
      fontSize: "0.8rem",
    },
    body1: {
      fontSize: "0.9rem",
    },
    body2: {
      fontSize: "0.8rem",
    }
  },
};

export default {
  default: createTheme({ ...gobraxTheme, ...overrides }, ptBR ),
};
