import * as yup from "yup";

export default yup.object().shape({
  name: yup.string().required("Campo obrigatório"),
  email: yup.string().email('O campo deve ser um e-mail válido').required("Campo obrigatório"),
  password: yup
    .string()
    .required('Campo obrigatório')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Deve conter 8 caracteres, uma maiúscula, uma minúscula, um número e um caractere especial"
    ),
  role: yup.object().required("Campo obrigatório").nullable(),
});
