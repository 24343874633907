import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  itemIcon: {
    fontSize: "1rem",
    border: `1px solid ${theme.palette.gray.light}`,
    borderRadius: "50%",
  },
  fontBold: {
    fontWeight: "bold",
  }
}));
