import React from "react";
import { Button, Grid, Paper } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import ShowChart from "@material-ui/icons/ShowChart";
import classNames from "classnames";
import IndicatorSkeleton from "components/Skeletons/IndicatorSkeleton";
import Indicator from "components/Indicator";
import CustomIndicator from "components/CustomIndicator";
import PermissionsGate from "components/PermissionsGate";
import useStyles from "./styles";
import "@splidejs/splide/dist/css/splide.min.css";
import { useWindowSize } from "hooks/useWindowsSize";
import LoadingComponent from "components/LoadingComponent";

const FleetPerformance = ({
  indicators,
  loading,
  setShowFleetChart,
  indicatorsByOperation,
  print,
}) => {
  const classes = useStyles();
  const size = useWindowSize();
  let indicatorList = [...indicators];

  return (
    <Paper
      elevation={0}
      className={classNames(classes.root, { [classes.print]: print })}
    >
      <Grid container alignItems="center" data-cy="infoFrotaPorcentagens">
        {loading.indicators ? (
          <IndicatorSkeleton />
        ) : !indicators || indicatorList.length === 0 ? (
          <Alert severity="info">
            <AlertTitle>Atenção!</AlertTitle>
            Não foram encontrados indicadores para a frota selecionada,{" "}
            <strong>verifique os filtros.</strong>
          </Alert>
        ) : (
          <Grid container spacing={1}>
            {size.mobile ? null : (
              <PermissionsGate scopes={["can_view_dashboard_chart"]}>
                {!print && (
                  <Grid
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    xs={12}
                    className={classes.containerBtChart}
                  >
                    <Button
                      onClick={() => setShowFleetChart(true)}
                      variant="contained"
                      className={classes.chartButton}
                    >
                      <ShowChart fontSize="small" /> Gráfico
                    </Button>
                  </Grid>
                )}
              </PermissionsGate>
            )}
            <Grid item xl={12} lg={12} md={12} xs={12}>
              {loading.statistics ? (
                <LoadingComponent />
              ) : !print ? (
                <Splide
                  options={{
                    type: "slide",
                    perPage: size.mobile
                      ? Math.trunc(size.width / 200)
                      : Math.trunc(size.width / 200),
                    rewind: true,
                    pagination: false,
                  }}
                  className={classes.splide}
                >
                  {indicatorList.map((ind, index) => {
                    const hasBonus = indicatorsByOperation.find(
                      (o) => o === ind.name,
                    );
                    return (
                      <SplideSlide key={index} >
                        {ind.name === "throttlePressureScore" ? (
                          <CustomIndicator
                            indicator={ind}
                            hasBonus={hasBonus}
                          />
                        ) : (
                          <Indicator indicator={ind} hasBonus={hasBonus} />
                        )}
                      </SplideSlide>
                    );
                  })}
                </Splide>
              ) : (
                <Grid container spacing={0}>
                  {indicatorList.map((ind, index) => {
                    const hasBonus = indicatorsByOperation.find(
                      (o) => o === ind.name,
                    );
                    return (
                      <Grid
                        item
                        key={index}
                        xl={3}
                        lg={3}
                        md={3}
                        sm={3}
                        xs={3}
                        className={classes.itemToPrint}
                      >
                        {ind.name === "throttlePressureScore" ? (
                          <CustomIndicator
                            indicator={ind}
                            hasBonus={hasBonus}
                          />
                        ) : (
                          <Indicator
                            indicator={ind}
                            hasBonus={hasBonus}
                            print={print}
                          />
                        )}
                      </Grid>
                    );
                  })}
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default FleetPerformance;
