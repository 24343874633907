import React, { useCallback, useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Paper,
} from '@material-ui/core';
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { toast } from 'react-toastify';

// helpers
import makeColumns from './tableColumns';
import { defaultOperation } from "helpers/follow";
import { formatVehicleGroups, generateRange, getYearsOptions } from "helpers/operations";

//services
import { getDrivers, getDriversByOperation } from "services/driver";
import { getDriversMonitoring } from 'services/drivers';
import { getOperations } from "services/operations";
import { getCustomersById } from 'services/customers';

// components
import Aux from 'hoc/auxiliar';
import Table from "components/Table";
import NotFoundWithLottie from "components/NotFoundWithLottie";
import DriversMonitoringSkeleton from 'components/Skeletons/DriversMonitoringSkeleton';
import PermissionsGate, { hasPermission } from "components/PermissionsGate";
import Infobox from 'components/Infobox';

// styles
import useStyles from './styles';
import { colourStyles } from './colourStyles';
import notFound from 'assets/lottie/sad.json';

// redux
import { useAppDispatch, useAppSelector, store } from 'redux/store';
import { saveCustomerProfile } from 'redux/features/customerProfileSlice';
import { useSelectDrivers } from 'context/useSelectDrivers';
import { clearFilters, setFilters, setPerformancesReducer, setStepAdd, setPaginationReducer } from 'redux/features/driversMonitoringSlice';

export default function DriversMonitoring() {
  const { currentCustomer } = useAppSelector( state => state.global.user)
  const dispatch = useAppDispatch()
  const { profile } = useAppSelector((state) => state.customerProfile);
  const { performances, fields, filters, pagination } = useAppSelector((state) => state.driversMonitoring);
  const TODAY = new Date()
  const history = useHistory()
  const classes = useStyles()
  const animatedComponents = makeAnimated()
  const yearsOptions = getYearsOptions()
  const dateFormatTemplate = "yyyy-MM-dd'T'HH:mm:ssXX"

  const [loading, setLoading] = useState(false)
  const [loadingData, setLoadingData] = useState(false)
  const [allDrivers, setAllDrivers] = useState(null)
  const [operationsOptions, setOperationsOptions] = useState([])

  const { cutOffDatesOptions, setCutOffDatesOptions } = useSelectDrivers()

  const handleSelectRow = (data) => {
    if (!hasPermission({ scopes: ['can_view_drivers_follow_details'] })) {
      return false
    }
    const driverId = data[Object.keys(data).length - 1]
    const driverDetails = performances.find(
      (item) => item.id === driverId,
    );
    const driverName = driverDetails.driverName
    const vehiclesMonitorng = driverDetails?.vehiclesMonitorng
    if (!vehiclesMonitorng || vehiclesMonitorng.lenght === 0) {
      toast.error("Motorista sem viagens registradas.")
      return;
    }
    const dataTable = vehiclesMonitorng.map((trip) => ({
      ...trip,
      id: trip.vehicleId,
    }))
    dispatch(setStepAdd())
    history.push({
      pathname: "/fleet/driversMonitoring/details",
      state: {
        data: dataTable,
        name: driverName,
        driverId: driverId,
      },
    })
  }

  const handleCutOffDates = useCallback((customerOptions, year, isInitialRender = true) => {
    const { starting_day, period, finishing_day } = customerOptions
    const currentYear = year.value
    if (starting_day && finishing_day && !isNaN(period) && currentYear) {
      const cutOffDate = generateRange(starting_day, finishing_day, period, currentYear);
      setCutOffDatesOptions(cutOffDate);
      if (fields.step === 0) {
        return
      } else {
        const selectedMonth = TODAY.getDate() > finishing_day ? TODAY.getMonth() + 1 : TODAY.getMonth()
        dispatch(setFilters({ selectedOption: isInitialRender ? cutOffDate[selectedMonth] : null, name: 'cutoffDate' }))
      }
    } else {
      setCutOffDatesOptions(null);
      dispatch(setFilters({ selectedOption: null, name: 'cutoffDate' }))
    }
  },[])

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const driversResponse = await getDrivers(currentCustomer);
      const operationsResponse = await getOperations(currentCustomer);
      const customersResponse = await getCustomersById(currentCustomer);
      
      if (driversResponse.status !== 200) {
        throw new Error("Error fetching drivers");
      }

      if (operationsResponse.status !== 200) {
        throw new Error("Error fetching operations");
      }

      if (customersResponse.status !== 200) {
        throw new Error("Error fetching customers");
      }

      setAllDrivers(driversResponse.data.drivers);
      const operationData = operationsResponse?.data?.data?.operation;
      if (operationData) {
        const flatArrayAllVehicles = formatVehicleGroups(operationData)
          .flatMap(item => item.vehicles);
        const newOperation = { ...defaultOperation, vehicles: flatArrayAllVehicles };
        const newArrayGroups = [newOperation, ...formatVehicleGroups(operationData)];
        setOperationsOptions(newArrayGroups);
      }
      const customersOptions = customersResponse.data.customers[0];
      dispatch(saveCustomerProfile(customersOptions))
      handleCutOffDates(customersOptions, filters.year)
    } catch (err) {
      toast.error("Erro ao buscar dados. Contate o suporte.");
    } finally {
      setLoading(false);
    }
  }, []);

  const handleDataByOperation = async (operation, cutoffDate) => {
    setLoading(true);
    try {
      const { id: operationId, value } = operation;
      const { startDate, finishDate } = JSON.parse(cutoffDate);
      const formattedStartDate = format(new Date(startDate), dateFormatTemplate);
      const formattedFinishDate = format(new Date(finishDate), dateFormatTemplate);

      let drivers = [];

      if (value === 0) {
        drivers = allDrivers;
      } else {
        const response = await getDriversByOperation(
          operationId,
          formattedStartDate,
          formattedFinishDate
        );

        if (response.status === 200 || response.status === 201) {
          drivers = response.data.drivers || [];
        } else {
          throw new Error("Error fetching drivers");
        }
      }
      const listVehicleByOperation = operation.vehicles?.map((elm) => elm.vehicleId)

      const modelFormattedCutoffDates = {
        startDate: formattedStartDate,
        finishDate: formattedFinishDate
      }
      
      fetchDriversMonitoring(drivers, listVehicleByOperation, modelFormattedCutoffDates)
    } catch (error) {
      toast.error("Erro ao carregar motoristas. Entre em contato com o suporte.");
    } finally {
      setLoading(false);
    }
  };
  
  const fetchDriversMonitoring = async (drivers, listVehicleByOperation, modelFormattedCutoffDates) => {
    try {
      const { startDate, finishDate } = modelFormattedCutoffDates
      setLoadingData(true)
      dispatch(setPaginationReducer({ isLoading: true }));
      if (drivers && drivers.length && modelFormattedCutoffDates) {
        const driverList = drivers.map(
          (driver) => driver.id || driver.driverId,
        )
        const updatedPagination = store.getState().driversMonitoring.pagination;
        const response = await getDriversMonitoring(
          driverList,
          listVehicleByOperation,
          startDate,
          finishDate,
          updatedPagination.rowsPerPage,
          updatedPagination.page,
        )
        if (response.status !== 200) {
          throw new Error("Error fetching reward");
        }
        const { driverMonitoring } = response.data
        const driverPerformances = driverMonitoring?.drivers.map((driver) => ({
          ...driver,
          id: driver.driverId,
        }))
        
        dispatch(setPerformancesReducer(driverPerformances))
        dispatch(setPaginationReducer({ isLoading: false, count: response.data.total_items || 0 }))
      } else {
        dispatch(setPerformancesReducer([]))
      }
    } catch (error) {
      toast.error('Erro ao buscar dados. Contate o suporte.')
      console.error(error)
    } finally {
      setLoadingData(false)
    }
  };

  const onChangeFilters = (selectedOption, name) => {
    try {
      setLoading(true)
      dispatch(setFilters({ selectedOption, name }))
      const updatedFilters = store.getState().driversMonitoring.filters;
      if (name === 'year') {
        const isInitialRender = false
        handleCutOffDates(profile, updatedFilters.year, isInitialRender)
        dispatch(setPerformancesReducer(null))
      } else if (!!updatedFilters.operation && !!updatedFilters.cutoffDate) {
        handleDataByOperation(updatedFilters.operation, updatedFilters.cutoffDate);
      }
    } catch (error) {

    } finally {
      setLoading(false)
    }
  };

  const handleTablePagination = (action, tableState) => {
    const updatedFilters = store.getState().driversMonitoring.filters;
    switch (action) {
      case 'changePage':
        dispatch(setPaginationReducer({ isLoading: true, page: tableState.page+1 }))
        handleDataByOperation(updatedFilters.operation, updatedFilters.cutoffDate)
        break;
      case 'changeRowsPerPage':
        dispatch(setPaginationReducer({ isLoading: true, rowsPerPage: tableState.rowsPerPage }))
        handleDataByOperation(updatedFilters.operation, updatedFilters.cutoffDate)
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if(hasPermission({ scopes: ['can_view_drivers_follow'] })) {
      fetchData()
    } 
  }, [fetchData]);
  
  useEffect(() => {
    if (fields.step > 0) {
      dispatch(clearFilters())
    }
  }, [fields, dispatch]);
  
  if ((loading || loadingData) && filters.operation?.id >= 0) return <DriversMonitoringSkeleton />;

  const Filters = () => (
    <Grid container justifyContent="space-around">
      {operationsOptions.lenght === 0 ? (
        <Paper elevation={0} className={classes.center}>
          <Typography>Não foram encontradas operações disponíveis.</Typography>
        </Paper>
      ) : (
        <Paper elevation={0} className={classes.paper}>
          <Select
            name="operation"
            placeholder="Selecione uma operação"
            options={operationsOptions}
            styles={colourStyles}
            onChange={(selectedOption) => onChangeFilters(selectedOption, "operation")}
            value={filters.operation}
            defaultValue={filters.operation}
            components={animatedComponents}
            noOptionsMessage={() => "Nenhum resultado encontrado!"}
          />
        </Paper>
      )}
      <Paper elevation={0} className={classes.paperSelect}>
        <Select
          name="year"
          placeholder="Ano"
          options={yearsOptions.reverse()}
          styles={colourStyles}
          onChange={(selectedOption) => onChangeFilters(selectedOption, "year")}
          value={filters.year}
          defaultValue={filters.year}
          components={animatedComponents}
        />
      </Paper>
      {cutOffDatesOptions === null ? (
        <Paper elevation={0} className={classes.center}>
          <Typography>Não foram encontradas data de corte.</Typography>
        </Paper>
      ) : (
        <Paper elevation={0} className={classes.paper}>
          <Select
            name='cutoffDate'
            placeholder="Selecione a data de corte"
            options={cutOffDatesOptions}
            styles={colourStyles}
            components={animatedComponents}
            onChange={(selectedOption) => onChangeFilters(selectedOption, "cutoffDate")}
            value={JSON.parse(filters.cutoffDate)}
          />
        </Paper>
      )}
    </Grid>
  )

  const columns = makeColumns();

  return (
    <Aux>
      <PermissionsGate scopes={['can_view_drivers_follow']}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.spacingContainer}>
          <Filters />
          {performances === null && <Infobox />}
          {performances?.length === 0 && (
            <NotFoundWithLottie
              lottieFiles={notFound}
              description="Não foram encontradas dados de motoristas."
              strongDescription="Verifique os filtros!"
            />
          )}
          {performances?.length > 0 && (
            <Grid container spacing={3}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography className={classes.followTitle}>
                  Acompanhamento dos Motoristas
                </Typography>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Table
                  columns={columns}
                  data={performances}
                  options={{
                    onRowClick: handleSelectRow,
                    rowsPerPage: pagination.rowsPerPage,
                    serverSide: true,
                    count: pagination.count,
                    onTableChange: handleTablePagination,
                    isLoading: pagination.isLoading,
                    page: pagination.page-1
                  }}
                  pointer={true}
                  tableName="drivers-monitoring"
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </PermissionsGate>
    </Aux>
  );
}
